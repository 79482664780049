import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";
import "./SubMenu.scss";

const SidebarLink = styled(NavLink)`
  display: flex;
  color: #39ace5;
  // background-color: #14395e;
  background-color: #1853a2;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  list-style: none;
  height: 50px;
  text-decoration: none;
  font-size: 13px;

  &:hover {
    // background-color: #f2f6fb;
    color: #fff;
    // margin-top: 3px;
    // margin-bottom: 3px;
  }
  &.active {
    // background-color: #f2f6fb;
    color: #fff;
    // margin-top: 3px;
    // margin-bottom: 3px;
  }
`;

const SidebarLabel = styled.div`
  margin-left: 16px;
  // background-color: #14395e;
  display: flex;
  align-items: center;
  // width: 100%;
  height: 100%;
  margin-top: 3px;
  margin-bottom: 3px;
`;
const SubSidebarLabel = styled.div`
  margin-left: 25px;
`;

const SideBarIcon = styled.div`
  background-color: #1853a2;
  padding: 1rem;
  padding-top: 15px;
  width: 55px;
  height: 100%;
`;

const DropdownLink = styled(NavLink)`
  height: 50px;
  // margin-left: 52px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #f5f5f5;
  font-size: 13px;

  &:hover {
    background-color: #f2f6fb;
    color: #27427a;
    // margin-top: 3px;
    // margin-bottom: 3px;
    // opacity: 39%;
    // width: 200px;
    // height: 50px;
    // border-top-right-radius: 25px;
    // border-bottom-right-radius: 25px;
    // transition: 0.5s;
    // color: #ffffff;
  }
  &.active {
    background-color: #f2f6fb;
    color: #27427a;
    // margin-top: 3px;
    // margin-bottom: 3px;
    // background-color: #000000;
    // opacity: 39%;
    // margin-top: 3px;
    // margin-bottom: 3px;
    // width: 200px;
    // height: 50px;
    // border-top-right-radius: 25px;
    // border-bottom-right-radius: 25px;
    // transition: 0.5s;
    // color: #ffffff;
  }
`;

const SideBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  // width: 100%;
  height: 100%;
`;

const SubMenu = ({
  item,
  hideSideBar,
  isCoach,
  isCoachChecker,
  hrCheckerList,
  managerCheckerList,
  financeCheckerList,
  coachingRequestsList,
  skillLinksMakerList,
  ijpMakerList,
  skillLinksReferralPayoutsList,
  benchList,
  roleManagement
}: {
  item: any;
  hideSideBar: any;
  isCoach: any;
  isCoachChecker: any;
  hrCheckerList: any;
  managerCheckerList: any;
  financeCheckerList: any;
  coachingRequestsList: any;
  skillLinksMakerList: any;
  ijpMakerList: any;
  skillLinksReferralPayoutsList: any;
  benchList: any;
  roleManagement:any;
}) => {
  const [subnav, setSubnav] = useState(false);
  const location = useLocation();
  return (
    <>
      {benchList == true && item.title == "Resource Management" ? (
        <></> 
      ): roleManagement == true && item.title == "Role Management" ? (
        <></>
      ) : (
        <div onClick={() => localStorage.removeItem("filterData")}>
          <SidebarLink
            to={item.path}
            onMouseEnter={(e) => {
              setSubnav(true);
            }}
            onMouseLeave={(e) => {
              setSubnav(false);
            }}
            end
          >
            <SideBarContainer>
              <SideBarIcon>
                {location.pathname === item.path ? item.active_icon : item.icon}
              </SideBarIcon>
              {<SidebarLabel>{item.title}</SidebarLabel>}
            </SideBarContainer>
            {
              <div>
                {item.subNav && subnav
                  ? item.iconOpened
                  : item.subNav
                  ? item.iconClosed
                  : null}
              </div>
            }
          </SidebarLink>
          <div
            className={
              hideSideBar
                ? "submenu-hover-wrapper"
                : "mobile-submenu-hover-container"
            }
            onMouseLeave={(e) => {
              setSubnav(false);
            }}
          >
            {subnav
              ? item?.subNav?.length &&
                item.subNav.map((item: any, index: any) => {
                  return (
                    <React.Fragment key={index}>
                      {item.title == "Be A Coach" &&
                      !isCoach &&
                      !isCoachChecker ? (
                        <></>
                      ) : item.title == "HR Requests" && hrCheckerList ? (
                        <></>
                      ) : item.title == "Manager Requests" &&
                        managerCheckerList ? (
                        <></>
                      ) : item.title == "Finance Requests" &&
                        financeCheckerList ? (
                        <></>
                      ) : item.title == "Coaching Requests" &&
                        coachingRequestsList ? (
                        <></>
                      ) : item.title == "Post a Job" && skillLinksMakerList ? (
                        <></>
                      ) : item.title == "Post a Job to IJP" && ijpMakerList ? (
                        <></>
                      ) : item.title == "Eligible Referral Payouts" &&
                        skillLinksReferralPayoutsList ? (
                        <></>
                      ) : (
                        <div
                          className="submenu-hover"
                          onMouseEnter={(e) => {
                            setSubnav(true);
                          }}
                        >
                          <DropdownLink to={item.path} key={index} end>
                            <SidebarLabel>
                              <SubSidebarLabel>{item.title}</SubSidebarLabel>
                            </SidebarLabel>
                          </DropdownLink>
                        </div>
                      )}
                    </React.Fragment>
                  );
                })
              : null}
          </div>
        </div>
      )}
    </>
  );
};

export default SubMenu;
