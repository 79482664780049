import  {  useRef } from "react";
import moment from "moment";
import { icons } from "../../../../assets";

const PdfModal = (props: any) => {
  const {
    setPreviewPdf,
    pdfData,
    Header,
    printPdf
  } = props;
  const pdfContentRef = useRef(null);
  const generatePdfFunction = () => {
    setPreviewPdf(false);
    printPdf()
  };
  return (
    <>
      <div ref={pdfContentRef} id="pdf-content-profile" className="p-4">
        <div className="skillminelogoSection">
          <img src={Header} alt="no_header_img" className="skillmine_Logo" />
        </div>
        <div className="imageDiv">
          <div>
            <b>Profile Name : </b>
            {pdfData?.candidateName +
              " " +
              pdfData?.middleName +
              " " +
              pdfData?.lastname}

            <div className="pt-2">
              <b>Designation : </b>
              {pdfData?.designation}
            </div>
            <div className="pt-2">
              <b>Experience : </b>
              {pdfData?.totalExperience} year(s)
            </div>
          </div>
          <img
            src={
              pdfData?.candidatePhoto?.document_path_url
                ? pdfData.candidatePhoto.document_path_url
                : pdfData?.candidatePhoto?.candidatePhoto?.document_path_url
                ? pdfData.candidatePhoto.candidatePhoto.document_path_url
                : icons.default_avatar
            }
            alt="icon"
            className="profilePic"
          />
        </div>

        {pdfData?.summary?.length > 0 && (
          <div className="pt-2">
            <b>Profile Summary : </b>
            <ul>
              {pdfData?.summary?.map((ele: any) => {
                return (
                  <li>
                    <p>{ele?.summary}</p>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        {pdfData?.qualifications?.length > 0 && (
          <div className="pt-2">
            <h6>
              <b>Educational Info : </b>
            </h6>
            <div className="table table-responsive">
              <table className="table-main table table-sm mt-3">
                <thead className="table-head">
                  <tr>
                    <th className="table-head-row">Qualification</th>
                    <th className="table-head-row">Branch/tream</th>
                    <th className="table-head-row">Institute/School/College</th>
                    <th className="table-head-row">Board/University</th>
                    <th className="table-head-row">Year of Passing</th>
                    <th className="table-head-row">Percentage / CGPA</th>
                  </tr>
                </thead>
                <tbody className="table-body">
                  {pdfData?.qualifications?.map((ele: any, index: any) => {
                    return (
                      <tr key={index}>
                        <td className="table-body-row">{ele?.qualification}</td>
                        <td className="table-body-row">{ele?.branch}</td>
                        <td className="table-body-row">{ele?.institute}</td>
                        <td className="table-body-row">{ele?.board}</td>
                        <td className="table-body-row">{ele?.year}</td>
                        <td className="table-body-row">{ele?.marks}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {pdfData?.experiences?.length > 0 && (
          <div className="pt-2">
            <h6>
              <b>Employment History : </b>
            </h6>
            <div className="table table-responsive">
              <table className="table-main table table-sm mt-3">
                <thead className="table-head">
                  <tr>
                    <th className="table-head-row">Company Name</th>
                    <th className="table-head-row">Designation</th>
                    <th className="table-head-row">Location</th>
                    <th className="table-head-row">Roles & Responsibility</th>
                    <th className="table-head-row">Date of Joining</th>
                    <th className="table-head-row">Date of Relieving</th>
                  </tr>
                </thead>
                <tbody className="table-body">
                  {pdfData?.experiences?.map((ele: any, index: any) => {
                    return (
                      <tr key={index}>
                        <td className="table-body-row">{ele?.companyName}</td>
                        <td className="table-body-row">{ele?.designation}</td>
                        <td className="table-body-row">{ele?.location}</td>
                        <td className="table-body-row">
                          {ele?.rolesAndResponsibility}
                        </td>
                        <td className="table-body-row">
                          {moment(ele?.startDate).format("DD/MM/YYYY")}
                        </td>
                        <td className="table-body-row">
                          {moment(ele?.endDate).format("DD/MM/YYYY")}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}

      </div>
      <div className="modal-btn-wrapper mt-4 mb-4">
        <div>
          <button
            className="btn cancel-btn buttonsize"
            onClick={ () => setPreviewPdf(false)}
          >
            Cancel
          </button>
        </div>
        <div>
          <button
            className="btn add-submit-button buttonsize"
            onClick={generatePdfFunction}
          >
            Download PDF
          </button>
        </div>
      </div>
    </>
  );
};

export default PdfModal;
