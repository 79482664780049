import { useEffect, useRef, useState } from "react";
import Card from "../../../../component/Card/Card";
import { icons } from "../../../../assets";
import useLoadingIndicator from "../../../../component/Loader/Loader";

import { useDispatch } from "react-redux";
import * as actions from "../../../../Redux/Actions";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../../component/Toasts/Toasts";
import ModalComponents from "../../../../component/ModalComponent/ModalComponent";
import EditProfileSummary from "../../../Profile/PersonalInfo/EditProfileSummary/EditProfileSummary";
import Button from "../../../../component/Buttons/Button";
import { webUrl } from "../../../../config/constants";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import moment from "moment";
import PdfModal from "../../../Profile/PersonalInfo/PdfModal/PdfModal";
import Header from "../../../../assets/SkillmineLogoPng.png";
import { Strings } from "../../../../constant/Strings";
const ProfileInfo = (props: any) => {
  const {
    summary,
    getApiDispatch,
    emailId,
    candidateName,
    lastName,
    middleName,
    eId,
    status,
    resume,
    profileInfoDisplayCondition,
    employeeId,
    profileImage,
    designation
  } = props;
  const {  clickUpload,
    dragDrop,
    fileTypeSize,ProfileSummary,uploadValidation,update_resume,ViewResume,
    cancel,Send,Content,employmentHistory,EmailId,CompanyName,Designation,
    Location,Roles,Joining,Relieving,EducationalInfo,Qualification,BranchStream,
    Institute,Board,YearOfPassing,Percentage,ProfileSummaryLabel,Experience,
    DesignationLabel,ProfileName,SkillmineResume,EmployeeResume} = Strings;
  const role = localStorage.getItem("role");
  const userLogin: any = localStorage.getItem("login");
  const loginId = JSON.parse(userLogin)?.id;
  const [editProfileSummary, setEditProfileSummary] = useState(false);
  const [showReminder, setShowReminder] = useState(false);
  const {
    loadingComponent,
    showLoadingIndicator,
    hideLoadingIndicator,
    apiCallCompleted,
  } = useLoadingIndicator();
  const dispatch = useDispatch();
  const [docFile, setDocFile] = useState<any>({
    data: null,
  });
  const [pData, setPData] = useState(false);
  const [pdfData, setPdfData] = useState<any>();
  const [previewPdf, setPreviewPdf] = useState(false);
  const pdfContentRef = useRef(null);
  const [employeeResumeModal, setEmployeeResumeModal] = useState(false)
  const { default_avatar, edit_orange_icon, upload_icon } = icons;
  const handleProfileSummary = () => {
    setEditProfileSummary(!editProfileSummary);
  };

  const handleRemider = () => {
    const obj = {
      to: emailId,
      htmlContent: `<html><body>
      <b>THIS IS AN AUTOGENERATED EMAIL. PLEASE DO NOT REPLY.</b><br><br>
      Dear ${candidateName} ${middleName} ${lastName} - ${employeeId},<br><br>Please treat this as a gentle reminder to complete your Profile Summary in Spotlight Portal which is a mandatory requirement for all employees within Skillmine. This activity is an important part of employee documentation process and must be completed without further delay.
      <br><br>Please note that it is mandatory for all to fill out the Five Bullet Points (Update your top 5 skills and areas of expertise) provided in the Profile Summary and To assist you in this process, Click <a href="${webUrl}/employeeInfo/${eId}"> here</a> to redirect to Profile Info/Profile Summary Page. Kindly note there are five mandatory bullet point which requires to be filled.<br><br>
      <b>Note: Please click on submit after entering 5 mandatory summary points.</b><br><br>
      If you have any question or face any issues, please feel free to write Email ID
      <a href="mailto:ruchita.rao@skill-mine.com">ruchita.rao@skill-mine.com</a> , <a href="mailto:shilpa.shree@skill-mine.com">shilpa.shree@skill-mine.com</a> , <a href="mailto:sruthi.chintalapati@skill-mine.com">sruthi.chintalapati@skill-mine.com</a><br><br>
      Thank you for your prompt attention to this matter.
      <br><br>Best Regards,<br>SMTC HR</body></html>`,
    };
    dispatch(
      actions.AddProfileReminder(obj, (res: any) => {
        if (res?.error?.error_description) {
          showErrorToastMessage(res?.error?.error_description);
        } else if (res?.code === 200 && res?.data ) {
            hideLoadingIndicator();
              showSuccessToastMessage("Reminder Sent Successfully");
        }
        getApiDispatch();
      })
    );
  };
  const disableCondition: any =
    role &&
    role.includes("employee-db-checker") &&
    eId != loginId &&
    status != "Inactive";

  const getResumeApiDispatch = (e: any) => {
    e.preventDefault();
    dispatch(
      actions.generateResumeDataAction((res: any) => {
        if (res?.error?.error_description) {
          hideLoadingIndicator();
          showErrorToastMessage(res.error.error_description);
        } else if (res?.code === 200) {
          setPdfData(res.data[0]);
          setPreviewPdf(true)
          hideLoadingIndicator();
        }
      })
    );
  };
  const printPdf = () => {
    setPData(!pData);
    const pdfContentDiv: any = document.getElementById("pdf-content-profile");
    if (pdfContentDiv) {
      showLoadingIndicator();
      pdfContentDiv.style.display = "block";
    }
  };
  useEffect(() => {
    const loadImage = (src: string) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = src;
        img.onload = () => resolve(img);
        img.onerror = (err) => reject(err);
      });
    };

    const loadImages = async () => {
      const images = Array.from(
        document.querySelectorAll<HTMLImageElement>("#pdf-content-profile img")
      );
      const promises = images.map((img) => loadImage(img.src));
      await Promise.all(promises);
    };
    const generatePdf = async () => {
      const pdfContentDiv = document.getElementById("pdf-content-profile");
      if (pdfContentDiv) {
        if (pdfContentDiv.style.display === "block") {
          await loadImages();
          const pdfDocument = new jsPDF();
          const canvas = await html2canvas(pdfContentDiv, {
            useCORS: true,
            allowTaint: true,
          });

          const imgData = canvas.toDataURL("image/png");
          const imgProps = pdfDocument.getImageProperties(imgData);
          const pdfWidth = pdfDocument.internal.pageSize.getWidth() - 20;
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

          pdfDocument.addImage(imgData, "PNG", 10, 10, pdfWidth, pdfHeight);
          pdfDocument.save(
            `${employeeId + "_" + candidateName + "_"}Resume.pdf`
          );
          pdfContentDiv.style.display = "none";
          hideLoadingIndicator();
        }
      }
    };

    setTimeout(generatePdf, 2000);
  }, [pData])

  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleSubmitResume = (e: any) => {
    const file = e.target.files[0];
    const fileSize = file.size;
    const fileType = file.type;
    if (fileSize > 5 * 1024 * 1024) {
      showErrorToastMessage("File size too large. Maximum size is 5 MB.");
      return;
    } else if (!["application/pdf"].includes(fileType)) {
      showErrorToastMessage("File type not allowed. Allowed type is PDF.");
      return;
    } else {
      setDocFile({ ...docFile, resume: e.target.files[0] });
      dispatch(
        actions.employeeDetailsDocUploadAction(
          { file: e.target.files[0], eId: eId },
          (res: any) => {
            if (res.code == 200 && res.data) {
              showSuccessToastMessage("Resume Added Successfully!");

              dispatch(
                actions.editEmployeeDataAction(
                  { resume: res.data, updatedBy: loginId, eId: eId },
                  (res: any) => { }
                )
              );
              setEmployeeResumeModal(false)
            }
          }
        )
      );
    }
  };
  const veiwResume = () => {
    if (resume?.document_path_url) {
      window.open(resume?.document_path_url, "_blank")
    } else {
      showErrorToastMessage("Resume does not exist!! Please upload the resume to view.")
    }
  }
  const handleSubmitImage = (e: any) => {
    const file = e.target.files[0];
    const fileSize = file.size;
    const fileType = file.type;
    if (fileSize > 5 * 1024 * 1024) {
      showErrorToastMessage("File size too large. Maximum size is 5 MB.");
      return;
    } else if (!["image/jpeg", "image/png", "image/gif"].includes(fileType)) {
      showErrorToastMessage(
        "File type not allowed. Allowed types are JPEG, PNG and GIF"
      );
      return;
    } else {
      showLoadingIndicator();
      dispatch(
        actions.employeeDetailsImageUploadAction(
          { file: e.target.files[0], eId: eId },
          (res: any) => {
            if (res.code == 200 && res.data) {
              dispatch(
                actions.editEmployeeDataAction(
                  { candidatePhoto: res.data, updatedBy: eId, eId: eId },
                  (res: any) => {
                    if (res.code == 200 && res.data) {
                      hideLoadingIndicator();
                      showSuccessToastMessage("Picture Added Successfully!");
                    }
                  }
                )
              );
            }
          }
        )
      );
    }
  };

  return (
    <>
      {loadingComponent()}
      <div>
        <Card>
          <div className="personal__info-container">
            <div className="personal__info-profile-details">
              <img
                src={profileImage ? profileImage : default_avatar}
                alt="icon"
              />
              <div>
                <p>{`${candidateName} ${middleName} ${lastName}`}</p>
                <div className="profile-position">{designation}</div>
                <div className="profile-position">{emailId}</div>
              </div>
            </div>

            <div className="upload-wrapper">
              <div>
                <img src={upload_icon} alt="icon" />
                <p>
                  <b>{clickUpload}</b> {dragDrop} <br />
                  {fileTypeSize}
                </p>
              </div>
              <input type="file" value={""} onChange={handleSubmitImage} />
            </div>
          </div>
        </Card>
        <Card>
          <div className="edit-icon-wrapper">
            <h5>
              {ProfileSummary}<span className="required">*</span>
            </h5>
          </div>
          <div className="profileBodysection">
            {summary?.length > 0 ? (
              <ul>
                {summary?.map((ele: any,index:any) => {
                  return <li key={index}>{ele?.summary}</li>;
                })}
              </ul>
            ) : (
              <>
                {" "}
                {disableCondition && (
                  <div className="col-auto">
                    <Button
                      title="Send Reminder"
                      className="reminder-checker-outline-btn"
                      onClick={() => {
                        setShowReminder(true);
                      }}
                    ></Button>
                  </div>
                )}
              </>
            )}
          </div>
        </Card>
        <Card>
          <div className="personalinfo-resume-section">
            <button className="resume-button" onClick={() => setEmployeeResumeModal(true)}>
              {EmployeeResume}
            </button>
            {profileInfoDisplayCondition && (
              <button className="resume-button" onClick={(e) =>
                getResumeApiDispatch(e)
              }>
                {SkillmineResume}
              </button>
            )}

          </div>
        </Card>
      </div>
      <div
        ref={pdfContentRef}
        id="pdf-content-profile"
        style={{ display: "none" }}
      >
        <div className="skillminelogoSection">
          <img src={Header} alt="no_header_img" className="skillmine_Logo" />
        </div>
        <div className="imageDiv">
          <div>
            <b>{ProfileName}</b>
            {pdfData?.candidateName +
              " " +
              pdfData?.middleName +
              " " +
              pdfData?.lastname}

            <div className="pt-2">
              <b>{DesignationLabel}</b>
              {pdfData?.designation}
            </div>
            <div className="pt-2">
              <b>{Experience}</b>
              {pdfData?.totalExperience} year(s)
            </div>
          </div>
          <img
            src={
              pdfData?.candidatePhoto?.document_path_url
                ? pdfData.candidatePhoto.document_path_url
                : pdfData?.candidatePhoto?.candidatePhoto?.document_path_url
                  ? pdfData.candidatePhoto.candidatePhoto.document_path_url
                  : icons.default_avatar
            }
            alt="icon"
            className="profilePic"
          />
        </div>
        {pdfData?.summary?.length > 0 && (
          <div className="pt-2">
            <h4>
              <b>{ProfileSummaryLabel}</b>
            </h4>
            <ul>
              {pdfData?.summary?.map((ele: any,index:any) => {
                return (
                  <li key={index}>
                    <p>
                      <b>{ele?.summary}</b>
                    </p>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        {pdfData?.qualifications?.length > 0 && (
          <div className="pt-2">
            <h4>
              <b>{EducationalInfo}</b>
            </h4>
            <div className="table table-responsive">
              <table className="table-main table table-sm mt-3">
                <thead className="table-head">
                  <tr>
                    <th className="table-head-row">{Qualification}</th>
                    <th className="table-head-row">{BranchStream}</th>
                    <th className="table-head-row">
                      {Institute}
                    </th>
                    <th className="table-head-row">{Board}</th>
                    <th className="table-head-row">{YearOfPassing}</th>
                    <th className="table-head-row">{Percentage}</th>
                  </tr>
                </thead>
                <tbody className="table-body">
                  {pdfData?.qualifications?.map((ele: any, index: any) => {
                    return (
                      <tr key={index}>
                        <td className="table-body-row">{ele?.qualification}</td>
                        <td className="table-body-row">{ele?.branch}</td>
                        <td className="table-body-row">{ele?.institute}</td>
                        <td className="table-body-row">{ele?.board}</td>
                        <td className="table-body-row">{ele?.year}</td>
                        <td className="table-body-row">{ele?.marks}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {pdfData?.experiences?.length > 0 && (
          <div className="pt-2">
            <h4>
              <b>{employmentHistory}</b>
            </h4>
            <div className="table table-responsive">
              <table className="table-main table table-sm mt-3">
                <thead className="table-head">
                  <tr>
                    <th className="table-head-row">{CompanyName}</th>
                    <th className="table-head-row">{Designation}</th>
                    <th className="table-head-row">{Location}</th>
                    <th className="table-head-row">{Roles}</th>
                    <th className="table-head-row">{Joining}</th>
                    <th className="table-head-row">{Relieving}</th>
                  </tr>
                </thead>
                <tbody className="table-body">
                  {pdfData?.experiences?.map((ele: any, index: any) => {
                    return (
                      <tr key={index}>
                        <td className="table-body-row">{ele?.companyName}</td>
                        <td className="table-body-row">{ele?.designation}</td>
                        <td className="table-body-row">{ele?.location}</td>
                        <td className="table-body-row">
                          {ele?.rolesAndResponsibility}
                        </td>
                        <td className="table-body-row">
                          {moment(ele?.startDate).format("DD/MM/YYYY")}
                        </td>
                        <td className="table-body-row">
                          {moment(ele?.endDate).format("DD/MM/YYYY")}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}

      </div>
      <ModalComponents
        show={editProfileSummary}
        onHide={handleProfileSummary}
        title="Edit Profile Summary"
        modalBody={
          <EditProfileSummary
            handleProfileSummary={handleProfileSummary}
            apiCallCompleted={apiCallCompleted}
            summary={summary}
            getApiDispatch={getApiDispatch}
          />
        }
        className="modal-lg"
      />

      <ModalComponents
        show={showReminder}
        onHide={() => setShowReminder(false)}
        title="Send Reminder"
        modalBody={
          <div className="reminderSection">
            <div>
              <label className="form-label">
               {EmailId}<span className="required">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                value={emailId ?? ""}
                disabled
              />
            </div>
            <div>
              <label className="form-label">
              {Content}<span className="required">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                value="Please Fill Your Profile Summary Details"
                disabled
              />
            </div>
            <div className="modal-btn-wrapper mt-5 mx-auto">
              <div>
                <button
                  className="btn reminder-cancel-btn buttonsize"
                  onClick={() => setShowReminder(false)}
                >
                  {cancel}
                </button>
              </div>
              <div>
                <button
                  className="btn reminder-add-submit-button buttonsize"
                  onClick={() => {
                    setShowReminder(false);
                    handleRemider();
                  }}
                >
                  {Send}
                </button>
              </div>
            </div>
          </div>
        }
        className="modal-lg"
      />
      <ModalComponents
        show={previewPdf}
        onHide={() => setPreviewPdf(false)}
        title="Profile Info Preview"
        modalBody={
          <PdfModal
            setPreviewPdf={setPreviewPdf}
            pdfData={pdfData}
            Header={Header}
            printPdf={printPdf}
          />
        }
        className="modal-lg"
      />
      <ModalComponents
        show={employeeResumeModal}
        onHide={() => setEmployeeResumeModal(false)}
        title="Employee Resume"
        modalBody={
          <div className="modalResumeSection profile-width">
            {resume?.document_path_url &&
              <button className="modal-resume-button" onClick={veiwResume}>
               {ViewResume}
              </button>
            }
            {!resume?.document_path_url &&
            <div>
              <div className="modal-profile-section">
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleSubmitResume}
                />
                <button className="modal-resume-button" onClick={handleButtonClick}>
                  {update_resume}
                </button>
                
              </div>
              <div className="profileError">{uploadValidation}</div>
              </div>
            }
          </div>
        }
        className="modal-lg"
      />
    </>
  );
};

export default ProfileInfo;
