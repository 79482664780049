import  { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as actions from "../../../../Redux/Actions/index";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../../component/Toasts/Toasts";
import { Strings, requiredFeild } from "../../../../constant/Strings/index";

const AddProjectUpload = (props: any) => {
  const { setnextScreen, apiCallCompleted, projectDetails } = props;
  const {
    cancel,
    submit,
    uploadPoDoc,
    uploadFile,
    imageSize,
    uploadMsa,
    uploadSow,
  } = Strings;
  const [btnDisable, setBtnDisable] = useState(false);

  const dispatch = useDispatch();
  const [docFile, setDocFile] = useState<any>({
    uploadPoNumber: null,
    msa: null,
    sow: null,
  });

  const [projectData, setProjectData] = useState<any>({
    uploadPoNumber: "",
  });

  const projectId: any = localStorage.getItem("projectId");

  const handleSubmit = () => {
    apiCallCompleted(false);
    dispatch(
      actions.editProjectRepositoryAction(
        projectId,
        { ...projectData, ...projectDetails },
        (res: any) => {
              if (res?.error?.error_description) {
                showErrorToastMessage(res.error.error_description);
                apiCallCompleted(true);
              } else if (res.code === 200) {
                apiCallCompleted(true);
                showSuccessToastMessage("Documents Uploaded Successfully!");
              }
        }
      )
    );
    setnextScreen(false);
    localStorage.removeItem("projectId");
  };

  useEffect(() => {
    if (projectData.uploadPoNumber) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  });

  const handleSubmitPoDocumentImage = (e: any) => {
    const file = e.target.files[0];
    const fileSize = file.size;
    const fileType = file.type;
    if (fileSize > 20 * 1024 * 1024) {
      showErrorToastMessage("File size too large. Maximum size is 20 MB.");
      return;
    } else if (
      ![
        "image/jpeg",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/pdf",
      ].includes(fileType)
    ) {
      showErrorToastMessage(
        "File type not allowed. Allowed types are JPEG, DOC, and PDF."
      );
      return;
    } else {
      setDocFile({ ...docFile, uploadPoNumber: e.target.files[0] });
      dispatch(
        actions.projectDetailsDocUploadAction(
          { file: e.target.files[0] },
          (res: any) => {
            if (res.code == 200 && res.data) {
              setProjectData({
                ...projectData,
                uploadPoNumber: res.data,
              });
            }
          }
        )
      );
    }
  };

  const handleSubmitMsaImage = (e: any) => {
    const file = e.target.files[0];
    const fileSize = file.size;
    const fileType = file.type;
    if (fileSize > 20 * 1024 * 1024) {
      showErrorToastMessage("File size too large. Maximum size is 20 MB.");
      return;
    } else if (
      ![
        "image/jpeg",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/pdf",
      ].includes(fileType)
    ) {
      showErrorToastMessage(
        "File type not allowed. Allowed types are JPEG, DOC, and PDF."
      );
      return;
    } else {
      setDocFile({ ...docFile, msa: e.target.files[0] });
      dispatch(
        actions.projectDetailsDocUploadAction(
          { file: e.target.files[0] },
          (res: any) => {
            if (res.code == 200 && res.data) {
              setProjectData({
                ...projectData,
                msa: res.data,
              });
            }
          }
        )
      );
    }
  };
  const handleSubmitSowImage = (e: any) => {
    const file = e.target.files[0];
    const fileSize = file.size;
    const fileType = file.type;
    if (fileSize > 20 * 1024 * 1024) {
      showErrorToastMessage("File size too large. Maximum size is 20 MB.");
      return;
    } else if (
      ![
        "image/jpeg",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/pdf",
      ].includes(fileType)
    ) {
      showErrorToastMessage(
        "File type not allowed. Allowed types are JPEG, DOC, and PDF."
      );
      return;
    } else {
      setDocFile({ ...docFile, sow: e.target.files[0] });
      dispatch(
        actions.projectDetailsDocUploadAction(
          { file: e.target.files[0] },
          (res: any) => {
            if (res.code == 200 && res.data) {
              setProjectData({
                ...projectData,
                sow: res.data,
              });
            }
          }
        )
      );
    }
  };

  return (
    <>
      <div className="row g-3">
        <div className="col-md-4">
          <label className="form-label">{uploadPoDoc}</label>

          <div>
            <input
              type="file"
              className="form-control"
              onChange={handleSubmitPoDocumentImage}
              hidden
              id="actual-btn-po"
            />
            <label
              htmlFor="actual-btn-po"
              className="btn btn-outline-secondary buttonsize"
            >
              {uploadFile}
            </label>
          </div>
          <label className="form-label">
            {imageSize}
            <span className="required">{requiredFeild.required}</span>
          </label>
          <a
            className="buttonsize"
            href={projectData.uploadPoNumber?.document_path_url}
            target="_blank"
          >
            {projectData.uploadPoNumber?.document_path_url ? "View File" : ""}
          </a>
          <p className="image-size-condition">{docFile.uploadPoNumber?.name}</p>
        </div>

        <div className="col-md-4">
          <label className="form-label">{uploadMsa}</label>
          <div>
            <input
              type="file"
              className="form-control"
              onChange={handleSubmitMsaImage}
              hidden
              id="actual-btn-msa"
            />
            <label
              htmlFor="actual-btn-msa"
              className="btn btn-outline-secondary buttonsize"
            >
              {uploadFile}
            </label>
          </div>
          <label className="form-label">
            {imageSize}
            <span className="required">{requiredFeild.required}</span>
          </label>
          <a
            className="buttonsize"
            href={projectData.msa?.document_path_url}
            target="_blank"
          >
            {projectData.msa?.document_path_url ? "View File" : ""}
          </a>
          <p className="image-size-condition">{docFile.msa?.name}</p>
        </div>

        <div className="col-md-4">
          <label className="form-label">{uploadSow}</label>
          <div>
            <input
              type="file"
              className="form-control"
              onChange={handleSubmitSowImage}
              hidden
              id="actual-btn-sow"
            />
            <label
              htmlFor="actual-btn-sow"
              className="btn btn-outline-secondary buttonsize"
            >
              {uploadFile}
            </label>
          </div>
          <label className="form-label">
            {imageSize}
            <span className="required">{requiredFeild.required}</span>
          </label>
          <a
            className="buttonsize"
            href={projectData.sow?.document_path_url}
            target="_blank"
          >
            {projectData.sow?.document_path_url ? "View File" : ""}
          </a>
          <p className="image-size-condition">{docFile.sow?.name}</p>
        </div>
      </div>

      <div className="modal-btn-wrapper mt-5 mx-auto">
        <div>
          <button
            className="btn cancel-btn buttonsize"
            onClick={() => setnextScreen(false)}
          >
            {cancel}
          </button>
        </div>
        <div>
          <button
            className={
              btnDisable
                ? "btn add-submit-button buttonsize"
                : "btn add-submit-button btn-disabled buttonsize"
            }
            onClick={handleSubmit}
          >
            {submit}
          </button>
        </div>
      </div>
    </>
  );
};

export default AddProjectUpload;
