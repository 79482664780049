import { useEffect } from "react";
import InseedReportTable from "./InseedReportTable";
import InseedStaticsComponent from "./InseedStaticsComponent";
import MainCard from "../../../component/MainCard/MainCard";
import hanldeUnauthorizedUser from "../../../component/AccessConditions/UnauthorizedUser";

const InseedReports = () => {
  const { unAuthorizeUser } = hanldeUnauthorizedUser();
  useEffect(() => {
    unAuthorizeUser();
  }, []);

  return (
    <div>
      <MainCard heading="Inseed Reports">
        <>
          <InseedStaticsComponent />
          <InseedReportTable />
        </>
      </MainCard>
    </div>
  );
};

export default InseedReports;
