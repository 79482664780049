import { useState, useEffect } from "react";
import { Table } from "reactstrap";
import * as actions from "./../../../Redux/Actions";
import useLoadingIndicator from "../../../component/Loader/Loader";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import "./EducationalInfo.scss";
import {
  Strings,
  certficationsTableHeader,
  educationalTableHeader,
  requiredFeild,
} from "../../../constant/Strings";
import Button from "../../../component/Buttons/Button";
import ModalComponents from "../../../component/ModalComponent/ModalComponent";
import AddQualification from "../../EmployeeDb/EmployeeDetails/DbSideBarPages/Qualification/AddQualification";
import { icons } from "../../../assets";
import DeleteQualification from "../../EmployeeDb/EmployeeDetails/DbSideBarPages/Qualification/DeleteQualification";
import EditQualification from "../../EmployeeDb/EmployeeDetails/DbSideBarPages/Qualification/EditQualification";
import AddCertifications from "../../EmployeeDb/EmployeeDetails/DbSideBarPages/Certifications/AddCertifications";
import DeleteCertifications from "../../EmployeeDb/EmployeeDetails/DbSideBarPages/Certifications/DeleteCertifications";
import EditCertifications from "../../EmployeeDb/EmployeeDetails/DbSideBarPages/Certifications/EditCertifications";

const EducationalInfo = (props: any) => {
  const { status } = props;
  const userLogin: any = localStorage.getItem("login");
  const loginId = JSON.parse(userLogin)?.id;
  const role = localStorage.getItem("role");
  const selectedId = localStorage.getItem("selectedId");
  const [info, setInfo] = useState([]);
  const dispatchQualifications = useDispatch();
  const { loadingComponent, showLoadingIndicator, hideLoadingIndicator } =
    useLoadingIndicator();
  const [qualifications, setQualifications] = useState({
    id: "",
    qualification: "",
    branch: "",
    institute: "",
    board: "",
    year: "",
    marks: "",
    qualificationImage: "",
  });
  const { educational_information_heading, certficationsTitle } = Strings;
  const { edit_blue_icon, delete_icon } = icons;
  const { required } = requiredFeild;
  const [deleteId, setDeleteId] = useState("");
  const [showAddQualifications, setShowAddQualifications] = useState(false);

  const handleAddQualifications = () => {
    setShowAddQualifications(true);
  };
  const hideAddQualifications = () => {
    setShowAddQualifications(false);
  };
  const [showEditQualifications, setShowEditQualifications] = useState(false);
  const [showDeleteQualifications, setShowDeleteQualifications] =
    useState(false);
  const handleEditQualifications = (
    id: any,
    qualification: any,
    branch: string,
    institute: string,
    board: string,
    year: string,
    marks: any,
    qualificationImage: any
  ) => {
    setQualifications({
      id: id,
      qualification: qualification,
      branch: branch,
      institute: institute,
      board: board,
      year: year,
      marks: marks,
      qualificationImage: qualificationImage,
    });
    setShowEditQualifications(true);
  };
  const addEmployeeQualification = useSelector(
    (state: any) => state.employeeReducer.addEmployeeQualification
  );
  const deleteEmployeeQualification = useSelector(
    (state: any) => state.employeeReducer.deleteEmployeeQualification
  );
  const editEmployeeQualification = useSelector(
    (state: any) => state.employeeReducer.editEmployeeQualification
  );
  useEffect(() => {
    showLoadingIndicator();
    dispatchQualifications(actions.getEmployeeQualificationAction());
    setTimeout(() => {
      hideLoadingIndicator();
    }, 1000);
  }, [
    addEmployeeQualification,
    deleteEmployeeQualification,
    editEmployeeQualification,
    selectedId,
  ]);
  const hideEditQualifications = () => {
    setShowEditQualifications(false);
  };

  const handleDeleteQualifications = (id: any) => {
    setDeleteId(id);
    setShowDeleteQualifications(true);
  };
  const hideDeleteQualifications = () => {
    setShowDeleteQualifications(false);
  };

  const employeeQualification = useSelector(
    (state: any) => state.employeeReducer.getEmployeeQualification
  );

  useEffect(() => {
    if (employeeQualification) {
      if (employeeQualification.data) {
        setInfo(employeeQualification.data);
      }
    }
  }, [employeeQualification, info, selectedId]);

  const [infoCertifications, setInfoCertifications] = useState([]);
  const [certifications, setCertifications] = useState({
    id: "",
    nameOfCertification: "",
    institute: "",
    board: "",
    year: "",
    marks: "",
  });
  const [deleteIdCertifications, setDeleteIdCertifications] = useState("");

  const [showAddCertifications, setShowAddCertifications] = useState(false);
  const handleAddCertifications = () => {
    setShowAddCertifications(true);
  };
  const hideAddCertifictions = () => {
    setShowAddCertifications(false);
  };

  const [showEditCertifications, setShowEditCertifications] = useState(false);
  const handleEditCertifications = (
    id: string,
    nameOfCertification: any,
    institute: any,
    board: any,
    year: string,
    marks: string
  ) => {
    setCertifications({
      id: id,
      nameOfCertification: nameOfCertification,
      institute: institute,
      board: board,
      year: year,
      marks: marks,
    });
    setShowEditCertifications(true);
  };
  const hideEditCertifications = () => {
    setShowEditCertifications(false);
  };

  const [showDeleteCertifications, setShowDeleteCertifications] =
    useState(false);
  const handleDeleteCertifications = (id: any) => {
    setDeleteIdCertifications(id);
    setShowDeleteCertifications(true);
  };
  const hideDeleteCertifications = () => {
    setShowDeleteCertifications(false);
  };
  const addEmployeeCertifications = useSelector(
    (state: any) => state.employeeReducer.addEmployeeCertifications
  );
  const editEmployeeCertifications = useSelector(
    (state: any) => state.employeeReducer.editEmployeeCertifications
  );
  const deleteEmployeeCertifications = useSelector(
    (state: any) => state.employeeReducer.deleteEmployeeCertifications
  );

  const dispatchCertifications = useDispatch();
  useEffect(() => {
    showLoadingIndicator();
    dispatchCertifications(actions.getEmployeeCertificationsAction());
    setTimeout(() => {
      hideLoadingIndicator();
    }, 1000);
  }, [
    addEmployeeCertifications,
    editEmployeeCertifications,
    deleteEmployeeCertifications,
    selectedId,
  ]);

  const employeeCertifications = useSelector(
    (state: any) => state.employeeReducer.getEmployeeCertifications
  );

  useEffect(() => {
    if (employeeCertifications) {
      if (employeeCertifications.data) {
        setInfoCertifications(employeeCertifications.data);
      }
    }
  }, [employeeCertifications, infoCertifications]);
  return (
    <>
      {loadingComponent()}
      <div className="educational-container">
        <div className="heading-button-wrapper">
          <div>
            <h5>
              {educational_information_heading}
              <span className="required">{required}</span>
            </h5>
          </div>
          {(status !== "Active" && status !== "Inactive") ||
            (role &&
              role.includes("hr") &&
              status === "Active" &&
              selectedId != loginId) ? (
            <div className="btn-wrapper">
              <Button
                title="+ Add"
                onClick={handleAddQualifications}
                className="add-employee-btn add-employee-details-skills"
              />
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="card-div-container">
          <Table className="table-conatiner" cellSpacing={2} cellPadding={20}>
            <thead className="table-head">
              <tr>
                {educationalTableHeader.map((item, key) => (
                  <th className="table-th" key={key}>
                    {item}
                  </th>
                ))}
                {(status !== "Active" && status !== "Inactive") ||
                  (role &&
                    role.includes("hr") &&
                    status === "Active" &&
                    selectedId != loginId) ? (
                  <th className="table-th">Action</th>
                ) : null}
              </tr>
            </thead>

            <tbody className="table-body">
              {info.map((ele: any, key) => {
                return (
                  <tr key={key}>
                    <td className="table-td">{ele.qualification}</td>
                    <td className="table-td">{ele.branch}</td>
                    <td className="table-td">{ele.institute}</td>
                    <td className="table-td">{ele.board}</td>
                    <td className="table-td">{ele.year}</td>
                    <td className="table-td">{ele.marks}</td>
                    {(status !== "Active" && status !== "Inactive") ||
                      (role &&
                        role.includes("hr") &&
                        status === "Active" &&
                        selectedId != loginId) ? (
                      <>
                        <td className="table-td">
                          <div className="text-center">
                            <img
                              src={edit_blue_icon}
                              alt="edit_icon"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Edit"
                              onClick={() =>
                                handleEditQualifications(
                                  ele.id,
                                  ele.qualification,
                                  ele.branch,
                                  ele.institute,
                                  ele.board,
                                  ele.year,
                                  ele.marks,
                                  ele.qualificationImage
                                )
                              }
                              className="edit-icon"
                            />
                            <img
                              src={delete_icon}
                              alt="delete-icon"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Delete"
                              onClick={() => handleDeleteQualifications(ele.id)}
                              className="edit-icon"
                            />
                          </div>
                        </td>
                      </>
                    ) : (
                      <></>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>

        <div className="heading-button-wrapper">
          <div>
            <h5>{certficationsTitle}</h5>
          </div>
          {(status !== "Active" && status !== "Inactive") ||
            (role &&
              role.includes("hr") &&
              status === "Active" &&
              selectedId != loginId) ? (
            <div className="btn-wrapper">
              <Button
                title="+ Add"
                onClick={handleAddCertifications}
                className="add-employee-btn add-employee-details-skills"
              />
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="card-div-container">
          <Table className="table-conatiner" cellSpacing={2} cellPadding={20}>
            <thead className="table-head">
              <tr className="table-head">
                {certficationsTableHeader.map((ele: any) => (
                  <td className="table-td" key={ele}>
                    {ele}
                  </td>
                ))}

                {(status !== "Active" && status !== "Inactive") ||
                  (role &&
                    role.includes("hr") &&
                    status === "Active" &&
                    selectedId != loginId) ? (
                  <>
                    <td className="table-td">Action</td>
                  </>
                ) : (
                  <>{""}</>
                )}
              </tr>
            </thead>
            <tbody className="table-body">
              {infoCertifications.length > 0 &&
                infoCertifications.map((ele: any) => (
                  <tr key={ele.id}>
                    <td className="table-td">{ele.nameOfCertification}</td>
                    <td className="table-td">{ele.institute}</td>
                    <td className="table-td">{ele.board}</td>
                    <td className="table-td">{ele.year}</td>
                    <td className="table-td">{ele.marks}</td>
                    {(status !== "Active" && status !== "Inactive") ||
                      (role &&
                        role.includes("hr") &&
                        status === "Active" &&
                        selectedId != loginId) ? (
                      <>
                        <td className="table-td">
                          <div className="action-btn">
                            <img
                              src={icons.edit_blue_icon}
                              alt="edit_icon"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Edit"
                              onClick={() =>
                                handleEditCertifications(
                                  ele.id,
                                  ele.nameOfCertification,
                                  ele.institute,
                                  ele.board,
                                  ele.year,
                                  ele.marks
                                )
                              }
                              className="edit-icon"
                            />
                            <img
                              src={icons.delete_icon}
                              alt="delete-icon"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Delete"
                              onClick={() => handleDeleteCertifications(ele.id)}
                              className="edit-icon"
                            />
                          </div>
                        </td>
                      </>
                    ) : (
                      <></>
                    )}
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      </div>

      <div>
        <ModalComponents
          show={showAddCertifications}
          onHide={hideAddCertifictions}
          title="Add Certification & Credentials"
          modalBody={
            <AddCertifications
              showAddCertifications={handleAddCertifications}
              hideAddCertifictions={hideAddCertifictions}
            />
          }
          className="modal-lg"
        />

        <ModalComponents
          show={showEditCertifications}
          onHide={hideEditCertifications}
          title="Edit Certification & Credentials"
          modalBody={
            <EditCertifications
              id={certifications.id}
              nameOfCertification={certifications.nameOfCertification}
              institute={certifications.institute}
              board={certifications.board}
              year={certifications.year}
              marks={certifications.marks}
              hideEditCertifications={hideEditCertifications}
            />
          }
          className="modal-lg"
        />

        <ModalComponents
          show={showDeleteCertifications}
          onHide={hideDeleteCertifications}
          title="Delete Certification & Credentials"
          modalBody={
            <DeleteCertifications
              id={deleteIdCertifications}
              hideDeleteCertifications={hideDeleteCertifications}
            />
          }
          className="modal-md"
        />
      </div>

      <ModalComponents
        show={showAddQualifications}
        onHide={hideAddQualifications}
        title="Add Qualification"
        modalBody={
          <AddQualification
            showAddQualifications={handleAddQualifications}
            hideAddQualifications={hideAddQualifications}
          />
        }
        className="modal-lg"
      />

      <ModalComponents
        show={showEditQualifications}
        onHide={hideEditQualifications}
        title="Edit Qualification"
        modalBody={
          <EditQualification
            id={qualifications.id}
            qualification={qualifications.qualification}
            branch={qualifications.branch}
            institute={qualifications.institute}
            board={qualifications.board}
            year={qualifications.year}
            marks={qualifications.marks}
            qualificationImage={qualifications?.qualificationImage}
            hideEditQualifications={hideEditQualifications}
          />
        }
        className="modal-lg"
      />

      <ModalComponents
        show={showDeleteQualifications}
        onHide={hideDeleteQualifications}
        title="Delete Qualification"
        modalBody={
          <DeleteQualification
            id={deleteId}
            hideDeleteQualifications={hideDeleteQualifications}
          />
        }
        className="modal-md"
      />
    </>
  );
};

export default EducationalInfo;
