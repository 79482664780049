import React, { useEffect, useRef, useState } from "react";
import Card from "../../../component/Card/Card";
import { Strings } from "../../../constant/Strings";
import { icons } from "../../../assets";
import "./PersonalInfo.scss";
import EditButton from "../../../component/Buttons/EditButton/EditButton";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import ModalComponents from "../../../component/ModalComponent/ModalComponent";
import EditPersonalInfo from "./EditPersonalInfo/EditPersonalInfo";
import EditDocumentInfo from "./EditDocumentInfo/EditDocumentInfo";
import moment from "moment";
import useLoadingIndicator from "../../../component/Loader/Loader";
import Button from "../../../component/Buttons/Button";
import { useDispatch } from "react-redux";
import * as actions from "../../../Redux/Actions/index";
import { showErrorToastMessage, showSuccessToastMessage } from "../../../component/Toasts/Toasts";
import { Table } from "reactstrap";
interface preficiency {
  read: boolean;
  write: boolean;
  speak: boolean;
}
interface Language {
  language: string;
  proficiency: preficiency;
  expertise: string
}
const PersonalInfo = (props: any) => {
  const {

    candidateName,
    lastName,
    middleName,
    emailId,
    designation,
    title,
    dateOfBirth,
    pan,
    aadharNumber,
    passportNumber,
    recordStatus,
    eId,
    aadharCardImage,
    panCardImage,
    bloodGroup,
    status,
    getApiDispatch,
    languageDetails
  } = props;
  const {
    showLoadingIndicator,
    hideLoadingIndicator,
    loadingComponent,
    apiCallCompleted,
  } = useLoadingIndicator();
  const [editPersonalInfo, setEditPersonalInfo] = useState(false);
  const [editDocumentInfo, setEditDocumentInfo] = useState(false);
  const [showLoanguage]= useState(false);
  const {
    personal_info_heading,
    first_name,
    last_name,
    employee_title,
    middle_name,
    date_of_birth,
    pan_number,
    aadhar_no,
    passport_no,
    documents,
    blood_group,
  } = Strings;
  const { edit_orange_icon } = icons;

  const userLogin: any = localStorage.getItem("login");
  const loginId = JSON.parse(userLogin)?.id;
  const role = localStorage.getItem("role");
  const [languageData, setLanguageData] = useState<Language[]>([])

  useEffect(() => {
    if (languageDetails?.length) {
      const mappedData = languageDetails.map((item: any) => {
        const proficiency: preficiency = {
          read: item.proficiency.includes('Read'),
          write: item.proficiency.includes('Write'),
          speak: item.proficiency.includes('Speak'),
        };
  
        return {
          language: item.language,
          proficiency,
          expertise: item.expertise,
        };
      });
  
      setLanguageData(mappedData);
    }else{
      setLanguageData([])
    }
  
  },[languageDetails])
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");
  const editShowCondition =
    (status !== "Active" && status !== "Inactive") ||
    (role && role.includes("hr") && status === "Active" && eId != loginId);

  const handleEditPersonalInfo = () => {
    setEditPersonalInfo(!editPersonalInfo);
  };
  const handleEditDocumentInfo = () => {
    setEditDocumentInfo(!editDocumentInfo);
  };



  const addInput = () => {
    const newLanguage: Language = {
      language: '',
      proficiency: {
        read: false,
        write: false,
        speak: false
      },
      expertise: ''
    };
    setLanguageData([...languageData, newLanguage]);
  };

  const removeInput = (Index: number) => {
    showLoadingIndicator()
    const filterLanguageData = languageData?.filter((_, i) => i !== Index)
    const updatedLanguageData = filterLanguageData.map((item: any) => ({
      ...item,
      proficiency: Object.keys(item.proficiency)
        .filter(key => item.proficiency[key])
        .map(key => key.charAt(0).toUpperCase() + key.slice(1)),
    }));
    const payload: any = {
      languageDetails: updatedLanguageData,
      updatedBy: loginId
    }
    const selectedId: any = localStorage.getItem("selectedId");
    dispatch(
      actions.postProfileSummaryAction(
        payload,
        selectedId,
        (res: any) => {
          if (res?.error?.error_description) {
            hideLoadingIndicator()
            showErrorToastMessage(res.error.error_description);
            apiCallCompleted(true);
            getApiDispatch()
          } else if (res?.code === 200) {
            hideLoadingIndicator()
            showSuccessToastMessage("Deleted Successfully");
            apiCallCompleted(true);
            getApiDispatch()
          }
        }
      )
    );
  };
  const handleChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const updatedLanguages = [...languageData];
    if (e.target instanceof HTMLInputElement && e.target.type === 'checkbox') {
      const { name, checked } = e.target;
      updatedLanguages[index].proficiency = {
        ...updatedLanguages[index].proficiency,
        [name]: checked,
      };
    } else if (e.target instanceof HTMLSelectElement) {
      updatedLanguages[index].expertise = e.target.value;
    }

    setLanguageData(updatedLanguages);
  };
  const validateInputs = () => {
    return languageData.every((input: any) => {
      const isExpertiseValid = input.expertise.trim() !== "";
      const isProficiencyValid = input.proficiency.read || input.proficiency.speak || input.proficiency.write;
      const islanguage = input.language.trim() !== "";
      return islanguage && isExpertiseValid && isProficiencyValid;
    });
  };
  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (validateInputs()) {
      setErrorMessage("");
      apiCallCompleted(false);
      const updatedLanguageData = languageData.map((item: any) => ({
        ...item,
        proficiency: Object.keys(item.proficiency)
          .filter(key => item.proficiency[key])
          .map(key => key.charAt(0).toUpperCase() + key.slice(1)),
      }));
      const payload: any = {
        languageDetails: updatedLanguageData,
        updatedBy: loginId
      }
      const selectedId: any = localStorage.getItem("selectedId");
      dispatch(
        actions.postProfileSummaryAction(
          payload,
          selectedId,
          (res: any) => {
            if (res?.error?.error_description) {
              showErrorToastMessage(res.error.error_description);
              apiCallCompleted(true);
              getApiDispatch()
            } else if (res?.code === 200) {
              showSuccessToastMessage("Saved Successfully");
              apiCallCompleted(true);
              getApiDispatch()
            }
          }
        )
      );
    } else {
      setErrorMessage("Please fill out all  fields.");
    }
  };

  return (
    <>
      {loadingComponent()}
      <div>
        <h5>{personal_info_heading}</h5>
        <Card>
          <div className="edit-icon-wrapper">
            <h5>{personal_info_heading}</h5>
            {editShowCondition && (
              <EditButton
                title="Edit"
                icon={edit_orange_icon}
                onClick={handleEditPersonalInfo}
              />
            )}
          </div>

          <div className="edit-icon-wrapper">
            <div className="profile-details">
              <span>{employee_title}</span> <p>{title}</p>
            </div>
            <div className="profile-details">
              <span>{first_name}</span> <p>{candidateName}</p>
            </div>
            <div className="profile-details">
              <span>{middle_name}</span> <p>{middleName}</p>
            </div>
            <div className="profile-details">
              <span>{last_name}</span> <p>{lastName}</p>
            </div>
          </div>

          <div className="edit-icon-wrapper">
            <div className="profile-details">
              <span>{date_of_birth}</span> <p>{dateOfBirth}</p>
            </div>
            <div className="profile-details">
              <span>{blood_group}</span> <p>{bloodGroup}</p>
            </div>
            <div className="profile-details"></div>
            <div className="profile-details"></div>
          </div>
        </Card>
         {showLoanguage && (
        <Card>
          <div className="edit-icon-wrapper marginbottom">
            <h5>Language</h5>
            <button type="button" className="btn language-btn" onClick={addInput}>
              + Add
            </button>
          </div>
          <Table className="table-container" cellSpacing={2} cellPadding={20}>
            <thead className="table-head">
              <tr>
                <td className="table-td">Language</td>
                <td className="table-td">Proficiency</td>
                <td className="table-td">Expertise</td>
                <td className="table-td">Action</td>
              </tr>
            </thead>
            <tbody className="table-body">
              {languageData.map((lang, index) => (
                <tr key={index}>
                  <td className="table-td">
                    <input
                      type="text"
                      placeholder="Enter Language"
                      className="form-control"
                      value={lang.language}
                      onChange={(e) => {
                        if(e.target.value == "" || /^[A-Za-z\s]+$/.test(e.target.value) ){
                        const updatedLanguages = [...languageData];
                        updatedLanguages[index].language =
                          e.target.value.charAt(0).toUpperCase() +
                          e.target.value.slice(1);
                        setLanguageData(updatedLanguages);
                        }
                      }}
                    />
                  </td>
                  <td className="table-td">
                    <div className="proficiencyLabel">
                      <label>
                        Read
                      </label>
                      <input
                        type="checkbox"
                        name="read"
                        className="labelCheckBox"
                        checked={lang.proficiency.read}
                        onChange={(e) => handleChange(index, e)}
                      />

                      <label>
                        Write
                      </label>
                      <input
                        type="checkbox"
                        name="write"
                        className="labelCheckBox"
                        checked={lang.proficiency.write}
                        onChange={(e) => handleChange(index, e)}
                      />

                      <label>
                        Speak
                      </label>
                      <input
                        type="checkbox"
                        name="speak"
                        className="labelCheckBox"
                        checked={lang.proficiency.speak}
                        onChange={(e) => handleChange(index, e)}
                      />

                    </div>
                  </td>
                  <td className="table-td">
                    <select
                      className="expertise-select"
                      value={lang.expertise}
                      onChange={(e) => handleChange(index, e)}
                    >
                      <option value="">Select</option>
                      <option value="Beginner">Beginner</option>
                      <option value="Intermediate">Intermediate</option>
                      <option value="Fluent">Fluent</option>
                    </select>
                  </td>
                    <td className="table-td">
                        <div className="text-center">
                            <img
                              src={icons.delete_icon}
                              alt="delete-icon"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Delete"
                              onClick={() =>
                                removeInput(index)
                              }
                              className="edit-icon"
                            />
                          </div>
                    </td>
                </tr>
              ))}
            </tbody>
            {languageData.length === 0 && (
              <tbody className="no-data">
                <td colSpan={4} className="no-language-data">
                  No Language Data
                </td>
              </tbody>
            )}
          </Table>

          {errorMessage != "" && (
            <p className="text-danger text-center mt-2">{errorMessage}</p>
          )}

          {languageData?.length > 0 && (
            <div className="submit-language">
              <Button
                className="maker-checker-btn"
                title="Save"
                onClick={handleSubmit}
              />
            </div>
          )}
        </Card>
          )}
        <Card>
          <div className="edit-icon-wrapper">
            <h5>{documents}</h5>
            <EditButton
              title="Edit"
              icon={edit_orange_icon}
              onClick={handleEditDocumentInfo}
            />
          </div>

          <div className="edit-icon-wrapper personal__info-container">
            <div className="profile-details">
              <span>{pan_number}</span>
              <p>
                {pan}
                <span className="mx-3">
                  {panCardImage?.document_path_url ? (
                    <a
                      className="view-resume"
                      href={panCardImage?.document_path_url}
                      target="_blank"
                    >
                      View File
                    </a>
                  ) : (
                    ""
                  )}
                </span>
              </p>
            </div>
            <div className="profile-details">
              <span>{aadhar_no}</span>{" "}
              <p>
                {aadharNumber}
                <span className="mx-3">
                  {aadharCardImage?.document_path_url ? (
                    <a
                      className="view-resume"
                      href={aadharCardImage?.document_path_url}
                      target="_blank"
                    >
                      View File
                    </a>
                  ) : (
                    ""
                  )}
                </span>
              </p>
            </div>
            <div className="profile-details">
              <span>{passport_no}</span> <p>{passportNumber}</p>
            </div>
          </div>
        </Card>

      </div>
      <ModalComponents
        show={editPersonalInfo}
        onHide={handleEditPersonalInfo}
        title="Edit Personal Details"
        modalBody={
          <EditPersonalInfo
            apiCallCompleted={apiCallCompleted}
            candidateName={candidateName}
            lastName={lastName}
            middleName={middleName}
            emailId={emailId}
            designation={designation}
            title={title}
            dateOfBirth={dateOfBirth ? moment(dateOfBirth).format("YYYY-MM-DD"):""}
            recordStatus={recordStatus}
            handleEditPersonalInfo={handleEditPersonalInfo}
            eId={eId}
            bloodGroup={bloodGroup}
          />
        }
        className="modal-lg"
      />
      <ModalComponents
        show={editDocumentInfo}
        onHide={handleEditDocumentInfo}
        title="Edit Documents Details"
        modalBody={
          <EditDocumentInfo
            apiCallCompleted={apiCallCompleted}
            pan={pan}
            aadharNumber={aadharNumber}
            passportNumber={passportNumber}
            panCardImage={panCardImage}
            aadharCardImage={aadharCardImage}
            handleEditDocumentInfo={handleEditDocumentInfo}
            eId={eId}
            status={status}
          />
        }
        className="modal-lg"
      />


    </>
  );
};

export default PersonalInfo;
