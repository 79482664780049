import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MainCard from "../../../component/MainCard/MainCard";
import * as actions from "../../../Redux/Actions";
import { Table } from "react-bootstrap";
import "./ReportPdf.scss";
import { useParams } from "react-router-dom";
import useLoadingIndicator from "../../../component/Loader/Loader";
import { showErrorToastMessage } from "../../../component/Toasts/Toasts";
import { Link } from "react-router-dom";
import { icons } from "../../../assets";
import Button from "../../../component/Buttons/Button";
import CoachTabs from "../../../component/Tabs/Tabs";
import { Strings } from "../../../constant/Strings";
import ShowEntries from "../../../component/ShowEntries/ShowEntries";
import TablePagination from "../../../component/TablePagination/TablePagination";

function ReportPdf() {
  const { loadingComponent, showLoadingIndicator, hideLoadingIndicator } =
    useLoadingIndicator();
  let { id } = useParams();
  const [info, setInfo] = useState<any>([]);
  const [coachReportTab, setCoachReportTab] = useState(true);
  const [page, setPage] = useState<Number>(1);
  const [totalPages, setTotalPages] = useState(0);
  const [filterValue, setFilterValue] = useState<any>([]);
  const [pagePerSize, setPagePerSize] = useState<any>(10);
  const [totalEntries, setTotalEntries] = useState<any>();

  const {
    back,
    coach_name,
    coachee_list,
    sessions_completed,
    active_sessions,
    total_active_sessions,
    total_sessions_completed,
  } = Strings;
  const { backIcon } = icons;

  const dispatch = useDispatch();
  useEffect(() => {
    showLoadingIndicator();
    dispatch(
      actions.getCoachingReportPdfAction(
        id,
        page,
        pagePerSize,
        filterValue,
        "",
        (res: any) => {
          if (res?.error?.error_description) {
            showErrorToastMessage(res?.error?.error_description);
          } else if (res.code === 200) {
              hideLoadingIndicator();
              setTotalPages(res.meta_data.totalPageCount);
              setTotalEntries(res.meta_data.totalEntries);
            }
        }
      )
    );
  }, [page, pagePerSize, filterValue]);


  const reportPdf = useSelector(
    (state: any) => state.employeeReducer.getCoachingReportPdfDetails
  );

  const techSpec = useSelector(
    (state: any) => state.employeeReducer.getTechSpecReportDetails
  );

  useEffect(() => {
    if (reportPdf) {
      if (reportPdf.data) {
        setInfo(reportPdf.data);
      }
    }
  }, [reportPdf, info, techSpec]);

  function generatePDF() {
    const pdfDocument = new jsPDF();
    const table: any = document.getElementById("pdf-content");
    html2canvas(table).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const imgProps = pdfDocument.getImageProperties(imgData);
      const pdfWidth = pdfDocument.internal.pageSize.getWidth() - 20;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdfDocument.addImage(imgData, "PNG", 10, 10, pdfWidth, pdfHeight);
      pdfDocument.save("table.pdf");
    });
  }

  const handleCoachReportTab = () => {
    setCoachReportTab(true);
  };

  const handlePageChange = (newPage: any) => {
    setPage(newPage);
  };

  return (
    <div>
      {loadingComponent()}
      <MainCard>
        <div className="coach-details-card-container">
          <div className="coach-details-card__first-wrapper">
            <Link to="/coaching-reports">
              <img src={backIcon} className="backIcon" alt="icon" />
              <span>{back}</span>
            </Link>

            <div className="coach-name-title">{coach_name}</div>
            <div className="coach-name-data">
              {info?.coachData &&
                info?.coachData.length > 0 &&
                info?.coachData[0]?.Coach[0]?.candidateName +
                  " " +
                  info?.coachData[0]?.Coach[0]?.middleName +
                  " " +
                  info?.coachData[0]?.Coach[0]?.lastname}
            </div>
          </div>

          <div className="coach-details-card__second-wrapper">
            <div className="coach-details-wrapper">
              <div className="coach-title-wrapper">
                <span>{total_active_sessions}</span>
                <p>
                  {(info?.coachData &&
                    info?.coachData[0]?.ActiveSession[0]?.ActiveSessionCount) ||
                    0}
                </p>
              </div>
              <div className="coach-title-wrapper">
                <span>{total_sessions_completed}</span>
                <p>
                  {(info?.coachData &&
                    info?.coachData[0]?.CompletedSession[0]
                      ?.CompletedSessionCount) ||
                    0}
                </p>
              </div>
            </div>

            <div className="">
              <Button
                title="Download Report"
                onClick={generatePDF}
                className="add-employee-btn"
              />
            </div>
          </div>
        </div>

        <div className="coach-report-tabs-container">
          <CoachTabs
            title="Trainee Report"
            onClick={handleCoachReportTab}
            active={coachReportTab}
          />
        </div>
        <div>
          {coachReportTab && (
            <>
              <Table id="pdf-content">
                <thead className="employeedb">
                  <tr className="table-head-row">
                    <td>{coachee_list}</td>
                    <td>{active_sessions}</td>
                    <td>{sessions_completed}</td>
                  </tr>
                </thead>
                <tbody className="employeedata">
                  {info?.employeeList?.length > 0 &&
                    info?.employeeList?.map((ele: any) => (
                      <tr key={ele._id}>
                        <td>
                          {ele?.EmployeeData
                            ? ele?.EmployeeData[0]?.candidateName +
                              " " +
                              ele?.EmployeeData[0]?.middleName +
                              " " +
                              ele?.EmployeeData[0]?.lastname
                            : ""}
                        </td>

                        <td>
                          {ele?.detailStatus && ele?.detailStatus == "Scheduled"
                            ? "1"
                            : ""}
                        </td>
                        <td>
                          {ele?.detailStatus && ele?.detailStatus == "Complete"
                            ? "1"
                            : ""}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>

              <div className="show-entries-wrapper">
                <ShowEntries
                  pageSize={pagePerSize}
                  setPageSize={setPagePerSize}
                  totalRecords={0}
                  totalEntries={totalEntries}
                />

                <TablePagination
                  totalPages={totalPages}
                  currentPage={page}
                  onClick={handlePageChange}
                />
              </div>
            </>
          )}
        </div>
        {/* </div> */}
      </MainCard>
    </div>
  );
}
export default ReportPdf;
