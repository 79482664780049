import React, { useEffect, useMemo, useState } from "react";
import SearchBar from "../../../component/SearchBar/SearchBar";
import { FaSort } from "react-icons/fa";
import { Table } from "react-bootstrap";
import * as actions from "../../../Redux/Actions/index";
import ModalComponents from "../../../component/ModalComponent/ModalComponent";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import CoachRequestModal from "./CoachRequestModal";
import { showErrorToastMessage } from "../../../component/Toasts/Toasts";
import ShowEntries from "../../../component/ShowEntries/ShowEntries";
import { Strings, coachingRequestsFilter } from "../../../constant/Strings";
import "../CoachingRequest/CoachingRequest.scss";
import FilterValues from "../../../component/FieldFilter/FilterValues";
import TablePagination from "../../../component/TablePagination/TablePagination";
import "../CoachingRequest/CoachingRequest.scss";
import useLoadingIndicator from "../../../component/Loader/Loader";

const CoachingRequestTable = (props: any) => {
  const [page, setPage] = useState<Number>(1);
  const [totalEntries, setTotalEntries] = useState<any>();
  const [totalPages, setTotalPages] = useState(0);
  const [searchValue, setSearchValue] = useState<any>("");
  const [filterValue, setFilterValue] = useState<any>([]);
  const userLogin: any = localStorage.getItem("login");
  const loginId = JSON.parse(userLogin).id;
  const { requestACoach, noData, selectCoach, requestSent } = Strings;
  const { loadingComponent, apiCallCompleted } = useLoadingIndicator();
  const [requestId, setrequestId] = useState({
    coachRef: "",
    coachDataRef: "",
  });
  const dispatch = useDispatch();

  const [data, setdata] = useState<any>([]);
  const [order, setorder] = useState("ASC");

  const [totalRecords, setTotalRecords] = useState<any>(0);
  const [pageSize, setPageSize] = useState(10);

  const [isSorted, setisSorted] = useState(false);
  const [sortedData, setsortedData] = useState<any>([]);
  const sorting = (col: any) => {
    setisSorted(true);
    if (order === "ASC") {
      const sorted = [...data].sort((a: any, b: any) => {
        if (col == "candidateName") {
          if (a?.Coach[0]?.candidateName < b?.Coach[0]?.candidateName) {
            return 1;
          }
          if (a?.Coach[0]?.candidateName > b?.Coach[0]?.candidateName) {
            return -1;
          }
          return 0;
        } else if (col == "status") {
          if (a?.CoachingRequest[0]?.status < b?.CoachingRequest[0]?.status) {
            return 1;
          }
          if (a?.CoachingRequest[0]?.status > b?.CoachingRequest[0]?.status) {
            return -1;
          }
          return 0;
        } else if (col == "remarks") {
          if (a?.CoachingRequest[0]?.remarks < b?.CoachingRequest[0]?.remarks) {
            return 1;
          }
          if (a?.CoachingRequest[0]?.remarks > b?.CoachingRequest[0]?.remarks) {
            return -1;
          }
          return 0;
        } else {
          if (a[col] < b[col]) {
            return 1;
          }
          if (a[col] > b[col]) {
            return -1;
          }
          return 0;
        }
      });
      setsortedData(sorted);
      setorder("DSC");
    } else {
      const sorted = [...data].sort((a: any, b: any) => {
        if (a[col] < b[col]) {
          return -1;
        }
        if (a[col] > b[col]) {
          return 1;
        }
        return 0;
      });
      setsortedData(sorted);
      setorder("ASC");
    }
  };

  const coachRequest = useSelector(
    (state: any) => state.employeeReducer.addCoachRequestDetails
  );
  const [onclickSearch, setOnclickSearch] = useState(false);
  const onSearchClicked = (value: any) => {
    setOnclickSearch(value);
  };
  const condition: boolean =
    (filterValue.length == 0 && searchValue == "") ||
    (filterValue.length > 0 && onclickSearch);
  const filterKey = filterValue.map((ele: any) => ele.value);
  useEffect(() => {
    if (condition) {
      dispatch(
        actions.getAllCoachListAction(
          page,
          pageSize,
          filterKey,
          searchValue,
          loginId,
          (res: any) => {
            if (res?.error?.error_description) {
              showErrorToastMessage(res?.error?.error_description);
            } else  if(res?.code === 200) {
                setTotalPages(res.meta_data.totalPageCount);
                setTotalEntries(res.meta_data.totalEntries);
                setOnclickSearch(false);
              }
          }
        )
      );
    }
  }, [coachRequest, page, pageSize, filterValue, searchValue, condition]);

  const getAllCoachList = useSelector(
    (state: any) => state.employeeReducer.getAllCoachListDetails
  );

  const updateStatusRequest = useSelector(
    (state: any) => state.employeeReducer.updateStatusRemarksDetails
  );

  useEffect(() => {
    if (getAllCoachList && getAllCoachList.data) {
      setdata(getAllCoachList.data);
      setTotalRecords(getAllCoachList.data.length);
    }
  }, [getAllCoachList, updateStatusRequest, data]);

  const [showAddCoachRequest, setShowAddCoachRequest] = useState(false);
  const handleCoachRequestSubmit = (id: any, employeeRef: any) => {
    setShowAddCoachRequest(true);
    setrequestId({ coachRef: employeeRef, coachDataRef: id });
  };
  const hideAddCoachReuqest = () => {
    setShowAddCoachRequest(false);
  };

  const COLUMNS: any = [
    {
      Header: "Name Of the Coach",
      accessor: "candidateName",
    },
    {
      Header: "Experience",
      accessor: "yearsOfExperience",
    },
    {
      Header: "Expertise",
      accessor: "expertise",
    },
    {
      Header: "Location",
      accessor: "coachingCapacity",
    },
    {
      Header: "Action",
      accessor: "",
    },
    {
      Header: "Status",
      accessor: "status",
    },
  ];

  const columns = useMemo(() => COLUMNS, []);

  const handleSearch = (value: any) => {
    setSearchValue(value);
    setPage(1);
  };

  const filterChange = (selectedOption: any) => {
    if (selectedOption) {
      if (filterValue.find((obj: any) => obj.value == selectedOption.value)) {
        setFilterValue((prevValue: any) =>
          prevValue.filter((obj: any) => obj.value != selectedOption.value)
        );
      } else {
        setFilterValue((prevValue: any) => [...prevValue, selectedOption]);
      }
    }
  };

  const handlePageChange = (newPage: any) => {
    setPage(newPage);
    setOnclickSearch(true);
  };

  return (
    <>
      {loadingComponent()}
      <div className="coaching-request-heading-button-wrapper">
        <div>
          <h2>{requestACoach}</h2>
        </div>
      </div>
      <div className="mt-2">
        <div
          className="mt-3 buttonsize filter-coaching-request-wrapper"
        >
          <div className="coaching-request-searchbar-wrapper">
            <SearchBar
              filter={searchValue}
              setFilter={handleSearch}
              onSearchClicked={onSearchClicked}
            />
          </div>
          <div className="coaching-request-filter-container">
            <select
              id="select-filters"
              value={typeof filterValue === 'string' ? filterValue : ''}
              onChange={(event: any) => {
                const selectedValue = event.target.value;
                const selectedOption = coachingRequestsFilter.find(
                  (item: any) => item.value === selectedValue
                );
                if (selectedOption) {
                  filterChange(selectedOption);
                }
              }}
            >
              <option hidden>Filters</option>
              {coachingRequestsFilter.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.title}
                </option>
              ))}
            </select>
          </div>
        </div>
        <FilterValues
          filterValueArray={filterValue}
          onFilterChange={filterChange}
        />
        {data.length > 0 ? (
          <>
            <div className="table-responsive mt-2">
              <Table className="table-margin">
                <thead>
                  <tr className="custom-table-head-row">
                    {columns.map((ele: any) => (
                      <React.Fragment key={ele.Header}>
                        <td
                          onClick={() => sorting(ele.accessor)}
                          className="sorting-cursor"
                        >
                          {ele.Header}
                          {ele.Header !== "Action" &&
                          ele.Header !== "" &&
                          ele.Header != "Remark" ? (
                            <span> {<FaSort />} </span>
                          ) : (
                            " "
                          )}
                        </td>
                      </React.Fragment>
                    ))}
                  </tr>
                </thead>
                <tbody className="employeedata coaching-request-table">
                  {(isSorted ? sortedData : data).map(
                    (ele: any, index: any) => (
                        <tr key={index}>
                          <td>
                            {ele?.Coach[0]?.candidateName +
                              " " +
                              ele?.Coach[0]?.middleName +
                              " " +
                              ele?.Coach[0]?.lastname}
                          </td>
                          <td>{ele?.yearsOfExperience}</td>
                          <td>{ele?.expertise}</td>
                          <td>{ele?.location}</td>
                          <td>
                            {ele.CoachingRequest[0]?.purpose ? (
                              <button className="btn request-sent-btn" disabled>
                                {requestSent}
                              </button>
                            ) : (
                              <button
                                className="btn select-coach-btn"
                                onClick={() =>
                                  handleCoachRequestSubmit(
                                    ele._id,
                                    ele.employeeRef
                                  )
                                }
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Select coach"
                              >
                                {selectCoach}
                              </button>
                            )}
                          </td>
                          <td>
                            <button
                              type="button"
                              className={`btn ${
                                ele.CoachingRequest[0]?.status === 1
                                  ? "coachee-status-approved"
                                  : ele.CoachingRequest[0]?.status === 2
                                  ? "coachee-status-rejected"
                                  : ele.CoachingRequest[0]?.status === 3
                                  ? "new"
                                  : ""
                              }`}
                            >
                              {ele?.CoachingRequest[0]?.status === 1
                                ? "Accepted"
                                : ele?.CoachingRequest[0]?.status === 2
                                ? "Rejected"
                                : ele?.CoachingRequest[0]?.status === 3
                                ? "New"
                                : ""}
                            </button>
                          </td>
                        </tr>
                    )
                  )}
                </tbody>
              </Table>
              <div className="show-entries-wrapper">
                <ShowEntries
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  totalRecords={totalRecords}
                  totalEntries={totalEntries}
                />

                <TablePagination
                  totalPages={totalPages}
                  currentPage={page}
                  onClick={handlePageChange}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <p className="employeedata mt-2">{noData}</p>
          </>
        )}
      </div>

      <ModalComponents
        show={showAddCoachRequest}
        onHide={hideAddCoachReuqest}
        title={"Coach Request "}
        modalBody={
          <CoachRequestModal
            apiCallCompleted={apiCallCompleted}
            hideAddCoachReuqest={hideAddCoachReuqest}
            requestId={requestId}
          />
        }
        className="modal-lg"
      />

      <ToastContainer />
    </>
  );
};

export default CoachingRequestTable;
