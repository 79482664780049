import  { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import LearningDevManagerTable from "./LearningDevManagerTable";
import MainCard from "../../../component/MainCard/MainCard";
import { showErrorToastMessage } from "../../../component/Toasts/Toasts";
import { useNavigate } from "react-router-dom";
import { Strings } from "../../../constant/Strings";

const ManagerLearningDevelopment = () => {
  const [managerCheckerList, setManagerCheckerList] = useState(false);
  const navigate = useNavigate();
  const role = localStorage.getItem("role");
  const checkManagerList = role?.includes("learninganddevelopment-manager");
  const { inValidModuleAccess } = Strings;

  useEffect(() => {
    if (checkManagerList) {
      setManagerCheckerList(true);
    } else {
      setManagerCheckerList(false);
    }
    if (checkManagerList == undefined || !checkManagerList) {
      showErrorToastMessage(inValidModuleAccess);
      navigate("/profile");
    }
  }, [checkManagerList]);

  return (
    <>
      <MainCard>
        <>{managerCheckerList && <LearningDevManagerTable />}</>
      </MainCard>
      <ToastContainer />
    </>
  );
};

export default ManagerLearningDevelopment;
