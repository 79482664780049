import  { useEffect } from "react";
import "./CoachingRequest.scss";
import { useDispatch } from "react-redux";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../component/Toasts/Toasts";
import { Strings } from "../../../constant/Strings";
import useLoadingIndicator from "../../../component/Loader/Loader";
const UndoPopup = (props: any) => {
  const { loadingComponent, showLoadingIndicator, hideLoadingIndicator } =
    useLoadingIndicator();
  const {
    onHide,
    setShowUndoPopup,
    requestId,
    employeeName,
    statusDetails,
    undoActionApi,
    data,
  } = props;
  const { undo_action ,Requesthasbeen,Undo} = Strings;
  const dispatch = useDispatch();
  const handleUndoAction = (e: any) => {
    e.preventDefault();
    showLoadingIndicator();
    dispatch(
      undoActionApi(requestId, data, (res: any) => {
        if (res?.error?.error_description) {
          showErrorToastMessage(res?.error?.error_description);
          hideLoadingIndicator();
        } else if(res?.code === 200) {
            hideLoadingIndicator();
            showSuccessToastMessage(undo_action);
        }
      })
    );
    setShowUndoPopup(false);
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      onHide();
    }, 5 * 60 * 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [onHide]);

  return (
    <>
      {loadingComponent()}
      <div className="undo-popup">
        <span>
          {employeeName + " "} {Requesthasbeen} {statusDetails}
        </span>
        &nbsp;&nbsp;&nbsp;
        <a href="" onClick={(e: any) => handleUndoAction(e)}>
          {Undo}
        </a>
      </div>
    </>
  );
};

export default UndoPopup;
