import  {  useState } from "react";
import { useDispatch } from "react-redux";
import * as actions from "../../../Redux/Actions/index";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../component/Toasts/Toasts";
import { Dropdown } from "react-bootstrap";
import { Strings } from "../../../constant/Strings";

const InactiveEmployee = (props: any) => {
  const {Title,active_label,inactive_label,cancel,submit} = Strings;
  const { id, hideInactiveModal, apiCallCompleted } = props;
  const userLogin: any = localStorage.getItem("login");
  const loginId = JSON.parse(userLogin).id;
  const [active, setActive] = useState({
    status: "",
    updatedBy: loginId,
    eId: id,
  });
  const dispatch = useDispatch();
  const handleDelete = () => {
    setActive({
      ...active,
      status: "Inactive",
    });

    apiCallCompleted(false);
    dispatch(
      actions.editEmployeeDataAction(active, (res: any) => {
        if (res?.error?.error_description) {
          showErrorToastMessage(res?.error?.error_description);
          apiCallCompleted(true);
        } else {
          if (res?.code === 200) {
            showSuccessToastMessage("Edited Successfully!");
            apiCallCompleted(true);
          }
        }
      })
    );
    hideInactiveModal();
  };
  const [setDropdown, setShowDropdown] = useState(false);
  return (
    <div className="container">
      <div className="row mx-auto">
        <div className="col-md-12">
          <label className="form-label">
            {Title}
            <span className="required">*</span>
          </label>
          <Dropdown
            onMouseLeave={() => setShowDropdown(false)}
            onMouseOver={() => setShowDropdown(true)}
            onSelect={(eventKey: any) =>
              setActive({
                ...active,
                status: eventKey,
              })
            }
          >
            <Dropdown.Toggle variant="light" className="form-control">
              {active.status}
            </Dropdown.Toggle>
            <Dropdown.Menu show={setDropdown}>
              <Dropdown.Item eventKey="Active">{active_label}</Dropdown.Item>
              <Dropdown.Item eventKey="Inactive">{inactive_label}</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className="modal-btn-wrapper mt-3 mx-auto">
        <div>
          <button
            className="btn btn-outline-danger buttonsize"
            onClick={hideInactiveModal}
          >
            {cancel}
          </button>
        </div>
        <div>
          <button className="btn btn-primary buttonsize" onClick={handleDelete}>
            {submit}
          </button>
        </div>
      </div>
    </div>
  );
};

export default InactiveEmployee;
