import axios from "axios";
import { showErrorToastMessage } from "../../component/Toasts/Toasts";
import { baseUrl } from "../../config/constants";
import { group_id } from "../../config/constants";
import { BASE_URL } from "../../config/constants";

export const postPlayerId = async (data: any) => {
  const token: any = localStorage.getItem("access_token");

  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/notification/player/map`,
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {}
};

export const employeeIdBytoken = async () => {
  const token: any = localStorage.getItem("access_token");

  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/employee/empid`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const login: any = response.data.data;
    localStorage.setItem("login", JSON.stringify(login));
    localStorage.setItem("selectedId", login?.id);

    return response.data;
  } catch (err: any) {
    showErrorToastMessage(err.response.data.error.error_description);
    employeeLogoutApi();
    localStorage.clear();
  }
};
export const employeeLogoutApi = async () => {
  const token: any = localStorage.getItem("access_token");
  window.location.replace(
    `${BASE_URL}/token-srv/end_session?post_logout_redirect_uri=https://portal-sso.skill-mine.com&access_token_hint=${token}`
  );
};

export const loginUserApi = async (token: any) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${BASE_URL}/users-srv/userinfo`,
      // data: data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const user = response.data;
    localStorage.setItem("user", JSON.stringify(user));
    const spotlightGroup = user.groups.find(
      (grp: { group_id: string }) => grp.group_id === group_id
    );
    const role = spotlightGroup?.roles;
    localStorage.setItem("role", JSON.stringify(role));
    return response.data;
  } catch (err) {
    employeeLogoutApi();
    localStorage.clear();
  }
};

export const getemployeeDetailsApi = async (selectedId: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/employee/${selectedId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    err.response.data;
  }
};

export const getEmployeeDataApi = async () => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/employee/all/active`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (err: any) {
    err.response.data;
  }
};

export const getEmployeeDataPaginationApi = async (
  size: any,
  page: any,
  searchKey: any,
  searchValue: any
) => {
  const token: any = localStorage.getItem("access_token");

  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/employee/v2/all?page=${page}&size=${size}&searchKey=${searchKey}&searchValue=${searchValue}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    err.response.data;
  }
};

export const addEmployeeDataApi = async (employeeDetails: any) => {
  const token: any = localStorage.getItem("access_token");
  const data = employeeDetails;

  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/employee/create`,
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const editEmployeeDataApi = async (employeeDetails: any) => {
  const data = employeeDetails;
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "PUT",
      url: `${baseUrl}/v1/employee/update/${data.eId}`,
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const editEmployeePictureApi = async (employeePicture: any) => {
  const data = employeePicture;
  const token: any = localStorage.getItem("access_token");
  const employeeId: any = localStorage.getItem("selectedId");
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/employee/utils/upload/media/${employeeId}`,
      data: data[0],
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data;",
      },
    });
    if (response.data) {
      const candidatePhoto = { candidatePhoto: response.data.data };
      const userLogin: any = localStorage.getItem("login");
      const token: any = localStorage.getItem("access_token");
      const selectedId: any = localStorage.getItem("selectedId");
      const loginId = JSON.parse(userLogin).id;
      const updatedBy = loginId;
      try {
        const response = await axios({
          method: "PUT",
          url: `${baseUrl}/v1/employee/update/${selectedId}`,
          data: { candidatePhoto, updatedBy },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        return response.data;
      } catch (err: any) {
        err.response.data;
      }
    } else {
    }
  } catch (err: any) {
    err.response.data;
  }
};

export const deleteEmployeeDataApi = async (id: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "PUT",
      url: `${baseUrl}/v1/employee/soft-delete/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    err.response.data;
  }
};

export const getEmployeeSkillsApi = async () => {
  const selectedId: any = localStorage.getItem("selectedId");
  const token: any = localStorage.getItem("access_token");

  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/employee/skill/${selectedId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    err.response.data;
  }
};

export const addEmployeeSkillsApi = async (skills: any) => {
  const token: any = localStorage.getItem("access_token");
  const data = skills;
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/employee/skill/add`,
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.data;
  } catch (err: any) {
    err.response.data;
  }
};

export const editEmployeeSkillsApi = async (id: any, skills: any) => {
  const token: any = localStorage.getItem("access_token");
  const data = skills;
  try {
    const response = await axios({
      method: "PUT",
      url: `${baseUrl}/v1/employee/skill/${id}`,
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    err.response.data;
  }
};

export const deleteEmployeeSkillsApi = async (id: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "DELETE",
      url: `${baseUrl}/v1/employee/skill/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    err.response.data;
  }
};

export const getExperienceDataApi = async () => {
  const employeeId: any = localStorage.getItem("selectedId");
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/employee/experience/${employeeId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    err.response.data;
  }
};

export const addExperienceDataApi = async (experienceDetails: any) => {
  const data = experienceDetails;
  const token: any = localStorage.getItem("access_token");

  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/employee/experience/add`,
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    err.response.data;
  }
};

export const editExperienceDataApi = async (
  id: any,
  experienceDetails: any
) => {
  const token: any = localStorage.getItem("access_token");
  const data = experienceDetails;

  try {
    const response = await axios({
      method: "PUT",
      url: `${baseUrl}/v1/employee/experience/${id}`,
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    err.response.data;
  }
};

export const deleteExperienceDataApi = async (id: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "DELETE",
      url: `${baseUrl}/v1/employee/experience/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    err.response.data;
  }
};

export const getEmployeeQualificationApi = async () => {
  const employeeId: any = localStorage.getItem("selectedId");
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/employee/qualification/${employeeId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    err.response.data;
  }
};

export const addEmployeeQualificationApi = async (qualifications: any) => {
  const token: any = localStorage.getItem("access_token");
  const details = qualifications;
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/employee/qualification/add`,
      data: details,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    err.response.data;
  }
};

export const editEmployeeQualificationApi = async (
  id: any,
  qualifications: any
) => {
  const token: any = localStorage.getItem("access_token");
  const data = qualifications;
  try {
    const response = await axios({
      method: "PUT",
      url: `${baseUrl}/v1/employee/qualification/${id}`,
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    err.response.data;
  }
};

export const deleteEmployeeQualificationApi = async (id: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "DELETE",
      url: `${baseUrl}/v1/employee/qualification/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (err: any) {
    err.response.data;
  }
};

export const getEmployeeCertificationsApi = async () => {
  const employeeId: any = localStorage.getItem("selectedId");
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/employee/certification/${employeeId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    err.response.data;
  }
};

export const addEmployeeCertificationsApi = async (certifications: any) => {
  const token: any = localStorage.getItem("access_token");
  const data = certifications;
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/employee/certification/add`,
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    err.response.data;
  }
};

export const editEmployeeCertificationsApi = async (
  id: any,
  certifications: any
) => {
  const token: any = localStorage.getItem("access_token");
  const data = certifications;
  try {
    const response = await axios({
      method: "PUT",
      url: `${baseUrl}/v1/employee/certification/${id}`,
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    err.response.data;
  }
};

export const deleteEmployeeCertificationsApi = async (id: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "DELETE",
      url: `${baseUrl}/v1/employee/certification/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    err.response.data;
  }
};

export const getEmployeeWorkAchievementsApi = async () => {
  const employeeId: any = localStorage.getItem("selectedId");
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/employee/achievement/${employeeId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    err.response.data;
  }
};

export const addEmployeeWorkAchievementsApi = async (
  employeeWorkAchievements: any
) => {
  const token: any = localStorage.getItem("access_token");
  const data = employeeWorkAchievements;

  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/employee/achievement/add`,
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    err.response.data;
  }
};

export const editEmployeeWorkAchievementsApi = async (
  id: any,
  employeeWorkAchievements: any
) => {
  const token: any = localStorage.getItem("access_token");
  const data = employeeWorkAchievements;
  try {
    const response = await axios({
      method: "PUT",
      url: `${baseUrl}/v1/employee/achievement/${id}`,
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    err.response.data;
  }
};

export const deleteEmployeeWorkAchievementsApi = async (id: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "DELETE",
      url: `${baseUrl}/v1/employee/achievement/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    err.response.data;
  }
};

export const getEmployeePreferenceApi = async () => {
  const employeeId: any = localStorage.getItem("selectedId");
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/employee/preference/${employeeId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    err.response.data;
  }
};

export const addEmployeePreferenceApi = async (preferenceDetails: any) => {
  const token: any = localStorage.getItem("access_token");
  const data = preferenceDetails;
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/employee/preference/add`,
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    err.response.data;
  }
};

export const editEmployeePreferenceApi = async (
  id: any,
  preferenceDetails: any
) => {
  const token: any = localStorage.getItem("access_token");
  const details = preferenceDetails;
  try {
    const response = await axios({
      method: "PUT",
      url: `${baseUrl}/v1/employee/preference/${id}`,
      data: details,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    err.response.data;
  }
};

export const deleteEmployeePreferenceApi = async (id: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "DELETE",
      url: `${baseUrl}/v1/employee/preference/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    err.response.data;
  }
};

export const getEmployeeCareerGoalsApi = async () => {
  const employeeId: any = localStorage.getItem("selectedId");
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/employee/careergoal/${employeeId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (err: any) {
    err.response.data;
  }
};

export const addEmployeeCareerGoalsApi = async (careerGoals: any) => {
  const data = careerGoals;
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/employee/careergoal/add`,
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    err.response.data;
  }
};

export const editEmployeeCareerGoalsApi = async (id: any, careerGoals: any) => {
  const token: any = localStorage.getItem("access_token");
  const data = careerGoals;
  try {
    const response = await axios({
      method: "PUT",
      url: `${baseUrl}/v1/employee/careergoal/${id}`,
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    err.response.data;
  }
};

export const deleteEmployeeCareerGoalsApi = async (id: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "DELETE",
      url: `${baseUrl}/v1/employee/careergoal/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    err.response.data;
  }
};

export const qualificationsDocUploadApi = async (docFile: any) => {
  const employeeId: any = localStorage.getItem("selectedId");
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/employee/qualification/utils/upload/media/${employeeId}`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      data: docFile,
    });
    return response.data;
  } catch (err: any) {
    err.response.data;
  }
};

export const workAchievementsDocUploadApi = async (docFile: any) => {
  const employeeId: any = localStorage.getItem("selectedId");
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/employee/achievement/utils/upload/media/${employeeId}`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      data: docFile,
    });
    return response.data;
  } catch (err: any) {
    err.response.data;
  }
};
export const experienceDocUploadApi = async (docFile: any) => {
  const employeeId: any = localStorage.getItem("selectedId");
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/employee/experience/utils/upload/media/${employeeId}`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      data: docFile,
    });
    return response.data;
  } catch (err: any) {
    err.response.data;
  }
};

export const employeeDetailsDocUploadApi = async (docFile: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/employee/utils/upload/media/${docFile.eId}`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      data: docFile,
    });
    return response.data;
  } catch (err: any) {
    err.response.data;
  }
};

export const employeeDetailsImageUploadApi = async (docFile: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/employee/utils/upload/media/${docFile.eId}`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      data: docFile,
    });
    return response.data;
  } catch (err: any) {
    err.response.data;
  }
};

export const getStatesApi = async () => {
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/states/allState`,
    });
    return response.data.data;
  } catch (err: any) {
    err.response.data;
  }
};

export const getProjectRepositoryApi = async (
  size: any,
  page: any,
  searchKey: any,
  searchValue: any
) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/resource-management/v2/all-projects?page=${page}&size=${size}&searchKey=${searchKey}&searchValue=${searchValue}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getResourceAllocationApprovedApi = async (
  size: any,
  page: any,
  searchKey: any,
  searchValue: any
) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/resource-management/v2/all-approved-projects?page=${page}&size=${size}&searchKey=${searchKey}&searchValue=${searchValue}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getProjectRepositoryByIdApi = async (projectId: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/resource-management/project/${projectId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const addProjectRepositoryApi: any = async (details: any) => {
  const token: any = localStorage.getItem("access_token");
  const data = details;
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/resource-management/add-project`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const editProjectRepositoryApi = async (
  id: any,
  projectDetails: any
) => {
  const data = projectDetails;
  const token: any = localStorage.getItem("access_token");

  try {
    const response = await axios({
      method: "PUT",
      url: `${baseUrl}/v1/resource-management/update-project/${id}`,
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const deleteProjectRepositoryApi = async (id: any) => {
  const token: any = localStorage.getItem("access_token");

  try {
    const response = await axios({
      method: "DELETE",
      url: `${baseUrl}/v1/resource-management/delete-project/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const projectDetailsDocUploadApi = async (docFile: any) => {
  const projectId: any = localStorage.getItem("projectId");
  const token: any = localStorage.getItem("access_token");

  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/resource-management/utils/upload/media/${projectId}`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      data: docFile,
    });
    return response.data;
  } catch (err: any) {
    err.response.data;
  }
};

export const getProjectRepositoryInvoiceApi = async () => {
  const token: any = localStorage.getItem("access_token");
  const projectId: any = localStorage.getItem("projectId");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/project/invoice/${projectId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const addProjectRepositoryInvoiceApi = async (invoiceDetails: any) => {
  const token: any = localStorage.getItem("access_token");
  const data = invoiceDetails;

  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/project/invoice/add-invoice`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const editProjectRepositoryInvoiceApi = async (
  id: any,
  invoiceDetails: any
) => {
  const data = invoiceDetails;
  const token: any = localStorage.getItem("access_token");

  try {
    const response = await axios({
      method: "PUT",
      url: `${baseUrl}/v1/project/invoice/update-invoice/${id}`,
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (err: any) {
    err.response.data;
  }
};

export const deleteProjectRepositoryInvoiceApi = async (id: any) => {
  const token: any = localStorage.getItem("access_token");

  try {
    const response = await axios({
      method: "DELETE",
      url: `${baseUrl}/v1/project/invoice/delete-invoice/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    err.response.data;
  }
};

export const projectInvoiceDetailsDocUploadApi = async (docFile: any) => {
  const invoiceId: any = localStorage.getItem("invoiceId");
  const token: any = localStorage.getItem("access_token");

  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/project/invoice/utils/upload/media/${invoiceId}`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      data: docFile,
    });
    return response.data;
  } catch (err: any) {
    err.response.data;
  }
};

export const getResourceAllocationApi = async (
  projectId: any,
  page: any,
  size: any,
  searchKey: any,
  searchValue: any
) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/resource-allocation/v2/resource/${projectId}?page=${page}&size=${size}&searchKey=${searchKey}&searchValue=${searchValue}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getAllResourceAllocationApi = async (
  statusObj: any,
  page: any,
  size: any,
  searchKey: any,
  serachValue: any
) => {
  const status: any = statusObj.status;
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/resource-allocation/v2/all-resources?benchStatus=${status}&page=${page}&size=${size}&searchKey=${searchKey}&searchValue=${serachValue}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const addResourceAllocationApi = async (resourceDetails: any) => {
  const token: any = localStorage.getItem("access_token");
  const data = resourceDetails;

  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/resource-allocation/add-resource`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const editResourceAllocationApi = async (
  id: any,
  resourceDetails: any
) => {
  const data = resourceDetails;
  const token: any = localStorage.getItem("access_token");

  try {
    const response = await axios({
      method: "PUT",
      url: `${baseUrl}/v1/resource-allocation/update-resource/${id}`,
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (err: any) {
    err.response.data;
  }
};

export const deleteResourceAllocationApi = async (id: any) => {
  const token: any = localStorage.getItem("access_token");

  try {
    const response = await axios({
      method: "DELETE",
      url: `${baseUrl}/v1/resource-allocation/delete-resource/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    err.response.data;
  }
};

export const getProjectByClientApi = async (clientName: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/resource-management/project-details/${clientName}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getProjectDetailsByEmployeeIdApi = async (
  employeeId: any,
  page: any,
  size: any
) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/resource-allocation/v2/projectlist/${employeeId}?page=${page}&size=${size}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getAllCompetencyApi = async () => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/technology-stack/all`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getAllTechStackApi = async () => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/technology-stack/branch-stack/all`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const deleteCompetencyCoEApi = async (id: any) => {
  const token: any = localStorage.getItem("access_token");

  try {
    const response = await axios({
      method: "DELETE",
      url: `${baseUrl}/v1/technology-stack/delete/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const addTechStackApi = async (techStackData: any) => {
  const token: any = localStorage.getItem("access_token");
  const data = techStackData;
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/technology-stack/create`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const addBranchStackApi = async (branchStackData: any) => {
  const token: any = localStorage.getItem("access_token");
  const data = branchStackData;
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/technology-stack/branch-stack/create`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getTechStackByCompetencyApi = async (id: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/technology-stack/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const deleteBranchStackApi = async (id: any) => {
  const token: any = localStorage.getItem("access_token");

  try {
    const response = await axios({
      method: "DELETE",
      url: `${baseUrl}/v1/technology-stack/branch-stack/delete/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const addSubBranchApi = async (subbranchData: any) => {
  const token: any = localStorage.getItem("access_token");
  const data = subbranchData;
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/technology-stack/branch-stack/sub-domain/create`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const addRequirementsApi = async (requirementsData: any, id: any) => {
  const token: any = localStorage.getItem("access_token");
  const data = requirementsData;
  try {
    const response = await axios({
      method: "PUT",
      url: `${baseUrl}/v1/technology-stack/branch-stack/sub-domain/update/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const editRequirementsApi = async (requirementsData: any, id: any) => {
  const token: any = localStorage.getItem("access_token");
  const data = requirementsData;

  try {
    const response = await axios({
      method: "PUT",
      url: `${baseUrl}/v1/technology-stack/branch-stack/sub-domain/update/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getAllSubBranchApi = async (id: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/technology-stack/branch-stack/sub-domain/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const deleteSubBranchStackApi = async (id: any) => {
  const token: any = localStorage.getItem("access_token");

  try {
    const response = await axios({
      method: "DELETE",
      url: `${baseUrl}/v1/technology-stack/branch-stack/sub-domain/delete/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const addRateMyselfApi = async (rating: any) => {
  const token: any = localStorage.getItem("access_token");
  const data = rating;
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/selfassessment/ratemyself/add`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getAllCompetencyRequestApi = async (status: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/learning-and-development/all-status/${status}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getAllsubDomainApi = async (employee_id: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/selfassessment/ratemyself/allsubdomain/${employee_id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const updateStatusRequestApi = async (request_id: any, data: any) => {
  const token: any = localStorage.getItem("access_token");

  try {
    const response = await axios({
      method: "PUT",
      url: `${baseUrl}/v1/learning-and-development/update/${request_id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getStatusCountApi = async () => {
  const token: any = localStorage.getItem("access_token");

  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/learning-and-development/count/competency-status`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getAllCompetencyDevApi = async () => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/selfassessment/ratemyself/all`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getProficiencyByIdApi = async (id: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/selfassessment/ratemyself/all?employee_id=${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const editCompetencyDevApi = async (
  competencyData: any,
  proficiencyId: any
) => {
  const token: any = localStorage.getItem("access_token");
  const data = competencyData;

  try {
    const response = await axios({
      method: "PUT",
      url: `${baseUrl}/v1/selfassessment/ratemyself/${proficiencyId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const competencyDevPdpPlanUploadApi = async (
  docFile: any,
  employeeId: any
) => {
  const token: any = localStorage.getItem("access_token");

  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/selfassessment/ratemyself/pdpplan/upload/${employeeId}`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      data: docFile,
    });
    return response.data;
  } catch (err: any) {
    err.response.data;
  }
};

export const getAllCountOfStatusApi = async () => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/selfassessment/ratemyself/countofstatus`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};
export const addEnrollmentDataApi = async (data: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/learning-and-development/add-competency-request`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getAllEnrollmentRequestApi = async (employee_id: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/learning-and-development/requeslist/${employee_id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getAllProficiencyByTechIdApi = async (
  id: any,
  currentProficiency: any
) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/selfassessment/ratemyself/all-proficiency?techStackId=${id}&proficiency=${currentProficiency}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getAllInternalJobsApi = async (
  page: any,
  size: any,
  searchKey: any,
  searchValue: any
) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/internal-job-posting/all?page=${page}&size=${size}&searchKey=${searchKey}&searchValue=${searchValue}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getAllInternalJobsEmployeeViewApi = async (
  page: any,
  size: any,
  searchKey: any,
  searchValue: any
) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/internal-job-posting/all-approved?page=${page}&size=${size}&searchKey=${searchKey}&searchValue=${searchValue}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getAllJobsApi = async (
  page: any,
  size: any,
  searchKey: any,
  searchValue: any
) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/skill-link/post-job/v2/all?page=${page}&size=${size}&searchKey=${searchKey}&searchValue=${searchValue}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const addPostAJobApi = async (job: any) => {
  const token: any = localStorage.getItem("access_token");
  const data = job;
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/skill-link/post-job/create`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const jobDescriptionDocUploadApi = async (docFile: any) => {
  const userLogin: any = localStorage.getItem("login");
  const loginId = JSON.parse(userLogin).id;
  const token: any = localStorage.getItem("access_token");

  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/skill-link/post-job/utils/upload/media/${loginId}`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      data: docFile,
    });
    return response.data;
  } catch (err: any) {
    err.response.data;
  }
};

export const BulkUploadApi = async (docFile: any) => {
  const token: any = localStorage.getItem("access_token");

  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/skill-link/post-job/bulk/post-job/csv/upload`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      data: docFile,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};
export const ProjectBulkUploadApi = async (docFile: any) => {
  const token: any = localStorage.getItem("access_token");
  const userLogin: any = localStorage.getItem("login");
  const loginId = JSON.parse(userLogin).id;
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/resource-management/bulk-edit-project-repository-upload/${loginId}`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      data: docFile,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};
export const ResourceBulkUploadApi = async (docFile: any) => {
  const token: any = localStorage.getItem("access_token");
  const userLogin: any = localStorage.getItem("login");
  const loginId = JSON.parse(userLogin)?.id;
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/resource-allocation/bulk-edit-resource-allocation-upload/${loginId}`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      data: docFile,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getJobByJobIdApi = async (id: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/skill-link/post-job/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const addReferAResourceApi = async (resource: any) => {
  const token: any = localStorage.getItem("access_token");
  const data = resource;
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/skill-link/refer-resource/create`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const referResourceResumeDocUploadApi = async (docFile: any) => {
  const userLogin: any = localStorage.getItem("login");
  const loginId = JSON.parse(userLogin).id;
  const token: any = localStorage.getItem("access_token");

  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/skill-link/refer-resource/utils/upload/media/${loginId}`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      data: docFile,
    });
    return response.data;
  } catch (err: any) {
    err.response.data;
  }
};

export const getReferralListByIdApi = async (
  jobId: any,
  page: any,
  size: any,
  searchKey: any,
  searchVaue: any
) => {
  const token: any = localStorage.getItem("access_token");

  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/skill-link/refer-resource/v2/${jobId}?page=${page}&size=${size}&searchKey=${searchKey}&searchValue=${searchVaue}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const editSkillLinksReferResourceApi = async (
  statusData: any,
  id: any
) => {
  const token: any = localStorage.getItem("access_token");
  const data = statusData;

  try {
    const response = await axios({
      method: "PUT",
      url: `${baseUrl}/v1/skill-link/refer-resource/update/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getInternalJobByIdApi = async (id: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/internal-job-posting/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const applyInternalJobsApi = async (resource: any) => {
  const token: any = localStorage.getItem("access_token");

  const data = resource;
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/internal-job/create`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const editSkillLinksJobStatusApi = async (status: any, jobId: any) => {
  const token: any = localStorage.getItem("access_token");
  const data = status;
  try {
    const response = await axios({
      method: "PUT",
      url: `${baseUrl}/v1/skill-link/post-job/update/${jobId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const editIJPJobStatusApi = async (status: any, jobId: any) => {
  const token: any = localStorage.getItem("access_token");
  const data = status;

  try {
    const response = await axios({
      method: "PUT",
      url: `${baseUrl}/v1/internal-job-posting/update/${jobId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getAllInternallyAppliedJobApi = async (
  page: any,
  size: any,
  searchKey: any,
  searchValue: any,
  jobId: any
) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/internal-job/v2/${jobId}?page=${page}&size=${size}&searchKey=${searchKey}&searchValue=${searchValue}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const editApplicationStatusApi = async (statusData: any, id: any) => {
  const token: any = localStorage.getItem("access_token");
  const data = statusData;

  try {
    const response = await axios({
      method: "PUT",
      url: `${baseUrl}/v1/internal-job/update/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getInseedDataApi = async (
  page: any,
  size: any,
  searchKey: any,
  searchValue: any
) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/inseed-batch/v2/all-batch?page=${page}&size=${size}&searchKey=${searchKey}&searchValue=${searchValue}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getApprovedInseedDataApi = async (
  page: any,
  size: any,
  searchKey: any,
  searchValue: any,
  batchStatus: any
) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/inseed-batch/v2/all-batch/approved?page=${page}&size=${size}&searchKey=${searchKey}&searchValue=${searchValue}&batch=${batchStatus}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const addInseedDataApi = async (data: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/inseed-batch/add-batch`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const editInseedDataApi = async (batch_id: any, inseedData: any) => {
  const data = inseedData;
  const token: any = localStorage.getItem("access_token");

  try {
    const response = await axios({
      method: "PUT",
      url: `${baseUrl}/v1/inseed-batch/update-batch/${batch_id}`,
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const deleteInseedDataApi = async (batch_id: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "DELETE",
      url: `${baseUrl}/v1/inseed-batch/delete-batch/${batch_id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getInseedStatusCountApi = async () => {
  const token: any = localStorage.getItem("access_token");

  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/inseed-batch/count/batch-status`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getAllInseedReportApi = async (status: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/inseed-batch/status/${status}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getJobCategoryApi = async () => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/job-category/all-jobcategory`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const addJobCategoryApi = async (category: any) => {
  const token: any = localStorage.getItem("access_token");
  const data = category;
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/job-category/add-jobcategory`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getAllCoachApi = async () => {
  const userLogin: any = localStorage.getItem("login");
  const loginId = JSON.parse(userLogin).id;
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/coaching-and-mentoring/be-a-coach/all?employeeRef=${loginId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getAllCoachPagApi = async (
  page: any,
  size: any,
  searchkey: any,
  searchValue: any
) => {
  const userLogin: any = localStorage.getItem("login");
  const loginId = JSON.parse(userLogin).id;
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/coaching-and-mentoring/be-a-coach/v2/all?employeeRef=${loginId}&page=${page}&size=${size}&searchKey=${searchkey}&searchValue=${searchValue}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const addBeACoachApi = async (data: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/coaching-and-mentoring/be-a-coach/add`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getInseedResourceApi = async (batch_id: any, values: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/inseed-resource/resource/${batch_id}?values=${values}`,

      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getInseedResourcePagApi = async (
  batch_id: any,
  page: any,
  size: any,
  searchKey: any,
  searchValue: any
) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/inseed-resource/resource/v2/${batch_id}?page=${page}&size=${size}&searchKey=${searchKey}&searchValue=${searchValue}`,

      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const addInseedResourceApi = async (data: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/inseed-resource/add-resource`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const editInseedResourceApi = async (
  resource_id: any,
  inseedData: any
) => {
  const data = inseedData;
  const token: any = localStorage.getItem("access_token");

  try {
    const response = await axios({
      method: "PUT",
      url: `${baseUrl}/v1/inseed-resource/update-resource/${resource_id}`,
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const deleteInseedResourceApi = async (resource_id: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "DELETE",
      url: `${baseUrl}/v1/inseed-resource/delete-resource/${resource_id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getAllCoachListApi = async (
  page: any,
  size: any,
  searchKey: any,
  searchValue: any,
  loginId: any
) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/coaching-and-mentoring/coaching-request/v2/all-coach-list/${loginId}?page=${page}&size=${size}&searchKey=${searchKey}&searchValue=${searchValue}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const addCoachRequestApi = async (data: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/coaching-and-mentoring/coaching-request/add`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getAllCoachRequestApi = async (
  page: any,
  size: any,
  searchKey: any,
  searchValue: any,
  loginId: any
) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/coaching-and-mentoring/coaching-request/v2/all-request-hrbp-list/${loginId}?page=${page}&size=${size}&searchKey=${searchKey}&searchValue=${searchValue}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getAllCoachReportCountApi = async () => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/coaching-and-mentoring/coaching-progress/count`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getAllCoachReportApi = async () => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/coaching-and-mentoring/coaching-progress/all-coach-report`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getAllCoachReportPagApi = async (
  page: any,
  pageSize: any,
  searchKey: any,
  searchValue: any
) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/coaching-and-mentoring/coaching-progress/v2/all-coach-report?page=${page}&size=${pageSize}&searchKey=${searchKey}&searchValue=${searchValue}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const updateStatusRemarksApi = async (id: any, data: any) => {
  const token: any = localStorage.getItem("access_token");

  try {
    const response = await axios({
      method: "PUT",
      url: `${baseUrl}/v1/coaching-and-mentoring/coaching-request/update/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const inseedDocUploadApi = async (docFile: any) => {
  const employeeId: any = localStorage.getItem("selectedId");
  const token: any = localStorage.getItem("access_token");

  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/inseed-resource/utils/upload/media/${employeeId}`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      data: docFile,
    });
    return response.data;
  } catch (err: any) {
    err.response.data;
  }
};

export const getAllSkillCoinsApi = async () => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/skill-coins/all`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const assignSkillCoinsApi = async (skillcoins: any) => {
  const token: any = localStorage.getItem("access_token");
  const data = skillcoins;
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/skill-coins/create`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const assignmentLetterUploadDocApi = async (id: any, docFile: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/skill-coins/utils/upload/media/${id}`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      data: docFile,
    });
    return response.data;
  } catch (err: any) {
    err.response.data;
  }
};

export const deallocateSkillCoinsApi = async (reason: any, id: any) => {
  const token: any = localStorage.getItem("access_token");
  const data = reason;

  try {
    const response = await axios({
      method: "PUT",
      url: `${baseUrl}/v1/skill-coins/update/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getFetchSkillCoinsApi = async (employeeId: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/skill-coins/getCoins/${employeeId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const editRedeemSkillCoinsApi = async (
  skillCoinsid: any,
  redeemedData: any
) => {
  const data = redeemedData;
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "PUT",
      url: `${baseUrl}/v1/skill-coins/redeemCoins/${skillCoinsid}`,
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response;
  }
};

export const getSkillCoinsReportApi = async () => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/skill-coins/report`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getAllAcceptedCoachListApi = async (
  page: any,
  size: any,
  searchKey: any,
  searchValue: any,
  id: any
) => {
  const token: any = localStorage.getItem("access_token");
  const userLogin: any = localStorage.getItem("login");
  const loginId = JSON.parse(userLogin).id;
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/coaching-and-mentoring/coaching-progress/v2/all-coach-accepted/${loginId}?page=${page}&size=${size}&searchKey=${searchKey}&searchValue=${searchValue}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getAllAcceptedEmployeeListApi = async (
  page: any,
  size: any,
  searchKey: any,
  searchValue: any,
  id: any
) => {
  const token: any = localStorage.getItem("access_token");
  const userLogin: any = localStorage.getItem("login");
  const loginId = JSON.parse(userLogin).id;
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/coaching-and-mentoring/coaching-request/v2/all-employee-request-list/${loginId}?page=${page}&size=${size}&searchKey=${searchKey}&searchValue=${searchValue}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getAllSessionByEmpIdApi = async (
  page: any,
  size: any,
  searchValue: any,
  employeeId: any,
  coachId: any
) => {
  const token: any = localStorage.getItem("access_token");

  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/coaching-and-mentoring/coaching-progress/v2/session-list/${employeeId}/${coachId}?page=${page}&size=${size}&searchKey=sessionName&searchValue=${searchValue}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getEvaluationDataApi = async (
  batch_id: any,
  page: any,
  size: any
) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/inseed-evaluation/v2/individual-program/${batch_id}?page=${page}&size=${size}`,

      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const editEvaluationDataApi = async (
  evaluation_id: any,
  inseedData: any
) => {
  const data = inseedData;
  const token: any = localStorage.getItem("access_token");

  try {
    const response = await axios({
      method: "PUT",
      url: `${baseUrl}/v1/inseed-evaluation/update-evaluation/${evaluation_id}`,
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (err: any) {
    err.response.data;
  }
};

export const coachEmployeeDocUploadApi = async (docFile: any) => {
  const userLogin: any = localStorage.getItem("login");
  const loginId = JSON.parse(userLogin).id;
  const token: any = localStorage.getItem("access_token");

  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/coaching-and-mentoring/coaching-progress/utils/upload/media/${loginId}`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      data: docFile,
    });
    return response.data;
  } catch (err: any) {
    err.response.data;
  }
};

export const addNewSessionApi = async (sessionData: any) => {
  const token: any = localStorage.getItem("access_token");
  const data: any = sessionData;
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/coaching-and-mentoring/coaching-progress/add`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    err.response.data;
  }
};

export const ratingForSessionApi = async (id: any, ratingData: any) => {
  const token: any = localStorage.getItem("access_token");
  const data: any = ratingData;
  try {
    const response = await axios({
      method: "PUT",
      url: `${baseUrl}/v1/coaching-and-mentoring/coaching-progress/update/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const coachUpdateHwStatusApi = async (id: any, statusData: any) => {
  const token: any = localStorage.getItem("access_token");
  const data: any = statusData;
  try {
    const response = await axios({
      method: "PUT",
      url: `${baseUrl}/v1/coaching-and-mentoring/coaching-progress/update/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const uploadEmpHwApi = async (id: any, hwData: any) => {
  const token: any = localStorage.getItem("access_token");
  const data: any = hwData;
  try {
    const response = await axios({
      method: "PUT",
      url: `${baseUrl}/v1/coaching-and-mentoring/coaching-progress/update/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getAllTrainingsApi = async (
  page: any,
  size: any,
  searchKey: any,
  searchValue: any
) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/inseed-training/v2/all-trainings?page=${page}&size=${size}&searchKey=${searchKey}&searchValue=${searchValue}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getTrainingsByIdApi = async (id: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/inseed-training/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const createTrainingApi = async (trainingDetails: any) => {
  const token: any = localStorage.getItem("access_token");
  const data = trainingDetails;
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/inseed-training/add-training`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const trainingPlanUploadDocApi = async (docFile: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/inseed-batch/utils/upload/media`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      data: docFile,
    });
    return response.data;
  } catch (err: any) {
    err.response.data;
  }
};

export const editTrainingPlanApi = async (
  trainingId: any,
  trainingData: any
) => {
  const data = trainingData;
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "PUT",
      url: `${baseUrl}/v1/inseed-training/update-training/${trainingId}`,
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response;
  }
};

export const deleteTrainingModuleApi = async (trainingId: any) => {
  const token: any = localStorage.getItem("access_token");

  try {
    const response = await axios({
      method: "DELETE",
      url: `${baseUrl}/v1/inseed-training/delete-training/${trainingId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getDownloadPolicyApi = async (policyName: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/policy/${policyName}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const addClientNameApi = async (client: any) => {
  const token: any = localStorage.getItem("access_token");
  const data = client;
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/client/add-client`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getClientNameApi = async () => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/client/all-client`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const fetchSearchDataApi = async (query: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/employee/search?value=${query}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getCoachingReportPdfApi = async (
  coachId: any,
  page: any,
  size: any,
  searchKey: any,
  searchValue: any
) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/coaching-and-mentoring/coaching-progress/v2/report/${coachId}?page=${page}&size=${size}&searchKey=${searchKey}&searchValue=${searchValue}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getAllNotificationfApi = async () => {
  const token: any = localStorage.getItem("access_token");
  const userLogin: any = localStorage.getItem("login");
  const employeeId = JSON.parse(userLogin).employeeId;
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/notification-list/get/employee/${employeeId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const editNotificationApi = async (
  notificationId: any,
  notificationData: any
) => {
  const data = notificationData;
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "PUT",
      url: `${baseUrl}/v1/notification-list/update/${notificationId}`,
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response;
  }
};

export const getInseedAllModulesApi = async (
  size: any,
  page: any,
  searchKey: any,
  searchValue: any
) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/inseed-training/module-master/v2/all?page=${page}&size=${size}&searchKey=${searchKey}&searchValue=${searchValue}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};
export const getModulesByIdApi = async (id: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/inseed-training/module-master/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const addInseedModuleApi = async (data: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/inseed-training/module-master/create`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const editInseedModuleApi = async (module_id: any, inseedData: any) => {
  const data = inseedData;
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "PUT",
      url: `${baseUrl}/v1/inseed-training/module-master/update/${module_id}`,
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    err.response.data;
  }
};

export const deleteInseedModuleApi = async (module_id: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "DELETE",
      url: `${baseUrl}/v1/inseed-training/module-master/delete/${module_id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getAssessmentByIdApi = async (
  size: any,
  page: any,
  searchKey: any,
  searchValue: any,
  id: any
) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/inseed-training/assessment-master/batch/v2/${id}?page=${page}&size=${size}&searchKey=${searchKey}&searchValue=${searchValue}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const addAssessmentApi = async (data: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/inseed-training/assessment-master/create`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const editAssessmentApi = async (
  assessmentId: any,
  assessmentData: any
) => {
  const data = assessmentData;
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "PUT",
      url: `${baseUrl}/v1/inseed-training/assessment-master/update/${assessmentId}`,
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    err.response.data;
  }
};

export const deleteAssessmentApi = async (assessmentId: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "DELETE",
      url: `${baseUrl}/v1/inseed-training/assessment-master/delete/${assessmentId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getViewAllModulesIdApi = async (id: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/inseed-training/assessment-master/batch/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getAllModulesByTrainingIdDataApi = async (training_id: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/inseed-training/${training_id}`,

      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const EmployeeBulkUploadApi = async (docFile: any) => {
  const token: any = localStorage.getItem("access_token");

  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/employee/bulk-upload`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      data: docFile,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const postEmailNotificationApi = async (
  qualifications: any,
  employeeRef: any
) => {
  const token: any = localStorage.getItem("access_token");
  const details = qualifications;
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/email-notification/send/${employeeRef}`,
      data: details,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    err.response.data;
  }
};

export const getReportLessthan3DaysApi = async () => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/employee-database/status/less-than-three-days`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getReportGreaterthan3DaysApi = async () => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/employee-database/status/more-than-and-equal-to-three-days`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const EmployeeSkillsBulkUploadApi = async (docFile: any) => {
  const token: any = localStorage.getItem("access_token");

  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/employee/bulk-csv-details-upload`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      data: docFile,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getSkillCoinValueApi = async () => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/skill-coins/value/all`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const updateSkillCoinValueApi = async (skillCoinValue: any) => {
  const token: any = localStorage.getItem("access_token");
  const valueId: any = skillCoinValue.displayId;
  const data: any = { skillCoinValue: skillCoinValue.value };

  try {
    const response = await axios({
      method: "PUT",
      url: `${baseUrl}/v1/skill-coins/value/update/${valueId}`,
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const transactionHistoryApi = async (
  employeeId: any,
  page: any,
  size: any
) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/skill-coins/transactions/v2/${employeeId}?page=${page}&size=${size}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getSkillCoinsYearlyReportApi = async (
  fromYear: any,
  toYear: any
) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/skill-coins/transactions/year?fromYear=${fromYear}&toYear=${toYear}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getEmployeeMakerCheckerApi = async () => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/email-notification/email-group/all`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const ModuleBulkUploadApi = async (docFile: any) => {
  const token: any = localStorage.getItem("access_token");

  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/inseed-resource/bulk-resource-upload`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      data: docFile,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const uploadModuleSyllabusApi = async (docFile: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/inseed-training/module-master/utils/upload/media`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      data: docFile,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const EditBeACoachApi = async (id: any, data: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "PUT",
      url: `${baseUrl}/v1/coaching-and-mentoring/be-a-coach/update/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const UploadProfileBeACoach = async (docFile: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/coaching-and-mentoring/be-a-coach/utils/upload/media`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      data: docFile,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const ViewUploadProfileBeACoach = async (docFile: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/coaching-and-mentoring/be-a-coach/filename`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: docFile,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getInvoicesByProjectIdApi = async (project_id: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/resource-management/project/${project_id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getInvoicesByInvoiceIdApi = async (invoice_id: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/project/invoice/invoice/${invoice_id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getSkillDataPaginationApi = async (data: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/skill-coins/v2/all`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};
export const getTechSpecReportApi = async (
  coachId: any,
  page: any,
  size: any,
  searchKey: any,
  searchValue: any
) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/coaching-and-mentoring/coaching-progress/v2/technicalspecicationlist/${coachId}?page=${page}&size=${size}&searchKey=${searchKey}&searchValue=${searchValue}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getTechSpecDetailsApi = async (
  techId: any,
  page: any,
  size: any,
  searchKey: any,
  searchValue: any
) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/coaching-and-mentoring/coaching-progress/v2/sessionlist/${techId}?page=${page}&size=${size}&searchKey=${searchKey}&searchValue=${searchValue}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (err: any) {
    err.response.data;
  }
};

export const assignBulkSkillCoinsApi = async (skillcoins: any) => {
  const token: any = localStorage.getItem("access_token");
  const data = skillcoins;
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/skill-coins/create/multiple/employee`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getSkillCoinByEmpIdPaginationApi = async (
  employeeId: any,
  size: any,
  page: any,
  searchKey: any,
  searchValue: any
) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/skill-coins/v2/getCoins/${employeeId}?page=${page}&size=${size}&searchKey=${searchKey}&searchValue=${searchValue}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getSkillLinksEmployeeViewApi = async (
  page: any,
  size: any,
  searchKey: any,
  searchValue: any
) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/skill-link/post-job/v2/all-approved?page=${page}&size=${size}&searchKey=${searchKey}&searchValue=${searchValue}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getCoachMasterListApi = async () => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/coaching-and-mentoring/coach-checker-master/all-checker-and-coachList`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getApplicationDetailsViewApi = async (applicationId: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/internal-job/${applicationId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};
export const resumeDocUploadApi = async (docFile: any) => {
  const token: any = localStorage.getItem("access_token");

  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/internal-job/utils/upload/media`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      data: docFile,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const viewUploadProfileResume = async (docFile: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/internal-job/filename`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: docFile,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const addPostAJobIJPApi = async (job: any) => {
  const token: any = localStorage.getItem("access_token");
  const data = job;
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/internal-job-posting/create`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const jobDescriptionDocumentUploadApi = async (docFile: any) => {
  const token: any = localStorage.getItem("access_token");

  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/internal-job-posting/utils/upload/media`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      data: docFile,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const viewJobDescriptionDocApi = async (docFile: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/internal-job-posting/filename`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: docFile,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const IJPBulkUploadApi = async (docFile: any) => {
  const token: any = localStorage.getItem("access_token");

  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/internal-job-posting/bulk/post-job/csv/upload`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      data: docFile,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};
export const addInseedLocationApi = async (job: any) => {
  const token: any = localStorage.getItem("access_token");
  const data = job;
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/inseed-batch/training-loaction/create`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};
export const getInseedLocationApi = async () => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/inseed-batch/training-loaction/all`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const addLearningRequestApi = async (data: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/learning-and-development/add-learning-request`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const learningMasterCheckerApi = async () => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/LearningAndDevelopment/checker-master/all`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const learningManagerCheckerApi = async () => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/LearningAndDevelopment/manager-master/all`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const learningFinanceCheckerApi = async () => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/LearningAndDevelopment/finance-master/all`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const coachingRequestListApi = async () => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/coaching-and-mentoring/hrbp-master/all`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const learningAndDevelopmentHrRequestsApi = async (
  page: any,
  size: any,
  searchKey: any,
  searchValue: any
) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/learning-and-development/hr-request-list?page=${page}&size=${size}&searchKey=${searchKey}&searchValue=${searchValue}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const learningAndDevelopmentManagerRequestsApi = async (
  requestId: any,
  page: any,
  size: any,
  searchKey: any,
  searchValue: any
) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/learning-and-development/manager-request-list/${requestId}?page=${page}&size=${size}&searchKey=${searchKey}&searchValue=${searchValue}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const learningAndDevelopmentFinanceRequestsApi = async (
  page: any,
  size: any,
  searchKey: any,
  searchValue: any
) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/learning-and-development/finace-request-list?page=${page}&size=${size}&searchKey=${searchKey}&searchValue=${searchValue}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const learningAndDevelopmentDetailsApi = async (requestId: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/learning-and-development/${requestId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const specificEmployeeReferredListApi = async (
  page: any,
  size: any,
  searchKey: any,
  searchValue: any,
  employeeId: any
) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/skill-link/refer-resource/get-referral-list/${employeeId}?page=${page}&size=${size}&searchKey=${searchKey}&searchValue=${searchValue}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const AllEmployeesReferralHistoryApi = async (
  page: any,
  size: any,
  searchKey: any,
  searchValue: any
) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/skill-link/refer-resource/get-referral-history?page=${page}&size=${size}&searchKey=${searchKey}&searchValue=${searchValue}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const employeeDbCountryListApi = async () => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/employee/countries-master/all`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const addEmpoyeeDbCountryListApi = async (data: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/employee/countries-master/create`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getEmployeeDbHrbpListApi = async () => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/employee/all/hrbp`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getEligibleReferralPayoutsApi = async (
  page: any,
  size: any,
  searchKey: any,
  searchValue: any
) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/skill-links/eligible-referrals/all?page=${page}&size=${size}&searchKey=${searchKey}&searchValue=${searchValue}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const putEligibleReferralPayoutsApi = async (id: any, data: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "PUT",
      url: `${baseUrl}/v1/skill-links/eligible-referrals/update/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const skillCoinUploadApi = async (docFile: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/skill-coins/bulk-deallocate-skill-coin-data-upload`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      data: docFile,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};
export const addCompetencyApi = async (obj: any) => {
  const token: any = localStorage.getItem("access_token");
  const data = obj;
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/competency-planning-and-management/employeeCompetency/create`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getStreamsListApi = async () => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/competency-planning-and-management/stream/all`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getSubStreamsListApi = async (id: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/competency-planning-and-management/sub-stream/subStream/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getQuestionsListApi = async () => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/competency-planning-and-management/selfassessment-question-type/all-questionType-with-question-list`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};
export const addSelfAssessmentApi = async (obj: any) => {
  const token: any = localStorage.getItem("access_token");
  const data = obj;
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/competency-planning-and-management/selfassessment/create`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const checkCompenetencyApi = async (obj: any) => {
  const token: any = localStorage.getItem("access_token");
  const data = obj;
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/competency-planning-and-management/selfassessment/multiple/selfAssessment/validation`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getSelfAssessmentData = async (obj: any) => {
  const token: any = localStorage.getItem("access_token");
  const data = obj;
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/competency-planning-and-management/selfassessment/my-assessment`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getAssessmentListById = async (obj: any) => {
  const token: any = localStorage.getItem("access_token");
  const data = obj;
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/competency-planning-and-management/selfassessment/employee/assessment/question`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const gerCertificationlist = async (obj: any) => {
  const token: any = localStorage.getItem("access_token");
  const data = obj;
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/competency-planning-and-management/certifications/my-certifications`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const downloadCertification = async (obj: any) => {
  const token: any = localStorage.getItem("access_token");
  const data = obj;
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/competency-planning-and-management/certifications/filename`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getCompetancylist = async (employeeRef: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/competency-planning-and-management/employeeCompetency/employeeCompetency/${employeeRef}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const AddCertification = async (obj: any) => {
  const token: any = localStorage.getItem("access_token");
  const data = obj;
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/competency-planning-and-management/certifications/create`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const UploadCertificate = async (docFile: any) => {
  const token: any = localStorage.getItem("access_token");

  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/competency-planning-and-management/certifications/utils/upload/media`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      data: docFile,
    });
    return response.data;
  } catch (err: any) {
    err.response.data;
  }
};
export const bulkApproveApi = async (docFile: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/resource-allocation/bulk-approve-resource`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      data: docFile,
    });
    return response.data;
  } catch (err: any) {
    err.response.data;
  }
};

export const UpdateCertificate = async (certificationsId: any, data: any) => {
  const token: any = localStorage.getItem("access_token");

  try {
    const response = await axios({
      method: "PUT",
      url: `${baseUrl}/v1/competency-planning-and-management/certifications/update/${certificationsId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getStreamsList = async (name: any, data: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/competency-planning-and-management/${name}/all-${name}s`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const AddStreams = async (stream: any, data: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/competency-planning-and-management/${stream}/create`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const UpdateStreams = async (stream: any, streamId: any, data: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "PUT",
      url: `${baseUrl}/v1/competency-planning-and-management/${stream}/update/${streamId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getLeaderList = async () => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/competency-planning-and-management/stream/all-leaders`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};
export const getAllResourceExportApi = async () => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/resource-allocation/all-resource/export`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const AddBench = async (data: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/resource-management/bench-management/create`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const GetBenchTableApi = async (data: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/resource-management/bench-management/in-bench`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};
export const GetProjectHistoryApi = async (data: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/resource-management/bench-management/employee/project-history`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};
export const CompleteRealse = async (benchManagementId: any, data: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "PUT",
      url: `${baseUrl}/v1/resource-management/bench-management/update/${benchManagementId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const generateResumeDataApi = async () => {
  const token: any = localStorage.getItem("access_token");
  const userLogin: any = localStorage.getItem("selectedId");
  // const employeeId = JSON.parse(userLogin).employeeId;
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/employee/generate-resume/${userLogin}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const postProfileSummaryApi = async (data: any, id: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "PUT",
      url: `${baseUrl}/v1/employee/update/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};
export const ProfileReminderApi = async (data: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/employee/profile-summary/reminder`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getAllBenchResourceApi = async (data: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/resource-allocation/bench-data`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getAllEmployeeRoleManagementApi = async (payload: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/employee/get-all-auth-users`,
      data: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (err: any) {
    err.response.data;
  }
};
export const getAllRolesApi = async () => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/employee/get-all/auth-roles`,
      data: {
        group_id: group_id,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (err: any) {
    err.response.data;
  }
};

export const postUpdateRolesApi = async (data: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/employee/update-user/auth-roles`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const deleteAllRolesApi = async (payload: any) => {
  const token: any = localStorage.getItem("access_token");

  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/v1/employee/remove-auth-roles`,
      data: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};


export const getProjectHistoryByProjectIdApi = async (project_id: any) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/v1/resource-management/getAll/POHistory/${project_id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};
