import  { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as actions from "../../../../Redux/Actions/index";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../../component/Toasts/Toasts";
import { Dropdown } from "react-bootstrap";
import {
  Strings,
  addInvoiceErrorsValidations,
  invoiceModalLabels,
  requiredFeild,
  invoiceStatusDropdown,
} from "../../../../constant/Strings/index";
import {
  invoiceInvoiceNumberValidation,
  invoiceRevenueMonthValidation,
  invoiceBaseAmountValidation,
  invoiceCgstValidation,
  invoiceSgstValidation,
  invoiceIgstValidation,
  invoiceVatValidation,
  invoiceTdsValidation,
  invoiceTotalAmountValidation,
  invoicePaidAmountValidation,
  invoiceDifferenceAmountValidation,
} from "../../../../constant/Validations";
import { timeDateFormatYMD } from "../../../../component/FormatDate";

function EditInvoice(props: any) {
  const { id, projectInvoiceDetails, handleEditProjectInvoice } = props;
  const {
    difference_amount,
    paid_amount,
    total_amount,
    tds,
    vat,
    igst,
    sgst,
    cgst,
    baseAmount,
    revenueMonth,
    invoiceNumber,
  } = addInvoiceErrorsValidations;
  const { cancel, submit, uploadInvoice, uploadFile, imageSize, editSuccess } =
    Strings;
  const [btnDisable, setBtnDisable] = useState(false);

  const [projectInvoiceData, setProjectInvoiceData] = useState<any>({
    projectRef: projectInvoiceDetails.projectRef,
    revenueMonth: projectInvoiceDetails.revenueMonth,
    baseAmount: projectInvoiceDetails.baseAmount,
    cgst: projectInvoiceDetails.cgst,
    sgst: projectInvoiceDetails.sgst,
    igst: projectInvoiceDetails.igst,
    vat: projectInvoiceDetails.vat,
    tds: projectInvoiceDetails.tds,
    totalAmount: projectInvoiceDetails.totalAmount,
    paidAmount: projectInvoiceDetails.paidAmount,
    differenceAmount: projectInvoiceDetails.differenceAmount,
    status: projectInvoiceDetails.status,
    remarks: projectInvoiceDetails.remarks,

    invoiceNumber: projectInvoiceDetails.invoiceNumber,

    invoiceDate: projectInvoiceDetails.invoiceDate,
    paidDate: projectInvoiceDetails.paidDate,

    invoicePdf: projectInvoiceDetails.invoicePdf,
  });
  useEffect(() => {
    if (
      projectInvoiceData.revenueMonth &&
      projectInvoiceData.baseAmount &&
      projectInvoiceData.cgst &&
      projectInvoiceData.sgst &&
      projectInvoiceData.igst &&
      projectInvoiceData.vat &&
      projectInvoiceData.tds &&
      projectInvoiceData.totalAmount &&
      projectInvoiceData.paidAmount &&
      projectInvoiceData.differenceAmount &&
      projectInvoiceData.status &&
      projectInvoiceData.invoiceNumber &&
      projectInvoiceData.paidDate &&
      projectInvoiceData.invoicePdf
    ) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  });

  const [errors, setErrors] = useState({
    invoiceNumber: "",
    revenueMonth: "",
    baseAmount: "",
    cgst: "",
    sgst: "",
    igst: "",
    vat: "",
    tds: "",
    totalAmount: "",
    paidAmount: "",
    differenceAmount: "",
    status: "",
  });

  const dispatch = useDispatch();
  let valid: boolean = true;
  const validate = () => {
    if (
      invoiceInvoiceNumberValidation(projectInvoiceData.invoiceNumber) == false
    ) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        invoiceNumber: invoiceNumber,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        invoiceNumber: "",
      }));
    }
    if (
      invoiceRevenueMonthValidation(projectInvoiceData.revenueMonth) == false
    ) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        revenueMonth: revenueMonth,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        revenueMonth: "",
      }));
    }
    if (invoiceBaseAmountValidation(projectInvoiceData.baseAmount) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        baseAmount: baseAmount,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        baseAmount: "",
      }));
    }
    if (invoiceCgstValidation(projectInvoiceData.cgst) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        cgst: cgst,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        cgst: "",
      }));
    }
    if (invoiceSgstValidation(projectInvoiceData.sgst) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        sgst: sgst,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        sgst: "",
      }));
    }
    if (invoiceIgstValidation(projectInvoiceData.igst) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        igst: igst,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        igst: "",
      }));
    }
    if (invoiceVatValidation(projectInvoiceData.vat) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        vat: vat,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        vat: "",
      }));
    }
    if (invoiceTdsValidation(projectInvoiceData.tds) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        tds: tds,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        tds: "",
      }));
    }
    if (invoiceTotalAmountValidation(projectInvoiceData.totalAmount) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        totalAmount: total_amount,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        totalAmount: "",
      }));
    }
    if (invoicePaidAmountValidation(projectInvoiceData.paidAmount) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        paidAmount: paid_amount,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        paidAmount: "",
      }));
    }
    if (
      invoiceDifferenceAmountValidation(projectInvoiceData.differenceAmount) ==
      false
    ) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        differenceAmount: difference_amount,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        differenceAmount: "",
      }));
    }
  };
  const handleEditInvoiceSubmit = () => {
    validate();
    if (valid) {
      handleDispatch();
      handleEditProjectInvoice();
    }
  };
  const handleDispatch = () => {
    dispatch(
      actions.editProjectRepositoryInvoiceAction(id, projectInvoiceData)
    );
    handleEditProjectInvoice();
    showSuccessToastMessage(editSuccess);
  };

  const [docFile, setDocFile] = useState<any>({
    invoicePdf: null,
  });

  const handleSubmitInvoicePdf = (e: any) => {
    const file = e.target.files[0];
    const fileSize = file.size;
    const fileType = file.type;
    if (fileSize > 5 * 1024 * 1024) {
      showErrorToastMessage("File size too large. Maximum size is 5 MB.");
      return;
    } else if (
      ![
        "image/jpeg",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/pdf",
      ].includes(fileType)
    ) {
      showErrorToastMessage(
        "File type not allowed. Allowed types are JPEG, DOC, and PDF."
      );
      return;
    } else {
      setDocFile({ ...docFile, invoicePdf: e.target.files[0] });
      dispatch(
        actions.projectDetailsDocUploadAction(
          { file: e.target.files[0] },
          (res: any) => {
            if (res.code == 200 && res.data) {
              setProjectInvoiceData({
                ...projectInvoiceData,
                invoicePdf: res.data,
              });
            }
          }
        )
      );
    }
  };

  return (
    <>
      <div className="row g-3">
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {invoiceModalLabels.invoiceNo}
          </label>
          <span className="required">{requiredFeild.required}</span>
          <input
            type="text"
            className="form-control"
            value={projectInvoiceData.invoiceNumber}
            onChange={(e) =>
              setProjectInvoiceData({
                ...projectInvoiceData,
                invoiceNumber: e.target.value,
              })
            }
          />
          <p className="error">{errors.invoiceNumber}</p>
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {invoiceModalLabels.invoiceData}
          </label>
          <span className="required">{requiredFeild.required}</span>
          <input
            type="date"
            className="form-control"
            value={timeDateFormatYMD(projectInvoiceData.invoiceDate)}
            onChange={(e) =>
              setProjectInvoiceData({
                ...projectInvoiceData,
                invoiceDate: e.target.value,
              })
            }
          />
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {invoiceModalLabels.revenueMonth}
          </label>
          <span className="required">{requiredFeild.required}</span>
          <input
            type="text"
            className="form-control"
            value={projectInvoiceData.revenueMonth}
            onChange={(e) =>
              setProjectInvoiceData({
                ...projectInvoiceData,
                revenueMonth: e.target.value,
              })
            }
          />
          <p className="error">{errors.revenueMonth}</p>
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {invoiceModalLabels.baseAmount}
          </label>
          <span className="required">{requiredFeild.required}</span>
          <input
            type="text"
            className="form-control"
            value={projectInvoiceData.baseAmount}
            onChange={(e) =>
              setProjectInvoiceData({
                ...projectInvoiceData,
                baseAmount: e.target.value.replace(/\D/g, ""),
              })
            }
          />
          <p className="error">{errors.baseAmount}</p>
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {invoiceModalLabels.cgst}
          </label>
          <span className="required">{requiredFeild.required}</span>
          <input
            type="text"
            className="form-control"
            value={projectInvoiceData.cgst}
            onChange={(e) =>
              setProjectInvoiceData({
                ...projectInvoiceData,
                cgst: e.target.value,
              })
            }
          />
          <p className="error">{errors.cgst}</p>
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {invoiceModalLabels.sgst}
          </label>
          <span className="required">{requiredFeild.required}</span>
          <input
            type="text"
            className="form-control"
            value={projectInvoiceData.sgst}
            onChange={(e) =>
              setProjectInvoiceData({
                ...projectInvoiceData,
                sgst: e.target.value,
              })
            }
          />
          <p className="error">{errors.sgst}</p>
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {invoiceModalLabels.igst}
          </label>
          <span className="required">{requiredFeild.required}</span>
          <input
            type="text"
            className="form-control"
            value={projectInvoiceData.igst}
            onChange={(e) =>
              setProjectInvoiceData({
                ...projectInvoiceData,
                igst: e.target.value,
              })
            }
          />
          <p className="error">{errors.igst}</p>
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {invoiceModalLabels.vat}
          </label>
          <span className="required">{requiredFeild.required}</span>
          <input
            type="text"
            className="form-control"
            value={projectInvoiceData.vat}
            onChange={(e) =>
              setProjectInvoiceData({
                ...projectInvoiceData,
                vat: e.target.value,
              })
            }
          />
          <p className="error">{errors.vat}</p>
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {invoiceModalLabels.tds}
          </label>
          <span className="required">{requiredFeild.required}</span>
          <input
            type="text"
            className="form-control"
            value={projectInvoiceData.tds}
            onChange={(e) =>
              setProjectInvoiceData({
                ...projectInvoiceData,
                tds: e.target.value.replace(/\D/g, ""),
              })
            }
          />
          <p className="error">{errors.tds}</p>
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {invoiceModalLabels.totalAmount}
          </label>
          <span className="required">{requiredFeild.required}</span>
          <input
            type="text"
            className="form-control"
            value={projectInvoiceData.totalAmount}
            onChange={(e) =>
              setProjectInvoiceData({
                ...projectInvoiceData,
                totalAmount: e.target.value.replace(/\D/g, ""),
              })
            }
          />
          <p className="error">{errors.totalAmount}</p>
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {invoiceModalLabels.paidAmount}
          </label>
          <span className="required">{requiredFeild.required}</span>
          <input
            type="text"
            className="form-control"
            value={projectInvoiceData.paidAmount}
            onChange={(e) =>
              setProjectInvoiceData({
                ...projectInvoiceData,
                paidAmount: e.target.value.replace(/\D/g, ""),
              })
            }
          />
          <p className="error">{errors.paidAmount}</p>
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {invoiceModalLabels.diffAmount}
          </label>
          <span className="required">{requiredFeild.required}</span>
          <input
            type="text"
            className="form-control"
            value={projectInvoiceData.differenceAmount}
            onChange={(e) =>
              setProjectInvoiceData({
                ...projectInvoiceData,
                differenceAmount: e.target.value.replace(/\D/g, ""),
              })
            }
          />
          <p className="error">{errors.differenceAmount}</p>
        </div>

        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {invoiceModalLabels.status}
          </label>
          <span className="required">{requiredFeild.required}</span>
          <Dropdown
            onSelect={(eventKey: any) =>
              setProjectInvoiceData({
                ...projectInvoiceData,
                status: eventKey,
              })
            }
          >
            <Dropdown.Toggle variant="light" className="form-control">
              {projectInvoiceData.status}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {invoiceStatusDropdown.map((element: any) => (
                <Dropdown.Item eventKey={element}>{element}</Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {invoiceModalLabels.paidDate}
          </label>
          <span className="required">{requiredFeild.required}</span>
          <input
            type="date"
            className="form-control"
            value={timeDateFormatYMD(projectInvoiceData.paidDate)}
            onChange={(e) =>
              setProjectInvoiceData({
                ...projectInvoiceData,
                paidDate: e.target.value,
              })
            }
          />
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {invoiceModalLabels.remark}
          </label>
          <input
            type="text"
            className="form-control"
            value={projectInvoiceData.remarks}
            onChange={(e) =>
              setProjectInvoiceData({
                ...projectInvoiceData,
                remarks: e.target.value,
              })
            }
          />
        </div>
        <div className="col-md-4">
          <label className="form-label">{uploadInvoice}</label>
          <span className="required">{requiredFeild.required}</span>
          <div>
            <input
              type="file"
              className="form-control"
              onChange={handleSubmitInvoicePdf}
              hidden
              id="actual-btn-invoice"
            />
            <label
              htmlFor="actual-btn-invoice"
              className="btn btn-outline-secondary buttonsize"
            >
              {uploadFile}
            </label>
          </div>
          <p className="form-label">
            {imageSize}
            <span className="required">{requiredFeild.required}</span>
          </p>
          <a
            className="buttonsize"
            href={projectInvoiceData.invoicePdf?.document_path_url}
            target="_blank"
          >
            {projectInvoiceData.invoicePdf?.document_path_url
              ? "View File"
              : ""}
          </a>
          <p className="image-size-condition">{docFile.sow?.name}</p>
        </div>

        <div className="modal-btn-wrapper mt-5 mx-auto">
          <div>
            <button
              className="btn cancel-btn buttonsize"
              onClick={handleEditProjectInvoice}
            >
              {cancel}
            </button>
          </div>
          <div>
            <button
              className={
                btnDisable
                  ? "btn add-submit-button buttonsize"
                  : "btn add-submit-button btn-disabled buttonsize"
              }
              onClick={handleEditInvoiceSubmit}
            >
              {submit}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditInvoice;
