import { Strings } from "../../../constant/Strings";
import "./TrainingSchedule.scss";

const ViewAllModulesComponent = (props: any) => {
  const {
    moduleTitle,
    syllabus,
    successCriteria,
    trainer_name_value,
    technology_stream_value,
    duration_value,
    trainer_contact_value,
    trainer_institute_value,
  } = props;
  const {
    trainer_name,
    technology_stream,
    durationDays,
    trainer_contact,
    trainer_institute,
    content,
    success_criteria,
    module_name,
  } = Strings;
  return (
    <div className="card m-2 p-3">
      <div className="row">
        <div className="col-md-6">
          <div className="project-heading">{module_name} :</div>
          <div className="project-name">{moduleTitle}</div>
        </div>
        <div className="col-md-4 mx-4">
          <div className="row">
            <div className="col-md-6 module-title-label">{trainer_name}</div>
            <div className="col-md-6">{trainer_name_value}</div>
            <div className="col-md-6 module-title-label">
              {technology_stream}
            </div>
            <div className="col-md-6">{technology_stream_value}</div>
            <div className="col-md-6 module-title-label">{durationDays}</div>
            <div className="col-md-6">{duration_value}</div>
            <div className="col-md-6 module-title-label">{trainer_contact}</div>
            <div className="col-md-6">{trainer_contact_value}</div>
            <div className="col-md-6 module-title-label">
              {trainer_institute}
            </div>
            <div className="col-md-6">{trainer_institute_value}</div>
          </div>
        </div>
      </div>
      <div className="module-title-label">{content} :</div>
      <div className="selected-modules">{syllabus}</div>
      <div className="module-title-label">{success_criteria} :</div>
      <div className="selected-modules">{successCriteria}</div>
    </div>
  );
};

export default ViewAllModulesComponent;
