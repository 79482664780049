import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import * as actions from "../Redux/Actions";
import { useDispatch } from "react-redux";;

const SharedLayout = () => {
  const logOut = useSelector((state) => state.employeeReducer.logOut);
  const token = localStorage.getItem("access_token");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.userLoginAction(token));
  }, [logOut]);

  return (
    <>
      <div>
        <Outlet />
      </div>
    </>
  );
};

export default SharedLayout;
