import { useEffect, useMemo, useState } from "react";
import { TiArrowUnsorted } from "react-icons/ti";
import ModalComponents from "../../../component/ModalComponent/ModalComponent";
import SearchBar from "../../../component/SearchBar/SearchBar";
import { useParams } from "react-router-dom";

import {
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy,
} from "react-table";
import { useDispatch, useSelector } from "react-redux";
import ShowEntries from "../../../component/ShowEntries/ShowEntries";
import AddTraining from "./AddTraining";
import MainCard from "../../../component/MainCard/MainCard";
import { showErrorToastMessage } from "../../../component/Toasts/Toasts";
import * as actions from "../../../Redux/Actions";
import useLoadingIndicator from "../../../component/Loader/Loader";
import FilterValues from "../../../component/FieldFilter/FilterValues";
import TablePagination from "../../../component/TablePagination/TablePagination";
import { AllTrainingsFilter, Strings } from "../../../constant/Strings";
import BackButton from "../../../component/Buttons/BackButton";
import { timeDateFormat } from "../../../component/FormatDate";

const AllTrainings = () => {
  const {
    loadingComponent,
    showLoadingIndicator,
    hideLoadingIndicator,
    apiCallCompleted,
  } = useLoadingIndicator();
  const dispatch = useDispatch();
  const [page, setPage] = useState<Number>(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchValue, setSearchValue] = useState<any>("");
  const [filterValue, setFilterValue] = useState<any>([]);
  const [totalEntries, setTotalEntries] = useState<any>();
  const [totalRecords, setTotalRecords] = useState<any>(0);
  const [pageSize, setPageSize] = useState(10);
  const [info, setInfo] = useState([]);
  let { id } = useParams();
  const addModule = useSelector(
    (state: any) => state.employeeReducer.addInseedModuleDetails
  );
  const { no_data_found, filters, all_trainings } = Strings;
  const [onclickSearch, setOnclickSearch] = useState(false);
  const onSearchClicked = (value: any) => {
    setOnclickSearch(value);
  };
  const condition: boolean =
    (filterValue.length == 0 && searchValue == "") ||
    (filterValue.length > 0 && onclickSearch);
  const filterKey = filterValue.map((ele: any) => ele.value);
  const getApiDispatch = () => {
    dispatch(
      actions.getAllTrainingsAction(
        page,
        pageSize,
        filterKey,
        searchValue,
        (res: any) => {
          if (res) {  
            if (res?.error?.error_description) {
                showErrorToastMessage(res.error.error_description);
              } else if (res.code === 200) {
                setTotalPages(res.meta_data.totalPageCount);
                setTotalEntries(res.meta_data.totalEntries);
                hideLoadingIndicator();
                setOnclickSearch(false);
              }
            }
        }
      )
    );
  };
  useEffect(() => {
    getApiDispatch();
  }, [addModule]);

  useEffect(() => {
    if (condition) {
      showLoadingIndicator();
      getApiDispatch();
    }
  }, [page, pageSize, filterValue, searchValue, condition]);

  const moduleData = useSelector(
    (state: any) => state.employeeReducer.getAllTrainingsDetails
  );
  useEffect(() => {
    if (moduleData && moduleData.data) {
      setInfo(moduleData.data);
      setTotalRecords(moduleData.data.length);
    }
  }, [moduleData, info]);

  const [addData, setAddData] = useState({
    id: "",
    inseedBatchRef: "",
    trainingName: "",
  });
  const [showAddTraining, setShowAddTraining] = useState(false);
  const handleAddTraining = (
    id: any,
    inseedBatchRef: any,
    trainingName: any
  ) => {
    setAddData({
      id: id,
      inseedBatchRef: inseedBatchRef,
      trainingName: trainingName,
    });
    setShowAddTraining(true);
  };
  const hideAddTraining = () => {
    setShowAddTraining(false);
  };

  const ResourceAddingColumns: any = [
    {
      Header: "Training Name",
      accessor: "trainingName",
    },
    {
      Header: "Modules",
      accessor: "ModuleData[0].moduleName",
      Cell: (Action: any) => {
        const x = Action.row.original.ModuleData;
        return x.map((value: any) => value?.moduleName).join(", ");
      },
    },
    {
      Header: "Start Date",
      accessor: "trainingStartDate",
      Cell: (Action: any) => {
        const startDate = Action.value;
        return startDate ? timeDateFormat(startDate) : "";
      },
    },
    {
      Header: "End Date",
      accessor: "trainingEndDate",
      Cell: (Action: any) => {
        const endDate = Action.value;
        return endDate ? timeDateFormat(endDate) : "";
      },
    },
    {
      Header: "Action",
      accessor: "",
      Cell: (Action: any) => {
        return (
          <div>
            <button
              className="btn btn-success status-btn"
              onClick={() => {
                handleAddTraining(
                  Action.row.original._id,
                  Action.row.original.inseedBatchRef.map((value: any) => value),
                  Action.row.original.trainingName
                );
              }}
            >
              {"+ Add"}
            </button>
          </div>
        );
      },
    },
  ];
  const handlePageChange = (newPage: any) => {
    setPage(newPage);
    setOnclickSearch(true);
  };

  const handleSearch = (value: any) => {
    setSearchValue(value);
    setPage(1);
  };

  const filterChange = (selectedOption: any) => {
    if (selectedOption) {
      if (filterValue.find((obj: any) => obj.value == selectedOption.value)) {
        setFilterValue((prevValue: any) =>
          prevValue.filter((obj: any) => obj.value != selectedOption.value)
        );
      } else {
        setFilterValue((prevValue: any) => [...prevValue, selectedOption]);
      }
    }
  };
  const data = info;
  const columns = useMemo(() => ResourceAddingColumns, []);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      { columns, data, initialState: { pageSize: 10, pageIndex: 0 } },
      useGlobalFilter,
      useSortBy,
      usePagination
    );

  return (
    <>
      {loadingComponent()}
      <MainCard>
        <div className="heading-button-wrapper mb-3">
          <div>
            <h2>{all_trainings}</h2>
          </div>
        </div>
        <BackButton navigateTo={`/create-training/${id}`} />
        <div className="mt-2 buttonsize filter-wrapper-training-schedule">
          <div className="searchbar-wrapper">
            <SearchBar
              filter={searchValue}
              setFilter={handleSearch}
              onSearchClicked={onSearchClicked}
            />
          </div>
          <div className="filter-container">
            <select
              id="select-filters"
              value={filterValue}
              onChange={(event) => {
                const selectedValue = event.target.value;
                const selectedOption = AllTrainingsFilter.find(
                  (item: any) => item.value === selectedValue
                );
                if (selectedOption) {
                  filterChange(selectedOption);
                }
              }}
            >
              <option hidden>{filters}</option>
              {AllTrainingsFilter.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.title}
                </option>
              ))}
            </select>
          </div>
        </div>
        <FilterValues
          filterValueArray={filterValue}
          onFilterChange={filterChange}
        />
        {data.length > 0 ? (
          <>
            <div className="table-responsive">
              <div className="table">
                <table
                  className="table-main table table-sm mt-3"
                  {...getTableProps()}
                >
                  <thead className="table-head">
                    {headerGroups.map((headerGroup) => (
                      <tr
                        className="table-head-row"
                        {...headerGroup.getHeaderGroupProps()}
                      >
                        {headerGroup.headers.map((column: any) => (
                          <th
                            className="table-head-row"
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                            scope="col"
                          >
                            {column.render("Header")}

                            <span>
                              {column.id !== "action" &&
                              column.id !== "id" &&
                              column.id !== "remarks" ? (
                                column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <TiArrowUnsorted />
                                  ) : (
                                    <TiArrowUnsorted />
                                  )
                                ) : (
                                  <TiArrowUnsorted />
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody className="table-body" {...getTableBodyProps()}>
                    {rows.map((row) => {
                      prepareRow(row);
                      return (
                        <tr className="table-body-row" {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <td {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="show-entries-wrapper">
              <ShowEntries
                pageSize={pageSize}
                setPageSize={setPageSize}
                totalRecords={totalRecords}
                totalEntries={totalEntries}
              />

              <TablePagination
                totalPages={totalPages}
                currentPage={page}
                onClick={handlePageChange}
              />
            </div>
          </>
        ) : (
          <p className="no-data">{no_data_found}</p>
        )}

        <div>
          <ModalComponents
            show={showAddTraining}
            onHide={hideAddTraining}
            title="Add Training"
            modalBody={
              <AddTraining
                hideAddTraining={hideAddTraining}
                addData={addData}
                batchRef={id}
                apiCallCompleted={apiCallCompleted}
              />
            }
            className="modal-lg"
          />
        </div>
      </MainCard>
    </>
  );
};
export default AllTrainings;
