import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const showSuccessToastMessage = (succesMsg: any) => {
  toast.success(succesMsg, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 1000,
    hideProgressBar: true,
    pauseOnHover: false,
  });
};

export const showErrorToastMessage = (errorMsg: any) => {
  toast.error(errorMsg, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 1000,
    hideProgressBar: true,
    pauseOnHover: false,
  });
};
