import React, { useEffect, useMemo, useState } from "react";
import { FaSort } from "react-icons/fa";
import { Table } from "react-bootstrap";
import * as actions from "../../Redux/Actions/index";
import ModalComponents from "../../component/ModalComponent/ModalComponent";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import MainCard from "../../component/MainCard/MainCard";
import SearchBar from "../../component/SearchBar/SearchBar";
import ApplicationStatus from "./ApplicationStatusModal";
import useLoadingIndicator from "../../component/Loader/Loader";
import { showErrorToastMessage } from "../../component/Toasts/Toasts";
import { icons } from "../../assets/index";
import { Strings, ijpJobApplicationFilter } from "../../constant/Strings";
import ShowEntries from "../../component/ShowEntries/ShowEntries";
import FilterValues from "../../component/FieldFilter/FilterValues";
import TablePagination from "../../component/TablePagination/TablePagination";
import { ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import hanldeUnauthorizedUser from "../../component/AccessConditions/UnauthorizedUser";

const JobApplicationsTable = () => {
  const { loadingComponent, apiCallCompleted } = useLoadingIndicator();
  const { unAuthorizeUser } = hanldeUnauthorizedUser();
  const [changeStatus, setChangeStatus] = useState({
    id: "",
    remarks: "",
    status: "",
  });
  const { id } = useParams();
  const { backIcon } = icons;
  const { back, applicationList, noData, viewFile } = Strings;
  const [searchValue, setSearchValue] = useState<any>("");
  const [filterValue, setFilterValue] = useState<any>([]);
  const [page, setPage] = useState<Number>(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalEntries, setTotalEntries] = useState<any>();
  const allInternallyAppliedJob = useSelector(
    (state: any) => state.employeeReducer.getAllInternallyAppliedJob
  );
  const dispatch = useDispatch();
  const [data, setdata] = useState<any>([]);
  const [order, setorder] = useState("ASC");
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [isSorted, setisSorted] = useState(false);
  const [sortedData, setsortedData] = useState<any>([]);
  const sorting = (col: any) => {
    setisSorted(true);
    if (order === "ASC") {
      const sorted = [...data].sort((a: any, b: any) => {
        if (col == "jobId") {
          if (
            a?.InternalJobPosting[0]?.jobId < b?.InternalJobPosting[0]?.jobId
          ) {
            return 1;
          }
          if (
            a?.InternalJobPosting[0]?.jobId > b?.InternalJobPosting[0]?.jobId
          ) {
            return -1;
          }
          return 0;
        } else if (col == "candidateName") {
          if (a?.Employee[0]?.candidateName < b?.Employee[0]?.candidateName) {
            return 1;
          }
          if (a?.Employee[0]?.candidateName > b?.Employee[0]?.candidateName) {
            return -1;
          }
          return 0;
        } else if (col == "emailId") {
          if (a?.Employee[0]?.emailId < b?.Employee[0]?.emailId) {
            return 1;
          }
          if (a?.Employee[0]?.emailId > b?.Employee[0]?.emailId) {
            return -1;
          }
          return 0;
        } else if (col == "mobileNumber") {
          if (a?.Employee[0]?.mobileNumber < b?.Employee[0]?.mobileNumber) {
            return 1;
          }
          if (a?.Employee[0]?.mobileNumber > b?.Employee[0]?.mobileNumber) {
            return -1;
          }
          return 0;
        } else {
          if (a[col] < b[col]) {
            return 1;
          }
          if (a[col] > b[col]) {
            return -1;
          }
          return 0;
        }
      });
      setsortedData(sorted);
      setorder("DSC");
    } else {
      const sorted = [...data].sort((a: any, b: any) => {
        if (a[col] < b[col]) {
          return -1;
        }
        if (a[col] > b[col]) {
          return 1;
        }
        return 0;
      });
      setsortedData(sorted);
      setorder("ASC");
    }
  };
  const [showChangeStatus, setShowChangeStatus] = useState(false);

  const handleChangeStatus = (id: any, status: any, remarks: any) => {
    setShowChangeStatus(true);
    setChangeStatus({ id: id, status: status, remarks: remarks });
  };
  const hideChangeStatus = () => {
    setShowChangeStatus(false);
  };
  const editApplicationStatus = useSelector(
    (state: any) => state.employeeReducer.editApplicationStatusDetails
  );
  const role = localStorage.getItem("role");
  const ijpChecker = role && role.includes("internal-job-checker");
  const navigate = useNavigate();
  const [onclickSearch, setOnclickSearch] = useState(false);
  const onSearchClicked = (value: any) => {
    setOnclickSearch(value);
  };
  const condition: boolean =
    (filterValue.length == 0 && searchValue == "") ||
    (filterValue.length > 0 && onclickSearch);
  const filterKey = filterValue.map((ele: any) => ele.value);
  useEffect(() => {
    unAuthorizeUser();
    if (condition) {
      dispatch(
        actions.getAllInternallyAppliedJobAction(
          page,
          pageSize,
          filterKey,
          searchValue,
          id,
          (res: any) => {
            if (res?.error?.error_description) {
              showErrorToastMessage(res?.error?.error_description);
            } else if (res?.code === 200) {
                setTotalPages(res.meta_data.totalPageCount);
                setTotalEntries(res.meta_data.totalEntries);
                setOnclickSearch(false);
              }
          }
        )
      );
    }
  }, [
    editApplicationStatus,
    page,
    pageSize,
    filterValue,
    searchValue,
    condition,
  ]);

  useEffect(() => {
    if (allInternallyAppliedJob && allInternallyAppliedJob.data) {
      setdata(allInternallyAppliedJob.data);
      setTotalRecords(allInternallyAppliedJob.data.length);
    }
  }, [allInternallyAppliedJob, data]);

  const COLUMNS: any = [
    {
      Header: "Job ID",
      accessor: "jobId",
    },
    {
      Header: "Resource Name",
      accessor: "candidateName",
    },
    {
      Header: "Email Id",
      accessor: "emailId",
    },
    {
      Header: "Contact Number",
      accessor: "mobileNumber",
    },
    {
      Header: "Current CTC (in ₹)",
      accessor: "currentCtc",
    },
    {
      Header: "Preferred Location",
      accessor: "preferredlocation",
    },
    { Header: "Resume", accessor: "" },
    {
      Header: "Remarks",
      accessor: "remarks",
    },
  ];
  const CheckerCOLUMNS: any = [
    {
      Header: "Job ID",
      accessor: "jobId",
    },
    {
      Header: "Resource Name",
      accessor: "candidateName",
    },
    {
      Header: "Email Id",
      accessor: "emailId",
    },
    {
      Header: "Contact Number",
      accessor: "mobileNumber",
    },
    {
      Header: "Current CTC (in ₹)",
      accessor: "currentCtc",
    },
    {
      Header: "Preferred Location",
      accessor: "preferredlocation",
    },
    { Header: "Resume", accessor: "" },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Remarks",
      accessor: "remarks",
    },
  ];
  const columns = useMemo(() => (ijpChecker ? CheckerCOLUMNS : COLUMNS), []);
  const handleSearch = (value: any) => {
    setSearchValue(value);
    setPage(1);
  };
  const filterChange = (selectedOption: any) => {
    if (selectedOption) {
      if (filterValue.find((obj: any) => obj.value == selectedOption.value)) {
        setFilterValue((prevValue: any) =>
          prevValue.filter((obj: any) => obj.value != selectedOption.value)
        );
      } else {
        setFilterValue((prevValue: any) => [...prevValue, selectedOption]);
      }
    }
  };
  const handlePageChange = (newPage: any) => {
    setPage(newPage);
    setOnclickSearch(true);
  };

  const handleUploadProfileView = (e: any, docName: any) => {
    e.preventDefault();
    dispatch(
      actions.viewUploadProfileResumeAction(
        { filename: docName },
        (res: any) => {
          if (res.code == 200 && res.data) {
            window.open(res.data, "_blank");
          }
        }
      )
    );
  };

  return (
    <>
      {loadingComponent()}
      <MainCard>
        <div className="d-flex flex-column justify-content-center align-items-start progress-header">
          <div
            className="d-flex flex-row jutify-content-between request-back-button"
            onClick={() => navigate("/jobpost")}
          >
            <img src={backIcon} className="backIcon" alt="back button" />
            <p className="back-text">{back}</p>
          </div>
          <div>
            <h2>{applicationList}</h2>
          </div>
        </div>
        <div className="mt-2">
          <div className="mt-3 buttonsize filter-ijp-wrapper">
            <div className="ijp-searchbar-wrapper">
              <SearchBar
                filter={searchValue}
                setFilter={handleSearch}
                onSearchClicked={onSearchClicked}
              />
            </div>
            <div className="ijp-filter-container">
              <select
                id="select-filters"
                value={filterValue}
                onChange={(event) => {
                  const selectedValue = event.target.value;
                  const selectedOption = ijpJobApplicationFilter.find(
                    (item: any) => item.value === selectedValue
                  );
                  if (selectedOption) {
                    filterChange(selectedOption);
                  }
                }}
              >
                <option hidden>Filters</option>
                {ijpJobApplicationFilter.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.title}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <FilterValues
            filterValueArray={filterValue}
            onFilterChange={filterChange}
          />
        </div>
        {data.length > 0 ? (
          <>
            <div className="table-responsive">
              <Table className="table-margin">
                <thead className="employeedb">
                  <tr className="custom-table-head-row">
                    {columns.map((ele: any) => (
                      <React.Fragment key={ele.Header}>
                        <td
                          onClick={() => sorting(ele.accessor)}
                          className="sorting-cursor"
                        >
                          {ele.Header}
                          {ele.Header !== "Action" &&
                          ele.Header !== "" &&
                          ele.Header != "Remark" ? (
                            <span>{<FaSort />}</span>
                          ) : (
                            " "
                          )}
                        </td>
                      </React.Fragment>
                    ))}
                  </tr>
                </thead>
                <tbody className="employeedata">
                  {(isSorted ? sortedData : data).map(
                    (ele: any, index: any) => (
                      <>
                        <tr key={ele._id}>
                          <td>{ele?.InternalJobPosting[0]?.jobId}</td>
                          <td>
                            <Link to={`/application-details/${ele._id}/${id}`}>
                              {ele.Employee[0]?.candidateName +
                                " " +
                                ele.Employee[0]?.middleName +
                                " " +
                                ele.Employee[0]?.lastname}
                            </Link>
                          </td>
                          <td>{ele.Employee[0]?.emailId}</td>
                          <td>{ele.Employee[0]?.mobileNumber}</td>
                          <td>{ele.currentCtc}</td>
                          <td>{ele.preferredLocation}</td>
                          <td>
                            <a
                              href=""
                              onClick={(e) =>
                                handleUploadProfileView(
                                  e,
                                  ele?.resume?.document_name
                                )
                              }
                              target="_blank"
                            >
                              {viewFile}
                            </a>
                          </td>
                          {ijpChecker && (
                            <td>
                              {
                                <button
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Change Status"
                                  type="button"
                                  className={`btn ${
                                    ele.status === 1
                                      ? "ijp-accepted-status"
                                      : ele.status === 0
                                      ? "ijp-new-status"
                                      : ele.status === 2
                                      ? "ijp-rejected-status"
                                      : ele.status === 3
                                      ? "ijp-kiv-status"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    handleChangeStatus(
                                      ele._id,
                                      ele.status,
                                      ele.remarks
                                    )
                                  }
                                >
                                  {ele?.status === 0
                                    ? "New"
                                    : ele?.status === 1
                                    ? "Accepted"
                                    : ele?.status === 2
                                    ? "Rejected"
                                    : ele?.status === 3
                                    ? "KIV"
                                    : ""}
                                </button>
                              }
                            </td>
                          )}
                          <td>{ele.remarks}</td>
                        </tr>
                      </>
                    )
                  )}
                </tbody>
              </Table>
              <div className="show-entries-wrapper">
                <ShowEntries
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  totalRecords={totalRecords}
                  totalEntries={totalEntries}
                />

                <TablePagination
                  totalPages={totalPages}
                  currentPage={page}
                  onClick={handlePageChange}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <p className="employeedata mt-2">{noData}</p>
          </>
        )}
        <ModalComponents
          show={showChangeStatus}
          onHide={hideChangeStatus}
          title={"Change Status"}
          modalBody={
            <ApplicationStatus
              hideChangeStatus={hideChangeStatus}
              status={changeStatus}
              apiCallCompleted={apiCallCompleted}
            />
          }
          className="modal-md"
        />
        <ToastContainer />
      </MainCard>
    </>
  );
};

export default JobApplicationsTable;
