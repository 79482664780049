import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Strings } from "../../constant/Strings";
import * as actions from "../../Redux/Actions";
import { useDispatch } from "react-redux";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../component/Toasts/Toasts";
import useLoadingIndicator from "../../component/Loader/Loader";
import { group_id } from "../../config/constants";

const AssignRoleManagement = (props: any) => {
  const { loadingComponent, showLoadingIndicator, hideLoadingIndicator } =
    useLoadingIndicator();
  const [btnDisable, setBtnDisable] = useState(false);
  const {
    employeeDetails,
    hideAssignRoles,
    options,
    apiCallCompleted,
    roleKeys,
  } = props;
  const [selectedOptions, setSelectedOptions] = useState<any>(
    options
      .filter((role: any) => roleKeys.includes(role.value))
      .map((role: any) => ({
        label: role.label,
        value: role.value,
      }))?.length > 0
      ? options
          .filter((role: any) => roleKeys.includes(role.value))
          .map((role: any) => ({
            label: role.label,
            value: role.value,
          }))
      : []
  );
  const { cancel, submit, editSuccess } = Strings;
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedOptions?.length > 0) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  }, [selectedOptions]);
  const handleOptionChange = (option: any, isChecked: any) => {
    if (isChecked) {
      setSelectedOptions([...selectedOptions, option]);
    } else {
      setSelectedOptions(
        selectedOptions.filter((o: any) => o.value == option.value)
      );
    }
  };
  const handleSubmit = () => {
    hideAssignRoles();
    showLoadingIndicator();
    const rolesArr: any = [];
    selectedOptions?.forEach((ele: any) => {
      rolesArr?.push({
        role_key: ele?.value,
        expires_at: "",
        permissions: [],
      });
    });
    const payload = {
      sub: employeeDetails?.sub,
      group_id: group_id,
      expires_at: "",
      roles: [...rolesArr],
    };
    dispatch(
      actions.postUpdateRolesAction(payload, (res: any) => {
        if (res?.error?.error_description) {
          apiCallCompleted(true);
          showErrorToastMessage(res?.error?.error_description);
          hideLoadingIndicator();
        } else if(res.code === 200) {
            apiCallCompleted(true);
            showSuccessToastMessage(editSuccess);
            hideLoadingIndicator();
        }
      })
    );
  };
  return (
    <>
      {loadingComponent()}
      <div className="row g-3">
        <div className="col-md-6">
          <label htmlFor="employeeName" className="form-label">
            Employee Name
          </label>
          <input
            type="text"
            className="form-control"
            value={employeeDetails?.name}
            disabled={true}
          />
        </div>
        <div className="col-md-6">
          <label htmlFor="employeeName" className="form-label">
            Employee Email
          </label>
          <input
            type="text"
            className="form-control"
            value={employeeDetails?.email}
            disabled={true}
          />
        </div>
        <div className="col-md-12">
          <label htmlFor="inputName4" className="form-label">
            Roles
          </label>
          <span className="required">*</span>

          <Select
            options={options}
            isMulti
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            value={selectedOptions}
            onChange={(selected: any) => {
              setSelectedOptions(selected);
            }}
            closeMenuOnSelect={false}
            placeholder="Select Roles"
            className="dropdown-select"
            components={{
              Option: (props: any) => (
                <div className="custom-option buttonsize overflow-hidden my-3">
                  <div className="row">
                    <div className="col-8 mx-4 mt-2">{props.label}</div>
                    <div className="col-2 mt-2">
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          handleOptionChange(props, e.target.checked)
                        }
                        checked={selectedOptions.some(
                          (selectedOption: any) =>
                            selectedOption.value === props.data.value
                        )}
                      />
                    </div>
                  </div>
                </div>
              ),
            }}
          />
        </div>
        <div className="modal-btn-wrapper mt-5 mx-auto">
          <div>
            <button
              className="btn cancel-btn buttonsize"
              onClick={hideAssignRoles}
              title="Cancel Button"
            >
              {cancel}
            </button>
          </div>
          <div>
            <button
              className={
                btnDisable
                  ? "btn add-submit-button buttonsize"
                  : "btn add-submit-button btn-disabled buttonsize"
              }
              title="Submit Button"
              onClick={handleSubmit}
            >
              {submit}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssignRoleManagement;
