import  { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ResourceAdding from "./ResourceAdding";
import * as actions from "../../../../Redux/Actions";
import { useParams } from "react-router-dom";
import useLoadingIndicator from "../../../../component/Loader/Loader";
import { showErrorToastMessage } from "../../../../component/Toasts/Toasts";
import hanldeUnauthorizedUser from "../../../../component/AccessConditions/UnauthorizedUser";
function ResourceAddingTable() {
  const { unAuthorizeUser } = hanldeUnauthorizedUser();
  const { loadingComponent } = useLoadingIndicator();
  const addResource = useSelector(
    (state: any) => state.employeeReducer.addResourceAllocationDetails
  );
  const editResource = useSelector(
    (state: any) => state.employeeReducer.editResourceAllocationDetails
  );
  const deleteResource = useSelector(
    (state: any) => state.employeeReducer.deleteResourceAllocationDetails
  );

  const [searchKey, setSearchKey] = useState("");
  const [filterValue, setFilterValue] = useState<any>([]);
  const [searchValue, setSearchValue] = useState("");
  const [pageSize, setPageSize] = useState<any>(10);
  const [totalEntries, setTotalEntries] = useState<any>();
  const [totalEntriesLoaded, setTotalEntriesLoaded] = useState(false);
  const dispatch = useDispatch();
  const [page, setPage] = useState<Number>(0);
  const [totalPages, setTotalPages] = useState(0);
  let { id } = useParams();
  const [info, setInfo] = useState([]);


  useEffect(() => {
    dispatch(actions.getEmployeeDataAction((res: any) => {}));
  }, []);
  const [onclickSearch, setOnclickSearch] = useState(false);
  const onSearchClicked = (value: any) => {
    setOnclickSearch(value);
  };
  const condition: boolean =
    (filterValue.length == 0 && searchValue == "") ||
    (filterValue.length > 0 && onclickSearch);
  const filterKey = filterValue.map((ele: any) => ele?.value);

  useEffect(() => {
    unAuthorizeUser();
    if (condition) {
      dispatch(
        actions.getResourceAllocationAction(
          id,
          page,
          pageSize,
          filterKey,
          searchValue,
          (res: any) => {
            if (res?.error?.error_description) {
              showErrorToastMessage(res.error.error_description);
            } else {
              if (res?.code === 200) {
                setInfo(res.data);
                setTotalPages(res.meta_data.totalPageCount);
                setTotalEntries(res.meta_data?.totalEntries);
                setTotalEntriesLoaded(true);
                setOnclickSearch(false);
              }
            }
          }
        )
      );
    }
  }, [
    addResource,
    editResource,
    deleteResource,
    id,
    page,
    searchValue,
    searchKey,
    filterValue,
    pageSize,
    condition,
  ]);

  const getApiCall = () => {
    dispatch(
      actions.getResourceAllocationAction(
        id,
        page,
        pageSize,
        filterKey,
        searchValue,
        (res: any) => {
              if (res?.error?.error_description) {
                showErrorToastMessage(res.error.error_description);
              } else if(res.code === 200) {
                setInfo(res.data);
                setTotalPages(res.meta_data.totalPageCount);
                setTotalEntries(res.meta_data?.totalEntries);
                setTotalEntriesLoaded(true);
                setOnclickSearch(false);
          }
        }
      )
    );
  };
  const handleSearch = (value: any) => {
    setSearchValue(value);
    setPage(1);
  };
  const filterChange = (selectedOption: any) => {
    if (selectedOption) {
      if (filterValue.find((obj: any) => obj.value == selectedOption.value)) {
        setFilterValue((prevValue: any) =>
          prevValue.filter((obj: any) => obj.value != selectedOption.value)
        );
      } else {
        setFilterValue((prevValue: any) => [...prevValue, selectedOption]);
      }
    }
  };

  const handlePageChange = (newPage: any) => {
    setPage(newPage);
    setOnclickSearch(true);
  };
  return (
    <>
      {loadingComponent()}
      <ResourceAdding
        totalPages={totalPages}
        onPage={page}
        handlePageChange={handlePageChange}
        searchKey={searchKey}
        searchValue={searchValue}
        handleSearch={handleSearch}
        setSearchKey={setSearchKey}
        filterValue={filterValue}
        filterChange={filterChange}
        pagePerSize={pageSize}
        setPagePerSize={setPageSize}
        totalEntries={totalEntries}
        projectId={id}
        totalEntriesLoaded={totalEntriesLoaded}
        info={info}
        onSearchClicked={onSearchClicked}
        getApiCall={getApiCall}
      />
    </>
  );
}

export default ResourceAddingTable;
