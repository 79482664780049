import * as RiIcons from "react-icons/ri";
import my_profile from "../../assets/sidebaricons/my-profile-icon.svg";
import my_profile_active from "../../assets/sidebaricons/my-profile-active.svg";
import skill_links from "../../assets/sidebaricons/Skill-links.svg";
import skill_links_active from "../../assets/sidebaricons/Skill-links-active.svg";
import internal_job from "../../assets/sidebaricons/internal-work.svg";
import internal_job_active from "../../assets/sidebaricons/Internal-work-active.svg";
import learning_development from "../../assets/sidebaricons/Learning-development.svg";
import learning_development_active from "../../assets/sidebaricons/Learning-development-active.svg";
import skill_coins from "../../assets/sidebaricons/Skill-coins.svg";
import skill_coins_active from "../../assets/sidebaricons/Skill-coins-active.svg";
import coaching_mentoring from "../../assets/sidebaricons/Coaching-mentoring.svg";
import coaching_mentoring_active from "../../assets/sidebaricons/Coaching-mentoring-active.svg";
import employee_db from "../../assets/sidebaricons/employeedb-icon.svg";
import employee_db_active from "../../assets/sidebaricons/employeedb-active.svg";
import resource_management from "../../assets/sidebaricons/resource-management.svg";
import resource_managemnet_active from "../../assets/sidebaricons/resource-management-active.svg";
import inseed_program from "../../assets/sidebaricons/inseed-program.svg";
import inseed_program_active from "../../assets/sidebaricons/inseed-program-active.png";
import { RiAdminLine } from "react-icons/ri";

export const employeeSidebar: any = [
 
  {
    title: "Profile",
    path: "/profile",
    icon: <img src={my_profile_active} alt="icon" className="sidebaricon" />,
    active_icon: <img src={my_profile} alt="icon" className="sidebaricon" />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  {
    title: "Skill Links",
    path: "/refer-resource",
    icon: <img src={skill_links} alt="icon" className="sidebaricon" />,
    active_icon: (
      <img src={skill_links_active} alt="icon" className="sidebaricon" />
    ),
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      { title: "Refer a Resource", path: "/refer-resource" },
      {
        title: "Referred List",
        path: "/referred-list",
      },
    ],
  },
  {
    title: "Internal Job Posting",
    path: "/jobpost",
    icon: <img src={internal_job} alt="icon" className="sidebaricon" />,
    active_icon: (
      <img src={internal_job_active} alt="icon" className="sidebaricon" />
    ),
  },
  {
    title: "Learning & Development",
    path: "/courses",
    icon: <img src={learning_development} alt="icon" className="sidebaricon" />,
    active_icon: (
      <img
        src={learning_development_active}
        alt="icon"
        className="sidebaricon"
      />
    ),
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: `Manager Requests`,
        path: "/enrollment-manager-request",
      },
      {
        title: `Finance Requests`,
        path: "/enrollment-finance-request",
      },
      {
        title: "Courses",
        path: "/courses",
      },
    ],
  },
  {
    title: "Skill Coins",
    path: "/skillcoins-redeem",
    icon: <img src={skill_coins} alt="icon" className="sidebaricon" />,
    active_icon: (
      <img src={skill_coins_active} alt="icon" className="sidebaricon" />
    ),
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
 
  {
    title: "Coaching & Mentoring",
    path: "/coaching-request",
    icon: <img src={coaching_mentoring} alt="icon" className="sidebaricon" />,
    active_icon: (
      <img src={coaching_mentoring_active} alt="icon" className="sidebaricon" />
    ),
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: "Be A Coach",
        path: "/be-a-coach",
      },
      {
        title: "Request A Coach",
        path: "/coaching-request",
      },
     
    ],
  },
 
];

export const hrSidebar: any = [

  {
    title: "Employee Database",
    path: "/",
    icon: <img src={employee_db} alt="icon" className="sidebaricon" />,
    active_icon: (
      <img src={employee_db_active} alt="icon" className="sidebaricon" />
    ),
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: "Employee Database",
        path: "/",
      },
      {
        title: "Profile",
        path: "/profile",
      },
      {
        title: "Reports",
        path: "/employeedb-reports",
      },
    ],
  },

  {
    title: "Resource Management",
    path: "/project-repository",
    icon: (
      <img src={resource_management} alt="icon" className="sidebaricon mb-3" />
    ),
    active_icon: (
      <img
        src={resource_managemnet_active}
        alt="icon"
        className="sidebaricon"
      />
    ),
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: "Project Repository",
        path: "/project-repository",
      },
      {
        title: "Resource Allocation",
        path: "/resource-allocation",
      },
      {
        title: "Bench Management",
        path: "/bench-management",
      },
    ],
  },
  {
    title: "Skill Links",
    path: "/post-a-job",
    icon: <img src={skill_links} alt="icon" className="sidebaricon" />,
    active_icon: (
      <img src={skill_links_active} alt="icon" className="sidebaricon" />
    ),
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: "Post a Job",
        path: "/post-a-job",
      },
      {
        title: "Refer a Resource",
        path: "/refer-resource",
      },
      {
        title: "Eligible Referral Payouts",
        path: "/eligble-payout-list",
      },
      {
        title: "Employee Referral History",
        path: "/employee-referral-history",
      },
      {
        title: "Referred List",
        path: "/referred-list",
      },
    ],
  },
  {
    title: "Internal Job Posting",
    path: "/jobpost",
    icon: <img src={internal_job} alt="icon" className="sidebaricon" />,
    active_icon: (
      <img src={internal_job_active} alt="icon" className="sidebaricon" />
    ),
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: `Post a Job to IJP`,
        path: "/ijp-post-a-job",
      },
      {
        title: "Internal Job Posting",
        path: "/jobpost",
      },
    ],
  },
  {
    title: "Learning & Development",
    path: "/courses",
    icon: <img src={learning_development} alt="icon" className="sidebaricon" />,
    active_icon: (
      <img
        src={learning_development_active}
        alt="icon"
        className="sidebaricon"
      />
    ),
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: `HR Requests`,
        path: "/enrollment-request",
      },
      {
        title: `Manager Requests`,
        path: "/enrollment-manager-request",
      },
      {
        title: `Finance Requests`,
        path: "/enrollment-finance-request",
      },
      {
        title: "Courses",
        path: "/courses",
      },
    ],
  },
  {
    title: "Skill Coins",
    path: "/skill-coins",
    icon: <img src={skill_coins} alt="icon" className="sidebaricon" />,
    active_icon: (
      <img src={skill_coins_active} alt="icon" className="sidebaricon" />
    ),
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: `Skill Coins`,
        path: "/skill-coins",
      },
      {
        title: `Redeem Skill Coins`,
        path: "/skillcoins-redeem",
      },
    ],
  },
  {
    title: "Inseed Program",
    path: "/inseed-master",
    icon: <img src={inseed_program} alt="icon" className="sidebaricon" />,
    active_icon: (
      <img src={inseed_program_active} alt="icon" className="sidebaricon" />
    ),
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: "Inseed Master",
        path: "/inseed-master",
      },
      {
        title: "Module Master",
        path: "/module-master",
      },
      {
        title: "Trainee Master",
        path: "/trainee-master",
      },
      {
        title: "Training Schedule",
        path: "/training-schedule",
      },
      {
        title: "Assessment Master",
        path: "/assessment-master",
      },
      {
        title: "Final Evaluation",
        path: "/evaluation",
      },
    ],
  },
  {
    title: "Coaching & Mentoring",
    path: "/coaching-request",
    icon: <img src={coaching_mentoring} alt="icon" className="sidebaricon" />,
    active_icon: (
      <img src={coaching_mentoring_active} alt="icon" className="sidebaricon" />
    ),
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: "Be A Coach",
        path: "/be-a-coach",
      },
      {
        title: "Request A Coach",
        path: "/coaching-request",
      },
      {
        title: "Coaching Requests",
        path: "/all-coaching-requests",
      },
      {
        title: "Coaching Reports",
        path: "/coaching-reports",
      },
    ],
  },
 
  {
    title: "Role Management",
    path: "/role-management",
       icon: <RiAdminLine size={20}/>,
    active_icon: (
      <RiAdminLine size={20}/>
    ),
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  }
];
