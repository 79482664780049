import  { useEffect, useState } from "react";
import MainCard from "../../component/MainCard/MainCard";
import {
  Strings,
  applicationDetailsColumns1,
  applicationDetailsColumns2,
} from "../../constant/Strings";
import { Card } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { icons } from "../../assets";
import "../InternalJobPosting/InternalJob.scss";
import { useDispatch } from "react-redux";
import * as actions from "../../Redux/Actions/index";
import { showErrorToastMessage } from "../../component/Toasts/Toasts";
import useLoadingIndicator from "../../component/Loader/Loader";

const ApplicationDetails = () => {
  const { showLoadingIndicator, hideLoadingIndicator } = useLoadingIndicator();
  const [data, setData] = useState<any>([]);
  const [valueArray2, setValueArray2] = useState<any>([]);
  const { id, jobApplicationId } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    showLoadingIndicator();
    dispatch(
      actions.getApplicationDetailsViewAction(id, (res: any) => {
            if (res?.error?.error_description) {
              showErrorToastMessage(res.error.error_description);
          } else if (res.code === 200) {
              setData(res.data);
              setValueArray2([
                res.data[0]?.Employee[0].employeeId
                  ? res.data[0]?.Employee[0].employeeId
                  : "-",
                res.data[0]?.preferredLocation
                  ? res.data[0]?.preferredLocation
                  : "-",
                res.data[0]?.totalExperience
                  ? res.data[0]?.totalExperience
                  : "-",
                res.data[0]?.relevantExperience
                  ? res.data[0]?.relevantExperience
                  : "-",
                res.data[0]?.currentCtc ? res.data[0]?.currentCtc : "-",
              ]);
              hideLoadingIndicator();
        }
      })
    );
  }, []);
  const { resourceName, back, viewFile, whyInterested, aspiration } = Strings;
  const { backIcon, description } = icons;
  const navigate = useNavigate();

  const handleUploadProfileView = (e: any) => {
    e.preventDefault();
    dispatch(
      actions.viewUploadProfileResumeAction(
        { filename: data[0].resume?.document_name },
        (res: any) => {
          if (res.code == 200 && res.data) {
            window.open(res.data, "_blank");
          }
        }
      )
    );
  };

  return (
    <>
      <MainCard>
        <Card className="invoice-card p-2">
          <div className="row">
            <div className="d-flex flex-row justify-content-between">
              <div className="col-md-4">
                <div
                  className="d-flex flex-row jutify-content-between back-button"
                  onClick={() =>
                    navigate(`/jobapplicationstable/${jobApplicationId}`)
                  }
                >
                  <img src={backIcon} className="backIcon" alt="back button" />
                  <p className="back-text">{back}</p>
                </div>
                <div>
                  <div className="project-heading">{resourceName}</div>
                  <div className="project-name">
                    {data[0]?.Employee[0]?.candidateName &&
                    data[0]?.Employee[0]?.lastname
                      ? data[0]?.Employee[0]?.candidateName +
                        " " +
                        (data[0]?.Employee[0]?.middleName
                          ? data[0]?.Employee[0]?.middleName
                          : "") +
                        " " +
                        data[0]?.Employee[0]?.lastname
                      : ""}
                  </div>
                </div>
              </div>
              <div className="col-md-3 d-flex flex-column justify-content-center mx-4">
                <div className="row">
                  <div className="col-md-5">
                    {applicationDetailsColumns1.map(
                      (element: any, index: any) => {
                        return (
                          <div key={index} className="invoice-details-label">
                            {element}
                          </div>
                        );
                      }
                    )}
                  </div>
                  <div className="col-md-7">
                    <div className="view-file">
                      {data[0]?.resume ? (
                        <a
                          href=""
                          onClick={handleUploadProfileView}
                          target="_blank"
                        >
                          <img
                            src={description}
                            alt="view file icon"
                            className="mx-2"
                          />
                          {viewFile}
                        </a>
                      ) : (
                        "-"
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5 mx-4">
                <div className="row">
                  <div className="col-md-5">
                    {applicationDetailsColumns2.map(
                      (element: any, index: any) => {
                        return (
                          <div key={index} className="invoice-details-label">
                            {element}
                          </div>
                        );
                      }
                    )}
                  </div>
                  <div className="col-md-7">
                    {valueArray2.map((element: any, index: any) => {
                      return (
                        <div key={index} className="invoice-details-values">
                          {element}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>
        <Card className="p-4 mt-4">
          <h5 className="whyInterestedText">{whyInterested}</h5>
          <Card>
            <div className="value-card p-3">
              <p className="value-text">{data[0]?.interested}</p>
            </div>
          </Card>
          <h5 className="whyInterestedText mt-4">{aspiration}</h5>
          <Card>
            <div className="value-card p-3">
              <p className="value-text">{data[0]?.aspiration}</p>
            </div>
          </Card>
        </Card>
      </MainCard>
    </>
  );
};

export default ApplicationDetails;
