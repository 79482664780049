import  { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../Redux/Actions";
import { ToastContainer } from "react-toastify";
import useLoadingIndicator from "../../../component/Loader/Loader";
import { showErrorToastMessage } from "../../../component/Toasts/Toasts";
import AssessmentTable from "./AssessmentTable";
import MainCard from "../../../component/MainCard/MainCard";

const AssessmentTableData = () => {
  const { loadingComponent, apiCallCompleted } = useLoadingIndicator();
  const dispatch = useDispatch();
  const addResource = useSelector(
    (state: any) => state.employeeReducer.addResourceAllocationDetails
  );
  const [inseedBatchStartDate, setInseedBatchStartDate] = useState("");
  const [inseedBatchId, setinseedBatchId] = useState<any>("");
  const [inseedBatches, setinseedBatches] = useState<any>([]);
  const selectedInseedBatchId = inseedBatchId.split("&")[0];
  const selectedInseedRecordStatus = inseedBatchId.split("&")[1];
  useEffect(() => {
    dispatch(
      actions.getApprovedInseedDataAction("", "", "", "", "", (res: any) => {
            if (res?.error?.error_description) {
              showErrorToastMessage(res.error.error_description);
            } else if (res.code === 200) {
              setinseedBatches(res.data);
              setinseedBatchId(
                res.data[0]._id + "&" + res.data[0]?.recordStatus
              );
              setInseedBatchStartDate(res.data[0].startDate);
            }
      })
    );
  }, [addResource]);

  const handleSetInseedBatchId = (value: any) => {
    setinseedBatchId(value);
  };

  return (
    <>
      {loadingComponent()}
      <MainCard>
        <AssessmentTable
          inseedBatchId={selectedInseedBatchId}
          setinseedBatchId={handleSetInseedBatchId}
          inseedBatches={inseedBatches}
          inseedBatchRecordStatus={selectedInseedRecordStatus}
          apiCallCompleted={apiCallCompleted}
          inseedBatchStartDate={inseedBatchStartDate}
          setInseedBatchStartDate={setInseedBatchStartDate}
        />
        <ToastContainer />
      </MainCard>
    </>
  );
};

export default AssessmentTableData;
