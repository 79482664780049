import  { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MainCard from "../../../component/MainCard/MainCard";
import * as actions from "../../../Redux/Actions/index";
import useLoadingIndicator from "../../../component/Loader/Loader";
import { showErrorToastMessage } from "../../../component/Toasts/Toasts";
import RedeemSkillCoinsTable from "./RedeemSkillCoinsTable";

function SkillCoinsRedeemData() {
  const { loadingComponent, showLoadingIndicator, hideLoadingIndicator } =
    useLoadingIndicator();
  const userLogin: any = localStorage.getItem("login");
  const loginId = JSON.parse(userLogin)?.id;
  const [data, setdata] = useState<any>([]);
  const dispatch = useDispatch();
  const [page, setPage] = useState<Number>(1);
  const [searchKey, setSearchKey] = useState("");
  const [filterValue, setFilterValue] = useState<any>([]);
  const [searchValue, setSearchValue] = useState("");
  const [pageSize, setPageSize] = useState<any>(10);
  const [totalEntries] = useState<any>();

  const skillCoinsData = useSelector(
    (state: any) => state.employeeReducer.getFetchSkillCoinsDetails
  );
  const redeemSkillCoinsData = useSelector(
    (state: any) => state.employeeReducer.editRedeemSkillCoinsDetails
  );
  const deallocateSkillCoinsData = useSelector(
    (state: any) => state.employeeReducer.deallocateSkillCoinsDetails
  );

  useEffect(() => {
    showLoadingIndicator();
    dispatch(
      actions.getFetchSkillCoinsAction(loginId, (res: any) => {
            if (res?.error?.error_description) {
              showErrorToastMessage(res.error.error_description);
            } else if(res.code === 200) {
              hideLoadingIndicator();
        }
      })
    );
  }, [redeemSkillCoinsData, deallocateSkillCoinsData]);

  useEffect(() => {
    if (skillCoinsData && skillCoinsData.data) {
      setdata(skillCoinsData.data);
    }
  }, [skillCoinsData, data]);

  const handlePageChange = (newPage: any) => {
    setPage(newPage);
  };

  const handleSearch = (value: any) => {
    setSearchValue(value);
    setPage(1);
  };

  const filterChange = (value: any) => {
    if (value) {
      if (filterValue.includes(value)) {
        setFilterValue(filterValue.filter((obj: any) => obj != value));
      } else {
        setFilterValue((prevValue: any) => [...prevValue, value]);
      }
    }
  };

  return (
    <div>
      {loadingComponent()}
      <MainCard>
        <RedeemSkillCoinsTable
          totalPages={0}
          onPage={page}
          handlePageChange={handlePageChange}
          searchKey={searchKey}
          searchValue={searchValue}
          handleSearch={handleSearch}
          setSearchKey={setSearchKey}
          filterValue={filterValue}
          filterChange={filterChange}
          pagePerSize={pageSize}
          setPagePerSize={setPageSize}
          totalEntries={totalEntries}
        />
      </MainCard>
    </div>
  );
}

export default SkillCoinsRedeemData;
