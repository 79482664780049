import React, { useEffect, useMemo, useState } from "react";
import SearchBar from "../../../component/SearchBar/SearchBar";
import { Dropdown, Table } from "react-bootstrap";
import * as actions from "../../../Redux/Actions/index";
import ModalComponents from "../../../component/ModalComponent/ModalComponent";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import MainCard from "../../../component/MainCard/MainCard";
import { ToastContainer } from "react-toastify";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../component/Toasts/Toasts";
import AcceptedModal from "./AcceptedModal";
import RejectedModal from "./RejectedModal";
import { FaSort } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import useLoadingIndicator from "../../../component/Loader/Loader";
import ShowEntries from "../../../component/ShowEntries/ShowEntries";
import {
  Strings,
  RequestCoachingFilter,
  coachingRequestDropdown,
} from "../../../constant/Strings";
import { icons } from "../../../assets";
import FilterValues from "../../../component/FieldFilter/FilterValues";
import TablePagination from "../../../component/TablePagination/TablePagination";
import moment from "moment";
import UndoPopup from "./UndoPopup";
import "../CoachingRequest/CoachingRequest.scss";
import hanldeUnauthorizedUser from "../../../component/AccessConditions/UnauthorizedUser";

const AllCoachingRequests = () => {
  const { unAuthorizeUser } = hanldeUnauthorizedUser();
  const { loadingComponent, apiCallCompleted } = useLoadingIndicator();
  const { coachingRequest, filters, noData, updateStatusSuccess } =
    Strings;
  const {  calender_month_icon } = icons;
  const userLogin: any = localStorage.getItem("login");
  const loginId = JSON.parse(userLogin).id;
  const [searchValue, setSearchValue] = useState<any>("");
  const [filterValue, setFilterValue] = useState<any>([]);
  const [page, setPage] = useState<Number>(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalEntries, setTotalEntries] = useState<any>();
  const dispatch = useDispatch();
  const role = localStorage.getItem("role");
  const navigate = useNavigate();
  const [statusDetails, setStatusDetails] = useState("");
  const [isHrbp, setIsHrbp] = useState<any>(false);
  useEffect(() => {
    role?.includes("coaching-and-mentoring-hrbp")
      ? setIsHrbp(true)
      : setIsHrbp(false);
  }, []);
  const [data, setdata] = useState<any>([]);
  const [order, setorder] = useState("ASC");
  const [id, setid] = useState("");
  const [empName, setEmpName] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const [isSorted, setisSorted] = useState(false);
  const [sortedData, setsortedData] = useState<any>([]);
  const sorting = (col: any) => {
    setisSorted(true);
    if (order === "ASC") {
      const sorted = [...data].sort((a: any, b: any) => {
        if (col == "employeeId") {
          if (a?.Employee[0]?.employeeId < b?.Employee[0]?.employeeId) {
            return 1;
          }
          if (a?.Employee[0]?.employeeId > b?.Employee[0]?.employeeId) {
            return -1;
          }
          return 0;
        } else {
          if (a[col] < b[col]) {
            return 1;
          }
          if (a[col] > b[col]) {
            return -1;
          }
          return 0;
        }
      });
      setsortedData(sorted);
      setorder("DSC");
    } else {
      const sorted = [...data].sort((a: any, b: any) => {
        if (a[col] < b[col]) {
          return -1;
        }
        if (a[col] > b[col]) {
          return 1;
        }
        return 0;
      });
      setsortedData(sorted);
      setorder("ASC");
    }
  };

  const updateStatusRequest = useSelector(
    (state: any) => state.employeeReducer.updateStatusRemarksDetails
  );
  const getAllCoachList = useSelector(
    (state: any) => state.employeeReducer.getAllCoachListDetails
  );
  const [onclickSearch, setOnclickSearch] = useState(false);
  const onSearchClicked = (value: any) => {
    setOnclickSearch(value);
  };
  const condition: boolean =
    (filterValue.length == 0 && searchValue == "") ||
    (filterValue.length > 0 && onclickSearch);
  const filterKey = filterValue.map((ele: any) => ele.value);
  useEffect(() => {
    unAuthorizeUser();
    if (condition) {
      dispatch(
        actions.getAllCoachRequestAction(
          page,
          pageSize,
          filterKey,
          searchValue,
          loginId,
          (res: any) => {
            if (res?.error?.error_description) {
              showErrorToastMessage(res?.error?.error_description);
            } else  if (res?.code === 200) {
                setTotalPages(res.meta_data.totalPageCount);
                setTotalEntries(res.meta_data.totalEntries);
                setOnclickSearch(false);
              }
          }
        )
      );
    }
  }, [
    updateStatusRequest,
    getAllCoachList,
    page,
    pageSize,
    filterValue,
    searchValue,
    condition,
  ]);

  const getAllCoachRequest = useSelector(
    (state: any) => state.employeeReducer.getAllCoachRequestDetails
  );

  useEffect(() => {
    if (getAllCoachRequest && getAllCoachRequest.data) {
      setdata(getAllCoachRequest.data);
      setTotalRecords(getAllCoachRequest.data.length);
    }
  }, [getAllCoachRequest, data]);

  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const undoData = { status: "3", remarks: "" };
  const handleAccept = (id: any, employeeName: any) => {
    setShowAcceptModal(true);
    setid(id);
    setEmpName(employeeName);
    setStatusDetails("Accepted");
  };
  const hideAcceptModal = () => {
    setShowAcceptModal(false);
  };

  const handleRejection = (id: any, employeeName: any) => {
    setShowRejectModal(true);
    setid(id);
    setEmpName(employeeName);
    setStatusDetails("Rejected");
  };
  const hideRejection = () => {
    setShowRejectModal(false);
  };

  const CHECKERCOLUMNS: any = [
    {
      Header: "Employee ID",
      accessor: "employeeId",
    },
    {
      Header: "Name of the Employee",
      accessor: "candidateName",
    },
    {
      Header: "Coach Emp Id",
      accessor: "coachEmpId",
    },
    {
      Header: "Name of the Coach",
      accessor: "nameOfTheCoach",
    },
    {
      Header: "Purpose for the Coach",
      accessor: "purpose",
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Details Status",
      accessor: "detailsStatus",
    },
    {
      Header: "Scheduled Date",
      accessor: "dateScheduled",
    },
    {
      Header: "Action",
      accessor: "action",
    },
    {
      Header: "Remarks",
      accessor: "remarks",
    },
  ];

  const ALLCOLUMNS: any = [
    {
      Header: "Employee ID",
      accessor: "employeeId",
    },
    {
      Header: "Name of the Employee",
      accessor: "candidateName",
    },
    {
      Header: "Coach Emp Id",
      accessor: "coachEmpId",
    },
    {
      Header: "Name of the Coach",
      accessor: "nameOfTheCoach",
    },
    {
      Header: "Purpose for the Coach",
      accessor: "purpose",
    },
    {
      Header: "Status",
      accessor: "status",
    },

    {
      Header: "Remarks",
      accessor: "remarks",
    },
  ];

  const columns = useMemo(
    () => (isHrbp ? CHECKERCOLUMNS : ALLCOLUMNS),
    [isHrbp]
  );

  const handleSearch = (value: any) => {
    setSearchValue(value);
    setPage(1);
  };

  const handlePageChange = (newPage: any) => {
    setPage(newPage);
    setOnclickSearch(true);
  };

  const filterChange = (selectedOption: any) => {
    if (selectedOption) {
      if (filterValue.find((obj: any) => obj.value == selectedOption.value)) {
        setFilterValue((prevValue: any) =>
          prevValue.filter((obj: any) => obj.value != selectedOption.value)
        );
      } else {
        setFilterValue((prevValue: any) => [...prevValue, selectedOption]);
      }
    }
  };
  const [showUndoPopup, setShowUndoPopup] = useState(false);

  const handleOnSelect = (requestId: any, selectedOption: any) => {
    handleDropDownSelect(requestId, "detailStatus", selectedOption);
  };
  const handleOnChange = (requestId: any, e: any) => {
    handleDropDownSelect(requestId, "scheduledDate", e.target.value);
  };

  const handleDropDownSelect = (id: any, selectedKey: any, data: any) => {
    apiCallCompleted(false);
    dispatch(
      actions.updateStatusRemarksAction(
        id,
        { [selectedKey]: data },
        (res: any) => {
          if (res?.error?.error_description) {
            showErrorToastMessage(res?.error?.error_description);
            apiCallCompleted(true);
          } else if (res.code === 200) {
              showSuccessToastMessage(updateStatusSuccess);
              apiCallCompleted(true);
            }
        }
      )
    );
  };
  return (
    <>
      {loadingComponent()}
      <MainCard>
        <div className="d-flex flex-column justify-content-center align-items-start progress-header">
          <div>
            <h2>{coachingRequest}</h2>
          </div>
        </div>
        <div className="mt-2">
          <div
            className="mt-3 buttonsize filter-coaching-request-wrapper"
          >
            <div className="coaching-request-searchbar-wrapper">
              <SearchBar
                filter={searchValue}
                setFilter={handleSearch}
                onSearchClicked={onSearchClicked}
              />
            </div>
            <div className="coaching-request-filter-container">
              <select
                id="select-filters"
                value={typeof filterValue === 'string' ? filterValue : ''}
                onChange={(event) => {
                  const selectedValue = event.target.value;
                  const selectedOption = RequestCoachingFilter.find(
                    (item: any) => item.value === selectedValue
                  );
                  if (selectedOption) {
                    filterChange(selectedOption);
                  }
                }}
              >
                <option hidden>{filters}</option>
                {RequestCoachingFilter.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.title}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <FilterValues
            filterValueArray={filterValue}
            onFilterChange={filterChange}
          />
          {data.length > 0 ? (
            <>
              <div className="table-responsive mt-2">
                <Table className="table-margin">
                  <thead>
                    <tr className="custom-table-head-row">
                      {columns.map((ele: any) => (
                        <React.Fragment key={ele.Header}>
                          <td
                            onClick={() => sorting(ele.accessor)}
                            className="sorting-cursor"
                          >
                            {ele.Header}
                            {ele.Header !== "Action" &&
                            ele.Header !== "" &&
                            ele.Header != "Remark" ? (
                              <span>{<FaSort />}</span>
                            ) : (
                              " "
                            )}
                          </td>
                        </React.Fragment>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="employeedata">
                    {(isSorted ? sortedData : data).map(
                      (ele: any, index: any) => (
                        <>
                          <tr key={ele?._id}>
                            <td>{ele?.Employee[0]?.employeeId}</td>
                            <td>
                              {ele?.Employee[0]?.candidateName +
                                " " +
                                ele?.Employee[0]?.middleName +
                                " " +
                                ele?.Employee[0]?.lastname}
                            </td>
                            <td>{ele?.CoachData[0]?.employeeId}</td>
                            <td>
                              {ele?.CoachData[0]?.candidateName +
                                " " +
                                ele?.CoachData[0]?.middleName +
                                " " +
                                ele?.CoachData[0]?.lastname}
                            </td>
                            <td>{ele?.purpose}</td>

                            <td>
                              <button
                                className={`btn  ${
                                  ele.status === 1
                                    ? "coachee-status-approved"
                                    : ele.status === 2
                                    ? "coachee-status-rejected"
                                    : ele.status === 3
                                    ? "new"
                                    : ""
                                }`}
                              >
                                {ele?.status === 1
                                  ? "Accepted"
                                  : ele?.status === 2
                                  ? "Rejected"
                                  : ele?.status === 3
                                  ? "New"
                                  : ""}
                              </button>
                            </td>
                            {isHrbp && (
                              <td>
                                {ele?.status === 1 ? (
                                  <>
                                    <Dropdown
                                      onSelect={(eventkey: any) =>
                                        handleOnSelect(ele._id, eventkey)
                                      }
                                    >
                                      <Dropdown.Toggle
                                        variant="light"
                                        className="form-control"
                                      >
                                        {ele?.detailStatus}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        {coachingRequestDropdown.map(
                                          (value: any) => (
                                            <Dropdown.Item eventKey={value}>
                                              {value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </>
                                ) : (
                                  "-"
                                )}
                              </td>
                            )}
                            {isHrbp && (
                              <td className="calendar-btn">
                                {ele.scheduledDate ? (
                                  moment(ele.scheduledDate).format("DD/MM/YYYY")
                                ) : ele.detailStatus == "Scheduled" ? (
                                  <>
                                    <img
                                      src={calender_month_icon}
                                      alt={"calendar_icon"}
                                    />
                                    <input
                                      type="date"
                                      onChange={(e: any) => {
                                        handleOnChange(ele._id, e);
                                      }}
                                    />
                                  </>
                                ) : (
                                  "-"
                                )}
                              </td>
                            )}
                            {isHrbp && (
                              <>
                                {ele?.status === 1 || ele?.status === 2 ? (
                                  <>
                                    <td>-</td>
                                  </>
                                ) : (
                                  <>
                                    <td className="d-flex flex-row justify-content-center coaching-request-btns">
                                      {ele?.action}
                                      <button
                                        type="button"
                                        className="accept-btn mt-2"
                                        onClick={() =>
                                          handleAccept(
                                            ele._id,
                                            ele?.Employee[0]?.candidateName +
                                              " " +
                                              ele?.Employee[0]?.middleName +
                                              " " +
                                              ele?.Employee[0]?.lastname
                                          )
                                        }
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Accept"
                                      >
                                        <AiOutlineCheck />
                                      </button>
                                      <button
                                        type="button"
                                        className="reject-btn mt-2"
                                        onClick={() =>
                                          handleRejection(
                                            ele._id,
                                            ele?.Employee[0]?.candidateName +
                                              " " +
                                              ele?.Employee[0]?.middleName +
                                              " " +
                                              ele?.Employee[0]?.lastname
                                          )
                                        }
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Reject"
                                      >
                                        <AiOutlineClose />
                                      </button>
                                    </td>
                                  </>
                                )}
                              </>
                            )}

                            <td>{ele?.remarks}</td>
                          </tr>
                        </>
                      )
                    )}
                  </tbody>
                </Table>
                <div className="show-entries-wrapper">
                  <ShowEntries
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    totalRecords={totalRecords}
                    totalEntries={totalEntries}
                  />

                  <TablePagination
                    totalPages={totalPages}
                    currentPage={page}
                    onClick={handlePageChange}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <p className="employeedata mt-2">{noData}</p>
            </>
          )}
        </div>

        {showUndoPopup && (
          <UndoPopup
            onHide={() => setShowUndoPopup(false)}
            setShowUndoPopup={setShowUndoPopup}
            requestId={id}
            employeeName={empName}
            statusDetails={statusDetails}
            undoActionApi={actions.updateStatusRemarksAction}
            data={undoData}
          />
        )}
        {/* Accepted Modal */}
        <ModalComponents
          show={showAcceptModal}
          onHide={hideAcceptModal}
          title={"Accept"}
          modalBody={
            <AcceptedModal
              apiCallCompleted={apiCallCompleted}
              hideAcceptModal={hideAcceptModal}
              requestId={id}
              setShowUndoPopup={setShowUndoPopup}
            />
          }
          className="modal-lg"
        />

        <ModalComponents
          show={showRejectModal}
          onHide={hideRejection}
          title={"Reject"}
          modalBody={
            <RejectedModal
              apiCallCompleted={apiCallCompleted}
              hideRejection={hideRejection}
              requestId={id}
              setShowUndoPopup={setShowUndoPopup}
            />
          }
          className="modal-lg"
        />
      </MainCard>
      <ToastContainer />
    </>
  );
};

export default AllCoachingRequests;
