import  { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as actions from "../../../Redux/Actions/index";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../component/Toasts/Toasts";
import { Strings } from "../../../constant/Strings";

const AddTraining = (props: any) => {
  const navigate = useNavigate();
  const { addData, hideAddTraining, batchRef, apiCallCompleted } = props;
  const [batchId, setBatchId] = useState(addData.inseedBatchRef);
  const dispatch = useDispatch();
  const { confirmAdd, cancel, submit, addSuccess } = Strings;
  const handleSubmit = () => {
    const updatedBatchId = [...batchId, batchRef];
    setBatchId(updatedBatchId);
    apiCallCompleted(false);
    dispatch(
      actions.editTrainingPlanAction(
        addData.id,
        { inseedBatchRef: updatedBatchId },
        (res: any) => { 
          if (res?.error?.error_description) {
                showErrorToastMessage(res.error.error_description);
                apiCallCompleted(true);
              } else if(res.code === 200) {
                showSuccessToastMessage(addSuccess);
                navigate(`/training-schedule`);
                apiCallCompleted(true);
          }
        }
      )
    );
    hideAddTraining();
  };
  return (
    <div className="container">
      <div className="row mx-auto">
        {confirmAdd} {addData.trainingName}?
      </div>
      <div className="modal-btn-wrapper mt-5 mx-auto">
        <div>
          <button
            className="btn cancel-btn buttonsize"
            onClick={hideAddTraining}
          >
            {cancel}
          </button>
        </div>
        <div>
          <button
            className={"btn add-submit-button buttonsize"}
            onClick={handleSubmit}
          >
            {submit}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddTraining;
