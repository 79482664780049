import  { useEffect } from "react";
import { icons } from "../../../assets/index";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import * as actions from "../../../Redux/Actions/index";
import useLoadingIndicator from "../../../component/Loader/Loader";
import { showErrorToastMessage } from "../../../component/Toasts/Toasts";
import { Strings } from "../../../constant/Strings";

const InseedStaticsComponent = () => {
  const {Mapped,Ongoing} = Strings;
  const { loadingComponent, showLoadingIndicator, hideLoadingIndicator } =
    useLoadingIndicator();

  const dispatch = useDispatch();

  useEffect(() => {
    showLoadingIndicator();
    dispatch(
      actions.getInseedStatusCountAction((res: any) => {
            if (res?.error?.error_description) {
              showErrorToastMessage(res.error.error_description);
            } else if (res.code === 200) {
              hideLoadingIndicator();
        }
      })
    );
  }, []);

  const getInseedStatusCount = useSelector(
    (state: any) => state.employeeReducer.getInseedStatusCount
  );

  const statusCount = getInseedStatusCount?.data;

  return (
    <div>
      {loadingComponent()}
      <div className="report">
        <div className="cards">
          <div>
            <h2 className="completed">{statusCount?.totalMapped}</h2>
            <p className="buttonsize">={Mapped}</p>
          </div>
          <div></div>
          <img src={icons.select_icon} alt="icon" className="reporticons" />
        </div>
        <div className="cards">
          <div>
            <h2 className="open">{statusCount?.totalOnGoing}</h2>
            <p className="buttonsize">{Ongoing}</p>
          </div>
          <img src={icons.processing_icon} alt="icon" className="reporticons" />
        </div>
      </div>
    </div>
  );
};

export default InseedStaticsComponent;
