import  { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as actions from "../../../Redux/Actions/index";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../component/Toasts/Toasts";
import { Strings, requiredFeild } from "../../../constant/Strings";

const CoachRequestModal = (props: any) => {
  const { hideAddCoachReuqest, requestId, apiCallCompleted } = props;
  const userLogin: any = localStorage.getItem("login");
  const loginId = JSON.parse(userLogin).id;
  const { cancel, submit, coachingPurpose, requestSuccess } = Strings;
  const { required } = requiredFeild;
  const [btnDisable, setBtnDisable] = useState(false);
  const [coachRequest, setCoachRequestData] = useState({
    purpose: "",
    employeeRef: loginId,
    coachRef: requestId.coachRef,
    coachDataRef: requestId.coachDataRef,
  });

  const dispatch = useDispatch();
  const handleCoachRequestSubmit = () => {
    apiCallCompleted(false);
    dispatch(
      actions.addCoachRequestAction(coachRequest, (res: any) => {
        if (res?.error?.error_description) {
          showErrorToastMessage(res?.error?.error_description);
          apiCallCompleted(true);
        } else if (res.code === 200) {
            showSuccessToastMessage(requestSuccess);
            apiCallCompleted(true);
          }
      })
    );
    hideAddCoachReuqest();
  };

  useEffect(() => {
    if (coachRequest.purpose) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  });

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12 mt-2 mb-3">
          <label className="form-label">{coachingPurpose}</label>
          <span className="required">{required}</span>
          <textarea
            id="exampleFormControlTextarea1"
            className="form-control"
            style={{
              width: "24rem",
              height: "10rem",
            }}
            onChange={(e) =>
              setCoachRequestData({
                ...coachRequest,
                purpose: e.target.value,
              })
            }
          />
        </div>
      </div>

      <div className="modal-btn-wrapper mt-5 mx-auto">
        <div>
          <button
            className="btn cancel-btn buttonsize"
            onClick={hideAddCoachReuqest}
          >
            {cancel}
          </button>
        </div>
        <div>
          <button
            className={
              btnDisable
                ? "btn add-submit-button buttonsize"
                : "btn add-submit-button btn-disabled buttonsize"
            }
            onClick={handleCoachRequestSubmit}
          >
            {submit}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CoachRequestModal;
