import  { useState } from "react";
import * as actions from "../../../../Redux/Actions/index";
import { useDispatch } from "react-redux";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../../component/Toasts/Toasts";
import { Strings, pfDetailsValidations } from "../../../../constant/Strings";
import {
  validateInsuranceNumber,
  whiteSpaceValid,
} from "../../../../constant/Validations";

const EditInsuranceInfo = (props: any) => {
  const { insuranceNumber, handleEditInsuranceInfo, eId, apiCallCompleted } =
    props;
  const { editSuccess, cancel, submit, insuranceNumberName } = Strings;
  const [btnDisable, setBtnDisable] = useState(false);
  const [employeeData, setEmployeeData] = useState({
    insuranceNumber: insuranceNumber,
    eId: eId,
    updatedBy: eId,
  });
  const { insuranceNumberValid } = pfDetailsValidations;
  const [errors, setErrors] = useState({
    insuranceNumber: "",
  });
  const dispatch = useDispatch();
  let valid: boolean = true;
  const validate = () => {
    if (employeeData.insuranceNumber?.length > 0) {
      if (
        validateInsuranceNumber(employeeData.insuranceNumber) == false ||
        whiteSpaceValid(employeeData.insuranceNumber) == false
      ) {
        valid = false;
        setErrors((errorObj) => ({
          ...errorObj,
          insuranceNumber: insuranceNumberValid,
        }));
      } else {
        setErrors((errorObj) => ({ ...errorObj, insuranceNumber: "" }));
      }
    }
  };
  const handleEdit = () => {
    validate();
    if (valid) {
      handleDispatch();
    }
  };
  const handleDispatch = () => {
    apiCallCompleted(false);
    dispatch(
      actions.editEmployeeDataAction(employeeData, (res: any) => {
         if (res?.error?.error_description) {
              showErrorToastMessage(res.error.error_description);
              apiCallCompleted(true);
            } else if(res.code === 200) {
              showSuccessToastMessage(editSuccess);
              apiCallCompleted(true);
            }
      })
    );
    handleEditInsuranceInfo();
  };

  return (
    <div>
      <div className="row g-4">
        <div className="col-md-6">
          <label htmlFor="inputName4" className="form-label">
            {insuranceNumberName}
          </label>
          <input
            type="text"
            className="form-control"
            value={employeeData.insuranceNumber}
            onChange={(e) => {
              setBtnDisable(true);
              setEmployeeData({
                ...employeeData,
                insuranceNumber: e.target.value,
              });
            }}
          />
          <p className="error">{errors.insuranceNumber}</p>
        </div>
      </div>

      <div className="modal-btn-wrapper mt-4 mb-4">
        <div>
          <button
            className="btn cancel-btn buttonsize"
            onClick={handleEditInsuranceInfo}
          >
            {cancel}
          </button>
        </div>
        <div>
          <button
            className={
              btnDisable
                ? "btn add-submit-button buttonsize"
                : "btn add-submit-button btn-disabled buttonsize"
            }
            onClick={handleEdit}
          >
            {submit}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditInsuranceInfo;
