import React, { useEffect, useMemo, useState } from "react";
import SearchBar from "../../../component/SearchBar/SearchBar";
import Button from "../../../component/Buttons/Button";
import { FaSort } from "react-icons/fa";
import { Table } from "react-bootstrap";
import * as actions from "../../../Redux/Actions/index";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import moment from "moment";

import { ToastContainer } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import MainCard from "../../../component/MainCard/MainCard";
import useLoadingIndicator from "../../../component/Loader/Loader";
import { showErrorToastMessage } from "../../../component/Toasts/Toasts";
import ShowEntries from "../../../component/ShowEntries/ShowEntries";
import { Strings, coachingProgressFilter } from "../../../constant/Strings";
import "../CoachingProgress/Coaching.scss";
import FilterValues from "../../../component/FieldFilter/FilterValues";
import TablePagination from "../../../component/TablePagination/TablePagination";

const CoachListTable = (props: any) => {
  const { loadingComponent } = useLoadingIndicator();
  const [searchValue, setSearchValue] = useState<any>("");
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState<Number>(1);
  const [filterValue, setFilterValue] = useState<any>([]);
  const [totalRecords, setTotalRecords] = useState<any>(0);
  const [totalEntries, setTotalEntries] = useState<any>();
  const { coachingProgress, noData } = Strings;
  const userLogin: any = localStorage.getItem("login");
  const loginId = JSON.parse(userLogin).id;
  const dispatch = useDispatch();
  const [data, setdata] = useState<any>([]);
  const [order, setorder] = useState("ASC");

  //  pagination
  const [pageSize, setPageSize] = useState(10);
  const [isSorted, setisSorted] = useState(false);
  const [sortedData, setsortedData] = useState<any>([]);
  const sorting = (col: any) => {
    setisSorted(true);
    if (order === "ASC") {
      const sorted = [...data].sort((a: any, b: any) => {
        if (col == "coachName") {
          if (a?.Coach[0]?.candidateName < b?.Coach[0]?.candidateName) {
            return 1;
          }
          if (a?.Coach[0]?.candidateName > b?.Coach[0]?.candidateName) {
            return -1;
          }
          return 0;
        } else if (col == "RequestedDate") {
          if (a?.createdAt < b?.createdAt) {
            return 1;
          }
          if (a?.createdAt > b?.createdAt) {
            return -1;
          }
          return 0;
        } else {
          if (a[col] < b[col]) {
            return 1;
          }
          if (a[col] > b[col]) {
            return -1;
          }
          return 0;
        }
      });
      setsortedData(sorted);
      setorder("DSC");
    } else {
      const sorted = [...data].sort((a: any, b: any) => {
        if (a[col] < b[col]) {
          return -1;
        }
        if (a[col] > b[col]) {
          return 1;
        }
        return 0;
      });
      setsortedData(sorted);
      setorder("ASC");
    }
  };
  const [onclickSearch, setOnclickSearch] = useState(false);
  const onSearchClicked = (value: any) => {
    setOnclickSearch(value);
  };
  const condition: boolean =
    (filterValue.length == 0 && searchValue == "") ||
    (filterValue.length > 0 && onclickSearch);
  const filterKey = filterValue.map((ele: any) => ele.value);
  useEffect(() => {
    if (condition) {
      dispatch(
        actions.getAllAcceptedCoachListAction(
          page,
          pageSize,
          filterKey,
          searchValue,
          loginId,
          (res: any) => {
            if (res?.error?.error_description) {
              showErrorToastMessage(res?.error?.error_description);
            } else {
              if (res.code === 200) {
                setTotalPages(res.meta_data.totalPageCount);
                setTotalEntries(res.meta_data.totalEntries);
                setOnclickSearch(false);
              }
            }
          }
        )
      );
    }
  }, [page, pageSize, filterValue, searchValue, condition]);

  const getAllCoachList = useSelector(
    (state: any) => state.employeeReducer.getAllAcceptedCoachListDetails
  );

  useEffect(() => {
    if (getAllCoachList && getAllCoachList.data) {
      setdata(getAllCoachList.data);
      setTotalRecords(getAllCoachList.data.length);
    }
  }, [getAllCoachList, data]);
  const COLUMNS: any = [
    {
      Header: "Name of the Coach",
      accessor: "coachName",
    },
    {
      Header: "Purpose for Coaching",
      accessor: "Purpose",
    },
    {
      Header: "Requested Date",
      accessor: "RequestedDate",
    },
    {
      Header: "Status",
      accessor: "status",
    },
  ];
  const columns = useMemo(() => COLUMNS, []);
  const navigate = useNavigate();

  const handlePageChange = (newPage: any) => {
    setPage(newPage);
    setOnclickSearch(true);
  };

  const filterChange = (selectedOption: any) => {
    if (selectedOption) {
      if (filterValue.find((obj: any) => obj.value == selectedOption.value)) {
        setFilterValue((prevValue: any) =>
          prevValue.filter((obj: any) => obj.value != selectedOption.value)
        );
      } else {
        setFilterValue((prevValue: any) => [...prevValue, selectedOption]);
      }
    }
  };
  const handleSearch = (value: any) => {
    setSearchValue(value);
    setPage(1);
  };

  return (
    <>
      {loadingComponent()}
      <MainCard>
        <div className="coaching-progress-heading-button-wrapper">
          <div>
            <h2>{coachingProgress}</h2>
          </div>

          <div className="btn-wrapper">
            <Button
              title="Coach View"
              onClick={() => {
                navigate("/candidate-list");
              }}
              className="coaching-progress-view-btn"
            />
          </div>
        </div>
        <div className="mt-2">
          <div
            className="mt-3 buttonsize filter-coaching-progress-wrapper"
       
          >
            <div className="coaching-progress-searchbar-wrapper">
              <SearchBar
                filter={searchValue}
                setFilter={handleSearch}
                onSearchClicked={onSearchClicked}
              />
            </div>
            <div className="coaching-progress-filter-container">
              <select
                id="select-filters"
                value={filterValue}
                onChange={(event: any) => {
                  const selectedValue = event.target.value;
                  const selectedOption = coachingProgressFilter.find(
                    (item: any) => item.value === selectedValue
                  );
                  if (selectedOption) {
                    filterChange(selectedOption);
                  }
                }}
              >
                <option hidden>Filters</option>
                {coachingProgressFilter.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.title}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <FilterValues
            filterValueArray={filterValue}
            onFilterChange={filterChange}
          />
          {data.length > 0 ? (
            <>
              <div className="table-responsive mt-2">
                <Table className="table-margin">
                  <thead className="employeedb">
                    <tr className="custom-table-head-row">
                      {columns.map((ele: any) => (
                        <React.Fragment key={ele.Header}>
                          <td
                            onClick={() => sorting(ele.accessor)}
                            className="sorting-cursor"
                          >
                            {ele.Header}
                            {ele.Header !== "Action" &&
                            ele.Header !== "" &&
                            ele.Header != "Remark" ? (
                              <span>{<FaSort />} </span>
                            ) : (
                              " "
                            )}
                          </td>
                        </React.Fragment>
                      ))}
                    </tr>
                  </thead>

                  <tbody className="employeedata coaching-progress-table">
                    {(isSorted ? sortedData : data).map(
                      (ele: any, index: any) => (
                        <>
                          <tr key={ele?.loginId}>
                            <td>
                              {" "}
                              <Link
                                to={`/coaching-progress-card/${ele?.coachRef}`}
                              >
                                {ele?.Coach[0]?.candidateName +
                                  " " +
                                  ele?.Coach[0]?.middleName +
                                  " " +
                                  ele?.Coach[0]?.lastname}
                              </Link>
                            </td>
                            <td>{ele?.purpose}</td>
                            <td>
                              {moment(ele.createdAt).format("DD-MMM-YYYY")}
                            </td>

                            <td>
                              <button
                                className={`btn ${
                                  ele.status === 1
                                    ? "coaching-status-approved"
                                    : ele.status === 2
                                    ? "coaching-status-rejected"
                                    : ""
                                }`}
                              >
                                {ele?.status === 1
                                  ? "Accepted"
                                  : ele?.status === 2
                                  ? "Rejected"
                                  : ""}
                              </button>
                            </td>
                          </tr>
                        </>
                      )
                    )}
                  </tbody>
                </Table>
                <div className="show-entries-wrapper">
                  <ShowEntries
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    totalRecords={totalRecords}
                    totalEntries={totalEntries}
                  />

                  <TablePagination
                    totalPages={totalPages}
                    currentPage={page}
                    onClick={handlePageChange}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <p className="employeedata mt-2">{noData}</p>
            </>
          )}
        </div>
        <ToastContainer />
      </MainCard>
    </>
  );
};

export default CoachListTable;
