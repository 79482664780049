import  { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MainCard from "../../../component/MainCard/MainCard";
import * as actions from "../../../Redux/Actions/index";
import { showErrorToastMessage } from "../../../component/Toasts/Toasts";
import { useParams } from "react-router-dom";
import EmployeeSkillCoinTable from "./EmployeeSkillCoinTable";

const EmployeeSkillCoinData = () => {
  let { id } = useParams();
  const [data, setdata] = useState<any>([]);
  const dispatch = useDispatch();
  const [page, setPage] = useState<Number>(1);
  const [searchKey, setSearchKey] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [filterValue, setFilterValue] = useState<any>([]);
  const [searchValue, setSearchValue] = useState("");
  const [pageSize, setPageSize] = useState<any>(10);
  const [totalEntries, setTotalEntries] = useState<any>();
  const [empName, setEmpName] = useState<any>();
  const deallocateSkillCoinsData = useSelector(
    (state: any) => state.employeeReducer.deallocateSkillCoinsDetails
  );

  const skillCoinsData = useSelector(
    (state: any) => state.employeeReducer.getSkillcoinByEmpid
  );
  const redeemSkillCoinsData = useSelector(
    (state: any) => state.employeeReducer.editRedeemSkillCoinsDetails
  );
  const [onclickSearch, setOnclickSearch]= useState(false);
  const onSearchClicked = (value: any)=>{
    setOnclickSearch(value)
  }
  const condition: boolean =
  (filterValue.length == 0 && searchValue == "") ||
  (filterValue.length > 0 && onclickSearch);
  const filterKey = filterValue.map((ele: any) => ele.value);
  useEffect(() => {
    if(condition){
    dispatch(
      actions.getkillDataByEmpIdPaginationAction(
        id,
        pageSize,
        page,
        filterKey,
        searchValue,
        (res: any) => {
              if (res?.error?.error_description) {
                showErrorToastMessage(res.error.error_description);
              } else if(res.code === 200) {
                setOnclickSearch(false);
                setTotalPages(res?.meta_data?.totalPageCount);
                setTotalEntries(res?.meta_data?.totalEntries);
                setEmpName(
                  res?.meta_data?.employeeData?.candidateName +
                    " " +
                    res?.meta_data?.employeeData?.middleName +
                    " " +
                    res?.meta_data?.employeeData?.lastname
                );
              }
        }
      )
    );
    }
  }, [
    redeemSkillCoinsData,
    page,
    searchValue,
    searchKey,
    filterValue,
    pageSize,
    empName,
    deallocateSkillCoinsData,
    condition
  ]);

  useEffect(() => {
    if (skillCoinsData && skillCoinsData.data) {
      setdata(skillCoinsData.data);
    }
  }, [skillCoinsData, data]);

  const handlePageChange = (newPage: any) => {
    setPage(newPage);
    setOnclickSearch(true);
  };

  const handleSearch = (value: any) => {
    setSearchValue(value);
    setPage(1);
  };

  const filterChange = (selectedOption: any) => {
    if (selectedOption) {
      if (filterValue.find((obj: any) => obj.value == selectedOption.value)) {
        setFilterValue((prevValue: any) =>
          prevValue.filter((obj: any) => obj.value != selectedOption.value)
        );
      } else {
        setFilterValue((prevValue: any) => [...prevValue, selectedOption]);
      }
    }
  };

  const [policy, setPolicy] = useState<any>([]);
  useEffect(() => {
    dispatch(actions.getDownloadPolicyAction("SkillCoin"));
  }, []);

  const downloadPolicyDetails = useSelector(
    (state: any) => state.employeeReducer.getDownloadPolicyData
  );

  useEffect(() => {
    if (downloadPolicyDetails) {
      if (downloadPolicyDetails.data) {
        setPolicy(downloadPolicyDetails.data);
      }
    }
  }, [downloadPolicyDetails, policy]);

  return (
    <div>
      <MainCard>
        <EmployeeSkillCoinTable
          totalPages={totalPages}
          onPage={page}
          handlePageChange={handlePageChange}
          searchKey={searchKey}
          searchValue={searchValue}
          handleSearch={handleSearch}
          setSearchKey={setSearchKey}
          filterValue={filterValue}
          filterChange={filterChange}
          pagePerSize={pageSize}
          setPagePerSize={setPageSize}
          totalEntries={totalEntries}
          empName={empName}
          onSearchClicked = {onSearchClicked}
        />
      </MainCard>
    </div>
  );
};

export default EmployeeSkillCoinData;
