import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../component/Toasts/Toasts";
import * as actions from "../../../Redux/Actions";
import Select from "react-select";
import {
  Strings,
  addEmployeeSkillCoinsErrorsValidations,
} from "../../../constant/Strings";
const AddSpecificEmployee = (props: any) => {
  const {
    Employee_label,
    no_skill,
    assigned_Date,
    cancel_label,
    submit_label,
  } = Strings;
  const { employeeNameVal, assigned_dateVal, skillcoinsVal, skillcoinsRange } =
    addEmployeeSkillCoinsErrorsValidations;
  const { hideAddModal, apiCallCompleted } = props;
  const userLogin: any = localStorage.getItem("login");
  const loginId = JSON.parse(userLogin).id;
  const [btnDisable, setBtnDisable] = useState(false);
  const [employeeInfo, setEmployeeInfo] = useState([]);

  const [allocateSkillCoins, setAllocateSkillCoins] = useState<any>({
    skillcoins: "",
    assignment_letter: "",
    created_by: loginId,
    assigned_date: "",
    employeeName: "",
  });
  const [errors, setErrors] = useState({
    skillcoins: "",
    assignment_letter: "",
    assigned_date: "",
    employeeName: "",
  });

  useEffect(() => {
    if (
      allocateSkillCoins.employeeName &&
      allocateSkillCoins.skillcoins &&
      allocateSkillCoins.assigned_date
    ) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  });

  const dispatch = useDispatch();
  let valid: boolean = true;
  const validate = () => {
    if (allocateSkillCoins.employeeName.length == 0) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        employeeName: employeeNameVal,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({ ...errorObj, employeeName: "" }));
    }
    if (allocateSkillCoins.assigned_date.length == 0) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        assigned_date: assigned_dateVal,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({ ...errorObj, assigned_date: "" }));
    }
    if (allocateSkillCoins.skillcoins.length == 0) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        skillcoins: skillcoinsVal,
      }));
      setBtnDisable(false);
    }
    if (
      allocateSkillCoins.skillcoins < 100 ||
      allocateSkillCoins.skillcoins > 500
    ) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        skillcoins: skillcoinsRange,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({ ...errorObj, skillcoins: "" }));
    }
  };
  const handleAllocateSkillCoins = () => {
    validate();
    if (valid) {
      handleDispatch();
      hideAddModal();
    }
  };
  const handleDispatch = () => {
    apiCallCompleted(false);
    dispatch(
      actions.assignSkillCoinsAction(allocateSkillCoins, (res: any) => {
            if (res?.error?.error_description) {
              showErrorToastMessage(res.error.error_description);
              apiCallCompleted(true);
            } else if (res.code === 200) {
              showSuccessToastMessage("Added Successfully!");
              apiCallCompleted(true);
            }
      })
    );
  };

  const options: any = employeeInfo.map((ele: any) => ({
    value:
      ele.candidateName +
      " " +
      ele.middleName +
      " " +
      ele.lastname +
      "-" +
      ele.employeeId +
      "/" +
      ele._id,
    label:
      ele.candidateName +
      " " +
      ele.middleName +
      " " +
      ele.lastname +
      "-" +
      ele.employeeId,
  }));

  const employeeData = useSelector(
    (state: any) => state.employeeReducer.getEmployeeData
  );

  useEffect(() => {
    if (employeeData && employeeData.data) {
      setEmployeeInfo(employeeData.data);
    }
  }, [employeeData, employeeInfo]);
  useEffect(() => {
    dispatch(actions.getEmployeeDataAction((res: any) => {}));
  }, []);

  const handleChange = (selected: any) => {
    setAllocateSkillCoins({
      ...allocateSkillCoins,
      employeeRef: selected.value.split("/")[1],
      employeeName: selected.value.split("/")[0],
    });
  };
  return (
    <div>
      <div className="row g-3">
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {Employee_label}
          </label>
          <span className="required">*</span>
          <Select
            className="dropdown-select"
            options={options}
            isSearchable={true}
            onChange={handleChange}
          />
          <p className="error">{errors.employeeName}</p>
        </div>
        <div className="col-md-8">
          <label className="form-label">{no_skill}</label>
          <span className="required">*</span>
          <input
            type="number"
            min={100}
            max={500}
            className="form-control"
            onChange={(e: any) => {
              setAllocateSkillCoins({
                ...allocateSkillCoins,
                skillcoins: e.target.value,
              });
            }}
          />
          <p className="error">{errors.skillcoins}</p>
        </div>
        <div className="col-md-4">
          <label className="form-label">{assigned_Date}</label>
          <span className="required">*</span>
          <input
            type="Date"
            className="form-control"
            max={new Date().toISOString().split("T")[0]}
            onChange={(e: any) => {
              setAllocateSkillCoins({
                ...allocateSkillCoins,
                assigned_date: e.target.value,
              });
            }}
          />
          <p className="error">{errors.assigned_date}</p>
        </div>

        <div className="modal-btn-wrapper mt-5 mx-auto">
          <div>
            <button
              className="btn cancel-btn buttonsize"
              onClick={hideAddModal}
            >
              {cancel_label}
            </button>
          </div>
          <div>
            <button
              className={
                btnDisable
                  ? "btn add-submit-button buttonsize"
                  : "btn add-submit-button btn-disabled buttonsize"
              }
              onClick={handleAllocateSkillCoins}
            >
              {submit_label}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSpecificEmployee;
