import  { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../Toasts/Toasts";
import { Strings } from "../../constant/Strings";
import "../MainCard/MainCard.scss"

const ReasonForRejection = (props: any) => {
  const {
    hideModal,
    id,
    editApi,
    statusKey,
    hideEditModal,
    setShowUndoPopup,
    apiCallCompleted,
    data
  } = props;
  const [rejectionData, setRequirementData] = useState({
    reason: "",
    [statusKey]: "Rejected",
  });
  const [btnDisable, setBtnDisable] = useState(false);
  const { rejectSuccess, reasonforRejection, cancel, submit } = Strings;
  const dispatch = useDispatch();
  const handleSubmit = () => {
    apiCallCompleted(false);
    dispatch(
      editApi(id, data? data : rejectionData, (res: any) => {
        if (res?.error?.error_description) {
          showErrorToastMessage(res?.error?.error_description);
          apiCallCompleted(true);
        } else {
          if (res.code === 200) {
            showSuccessToastMessage(rejectSuccess);
            apiCallCompleted(true);
            setShowUndoPopup(true);
          }
        }
      })
    );
    hideModal();
    hideEditModal();
  };
  useEffect(() => {
    if (rejectionData.reason) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  });
  const handleChange = (e: any) => {
    setRequirementData({ ...rejectionData, reason: e.target.value });
  };
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12 mt-2 mb-3">
          <label className="form-label">{reasonforRejection}</label>
          <span className="required">*</span>
          <textarea
            id="exampleFormControlTextarea1"
            className="form-control ReasonRejectStyle"
            value={rejectionData.reason}
            onChange={handleChange}
           
          />
        </div>
      </div>
      <div className="modal-btn-wrapper mt-5 mx-auto">
        <div>
          <button className="btn cancel-btn buttonsize" onClick={hideModal}>
            {cancel}
          </button>
        </div>
        <div>
          <button
            className={
              btnDisable
                ? "btn add-submit-button buttonsize"
                : "btn add-submit-button btn-disabled buttonsize"
            }
            onClick={handleSubmit}
          >
            {submit}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReasonForRejection;
