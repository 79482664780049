import  { useEffect, useState } from "react";
import MainCard from "../../../../component/MainCard/MainCard";
import { useParams } from "react-router-dom";
import ProfileTabs from "../../../../component/Tabs/Tabs";
import InvoiceDetails from "./InvoiceDetails";
import RemarksTab from "./RemarksTab";
import { useDispatch } from "react-redux";
import { showErrorToastMessage } from "../../../../component/Toasts/Toasts";
import * as actions from "../../../../Redux/Actions/index";
import { useSelector } from "react-redux";
import ProjectDetailsTab from "../../../../component/ProjectDetailsTab/ProjectDetailsTab";
import {
  Strings,
  invoiceDetailsColumns1,
  invoiceDetailsColumns2,
  documentHeadingColumn,
} from "../../../../constant/Strings/index";
import hanldeUnauthorizedUser from "../../../../component/AccessConditions/UnauthorizedUser";
import { timeDateFormat } from "../../../../component/FormatDate";
import PoHistory from "./PoHistory";

const InvoiceTable = () => {
  const { unAuthorizeUser } = hanldeUnauthorizedUser();
  let { project_id } = useParams();
  const { project_name } = Strings;
  const [invoiceDetailsTab, setInvoiceDetailsTab] = useState(false);
  const [remarksTab, setRemarksTab] = useState(false);
  const [poHistoryTab,setPoHistoryTab] = useState(true);
  const [data, setdata] = useState<any>([]);
  const [invoices, setInvoices] = useState<any>([]);
  const [poHistoryData,setPoHistoryData] = useState([]);

  const handlePersonalInfo = () => {
    setInvoiceDetailsTab(true);
    setRemarksTab(false);
    setPoHistoryTab(false);
  };
  const handleContactInfo = () => {
    setRemarksTab(true);
    setInvoiceDetailsTab(false);
    setPoHistoryTab(false);
  };


  const handlePoHistory = () => {
    setRemarksTab(false);
    setInvoiceDetailsTab(false);
    setPoHistoryTab(true);
  };

  const addData = useSelector(
    (state: any) => state.employeeReducer.addProjectInvoiceDetails
  );

  const editData = useSelector(
    (state: any) => state.employeeReducer.editProjectInvoiceDetails
  );
  const deleteData = useSelector(
    (state: any) => state.employeeReducer.deleteProjectInvoiceDetails
  );

  const dispatch = useDispatch();
  useEffect(() => {
    unAuthorizeUser();
    dispatch(
      actions.getInvoicesByProjectIdAction(project_id, (res: any) => {
         if (res?.error?.error_description) {
              showErrorToastMessage(res.error.error_description);
            } else if(res.code === 200) {
              setdata(res.data[0]);
              setInvoices(res.data[0].invoice);
            }
      })
    );
  }, [editData, deleteData, addData]);


  useEffect(() => {
    dispatch(
      actions.getProjectHistoryByProjectIdAction(project_id, (res: any) => {
            if (res?.error?.error_description) {
              showErrorToastMessage(res.error.error_description);
            }else if (res?.code === 200) {
              setPoHistoryData(res.data);
            }
      })
    );
  }, []);
  const valueArray1 = [
    data?.techType ? data?.techType : "-",
    data?.poValueIn ? data?.poValueIn : "-",
    data?.billingRate ? data?.billingRate : "-",
    data?.poAmount ? data?.poAmount : "-",
    data?.approvedHc ? data?.approvedHc : "-",
    data?.actualHc ? data?.actualHc : "-",
  ];
  const valueArray2 = [
    data?.poNumber ? data?.poNumber : "-",
    data?.poStartDate ? timeDateFormat(data?.poStartDate) : "-",
    data?.poEndDate ? timeDateFormat(data?.poEndDate) : "-",
    data?.projectStartDate ? timeDateFormat(data?.projectStartDate) : "-",
    data?.projectEndDate ? timeDateFormat(data?.projectEndDate) : "-",
  ];
  const columnValues = [
    {
      name: "Master Services Agreement.pdf",
      value: data.msa?.document_path_url,
    },
    { name: "Statement of Work.pdf", value: data.sow?.document_path_url },
  ];
  return (
    <>
      <MainCard>
        <ProjectDetailsTab
          navigateTo={"/project-repository"}
          tabHeading={project_name}
          headingValue={data?.projectName}
          firstColumnHeadings={invoiceDetailsColumns1}
          firstColumnValues={valueArray1}
          secondColumnHeadings={invoiceDetailsColumns2}
          secondColumnValues={valueArray2}
        />
        <div className="tabs-container">
        <ProfileTabs
            title="PO History"
            onClick={handlePoHistory}
            active={poHistoryTab}
          />
          <ProfileTabs
            title="Invoice Details"
            onClick={handlePersonalInfo}
            active={invoiceDetailsTab}
          />
          <ProfileTabs
            title="Remarks & Additional Info"
            onClick={handleContactInfo}
            active={remarksTab}
          />
        </div>
        <div className="profile-container">
          {poHistoryTab && poHistoryData?.length > 0&&(
            <PoHistory poHistoryData={poHistoryData}/>
          )}
          {invoiceDetailsTab && (
            <InvoiceDetails data={invoices} projectRef={data?._id} />
          )}
          {remarksTab && (
            <RemarksTab
              data={data}
              columnValues={columnValues}
              documentHeadingColumn={documentHeadingColumn}
            />
          )}
        </div>
      </MainCard>
    </>
  );
};

export default InvoiceTable;
