import  { useEffect, useMemo, useState } from "react";
import {
  EmployeeskillDbFilters,
  Strings,
} from "../../../constant/Strings/index";
import { icons } from "../../../assets/index";
import SearchBar from "../../../component/SearchBar/SearchBar";
import {
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy,
} from "react-table";
import * as actions from "../../../Redux/Actions/index";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { showErrorToastMessage } from "../../../component/Toasts/Toasts";
import ShowEntries from "../../../component/ShowEntries/ShowEntries";
import { TiArrowUnsorted } from "react-icons/ti";
import { Link, useParams } from "react-router-dom";
import TablePagination from "../../../component/TablePagination/TablePagination";
import FilterValues from "../../../component/FieldFilter/FilterValues";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Header from "../../../assets/Header.jpg";
import Footer from "../../../assets/Footer.jpg";
import Signature from "../../../assets/Signature.jpg";
import useLoadingIndicator from "../../../component/Loader/Loader";
import ModalComponents from "../../../component/ModalComponent/ModalComponent";
import "./EmployeeSkillCoinTable.scss";
import DeallocateSkillCoins from "./DeallocateSkillCoins";
import { timeDateFormat } from "../../../component/FormatDate";

const EmployeeSkillCoinTable = (props: any) => {
  const {
    loadingComponent,
    showLoadingIndicator,
    hideLoadingIndicator,
    apiCallCompleted,
  } = useLoadingIndicator();
  const {
    skill_heading,
    refNo,
    empId,
    desc,
    assignDate,
    noSkill,
    bookValue,
    dateOfVesting,
    aggreDesc,
    forwarDesc,
    bestWish,
    skillName,
    signature_name,
    role,
  } = Strings;
  const { download_icon } = icons;
  const {
    totalPages,
    onPage,
    handlePageChange,
    searchValue,
    handleSearch,
    filterValue,
    filterChange,
    pagePerSize,
    totalEntries,
    setPagePerSize,
    empName,
    onSearchClicked,
  } = props;

  const { noData } = Strings;

  const [data, setdata] = useState<any>([]);
  const [totalRecords, setTotalRecords] = useState(0);

  const dispatch = useDispatch();
  const authRole = localStorage.getItem("role");
  const isCheckedData = authRole?.includes("skillcoin-checker");
  const isMakerData = authRole?.includes("skillcoin-maker");

  const [pData, setPData] = useState<any>({
    candidateName: "",
    middleName: "",
    lastname: "",
    referenceNo: "",
    employeeId: "",
    assigned_date: "",
    skillcoins: "",
    skValue: "",
    maturity_date: "",
  });

  useEffect(() => {
    dispatch(
      actions.getSkillCoinValueAction((res: any) => {
            if (res?.error?.error_description) {
              showErrorToastMessage(res.error.error_description);
            } else if(res.code === 200) {
              setPData((obj: any) => ({
                ...obj,
                skValue: res.data[0].skillCoinValue,
              }));
            }
      })
    );
  }, [pData.skValue]);

  useEffect(() => {
    setTimeout(() => {
      const pdfContentDiv: any = document.getElementById("pdf-content");
      if (pdfContentDiv) {
        if (pdfContentDiv.style.display === "block") {
          const pdfDocument = new jsPDF();
          const table = pdfContentDiv;

          html2canvas(table).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const imgProps = pdfDocument.getImageProperties(imgData);
            const pdfWidth = pdfDocument.internal.pageSize.getWidth() - 20;
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

            pdfDocument.addImage(imgData, "PNG", 10, 10, pdfWidth, pdfHeight);
            pdfDocument.save("Assignment Letter.pdf");

            pdfContentDiv.style.display = "none";
          });
        }
      }
      hideLoadingIndicator();
    }, 2000);
  }, [pData]);
  const printPdf = (
    e: any,
    candidateName: any,
    middleName: any,
    lastname: any,
    employeeId: any,
    assigned_date: any,
    skillcoins: any,
    maturity_date: any
  ) => {
    e.preventDefault();
    let assignedDateFormat = timeDateFormat(pData.assignedDate);
    let generateReference =
      "SMTC-SCA-" + pData.employeeId + "-" + assignedDateFormat;
    setPData((obj: any) => ({
      ...obj,
      candidateName: candidateName,
      middleName: middleName,
      lastname: lastname,
      employeeId: employeeId,
      assigned_date: assigned_date,
      skillcoins: skillcoins,
      maturity_date: maturity_date,
      referenceNo: generateReference,
    }));
    const pdfContentDiv: any = document.getElementById("pdf-content");
    if (pdfContentDiv) {
      showLoadingIndicator();
      pdfContentDiv.style.display = "block";
    }
  };
  const [skillCoinData, setSkillCoinData] = useState({
    skillcoins: "",
    skillCoinId: "",
    assignedDate: "",
    maturityDate: "",
    employeeId: "",
    employeeName: "",
    recordStatus: "",
    status: "",
    assignment_letter: "",
    remarks: "",
  });
  const [showDeallocateSkillCoins, setShowDeallocateSkillCoins] =
    useState(false);
  const handleDeallocateSkillCoins = (
    skillcoins: any,
    skillCoinId: any,
    assignedDate: any,
    maturityDate: any,
    employeeId: any,
    employeeName: any,
    recordStatus: any,
    status: any,
    assignment_letter: any,
    remarks: any
  ) => {
    setShowDeallocateSkillCoins(true);
    setSkillCoinData({
      skillcoins: skillcoins,
      skillCoinId: skillCoinId,
      assignedDate: assignedDate,
      maturityDate: maturityDate,
      employeeId: employeeId,
      employeeName: employeeName,
      recordStatus: recordStatus,
      status: status,
      assignment_letter: assignment_letter,
      remarks: remarks,
    });
  };

  const hideDeallocateSkillCoins = () => {
    setShowDeallocateSkillCoins(false);
  };

  const COLUMNS: any = [
    {
      Header: "Skill Coins",
      accessor: "skillcoins",
    },
    {
      Header: "Assigned Date",
      accessor: "assigned_date",
      Cell: (Action: any) => {
        const assigned_date = Action.row.original.assigned_date;
        return timeDateFormat(assigned_date);
      },
    },
    {
      Header: "Maturity Date",
      accessor: "maturity_date",
      Cell: (Action: any) => {
        const maturityDate = Action.row.original.maturity_date;
        return timeDateFormat(maturityDate);
      },
    },
    {
      Header: "Assignment Letter",
      accessor: "assignment_letter",
      Cell: (Action: any) => {
        const remarks = Action.row.original.EmployeeData;

        return (
          <div>
            {remarks ? (
              <div
                className="action-btn"
                data-toggle="tooltip"
                data-placement="top"
                title="Assignment Letter"
              >
                {remarks.map((remark: any, index: any) => (
                  <button
                    key={index}
                    className="border-none"
                    onClick={(e) => {
                      printPdf(
                        e,
                        remark?.candidateName,
                        remark?.middleName,
                        remark?.lastname,
                        remark?.employeeId,
                        Action.row.original.assigned_date,
                        Action.row.original.skillcoins,
                        Action.row.original.maturity_date
                      );
                    }}
                  >
                    <img src={download_icon} alt="downloadIcon" />
                  </button>
                ))}
              </div>
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: (Action: any) => {
        return (
          <>
            {Action.value && (
              <button
                type="button"
                className={
                  Action.value === "allocated"
                    ? "btn projectRepo-status-btn redeem-allocated-btn"
                    : Action.value === "deallocated"
                    ? "btn projectRepo-status-btn redeem-dellocated-btn"
                    : Action.value === "settled"
                    ? "btn projectRepo-status-btn redeem-settled-btn"
                    : ""
                }
              >
                {Action.value == "allocated"
                  ? "Allocated"
                  : Action.value == "deallocated"
                  ? "Deallocated"
                  : Action.value == "settled"
                  ? "Settled"
                  : Action.value}
              </button>
            )}
          </>
        );
      },
    },
    {
      Header: "Record Status",
      accessor: "recordStatus",
    },
  ];
  const makerCheckerColumns = [
    ...COLUMNS,
    {
      Header: "Action",
      disableSortBy: false,
      Cell: (Action: any) => {
        const item = Action.row.original;
        return (
          <div className="d-flex flex-row justify-content-center gap align-items-center">
            {item?.status != "settled" ? (
              <>
                <div
                  className="edit-section"
                  onClick={() =>
                    handleDeallocateSkillCoins(
                      item.skillcoins,
                      item._id,
                      item?.assigned_date,
                      item?.maturity_date,
                      item?.EmployeeData[0].employeeId,
                      item?.EmployeeData[0].candidateName +
                        " " +
                        item?.EmployeeData[0].middleName +
                        " " +
                        item?.EmployeeData[0].lastname,
                      item?.recordStatus,
                      item?.status,
                      item?.assignement_letter,
                      item?.remarks
                    )
                  }
                >
                  <img
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Edit"
                    src={icons.skill_edit}
                    alt="edit_icon"
                  />
                </div>
              </>
            ) : (
              <>
                <div className="disable-edit-section">
                  <img
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Edit"
                    src={icons.disableEditIcon}
                    alt="edit_icon"
                    className="disable-skil-icon"
                  />
                </div>
              </>
            )}
          </div>
        );
      },
    },
  ];
  const columns = useMemo(
    () => (isCheckedData || isMakerData ? makerCheckerColumns : COLUMNS),
    [pagePerSize, isCheckedData, isMakerData]
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: pagePerSize,
        pageIndex: 0,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const skillCoinsData = useSelector(
    (state: any) => state.employeeReducer.getSkillcoinByEmpid
  );

  useEffect(() => {
    if (skillCoinsData && skillCoinsData.data) {
      setdata(skillCoinsData.data);
      setTotalRecords(skillCoinsData.data.length);
    }
  }, [skillCoinsData, data, pagePerSize, pageSize]);
  let { id } = useParams();
  

  const [policy, setPolicy] = useState<any>([]);
  useEffect(() => {
    dispatch(actions.getDownloadPolicyAction("SkillCoin"));
  }, []);

  const downloadPolicyDetails = useSelector(
    (state: any) => state.employeeReducer.getDownloadPolicyData
  );

  useEffect(() => {
    if (downloadPolicyDetails) {
      if (downloadPolicyDetails.data) {
        setPolicy(downloadPolicyDetails.data);
      }
    }
  }, [downloadPolicyDetails, policy]);

  return (
    <>
      {loadingComponent()}
      <div className="employee-skill-heading-button-wrapper">
        <div className="left-section">
          <Link to="/skill-coins" className="back-btn">
            <img src={icons.backIcon} alt="backIcon" className="reporticons" />
            Back
          </Link>
        </div>
      </div>

      <div className="skill-heading">
        <div>
          <h2>{skill_heading}</h2>
        </div>
        <div className="emp-section">
          <span className="emp-label">{empName}</span>
        </div>
      </div>
      <div className="mt-3 buttonsize filter-wrapper margin-left">
        <div className="skill-searchbar-wrapper ">
          <SearchBar
            filter={searchValue}
            setFilter={handleSearch}
            onSearchClicked={onSearchClicked}
          />
        </div>

        <div className="skill-filter-container">
          <select
            id="select-filters"
            value={typeof filterValue === 'string' ? filterValue : ''}
            onChange={(event) => {
              const selectedValue = event.target.value;
              const selectedOption = EmployeeskillDbFilters.find(
                (item: any) => item.value === selectedValue
              );
              if (selectedOption) {
                filterChange(selectedOption);
              }
            }}
          >
            <option hidden>Filters</option>
            {EmployeeskillDbFilters.map((item: any, index: any) => (
              <option key={index} value={item.value}>
                {item.title}
              </option>
            ))}
          </select>
        </div>
      </div>
      <FilterValues
        filterValueArray={filterValue}
        onFilterChange={filterChange}
      />
      {data.length > 0 ? (
        <>
          <div className="table table-responsive">
            <table
              className="table-main table table-sm mt-3"
              {...getTableProps()}
            >
              <thead className="table-head">
                {headerGroups.map((headerGroup) => (
                  <tr
                    className="table-head-row"
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map(
                      (column: any, columnIndex: number) => (
                        <th
                          className="table-head-row"
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          scope="col"
                        >
                          {column.render("Header")}

                          <span>
                            {column.id !== "Action" && column.id !== "id" ? (
                              column.isSorted ? (
                                column.isSortedDesc ? (
                                  <TiArrowUnsorted />
                                ) : (
                                  <TiArrowUnsorted />
                                )
                              ) : (
                                <TiArrowUnsorted />
                              )
                            ) : (
                              ""
                            )}
                          </span>
                        </th>
                      )
                    )}
                  </tr>
                ))}
              </thead>

              <tbody className="table-body" {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr className="table-body-row" {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className="show-entries-wrapper">
            <ShowEntries
              pageSize={pagePerSize}
              setPageSize={setPagePerSize}
              totalRecords={totalRecords}
              totalEntries={totalEntries}
            />

            <TablePagination
              totalPages={totalPages}
              currentPage={onPage}
              onClick={handlePageChange}
            />
          </div>
        </>
      ) : (
        <p className="projectRepoData mt-4">{noData}</p>
      )}

      <ModalComponents
        show={showDeallocateSkillCoins}
        onHide={hideDeallocateSkillCoins}
        title="Edit Skill Coins Status"
        modalBody={
          <DeallocateSkillCoins
            apiCallCompleted={apiCallCompleted}
            skillCoinData={skillCoinData}
            hideDeallocateSkillCoins={hideDeallocateSkillCoins}
          />
        }
        className="modal-md"
      />
      <div id="pdf-content" style={{ display: "none" }}>
        <div className="p-4">
          <img src={Header} alt="no_header_img" className="footer-img" />
        </div>
        <div className="p-4">
          <h5>
            <b>{refNo}</b>
            {pData.referenceNo}
          </h5>
          <p className="mt-3">To,</p>
          <p className="m-0">
            <b>
              {pData.candidateName +
                " " +
                pData.middleName +
                " " +
                pData.lastname}
            </b>
          </p>
          <h5 className="pt-3">
            {empId}
            {pData.employeeId}
          </h5>
          <p className="paragraph-section">{desc}</p>
        </div>
        <div className="table table-responsive">
          <table className="table-main table table-sm mt-3">
            <thead className="table-head">
              <tr>
                <th className="table-head-row">{assignDate}</th>
                <th className="table-head-row">{noSkill}</th>
                <th className="table-head-row">{bookValue}</th>
                <th className="table-head-row">{dateOfVesting}</th>
              </tr>
            </thead>
            <tbody className="table-body">
              <tr>
                <td className="table-body-row">
                  {timeDateFormat(pData.assigned_date)}
                </td>
                <td className="table-body-row">{pData.skillcoins}</td>
                <td className="table-body-row">{pData.skValue}</td>
                <td className="table-body-row">
                  {timeDateFormat(pData.maturity_date)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="p-4">
          <p className="font-main-content">{aggreDesc}</p>
          <p className="font-main-content">{forwarDesc}</p>
          <p className="wishes-title">{bestWish}</p>
          <h4>{skillName}</h4>
          <img src={Signature} alt="no_img" />
          <h5>{signature_name}</h5>
          <h5>{role}</h5>
        </div>
        <div className="p-4">
          <img src={Footer} alt="no_footer_img" className="footer-img" />
        </div>
      </div>
    </>
  );
};

export default EmployeeSkillCoinTable;
