import * as service from "../Services/index";
import * as actionTypes from "../ActionTypes/index";
import { showErrorToastMessage } from "../../component/Toasts/Toasts";
import { BASE_URL, baseUrl } from "../../config/constants";

export const getEmployeeDataAction: any = (validation: any) => {
  return async (dispatch: any) => {
    const res = await service.getEmployeeDataApi();
    if (res) {
      dispatch(ACTION_CREATOR(actionTypes.GET_EMPLOYEE_DATA, res));
    }
  };
};

export const getEmployeeDataPaginationAction: any = (
  size: any,
  page: any,
  searchKey: any,
  searchValue: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.getEmployeeDataPaginationApi(
      size,
      page,
      searchKey,
      searchValue
    );
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_EMPLOYEE_DATA_PAGINATION, res));
    }
  };
};

export const addEmployeeDataAction: any = (
  employeeDetails: any,
  addres: any
) => {
  return async (dispatch: any) => {
    const res = await service.addEmployeeDataApi(employeeDetails);
    if (res) {
      addres(res);
      dispatch(ACTION_CREATOR(actionTypes.ADD_EMPLOYEE_DATA, res));
    }
  };
};

export const editEmployeeDataAction: any = (
  employeeDetails: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.editEmployeeDataApi(employeeDetails);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.EDIT_EMPLOYEE_DATA, res));
    }
  };
};

export const editEmployeePictureAction: any = (
  employeeDetails: any,
  addres: any
) => {
  return async (dispatch: any) => {
    const res = await service.editEmployeePictureApi(employeeDetails);
    if (res) {
      addres(res);
      dispatch(ACTION_CREATOR(actionTypes.EDIT_EMPLOYEE_PICTURE, res));
    }
  };
};

export const deleteEmployeeDataAction: any = (id: any, validation: any) => {
  return async (dispatch: any) => {
    const res = await service.deleteEmployeeDataApi(id);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.DELETE_EMPLOYEE_DATA, res));
    }
  };
};

export const getEmployeeSkillsAction: any = () => {
  return async (dispatch: any) => {
    const res = await service.getEmployeeSkillsApi();
    if (res) {
      dispatch(ACTION_CREATOR(actionTypes.GET_EMPLOYEE_SKILLS, res));
    }
  };
};

export const addEmployeeSkillsAction: any = (skills: any) => {
  return async (dispatch: any) => {
    const res = await service.addEmployeeSkillsApi(skills);
    if (res) {
      dispatch(ACTION_CREATOR(actionTypes.ADD_EMPLOYEE_SKILLS, res));
    }
  };
};

export const editEmployeeSkillsAction: any = (id: any, skillsData: any) => {
  return async (dispatch: any) => {
    const res = await service.editEmployeeSkillsApi(id, skillsData);
    if (res) {
      dispatch(ACTION_CREATOR(actionTypes.EDIT_EMPLOYEE_SKILLS, res));
    }
  };
};

export const deleteEmployeeSkillsAction: any = (id: any) => {
  return async (dispatch: any) => {
    const res = await service.deleteEmployeeSkillsApi(id);
    if (res) {
      dispatch(ACTION_CREATOR(actionTypes.DELETE_EMPLOYEE_SKILLS, res));
    }
  };
};

export const getEmployeeExperienceAction: any = () => {
  return async (dispatch: any) => {
    const res = await service.getExperienceDataApi();
    if (res) {
      dispatch(ACTION_CREATOR(actionTypes.GET_EXPERIENCE_DATA, res));
    }
  };
};

export const addExperienceDataAction: any = (
  experienceDetails: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.addExperienceDataApi(experienceDetails);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.ADD_EXPERIENCE_DATA, res));
    }
  };
};

export const editExperienceDataAction: any = (
  employeeDetails: any,
  id: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.editExperienceDataApi(employeeDetails, id);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.EDIT_EXPERIENCE_DATA, res));
    }
  };
};

export const deleteExperienceDataAction: any = (id: any, validation: any) => {
  return async (dispatch: any) => {
    const res = await service.deleteExperienceDataApi(id);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.DELETE_EXPERIENCE_DATA, res));
    }
  };
};

export const getEmployeeQualificationAction: any = () => {
  return async (dispatch: any) => {
    const res = await service.getEmployeeQualificationApi();

    if (res) {
      dispatch(ACTION_CREATOR(actionTypes.GET_EMPLOYEE_QUALIFICATION, res));
    }
  };
};

export const addEmployeeQualificationAction: any = (qualifications: any) => {
  return async (dispatch: any) => {
    const res = await service.addEmployeeQualificationApi(qualifications);
    if (res) {
      dispatch(ACTION_CREATOR(actionTypes.ADD_EMPLOYEE_QUALIFICATION, res));
    }
  };
};

export const editEmployeeQualificationAction: any = (
  qualificationData: any,
  id: any
) => {
  return async (dispatch: any) => {
    const res = await service.editEmployeeQualificationApi(
      qualificationData,
      id
    );
    if (res) {
      dispatch(ACTION_CREATOR(actionTypes.EDIT_EMPLOYEE_QUALIFICATION, res));
    }
  };
};

export const deleteEmployeeQualificationAction: any = (id: any) => {
  return async (dispatch: any) => {
    const res = await service.deleteEmployeeQualificationApi(id);
    if (res) {
      dispatch(ACTION_CREATOR(actionTypes.DELETE_EMPLOYEE_QUALIFICATION, res));
    }
  };
};

export const getEmployeeCertificationsAction: any = () => {
  return async (dispatch: any) => {
    const res = await service.getEmployeeCertificationsApi();
    if (res) {
      dispatch(ACTION_CREATOR(actionTypes.GET_EMPLOYEE_CERTIFICATIONS, res));
    }
  };
};

export const addEmployeeCertificationsAction: any = (certifications: any) => {
  return async (dispatch: any) => {
    const res = await service.addEmployeeCertificationsApi(certifications);
    if (res) {
      dispatch(ACTION_CREATOR(actionTypes.ADD_EMPLOYEE_CERTIFICATIONS, res));
    }
  };
};

export const editEmployeeCertificationsAction: any = (
  certificationsData: any,
  id: any
) => {
  return async (dispatch: any) => {
    const res = await service.editEmployeeCertificationsApi(
      certificationsData,
      id
    );
    if (res) {
      dispatch(ACTION_CREATOR(actionTypes.EDIT_EMPLOYEE_CERTIFICATIONS, res));
    }
  };
};

export const deleteEmployeeCertificationsAction: any = (id: any) => {
  return async (dispatch: any) => {
    const res = await service.deleteEmployeeCertificationsApi(id);
    if (res) {
      dispatch(ACTION_CREATOR(actionTypes.DELETE_EMPLOYEE_CERTIFICATIONS, res));
    }
  };
};

export const getEmployeeWorkAchievementsAction: any = () => {
  return async (dispatch: any) => {
    const res = await service.getEmployeeWorkAchievementsApi();

    if (res) {
      dispatch(ACTION_CREATOR(actionTypes.GET_EMPLOYEE_WORKACHIEVEMENTS, res));
    }
  };
};

export const addEmployeeWorkAchievementsAction: any = (
  workAchievements: any
) => {
  return async (dispatch: any) => {
    const res = await service.addEmployeeWorkAchievementsApi(workAchievements);
    if (res) {
      dispatch(ACTION_CREATOR(actionTypes.ADD_EMPLOYEE_WORKACHIEVEMENTS, res));
    }
  };
};

export const editEmployeeWorkAchievementsAction: any = (
  workAchievementData: any,
  id: any
) => {
  return async (dispatch: any) => {
    const res = await service.editEmployeeWorkAchievementsApi(
      workAchievementData,
      id
    );
    if (res) {
      dispatch(ACTION_CREATOR(actionTypes.EDIT_EMPLOYEE_WORKACHIEVEMENTS, res));
    }
  };
};

export const deleteEmployeeWorkAchievementsAction: any = (id: any) => {
  return async (dispatch: any) => {
    const res = await service.deleteEmployeeWorkAchievementsApi(id);
    if (res) {
      dispatch(
        ACTION_CREATOR(actionTypes.DELETE_EMPLOYEE_WORKACHIEVEMENTS, res)
      );
    }
  };
};

export const getemployeeDetailsAction: any = (
  selectedId: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.getemployeeDetailsApi(selectedId);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.EMPLOYEE_DETAILS, res));
    }
  };
};

export const getEmployeePreferenceAction: any = () => {
  return async (dispatch: any) => {
    const res = await service.getEmployeePreferenceApi();

    if (res) {
      dispatch(ACTION_CREATOR(actionTypes.GET_EMPLOYEE_PREFERENCE, res));
    }
  };
};

export const addEmployeePreferenceAction: any = (preferenceDetails: any) => {
  return async (dispatch: any) => {
    const res = await service.addEmployeePreferenceApi(preferenceDetails);
    if (res) {
      dispatch(ACTION_CREATOR(actionTypes.ADD_EMPLOYEE_PREFERENCE, res));
    }
  };
};

export const editEmployeePreferenceAction: any = (
  preferenceDetails: any,
  id: any
) => {
  return async (dispatch: any) => {
    const res = await service.editEmployeePreferenceApi(preferenceDetails, id);
    if (res) {
      dispatch(ACTION_CREATOR(actionTypes.EDIT_EMPLOYEE_PREFERENCE, res));
    }
  };
};

export const deleteEmployeePreferenceAction: any = (id: any) => {
  return async (dispatch: any) => {
    const res = await service.deleteEmployeePreferenceApi(id);
    if (res) {
      dispatch(ACTION_CREATOR(actionTypes.DELETE_EMPLOYEE_PREFERENCE, res));
    }
  };
};

export const getEmployeeCareerGoalsAction: any = () => {
  return async (dispatch: any) => {
    const res = await service.getEmployeeCareerGoalsApi();
    if (res) {
      dispatch(ACTION_CREATOR(actionTypes.GET_EMPLOYEE_CAREERGOALS, res));
    }
  };
};

export const addEmployeeCareerGoalsAction: any = (careerGoals: any) => {
  return async (dispatch: any) => {
    const res = await service.addEmployeeCareerGoalsApi(careerGoals);
    if (res) {
      dispatch(ACTION_CREATOR(actionTypes.ADD_EMPLOYEE_CAREERGOALS, res));
    }
  };
};

export const editEmployeeCareerGoalsAction: any = (
  id: any,
  careerGoals: any
) => {
  return async (dispatch: any) => {
    const res = await service.editEmployeeCareerGoalsApi(id, careerGoals);
    if (res) {
      dispatch(ACTION_CREATOR(actionTypes.EDIT_EMPLOYEE_CAREERGOALS, res));
    }
  };
};

export const deleteEmployeeCareerGoalsAction: any = (id: any) => {
  return async (dispatch: any) => {
    const res = await service.deleteEmployeeCareerGoalsApi(id);
    if (res) {
      dispatch(ACTION_CREATOR(actionTypes.DELETE_EMPLOYEE_CAREERGOALS, res));
    }
  };
};

export const updateSelectedIdAction: any = (selectedId: any) => {
  return async (dispatch: any) => {
    localStorage.setItem("selectedId", selectedId);
  };
};

export const qualificationsDocUploadAction: any = (
  docFile: any,
  docUrl: any
) => {
  return async (dispatch: any) => {
    const res = await service.qualificationsDocUploadApi(docFile);
    if (res) {
      docUrl(res);
      dispatch(ACTION_CREATOR(actionTypes.QUALIFICATION_DOC_UPLOAD, res));
    }
  };
};
export const workAchievementsDocUploadAction: any = (
  docFile: any,
  docUrl: any
) => {
  return async (dispatch: any) => {
    const res = await service.workAchievementsDocUploadApi(docFile);
    if (res) {
      docUrl(res);
      dispatch(ACTION_CREATOR(actionTypes.WORKACHIEVEMENTS_DOC_UPLOAD, res));
    }
  };
};
export const experienceDocUploadAction: any = (docFile: any, docUrl: any) => {
  return async (dispatch: any) => {
    const res = await service.experienceDocUploadApi(docFile);
    if (res) {
      docUrl(res);
      dispatch(ACTION_CREATOR(actionTypes.EXPERIENCE_DOC_UPLOAD, res));
    }
  };
};

export const employeeDetailsDocUploadAction: any = (
  docFile: any,
  docUrl: any
) => {
  return async (dispatch: any) => {
    const res = await service.employeeDetailsDocUploadApi(docFile);
    if (res) {
      docUrl(res);
      dispatch(ACTION_CREATOR(actionTypes.EMPLOYEE_DETAILS_DOC_UPLOAD, res));
    }
  };
};

export const employeeDetailsImageUploadAction: any = (
  docFile: any,
  docUrl: any
) => {
  return async (dispatch: any) => {
    const res = await service.employeeDetailsImageUploadApi(docFile);
    if (res) {
      docUrl(res);
      dispatch(ACTION_CREATOR(actionTypes.EMPLOYEE_DETAILS_IMAGE_UPLOAD, res));
    }
  };
};

export const getStatesAction: any = () => {
  return async (dispatch: any) => {
    const res = await service.getStatesApi();
    if (res) {
      dispatch(ACTION_CREATOR(actionTypes.GET_STATES_DETAILS, res));
    }
  };
};

export const userLoginAction: any = (token: any) => {
  return async (dispatch: any) => {
    if (token) {
      const parsedToken = JSON.parse(atob(token.split(".")[1]));
      const tokenExpiry = parsedToken.exp;
      const expirationTime = tokenExpiry * 1000 - 60000;

      const timer = expirationTime - Date.now();
      dispatch(runLogoutTimerAction(timer));
    }
  };
};

export const logOutAction: any = (payload: any) => {
  const token: any = localStorage.getItem("access_token");
  localStorage.clear();
  if (baseUrl?.includes("spotlight-dev-api.skill-mine.com")) {
    (window.location.href = "https://spotlight-dev.skill-mine.com/"), "_blank";
  } else if (baseUrl?.includes("spotlight-api.skill-mine.com")) {
    localStorage.clear();
    (window.location.href = "https://spotlight.skill-mine.com/"), "_blank";
  } else if (baseUrl?.includes("https://spotlight-stage-api.skill-mine.com")) {
    localStorage.clear();
    (window.location.href = "https://spotlight-stage.skill-mine.com/"),
      "_blank";
  } else {
    window.location.replace(
      `${BASE_URL}/token-srv/end_session?post_logout_redirect_uri=https://portal-sso.skill-mine.com&access_token_hint=${token}`
    );
  }
};

export const runLogoutTimerAction: any = (timer: any) => {
  return async (dispatch: any) => {
    setTimeout(() => {
      dispatch(logOutAction("logout"));
      showErrorToastMessage("Session Expired Login again");
    }, timer);
  };
};

export const getProjectRepositoryAction: any = (
  size: any,
  page: any,
  searchKey: any,
  searchValue: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.getProjectRepositoryApi(
      size,
      page,
      searchKey,
      searchValue
    );
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_PROJECT_REPOSITORY, res));
    }
  };
};

export const getResourceAllocationApprovedAction: any = (
  size: any,
  page: any,
  searchKey: any,
  searchValue: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.getResourceAllocationApprovedApi(
      size,
      page,
      searchKey,
      searchValue
    );
    if (res) {
      validation(res);
      dispatch(
        ACTION_CREATOR(actionTypes.GET_RESOURCE_ALLOCATION_APPROVED, res)
      );
    }
  };
};

export const getProjectRepositoryByIdAction: any = (
  projectId: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.getProjectRepositoryByIdApi(projectId);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_PROJECT_REPOSITORY_BYID, res));
    }
  };
};
export const addProjectRepositoryAction: any = (
  projectDetails: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.addProjectRepositoryApi(projectDetails);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.ADD_PROJECT_REPOSITORY, res));
      localStorage.setItem("projectId", res?.data?.id);
    }
  };
};
export const editProjectRepositoryAction: any = (
  id: any,
  projectDetails: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.editProjectRepositoryApi(id, projectDetails);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.EDIT_PROJECT_REPOSITORY, res));
    }
  };
};

export const deleteProjectRepositoryAction: any = (
  id: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.deleteProjectRepositoryApi(id);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.DELETE_PROJECT_REPOSITORY, res));
    }
  };
};

export const projectDetailsDocUploadAction: any = (
  docFile: any,
  docUrl: any
) => {
  return async (dispatch: any) => {
    const res = await service.projectDetailsDocUploadApi(docFile);
    if (res) {
      docUrl(res);
      dispatch(ACTION_CREATOR(actionTypes.PROJECT_DETAILS_DOC_UPLOAD, res));
    }
  };
};

export const getProjectRepositoryInvoiceAction: any = () => {
  return async (dispatch: any) => {
    const res = await service.getProjectRepositoryInvoiceApi();
    if (res) {
      dispatch(ACTION_CREATOR(actionTypes.GET_PROJECT_REPOSITORY_INVOICE, res));
    }
  };
};

export const addProjectRepositoryInvoiceAction: any = (
  invoiceDetails: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.addProjectRepositoryInvoiceApi(invoiceDetails);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.ADD_PROJECT_REPOSITORY_INVOICE, res));
      localStorage.setItem("invoiceId", res.data.id);
    }
  };
};
export const editProjectRepositoryInvoiceAction: any = (
  id: any,
  invoiceDetails: any
) => {
  return async (dispatch: any) => {
    const res = await service.editProjectRepositoryInvoiceApi(
      id,
      invoiceDetails
    );
    if (res) {
      dispatch(
        ACTION_CREATOR(actionTypes.EDIT_PROJECT_REPOSITORY_INVOICE, res)
      );
    }
  };
};

export const deleteProjectRepositoryInvoiceAction: any = (id: any) => {
  return async (dispatch: any) => {
    const res = await service.deleteProjectRepositoryInvoiceApi(id);
    if (res) {
      dispatch(
        ACTION_CREATOR(actionTypes.DELETE_PROJECT_REPOSITORY_INVOICE, res)
      );
    }
  };
};

export const projectInvoiceDetailsDocUploadAction: any = (
  docFile: any,
  docUrl: any
) => {
  return async (dispatch: any) => {
    const res = await service.projectInvoiceDetailsDocUploadApi(docFile);
    if (res) {
      docUrl(res);
      dispatch(
        ACTION_CREATOR(actionTypes.PROJECT_INVOICE_DETAILS_DOC_UPLOAD, res)
      );
    }
  };
};

export const getResourceAllocationAction: any = (
  projectId: any,
  page: any,
  size: any,
  searchKey: any,
  searchValue: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.getResourceAllocationApi(
      projectId,
      page,
      size,
      searchKey,
      searchValue
    );
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_RESOURCE_ALLOCATION, res));
    }
  };
};

export const getAllResourceAllocationAction: any = (
  statusObj: any,
  page: any,
  size: any,
  searchKey: any,
  searchValue: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.getAllResourceAllocationApi(
      statusObj,
      page,
      size,
      searchKey,
      searchValue
    );
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_ALL_RESOURCE_ALLOCATION, res));
    }
  };
};
export const addResourceAllocationAction: any = (
  resourceDetails: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.addResourceAllocationApi(resourceDetails);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.ADD_RESOURCE_ALLOCATION, res));
    }
  };
};
export const editResourceAllocationAction: any = (
  id: any,
  resourceDetails: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.editResourceAllocationApi(id, resourceDetails);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.EDIT_RESOURCE_ALLOCATION, res));
    }
  };
};

export const deleteResourceAllocationAction: any = (
  id: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.deleteResourceAllocationApi(id);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.DELETE_RESOURCE_ALLOCATION, res));
    }
  };
};

export const getProjectByClientAction: any = (
  clientName: any,
  projectData: any
) => {
  return async (dispatch: any) => {
    const res = await service.getProjectByClientApi(clientName);
    projectData(res);
    if (res) {
      dispatch(ACTION_CREATOR(actionTypes.GET_PROJECT_BY_CLIENT, res));
    }
  };
};

export const getProjectByEmployeeIdAction: any = (
  employeeId: any,
  page: any,
  size: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.getProjectDetailsByEmployeeIdApi(
      employeeId,
      page,
      size
    );
    if (res) {
      validation(res);
      dispatch(
        ACTION_CREATOR(actionTypes.GET_PROJECT_DETAILS_BY_EMPLOYEEID, res)
      );
    }
  };
};

export const getAllCompetencyAction: any = (validation: any) => {
  return async (dispatch: any) => {
    const res = await service.getAllCompetencyApi();
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_ALL_COMPETENCY, res));
    }
  };
};

export const getAllTechStackAction: any = () => {
  return async (dispatch: any) => {
    const res = await service.getAllTechStackApi();
    if (res) {
      dispatch(ACTION_CREATOR(actionTypes.GET_ALL_TECH_STACK, res));
    }
  };
};

export const deleteCompetencyCoEAction: any = (id: any, validation: any) => {
  return async (dispatch: any) => {
    const res = await service.deleteCompetencyCoEApi(id);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.DELETE_COMPETENCY_COE, res));
    }
  };
};

export const addTechStackAction: any = (
  techStackData: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.addTechStackApi(techStackData);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.ADD_TECH_STACK, res));
    }
  };
};

export const addBranchStackAction: any = (
  branchStackData: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.addBranchStackApi(branchStackData);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.ADD_BRANCH_STACK, res));
    }
  };
};

export const getTechStackByCompetencyAction: any = (id: any) => {
  return async (dispatch: any) => {
    const res = await service.getTechStackByCompetencyApi(id);
    if (res) {
      dispatch(ACTION_CREATOR(actionTypes.GET_TECH_STACK_BY_COMPETENCY, res));
    }
  };
};

export const deleteBranchStackAction: any = (id: any, validation: any) => {
  return async (dispatch: any) => {
    const res = await service.deleteBranchStackApi(id);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.DELETE_BRANCH_STACK, res));
    }
  };
};

export const getAllSubBranchAction: any = (id: any) => {
  return async (dispatch: any) => {
    const res = await service.getAllSubBranchApi(id);
    if (res) {
      dispatch(ACTION_CREATOR(actionTypes.GET_ALL_SUB_BRANCH, res));
    }
  };
};

export const addSubBranchAction: any = (
  subbranchdata: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.addSubBranchApi(subbranchdata);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.ADD_SUB_BRANCH, res));
    }
  };
};

export const addRequirementsAction: any = (requirementsdata: any, id: any) => {
  return async (dispatch: any) => {
    const res = await service.addRequirementsApi(requirementsdata, id);
    if (res) {
      dispatch(ACTION_CREATOR(actionTypes.ADD_REQUIREMENTS, res));
    }
  };
};

export const editRequirementsAction: any = (requirementsdata: any, id: any) => {
  return async (dispatch: any) => {
    const res = await service.editRequirementsApi(requirementsdata, id);
    if (res) {
      dispatch(ACTION_CREATOR(actionTypes.EDIT_REQUIREMENTS, res));
    }
  };
};

export const deleteSubBranchStackAction: any = (id: any, validation: any) => {
  return async (dispatch: any) => {
    const res = await service.deleteSubBranchStackApi(id);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.DELETE_SUB_BRANCH_STACK, res));
    }
  };
};

export const addRateMyselfAction: any = (rating: any, ratingData: any) => {
  return async (dispatch: any) => {
    const res = await service.addRateMyselfApi(rating);
    if (res) {
      ratingData(res);
      dispatch(ACTION_CREATOR(actionTypes.ADD_RATE_MYSELF, res));
    }
  };
};

export const getAllCompetencyRequestAction: any = (status: any) => {
  return async (dispatch: any) => {
    const res = await service.getAllCompetencyRequestApi(status);
    if (res) {
      dispatch(ACTION_CREATOR(actionTypes.GET_ALL_COMPETENCY_REQUESTS, res));
    }
  };
};

export const getAllsubDomainAction: any = (employee_id: any) => {
  return async (dispatch: any) => {
    const res = await service.getAllsubDomainApi(employee_id);
    if (res) {
      dispatch(ACTION_CREATOR(actionTypes.GET_ALL_SUBDOMAIN, res));
    }
  };
};

export const updateStatusRequestAction: any = (
  id: any,
  data: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.updateStatusRequestApi(id, data);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.UPDATE_STATUS_REQUEST, res));
    }
  };
};

export const getStatusCountAction: any = (validation: any) => {
  return async (dispatch: any) => {
    const res = await service.getStatusCountApi();
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_STATUS_COUNT, res));
    }
  };
};

export const addEnrollmentDataAction: any = (data: any, validation: any) => {
  return async (dispatch: any) => {
    const res = await service.addEnrollmentDataApi(data);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.ADD_ENROLLMENT_DATA, res));
    }
  };
};

export const getAllEnrollmentRequestAction: any = (
  employee_id: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.getAllEnrollmentRequestApi(employee_id);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_ALL_ENROLLMENT_REQUEST, res));
    }
  };
};

export const getAllCompetencyDevAction: any = (validation: any) => {
  return async (dispatch: any) => {
    const res = await service.getAllCompetencyDevApi();
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_ALL_COMPETENCY_DEV, res));
    }
  };
};
export const getProficiencyByIdAction: any = (id: any, data: any) => {
  return async (dispatch: any) => {
    const res = await service.getProficiencyByIdApi(id);
    if (res) {
      data(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_PROFICIENCY_BY_ID, res));
    }
  };
};

export const editCompetencyDevAction: any = (
  competencyData: any,
  proficiencyId: any,
  competency: any
) => {
  return async (dispatch: any) => {
    const res = await service.editCompetencyDevApi(
      competencyData,
      proficiencyId
    );
    if (res) {
      competency(res);
      dispatch(ACTION_CREATOR(actionTypes.EDIT_COMPETENCY_DEV, res));
    }
  };
};

export const competencyDevPdpPlanUploadAction: any = (
  docFile: any,
  docUrl: any,
  employeeId: any
) => {
  return async (dispatch: any) => {
    const res = await service.competencyDevPdpPlanUploadApi(
      docFile,
      employeeId
    );
    if (res) {
      docUrl(res);
      dispatch(
        ACTION_CREATOR(actionTypes.PROJECT_INVOICE_DETAILS_DOC_UPLOAD, res)
      );
    }
  };
};

export const getAllCountOfStatusAction: any = () => {
  return async (dispatch: any) => {
    const res = await service.getAllCountOfStatusApi();
    if (res) {
      dispatch(ACTION_CREATOR(actionTypes.GET_COUNT_STATUS, res));
    }
  };
};

export const getAllProficiencyByTechIdAction: any = (
  id: any,
  currentProficiency: any
) => {
  return async (dispatch: any) => {
    const res = await service.getAllProficiencyByTechIdApi(
      id,
      currentProficiency
    );
    if (res) {
      dispatch(ACTION_CREATOR(actionTypes.GET_ALL_PROFICIENCY_BY_TECH_ID, res));
    }
  };
};

export const getAllInternalJobsAction: any = (
  page: any,
  size: any,
  searchKey: any,
  searchValue: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.getAllInternalJobsApi(
      page,
      size,
      searchKey,
      searchValue
    );
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_ALL_INTERNAL_JOBS, res));
    }
  };
};
export const getAllInternalJobsEmployeeViewAction: any = (
  page: any,
  size: any,
  searchKey: any,
  searchValue: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.getAllInternalJobsEmployeeViewApi(
      page,
      size,
      searchKey,
      searchValue
    );
    if (res) {
      validation(res);
      dispatch(
        ACTION_CREATOR(actionTypes.GET_ALL_INTERNAL_JOBS_EMPLOYEE_VIEW, res)
      );
    }
  };
};
export const getAllJobsAction: any = (
  page: any,
  size: any,
  searchKey: any,
  searchValue: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.getAllJobsApi(page, size, searchKey, searchValue);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_ALL_JOBS, res));
    }
  };
};
export const addPostAJobAction: any = (job: any, jobData: any) => {
  return async (dispatch: any) => {
    const res = await service.addPostAJobApi(job);
    if (res) {
      jobData(res);
      dispatch(ACTION_CREATOR(actionTypes.ADD_POST_A_JOB, res));
    }
  };
};

export const jobDescriptionUploadAction: any = (docFile: any, docUrl: any) => {
  return async (dispatch: any) => {
    const res = await service.jobDescriptionDocUploadApi(docFile);
    if (res) {
      docUrl(res);
      dispatch(ACTION_CREATOR(actionTypes.JOB_DESCRIPTION_UPLOAD, res));
    }
  };
};

export const BulkUploadAction: any = (docFile: any, docUrl: any) => {
  return async (dispatch: any) => {
    const res = await service.BulkUploadApi(docFile);
    if (res) {
      docUrl(res);
      dispatch(ACTION_CREATOR(actionTypes.BULK_UPLOAD, res));
    }
  };
};

export const ProjectBulkUploadAction: any = (docFile: any, docUrl: any) => {
  return async (dispatch: any) => {
    const res = await service.ProjectBulkUploadApi(docFile);
    if (res) {
      docUrl(res);
      dispatch(ACTION_CREATOR(actionTypes.PROJECT_BULK_UPLOAD, res));
    }
  };
};

export const ResourceBulkUploadAction: any = (docFile: any, docUrl: any) => {
  return async (dispatch: any) => {
    const res = await service.ResourceBulkUploadApi(docFile);
    if (res) {
      docUrl(res);
      dispatch(ACTION_CREATOR(actionTypes.RESOURCE_BULK_UPLOAD, res));
    }
  };
};

export const getJobByJobIdAction: any = (id: any, validation: any) => {
  return async (dispatch: any) => {
    const res = await service.getJobByJobIdApi(id);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_JOB_BY_JOB_ID, res));
    }
  };
};
export const addReferAResourceAction: any = (
  resource: any,
  resourceData: any
) => {
  return async (dispatch: any) => {
    const res = await service.addReferAResourceApi(resource);
    if (res) {
      resourceData(res);
      dispatch(ACTION_CREATOR(actionTypes.ADD_REFER_A_RESOURCE, res));
    }
  };
};
export const referResourceResumeUploadAction: any = (
  docFile: any,
  docUrl: any
) => {
  return async (dispatch: any) => {
    const res = await service.referResourceResumeDocUploadApi(docFile);
    if (res) {
      docUrl(res);
      dispatch(ACTION_CREATOR(actionTypes.REFER_RESOURCE_RESUME_UPLOAD, res));
    }
  };
};
export const getReferralListByIdAction: any = (
  jobId: any,
  page: any,
  size: any,
  searchKey: any,
  searchValue: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.getReferralListByIdApi(
      jobId,
      page,
      size,
      searchKey,
      searchValue
    );
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_ALL_REFERRAL_LIST, res));
    }
  };
};
export const editSkilLinksReferResourceAction: any = (
  competencyData: any,
  id: any,
  competency: any
) => {
  return async (dispatch: any) => {
    const res = await service.editSkillLinksReferResourceApi(
      competencyData,
      id
    );
    if (res) {
      competency(res);
      dispatch(
        ACTION_CREATOR(actionTypes.EDIT_SKILL_LINKS_REFER_RESOURCE, res)
      );
    }
  };
};
export const editSkilLinksJobStatusAction: any = (
  jobId: any,
  status: any,
  jobData: any
) => {
  return async (dispatch: any) => {
    const res = await service.editSkillLinksJobStatusApi(status, jobId);
    if (res) {
      jobData(res);
      dispatch(ACTION_CREATOR(actionTypes.EDIT_SKILL_LINKS_JOB_STATUS, res));
    }
  };
};

export const editIJPJobStatusAction: any = (
  jobId: any,
  status: any,
  jobData: any
) => {
  return async (dispatch: any) => {
    const res = await service.editIJPJobStatusApi(status, jobId);
    if (res) {
      jobData(res);
      dispatch(ACTION_CREATOR(actionTypes.EDIT_IJP_JOB_STATUS, res));
    }
  };
};
export const getInternalJobByIdAction: any = (id: any, validation: any) => {
  return async (dispatch: any) => {
    const res = await service.getInternalJobByIdApi(id);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_INTERNAL_JOB_BY_ID, res));
    }
  };
};

export const addInseedDataAction: any = (data: any, validation: any) => {
  return async (dispatch: any) => {
    const res = await service.addInseedDataApi(data);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.ADD_INSEED_DATA, res));
    }
  };
};

export const getInseedDataAction: any = (
  page: any,
  size: any,
  searchKey: any,
  searchValue: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.getInseedDataApi(
      page,
      size,
      searchKey,
      searchValue
    );
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_INSEED_DATA, res));
    }
  };
};

export const getApprovedInseedDataAction: any = (
  page: any,
  size: any,
  searchKey: any,
  searchValue: any,
  batchStatus: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.getApprovedInseedDataApi(
      page,
      size,
      searchKey,
      searchValue,
      batchStatus
    );
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_APPROVED_INSEED_DATA, res));
    }
  };
};

export const getInseedDataPagAction: any = (
  batch_id: any,
  values: any,
  page: any,
  size: any,
  searchKey: any,
  searchValue: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.getInseedResourcePagApi(
      batch_id,
      page,
      size,
      searchKey,
      searchValue
    );
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_INSEED_DATA, res));
    }
  };
};

export const editInseedDataAction: any = (
  id: any,
  data: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.editInseedDataApi(id, data);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.EDIT_INSEED_DATA, res));
    }
  };
};

export const deleteInseedDataAction: any = (id: any, validation: any) => {
  return async (dispatch: any) => {
    const res = await service.deleteInseedDataApi(id);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.DELETE_INSEED_DATA, res));
    }
  };
};
export const getInseedStatusCountAction: any = (validation: any) => {
  return async (dispatch: any) => {
    const res = await service.getInseedStatusCountApi();
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_INSEED_STATUS_COUNT, res));
    }
  };
};
export const getAllInseedReportAction: any = (status: any) => {
  return async (dispatch: any) => {
    const res = await service.getAllInseedReportApi(status);
    if (res) {
      dispatch(ACTION_CREATOR(actionTypes.GET_ALL_INSEED_REPORT, res));
    }
  };
};

export const getJobCategoryAction: any = () => {
  return async (dispatch: any) => {
    const res = await service.getJobCategoryApi();
    if (res) {
      dispatch(ACTION_CREATOR(actionTypes.GET_JOB_CATEGORY, res));
    }
  };
};
export const addJobCategoryAction: any = (category: any) => {
  return async (dispatch: any) => {
    const res = await service.addJobCategoryApi(category);
    if (res) {
      dispatch(ACTION_CREATOR(actionTypes.ADD_JOB_CATEGORY, res));
    }
  };
};

export const getAllSkillCoinsAction: any = (validation: any) => {
  return async (dispatch: any) => {
    const res = await service.getAllSkillCoinsApi();
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_ALL_SKILL_COINS, res));
    }
  };
};
export const assignSkillCoinsAction: any = (
  skillcoins: any,
  skillcoinsData: any
) => {
  return async (dispatch: any) => {
    const res = await service.assignSkillCoinsApi(skillcoins);
    if (res) {
      skillcoinsData(res);
      dispatch(ACTION_CREATOR(actionTypes.ASSIGN_SKILL_COINS, res));
    }
  };
};
export const assignmentLetterUploadAction: any = (
  docFile: any,
  docUrl: any,
  id: any
) => {
  return async (dispatch: any) => {
    const res = await service.assignmentLetterUploadDocApi(id, docFile);
    if (res) {
      docUrl(res);
      dispatch(ACTION_CREATOR(actionTypes.ASSIGNMENT_LETTER_UPLOAD_DOC, res));
    }
  };
};
export const deallocateSkillCoinsAction: any = (
  id: any,
  reason: any,
  reasonData: any
) => {
  return async (dispatch: any) => {
    const res = await service.deallocateSkillCoinsApi(reason, id);
    if (res) {
      reasonData(res);
      dispatch(ACTION_CREATOR(actionTypes.DEALLOCATE_SKILL_COINS, res));
    }
  };
};

export const addInseedResourceAction: any = (data: any, validation: any) => {
  return async (dispatch: any) => {
    const res = await service.addInseedResourceApi(data);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.ADD_INSEED_RESOURCE, res));
    }
  };
};

export const getInseedResourceAction: any = (batch_id: any, values: any) => {
  return async (dispatch: any) => {
    const res = await service.getInseedResourceApi(batch_id, values);
    if (res) {
      dispatch(ACTION_CREATOR(actionTypes.GET_INSEED_RESOURCE, res));
    }
  };
};

export const editInseedResourceAction: any = (
  id: any,
  data: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.editInseedResourceApi(id, data);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.EDIT_INSEED_RESOURCE_DATA, res));
    }
  };
};

export const deleteInseedResourceAction: any = (id: any, validation: any) => {
  return async (dispatch: any) => {
    const res = await service.deleteInseedResourceApi(id);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.DELETE_INSEED_RESOURCE, res));
    }
  };
};

export const applyInternalJobsAction: any = (
  resource: any,
  resourceData: any
) => {
  return async (dispatch: any) => {
    const res = await service.applyInternalJobsApi(resource);
    if (res) {
      resourceData(res);
      dispatch(ACTION_CREATOR(actionTypes.APPLY_INTERNAL_JOBS, res));
    }
  };
};

export const getAllInternallyAppliedJobAction: any = (
  page: any,
  size: any,
  searchKey: any,
  searchValue: any,
  jobId: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.getAllInternallyAppliedJobApi(
      page,
      size,
      searchKey,
      searchValue,
      jobId
    );
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_INTERNALLY_APPLIED_JOB, res));
    }
  };
};

export const editApplicationStatusAction: any = (
  competencyData: any,
  id: any,
  competency: any
) => {
  return async (dispatch: any) => {
    const res = await service.editApplicationStatusApi(competencyData, id);
    if (res) {
      competency(res);
      dispatch(ACTION_CREATOR(actionTypes.EDIT_APPLICATION_STATUS, res));
    }
  };
};

export const getAllCoachAction: any = (validation: any) => {
  return async (dispatch: any) => {
    const res = await service.getAllCoachApi();
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_ALL_COACH, res));
    }
  };
};

export const getAllCoachPagAction: any = (
  page: any,
  size: any,
  searchkey: any,
  searchValue: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.getAllCoachPagApi(
      page,
      size,
      searchkey,
      searchValue
    );
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_ALL_COACH, res));
    }
  };
};

export const addBeACoachAction: any = (data: any, validation: any) => {
  return async (dispatch: any) => {
    const res = await service.addBeACoachApi(data);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.ADD_BE_A_COACH, res));
    }
  };
};

export const inseedDocUploadAction: any = (docFile: any, docUrl: any) => {
  return async (dispatch: any) => {
    const res = await service.inseedDocUploadApi(docFile);
    if (res) {
      docUrl(res);
      dispatch(ACTION_CREATOR(actionTypes.INSEED_DOC_UPLOAD, res));
    }
  };
};

export const getAllCoachListAction: any = (
  page: any,
  size: any,
  searchKey: any,
  searchValue: any,
  id: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.getAllCoachListApi(
      page,
      size,
      searchKey,
      searchValue,
      id
    );
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_ALL_COACH_LIST, res));
    }
  };
};

export const addCoachRequestAction: any = (data: any, validation: any) => {
  return async (dispatch: any) => {
    const res = await service.addCoachRequestApi(data);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.ADD_COACH_REQUEST, res));
    }
  };
};

export const getAllCoachRequestAction: any = (
  page: any,
  size: any,
  searchKey: any,
  searchValue: any,
  id: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.getAllCoachRequestApi(
      page,
      size,
      searchKey,
      searchValue,
      id
    );
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_ALL_COACH_REQUEST, res));
    }
  };
};

export const updateStatusRemarksAction: any = (
  id: any,
  data: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.updateStatusRemarksApi(id, data);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.UPDATE_STATUS_REMARKS, res));
    }
  };
};

export const getAllCoachReportCountAction: any = () => {
  return async (dispatch: any) => {
    const res = await service.getAllCoachReportCountApi();
    if (res) {
      dispatch(ACTION_CREATOR(actionTypes.GET_ALL_COACH_REPORT_COUNT, res));
    }
  };
};

export const getAllCoachReportAction: any = (validation: any) => {
  return async (dispatch: any) => {
    const res = await service.getAllCoachReportApi();
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_ALL_COACH_REPORT, res));
    }
  };
};

export const getAllCoachReportPagAction: any = (
  page: any,
  pageSize: any,
  searchKey: any,
  searchValue: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.getAllCoachReportPagApi(
      page,
      pageSize,
      searchKey,
      searchValue
    );
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_ALL_COACH_REPORT, res));
    }
  };
};

export const getFetchSkillCoinsAction: any = (id: any, validation: any) => {
  return async (dispatch: any) => {
    const res = await service.getFetchSkillCoinsApi(id);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_FETCH_SKILL_COINS, res));
    }
  };
};

export const editRedeemSkillCoinsAction: any = (
  skillCoinsId: any,
  redeemedData: any,
  redeem: any
) => {
  return async (dispatch: any) => {
    const res = await service.editRedeemSkillCoinsApi(
      skillCoinsId,
      redeemedData
    );
    if (res) {
      redeem(res);
      dispatch(ACTION_CREATOR(actionTypes.EDIT_REDEEM_SKILL_COINS, res));
    }
  };
};

export const getSkillCoinsReportAction: any = (validation: any) => {
  return async (dispatch: any) => {
    const res = await service.getSkillCoinsReportApi();
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_SKILL_COINS_REPORT, res));
    }
  };
};

export const getAllAcceptedCoachListAction: any = (
  page: any,
  size: any,
  searchKey: any,
  searchValue: any,
  id: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.getAllAcceptedCoachListApi(
      page,
      size,
      searchKey,
      searchValue,
      id
    );
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_ALL_ACCEPTED_COACH_LIST, res));
    }
  };
};

export const getAllAcceptedEmployeeListAction: any = (
  page: any,
  size: any,
  searchKey: any,
  searchValue: any,
  id: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.getAllAcceptedEmployeeListApi(
      page,
      size,
      searchKey,
      searchValue,
      id
    );
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_ALL_ACCEPTED_EMPLOYEE_LIST, res));
    }
  };
};

export const getAllSessionByEmpIdAction: any = (
  page: any,
  size: any,
  searchValue: any,
  employeeId: any,
  coachId: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.getAllSessionByEmpIdApi(
      page,
      size,
      searchValue,
      employeeId,
      coachId
    );
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_ALL_SESSION_BY_EMPID, res));
    }
  };
};

export const getTrainingsByIdAction: any = (id: any) => {
  return async (dispatch: any) => {
    const res = await service.getTrainingsByIdApi(id);
    if (res) {
      dispatch(ACTION_CREATOR(actionTypes.GET_TRAININGS_BY_ID, res));
    }
  };
};
export const createTrainingAction: any = (trainingData: any, training: any) => {
  return async (dispatch: any) => {
    const res = await service.createTrainingApi(trainingData);
    if (res) {
      training(res);
      dispatch(ACTION_CREATOR(actionTypes.CREATE_TRAINING, res));
    }
  };
};
export const trainingPlanUploadAction: any = (docFile: any, docUrl: any) => {
  return async (dispatch: any) => {
    const res = await service.trainingPlanUploadDocApi(docFile);
    if (res) {
      docUrl(res);
      dispatch(ACTION_CREATOR(actionTypes.TRAINING_PLAN_UPLOAD_DOC, res));
    }
  };
};

export const editTrainingPlanAction: any = (
  trainingId: any,
  trainingData: any,
  training: any
) => {
  return async (dispatch: any) => {
    const res = await service.editTrainingPlanApi(trainingId, trainingData);
    if (res) {
      training(res);
      dispatch(ACTION_CREATOR(actionTypes.EDIT_TRAINING_PLAN, res));
    }
  };
};

export const deleteTrainingModuleAction: any = (
  trainingId: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.deleteTrainingModuleApi(trainingId);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.DELETE_TRAINING_MODULE, res));
    }
  };
};

export const getEvaluationDataAction: any = (
  batch_id: any,
  page: any,
  size: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.getEvaluationDataApi(batch_id, page, size);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_EVALUATION_DETAILS, res));
    }
  };
};

export const editEvaluationDataAction: any = (
  id: any,
  data: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.editEvaluationDataApi(id, data);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.EDIT_EVALUATION_DATA, res));
    }
  };
};

export const coachEmployeeDocUploadAction: any = (
  docFile: any,
  docUrl: any
) => {
  return async (dispatch: any) => {
    const res = await service.coachEmployeeDocUploadApi(docFile);
    if (res) {
      docUrl(res);
      dispatch(ACTION_CREATOR(actionTypes.COACH_EMPLOYEE_DOC_UPLOAD, res));
    }
  };
};

export const addNewSessionAction: any = (sessionData: any, validation: any) => {
  return async (dispatch: any) => {
    const res = await service.addNewSessionApi(sessionData);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.ADD_NEW_SESSION, res));
    }
  };
};

export const ratingForSessionAction: any = (
  id: any,
  ratingData: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.ratingForSessionApi(id, ratingData);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.RATING_FOR_SESSION, res));
    }
  };
};

export const coachUpdateHwStatusAction: any = (
  id: any,
  statusData: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.coachUpdateHwStatusApi(id, statusData);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.COACH_UPDATE_HW_STATUS, res));
    }
  };
};

export const uploadEmpHwAction: any = (
  id: any,
  hwData: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.uploadEmpHwApi(id, hwData);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.UPLOAD_EMP_HW, res));
    }
  };
};

export const getDownloadPolicyAction: any = (policyName: any) => {
  return async (dispatch: any) => {
    const res = await service.getDownloadPolicyApi(policyName);
    if (res) {
      dispatch(ACTION_CREATOR(actionTypes.GET_DOWNLOAD_POLICY, res));
    }
  };
};

export const addClientNameAction: any = (client: any, validation: any) => {
  return async (dispatch: any) => {
    const res = await service.addClientNameApi(client);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.ADD_CLIENT_NAME, res));
    }
  };
};

export const getClientNameAction: any = () => {
  return async (dispatch: any) => {
    const res = await service.getClientNameApi();
    if (res) {
      dispatch(ACTION_CREATOR(actionTypes.GET_CLIENT_NAME, res));
    }
  };
};

export const fetchSearchDataAction: any = (query: any, result: any) => {
  return async (dispatch: any) => {
    const res = await service.fetchSearchDataApi(query);
    if (res) {
      result(res);
      dispatch(ACTION_CREATOR(actionTypes.FETCH_SEARCH_DATA, res));
    }
  };
};

export const getCoachingReportPdfAction: any = (
  coachId: any,
  page: any,
  size: any,
  searchKey: any,
  searchValue: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.getCoachingReportPdfApi(
      coachId,
      page,
      size,
      searchKey,
      searchValue
    );
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_COACHING_REPORT_PDF, res));
    }
  };
};

export const getAllNotificationAction: any = () => {
  return async (dispatch: any) => {
    const res = await service.getAllNotificationfApi();
    if (res) {
      dispatch(ACTION_CREATOR(actionTypes.GET_ALL_NOTIFICATION, res));
    }
  };
};

export const editNotificationDataAction: any = (id: any, data: any) => {
  return async (dispatch: any) => {
    const res = await service.editNotificationApi(id, data);
    if (res) {
      dispatch(ACTION_CREATOR(actionTypes.EDIT_NOTIFICATION_DETAILS, res));
    }
  };
};

export const getInseedAllModulesAction: any = (
  size: any,
  page: any,
  searchKey: any,
  searchValue: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.getInseedAllModulesApi(
      size,
      page,
      searchKey,
      searchValue
    );
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_ALL_INSEED_MODULES, res));
    }
  };
};

export const getInseedModuleByIdAction: any = (validation: any, id: any) => {
  return async (dispatch: any) => {
    const res = await service.getModulesByIdApi(id);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_INSEED_MODULES_BY_ID, res));
    }
  };
};

export const addInseedModuleAction: any = (data: any, validation: any) => {
  return async (dispatch: any) => {
    const res = await service.addInseedModuleApi(data);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.ADD_INSEED_MODULE, res));
    }
  };
};

export const editInseedModuleAction: any = (
  id: any,
  data: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.editInseedModuleApi(id, data);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.EDIT_INSEED_MODULE, res));
    }
  };
};

export const deleteInseedModuleAction: any = (id: any, validation: any) => {
  return async (dispatch: any) => {
    const res = await service.deleteInseedModuleApi(id);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.DELETE_INSEED_MODULE, res));
    }
  };
};

export const getInseedAssessmentByIdAction: any = (
  size: any,
  page: any,
  searchKey: any,
  searchValue: any,
  id: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.getAssessmentByIdApi(
      size,
      page,
      searchKey,
      searchValue,
      id
    );
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_INSEED_ASSESSMENT_BY_ID, res));
    }
  };
};

export const addInseedAssessmentAction: any = (data: any, validation: any) => {
  return async (dispatch: any) => {
    const res = await service.addAssessmentApi(data);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.ADD_INSEED_ASSESSMENT, res));
    }
  };
};

export const editInseedAssessmentAction: any = (
  id: any,
  data: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.editAssessmentApi(id, data);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.EDIT_INSEED_ASSESSMENT, res));
    }
  };
};

export const deleteInseedAssessmentAction: any = (id: any, validation: any) => {
  return async (dispatch: any) => {
    const res = await service.deleteAssessmentApi(id);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.DELETE_INSEED_ASSESSMENT, res));
    }
  };
};

export const getAllTrainingsAction: any = (
  page: any,
  size: any,
  searchKey: any,
  searchValue: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.getAllTrainingsApi(
      page,
      size,
      searchKey,
      searchValue
    );
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_ALL_TRAININGS, res));
    }
  };
};
export const getAllModulesByTrainingIdAction: any = (
  validation: any,
  id: any
) => {
  return async (dispatch: any) => {
    const res = await service.getAllModulesByTrainingIdDataApi(id);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_ALL_MODULES_BY_TRAINING_ID, res));
    }
  };
};

export const EmployeeBulkUploadAction: any = (docFile: any, docUrl: any) => {
  return async (dispatch: any) => {
    const res = await service.EmployeeBulkUploadApi(docFile);
    if (res) {
      docUrl(res);
      dispatch(ACTION_CREATOR(actionTypes.EMPLOYEE_BULK_UPLOAD, res));
    }
  };
};

export const postEmailNotificationAction: any = (
  employeeRef: any,
  details: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.postEmailNotificationApi(details, employeeRef);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.POST_EMAIL_NOTIFICATION, res));
    }
  };
};
export const getReportLessthan3DaysAction: any = (validation: any) => {
  return async (dispatch: any) => {
    const res = await service.getReportLessthan3DaysApi();
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_REPORT_LESSTHAN_3_DAYS, res));
    }
  };
};
export const getReportGreaterthan3DaysAction: any = (validation: any) => {
  return async (dispatch: any) => {
    const res = await service.getReportGreaterthan3DaysApi();
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_REPORT_GREATERTHAN_3_DAYS, res));
    }
  };
};
export const EmployeeSkillsBulkUploadAction: any = (
  docFile: any,
  docUrl: any
) => {
  return async (dispatch: any) => {
    const res = await service.EmployeeSkillsBulkUploadApi(docFile);
    if (res) {
      docUrl(res);
      dispatch(ACTION_CREATOR(actionTypes.EMPLOYEE_SKILLS_BULK_UPLOAD, res));
    }
  };
};

export const getSkillCoinValueAction: any = (validation: any) => {
  return async (dispatch: any) => {
    const res = await service.getSkillCoinValueApi();
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_SKILL_COIN_VALUE, res));
    }
  };
};

export const updateSkillCoinValueAction: any = (
  skillCoinValue: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.updateSkillCoinValueApi(skillCoinValue);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.UPDATE_SKILL_COIN_VALUE, res));
    }
  };
};
export const getSkillCoinsYearlyReportAction: any = (
  fromYear: any,
  toYear: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.getSkillCoinsYearlyReportApi(fromYear, toYear);
    validation(res);
    dispatch(ACTION_CREATOR(actionTypes.GET_SKILL_COINS_YEARLY_REPORT, res));
  };
};

export const getTransactionHistory: any = (
  page: any,
  size: any,
  employeeId: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.transactionHistoryApi(employeeId, page, size);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_TRANSACTION_HISTORY, res));
    }
  };
};

export const getEmployeeMakerCheckerAction: any = (validation: any) => {
  return async (dispatch: any) => {
    const res = await service.getEmployeeMakerCheckerApi();
    if (res) {
      validation(res);
      dispatch(
        ACTION_CREATOR(actionTypes.GET_EMPLOYEE_MAKER_CHECKER_DETAILS, res)
      );
    }
  };
};

export const ModuleBulkUploadAction: any = (docFile: any, docUrl: any) => {
  return async (dispatch: any) => {
    const res = await service.ModuleBulkUploadApi(docFile);
    if (res) {
      docUrl(res);
      dispatch(ACTION_CREATOR(actionTypes.MODULE_BULK_UPLOAD, res));
    }
  };
};
export const uploadModuleSyllabusAction: any = (docFile: any, docUrl: any) => {
  return async (dispatch: any) => {
    const res = await service.uploadModuleSyllabusApi(docFile);
    if (res) {
      docUrl(res);
      dispatch(ACTION_CREATOR(actionTypes.UPLOAD_MODULE_SYLLABUS_DETAILS, res));
    }
  };
};
export const editBeACoachAction: any = (
  id: any,
  data: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.EditBeACoachApi(id, data);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.EDIT_BE_A_COACH_DETAILS, res));
    }
  };
};
export const uploadProfileBeACoachAction: any = (docFile: any, docUrl: any) => {
  return async (dispatch: any) => {
    const res = await service.UploadProfileBeACoach(docFile);
    if (res) {
      docUrl(res);
      dispatch(ACTION_CREATOR(actionTypes.UPLOAD_PROFILE_BE_A_COACH, res));
    }
  };
};

export const viewUploadProfileBeACoachAction: any = (
  docFile: any,
  docUrl: any
) => {
  return async (dispatch: any) => {
    const res = await service.ViewUploadProfileBeACoach(docFile);
    if (res) {
      docUrl(res);
      dispatch(ACTION_CREATOR(actionTypes.VIEW_UPLOAD_PROFILE_BE_A_COACH, res));
    }
  };
};
export const getInvoicesByProjectIdAction: any = (
  project_id: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.getInvoicesByProjectIdApi(project_id);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_INVOICES_BY_PROJECT_ID, res));
    }
  };
};

export const getInvoicesByInvoiceIdAction: any = (
  invoice_id: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.getInvoicesByInvoiceIdApi(invoice_id);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_INVOICES_BY_INVOICE_ID, res));
    }
  };
};

export const getkillDataPaginationAction: any = (
  data: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.getSkillDataPaginationApi(data);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_SKILL_COINS_DATA, res));
    }
  };
};
export const getTechSpecReportAction: any = (
  coachId: any,
  page: any,
  size: any,
  searchKey: any,
  searchValue: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.getTechSpecReportApi(
      coachId,
      page,
      size,
      searchKey,
      searchValue
    );
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_TECH_SPEC_REPORT_DETAILS, res));
    }
  };
};

export const assignBulkSkillCoinsAction: any = (
  skillcoins: any,
  skillcoinsData: any
) => {
  return async (dispatch: any) => {
    const res = await service.assignBulkSkillCoinsApi(skillcoins);
    if (res) {
      skillcoinsData(res);
      dispatch(ACTION_CREATOR(actionTypes.ASSIGN_SKILL_COINS, res));
    }
  };
};
export const getTechSpecDetailsAction: any = (
  techId: any,
  page: any,
  size: any,
  searchKey: any,
  searchValue: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.getTechSpecDetailsApi(
      techId,
      page,
      size,
      searchKey,
      searchValue
    );
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_TECH_SPEC_DETAILS, res));
    }
  };
};

export const getkillDataByEmpIdPaginationAction: any = (
  employeeId: any,
  size: any,
  page: any,
  searchKey: any,
  searchValue: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.getSkillCoinByEmpIdPaginationApi(
      employeeId,
      size,
      page,
      searchKey,
      searchValue
    );
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_SkillCOIN_BY_EMPID, res));
    }
  };
};

export const getSkillLinksEmployeeViewAction: any = (
  page: any,
  size: any,
  searchKey: any,
  searchValue: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.getSkillLinksEmployeeViewApi(
      page,
      size,
      searchKey,
      searchValue
    );
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_SKILL_COINS_DATA, res));
    }
  };
};

export const getCoachMasterListAction: any = (validation: any) => {
  return async (dispatch: any) => {
    const res = await service.getCoachMasterListApi();
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_COACH_MASTER_LIST, res));
    }
  };
};

export const getApplicationDetailsViewAction: any = (
  applicationId: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.getApplicationDetailsViewApi(applicationId);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_APPLICATION_DETAILS_DATA, res));
    }
  };
};

export const resumeUploadAction: any = (docFile: any, docUrl: any) => {
  return async (dispatch: any) => {
    const res = await service.resumeDocUploadApi(docFile);
    if (res) {
      docUrl(res);
      dispatch(ACTION_CREATOR(actionTypes.RESUME_UPLOAD, res));
    }
  };
};

export const viewUploadProfileResumeAction: any = (
  docFile: any,
  docUrl: any
) => {
  return async (dispatch: any) => {
    const res = await service.viewUploadProfileResume(docFile);
    if (res) {
      docUrl(res);
      dispatch(ACTION_CREATOR(actionTypes.VIEW_UPLOAD_PROFILE_RESUME, res));
    }
  };
};

export const addPostAJobIJPAction: any = (job: any, jobData: any) => {
  return async (dispatch: any) => {
    const res = await service.addPostAJobIJPApi(job);
    if (res) {
      jobData(res);
      dispatch(ACTION_CREATOR(actionTypes.ADD_POST_A_JOB_IJP, res));
    }
  };
};

export const jobDescriptionDocumentUploadAction: any = (
  docFile: any,
  docUrl: any
) => {
  return async (dispatch: any) => {
    const res = await service.jobDescriptionDocumentUploadApi(docFile);
    if (res) {
      docUrl(res);
      dispatch(ACTION_CREATOR(actionTypes.JOB_DESCRIPTION, res));
    }
  };
};

export const viewJobDescriptionDocAction: any = (docFile: any, docUrl: any) => {
  return async (dispatch: any) => {
    const res = await service.viewJobDescriptionDocApi(docFile);
    if (res) {
      docUrl(res);
      dispatch(ACTION_CREATOR(actionTypes.VIEW_JOB_DESCRIPTION, res));
    }
  };
};

export const IJPBulkUploadAction: any = (docFile: any, docUrl: any) => {
  return async (dispatch: any) => {
    const res = await service.IJPBulkUploadApi(docFile);
    if (res) {
      docUrl(res);
      dispatch(ACTION_CREATOR(actionTypes.IJP_BULK_UPLOAD, res));
    }
  };
};
export const addInseedLocationAction: any = (data: any, validation: any) => {
  return async (dispatch: any) => {
    const res = await service.addInseedLocationApi(data);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.ADD_INSEED_LOCATION_DATA, res));
    }
  };
};

export const getInseedLocationAction: any = () => {
  return async (dispatch: any) => {
    const res = await service.getInseedLocationApi();
    if (res) {
      dispatch(ACTION_CREATOR(actionTypes.GET_INSEED_LOCATION_DATA, res));
    }
  };
};

export const addLearningDataAction: any = (data: any, validation: any) => {
  return async (dispatch: any) => {
    const res = await service.addLearningRequestApi(data);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.ADD_LEARNING_DATA, res));
    }
  };
};

export const chekerMasterAction: any = (validation: any) => {
  return async (dispatch: any) => {
    const res = await service.learningMasterCheckerApi();
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.CHECK_LEARNING_MASTER, res));
    }
  };
};

export const chekerManagerAction: any = (validation: any) => {
  return async (dispatch: any) => {
    const res = await service.learningManagerCheckerApi();
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.CHECK_LEARNING_MANAGER, res));
    }
  };
};

export const chekerFinanceAction: any = (validation: any) => {
  return async (dispatch: any) => {
    const res = await service.learningFinanceCheckerApi();
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.CHECK_FINANCE_MASTER, res));
    }
  };
};

export const coachingRequestListAction: any = (validation: any) => {
  return async (dispatch: any) => {
    const res = await service.coachingRequestListApi();
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.CHECK_COACHING_REQUEST_LIST, res));
    }
  };
};

export const hrRequestListAction: any = (
  page: any,
  size: any,
  searchKey: any,
  searchValue: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.learningAndDevelopmentHrRequestsApi(
      page,
      size,
      searchKey,
      searchValue
    );
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.HR_REQUEST_LIST, res));
    }
  };
};

export const managerRequestListAction: any = (
  requestId: any,
  page: any,
  size: any,
  searchKey: any,
  searchValue: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.learningAndDevelopmentManagerRequestsApi(
      requestId,
      page,
      size,
      searchKey,
      searchValue
    );
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.MANAGER_REQUEST_LIST, res));
    }
  };
};

export const financeRequestListAction: any = (
  page: any,
  size: any,
  searchKey: any,
  searchValue: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.learningAndDevelopmentFinanceRequestsApi(
      page,
      size,
      searchKey,
      searchValue
    );
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.FINANCE_REQUEST_LIST, res));
    }
  };
};

export const LearningDevDetailsAction: any = (
  requestId: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.learningAndDevelopmentDetailsApi(requestId);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.LEARNING_DEV_DETAILS, res));
    }
  };
};

export const specificEmployeeReferredListAction: any = (
  employeeId: any,
  page: any,
  size: any,
  searchKey: any,
  searchValue: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.specificEmployeeReferredListApi(
      page,
      size,
      searchKey,
      searchValue,
      employeeId
    );
    if (res) {
      validation(res);
      dispatch(
        ACTION_CREATOR(actionTypes.SPECIFIC_EMPLOYEE_REFERRED_LIST, res)
      );
    }
  };
};

export const allEmployeeReferralHistoryAction: any = (
  page: any,
  size: any,
  searchKey: any,
  searchValue: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.AllEmployeesReferralHistoryApi(
      page,
      size,
      searchKey,
      searchValue
    );
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.ALL_EMPLOYEE_REFERRAL_HISTORY, res));
    }
  };
};
export const employeeDbCountryListAction: any = (validation: any) => {
  return async (dispatch: any) => {
    const res = await service.employeeDbCountryListApi();
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.EMPLOYEE_COUNTRY_LIST, res));
    }
  };
};

export const addEmployeeDbCountryListAction: any = (
  data: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.addEmpoyeeDbCountryListApi(data);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.ADD_EMPLOYEE_COUNTRY_LIST, res));
    }
  };
};

export const getEmployeeDbHrbpListAction: any = () => {
  return async (dispatch: any) => {
    const res = await service.getEmployeeDbHrbpListApi();
    if (res) {
      dispatch(ACTION_CREATOR(actionTypes.GET_EMPLOYEE_HRBP_LIST, res));
    }
  };
};

export const getEligibleReferralPayoutsAction: any = (
  page: any,
  size: any,
  searchKey: any,
  searchValue: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.getEligibleReferralPayoutsApi(
      page,
      size,
      searchKey,
      searchValue
    );
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_ELIGIBLE_REFERRAL_PAYOUTS, res));
    }
  };
};

export const putEligibleReferralPayoutsAction: any = (
  id: any,
  data: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.putEligibleReferralPayoutsApi(id, data);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.PUT_ELIGIBLE_REFERRAL_PAYOUTS, res));
    }
  };
};
export const ACTION_CREATOR = (type: any, payload: any) => {
  return { type, payload };
};

export const skillCoinUploadApiAction: any = (docFile: any, docUrl: any) => {
  return async (dispatch: any) => {
    const res = await service.skillCoinUploadApi(docFile);
    if (res) {
      docUrl(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_SKILLCOIN_UPLOAD, res));
    }
  };
};

export const addCompetencyAction: any = (obj: any, compData: any) => {
  return async (dispatch: any) => {
    const res = await service.addCompetencyApi(obj);
    if (res) {
      compData(res);
      dispatch(ACTION_CREATOR(actionTypes.ADD_COMPETENCY, res));
    }
  };
};

export const getStreamsListAction: any = (validation: any) => {
  return async (dispatch: any) => {
    const res = await service.getStreamsListApi();
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.ALL_STREAMS, res));
    }
  };
};

export const getSubStreamsListAction: any = (id: any, validation: any) => {
  return async (dispatch: any) => {
    const res = await service.getSubStreamsListApi(id);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.ALL_SUB_STREAMS, res));
    }
  };
};

export const getQuestionsListAction: any = (validation: any) => {
  return async (dispatch: any) => {
    const res = await service.getQuestionsListApi();
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.ALL_QUESTIONS, res));
    }
  };
};

export const addSelfAssessmentAction: any = (obj: any, compData: any) => {
  return async (dispatch: any) => {
    const res = await service.addSelfAssessmentApi(obj);
    if (res) {
      compData(res);
      dispatch(ACTION_CREATOR(actionTypes.ADD_SELF_ASSESSMENT, res));
    }
  };
};

export const checkCompenetencyAction: any = (obj: any, validation: any) => {
  return async (dispatch: any) => {
    const res = await service.checkCompenetencyApi(obj);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.CHECK_COMPETENCY, res));
    }
  };
};

export const getSelfAssesmentAction: any = (obj: any, compData: any) => {
  return async (dispatch: any) => {
    const res = await service.getSelfAssessmentData(obj);
    if (res) {
      compData(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_ASSESSMENT_DATA, res));
    }
  };
};

export const getAssessmentListByIdAction: any = (obj: any, validation: any) => {
  return async (dispatch: any) => {
    const res = await service.getAssessmentListById(obj);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_ASSESSMENT_LIST_BYID, res));
    }
  };
};

export const getCertificationListAction: any = (obj: any, validation: any) => {
  return async (dispatch: any) => {
    const res = await service.gerCertificationlist(obj);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_CERTIFICTIONS_LIST, res));
    }
  };
};
export const bullApproveAction: any = (docFile: any, docUrl: any) => {
  return async (dispatch: any) => {
    const res = await service.bulkApproveApi(docFile);
    if (res) {
      docUrl(res);
      dispatch(ACTION_CREATOR(actionTypes.BULK_APPROVE, res));
    }
  };
};

export const getAllResourceExportAction: any = (validation: any) => {
  return async (dispatch: any) => {
    const res = await service.getAllResourceExportApi();
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_ALLRESOURCE_EXPORT_API, res));
    }
  };
};

export const DownloadCertificateAction: any = (obj: any, validation: any) => {
  return async (dispatch: any) => {
    const res = await service.downloadCertification(obj);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.DOWNLOAD_CERTIFICATE, res));
    }
  };
};

export const getCompetancyListAction: any = (
  employeeRef: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.getCompetancylist(employeeRef);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.COMPETANCY_LIST, res));
    }
  };
};

export const AddCertificateAction: any = (obj: any, validation: any) => {
  return async (dispatch: any) => {
    const res = await service.AddCertification(obj);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.ADD_CERTIFICATE, res));
    }
  };
};

export const uploadCertificateAction: any = (obj: any, validation: any) => {
  return async (dispatch: any) => {
    const res = await service.UploadCertificate(obj);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.UPLOAD_CERTIFICATE, res));
    }
  };
};

export const UpdateCertificateAction: any = (
  certificationsId: any,
  data: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.UpdateCertificate(certificationsId, data);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.UPDATE_CERTIFICATE, res));
    }
  };
};

export const getStreamListAction: any = (
  stream: any,
  obj: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.getStreamsList(stream, obj);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_STREAM_LIST, res));
    }
  };
};

export const AddStreamAction: any = (
  stream: any,
  obj: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.AddStreams(stream, obj);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.ADD_STREAM, res));
    }
  };
};

export const UpdateStreanAction: any = (
  stream: any,
  streamId: any,
  data: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.UpdateStreams(stream, streamId, data);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.UPDATE_STREAM, res));
    }
  };
};

export const getLeaderListAction: any = (validation: any) => {
  return async (dispatch: any) => {
    const res = await service.getLeaderList();
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.LEADER_LIST, res));
    }
  };
};

export const AddBenchAction: any = (obj: any, validation: any) => {
  return async (dispatch: any) => {
    const res = await service.AddBench(obj);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.ADD_BENCH, res));
    }
  };
};
export const GetBenchTableAction: any = (obj: any, validation: any) => {
  return async (dispatch: any) => {
    const res = await service.GetBenchTableApi(obj);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_BECNH_TABLE_API, res));
    }
  };
};
export const GetProjectHistoryAction: any = (obj: any, validation: any) => {
  return async (dispatch: any) => {
    const res = await service.GetProjectHistoryApi(obj);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_PROJECT_HISTORY_API, res));
    }
  };
};
export const CompleteReleaseAction: any = (
  id: any,
  obj: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.CompleteRealse(id, obj);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.COMPLETE_REALESE, res));
    }
  };
};

export const clientNameListAction: any = (validation: any) => {
  return async (dispatch: any) => {
    const res = await service.getClientNameApi();
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_CLIENT_NAME, res));
    }
  };
};

export const generateResumeDataAction: any = (validation: any) => {
  return async (dispatch: any) => {
    const res = await service.generateResumeDataApi();
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_GENERATE_RESUME_DATA, res));
    }
  };
};
export const postProfileSummaryAction: any = (
  obj: any,
  id: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.postProfileSummaryApi(obj, id);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.POST_PROFILE_SUMMARY_API, res));
    }
  };
};

export const AddProfileReminder: any = (obj: any, validation: any) => {
  return async (dispatch: any) => {
    const res = await service.ProfileReminderApi(obj);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.PROFILE_REMINDER_API, res));
    }
  };
};

export const getBenchResourceApi: any = (obj: any, validation: any) => {
  return async (dispatch: any) => {
    const res = await service.getAllBenchResourceApi(obj);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_BENCH_RESOURCE_API, res));
    }
  };
};

export const getAllEmployeeRoleManagementAction: any = (
  payload: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.getAllEmployeeRoleManagementApi(payload);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_EMPLOYEE_ROLE_MANANGEMENT, res));
    }
  };
};

export const getAllRolesAction: any = (validation: any) => {
  return async (dispatch: any) => {
    const res = await service.getAllRolesApi();
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_ALL_ROLES, res));
    }
  };
};

export const postUpdateRolesAction: any = (data: any, validation: any) => {
  return async (dispatch: any) => {
    const res = await service.postUpdateRolesApi(data);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.UPDATE_ROLES, res));
    }
  };
};

export const deleteAllRolesAction: any = (payload: any, validation: any) => {
  return async (dispatch: any) => {
    const res = await service.deleteAllRolesApi(payload);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.DELETE_ALL_ROLES, res));
    }
  };
};



export const getProjectHistoryByProjectIdAction: any = (
  project_id: any,
  validation: any
) => {
  return async (dispatch: any) => {
    const res = await service.getProjectHistoryByProjectIdApi(project_id);
    if (res) {
      validation(res);
      dispatch(ACTION_CREATOR(actionTypes.GET_PROJECTHISTORY_BY_PROJECT_ID, res));
    }
  };
};