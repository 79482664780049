import { useDispatch } from "react-redux";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../Toasts/Toasts";
import { useState } from "react";
import { Strings } from "../../constant/Strings";

const ModalWarning = (props: any) => {
  const [btnDisable, setBtnDisable] = useState(false);
  const {
    hideModal,
    header,
    id,
    editApi,
    data,
    hideEditModal,
    setShowUndoPopup,
    apiCallCompleted,
    getApiCall,
  } = props;
  const dispatch = useDispatch();
  const { cancel, confirm } = Strings;
  const handleDispatch = () => {
    const record =
      header == "Approve"
        ? header + "d"
        : header == "Reject"
        ? header + "ed"
        : header;
    apiCallCompleted(false);
    dispatch(
      editApi(id, data, (res: any) => {
        if (res?.error?.error_description) {
          showErrorToastMessage(res?.error?.error_description);
          apiCallCompleted(false);
        } else {
          if (res.code === 200) {
            setBtnDisable(true);
            apiCallCompleted(true);
            showSuccessToastMessage(record + " Successfully!");
            setShowUndoPopup(true);
            getApiCall ? getApiCall() : "";
          }
        }
      })
    );
    hideModal();
    hideEditModal();
  };
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12 mt-2 mb-3">
          <label className="form-label">
            Are you sure you want to {header}?
          </label>
        </div>
      </div>

      <div className="modal-btn-wrapper mt-5 mx-auto">
        <div>
          <button className="btn cancel-btn buttonsize" onClick={hideModal}>
            {cancel}
          </button>
        </div>
        <div>
          <button
            className={
              btnDisable
                ? "btn add-submit-button btn-disabled buttonsize"
                : "btn add-submit-button buttonsize"
            }
            onClick={() => handleDispatch()}
          >
            {confirm}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalWarning;
