import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../component/Toasts/Toasts";
import * as actions from "../../../Redux/Actions/index";
import "../InseedProgram.scss";
import { Dropdown } from "react-bootstrap";
import Select from "react-select";
import {
  Strings,
  assessmentLabels,
  requiredFeild,
  assessmentTypeDropdown,
} from "../../../constant/Strings/index";
import { timeDateFormatYMD } from "../../../component/FormatDate";

const AddAssessment = (props: any) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const { hideAddAssessment, selectedStatus, apiCallCompleted, inseedBatchStartDate } = props;
  const { addSuccess, cancel, submit } = Strings;
  const { assessmentDate, assessmentType, modulesCovered, remarks } =
    assessmentLabels;
  const { required } = requiredFeild;

  const [selectedOptions, setSelectedOptions] = useState<any>([]);
  const [btnDisable, setBtnDisable] = useState(false);
  const [assessmentData, setassessmentData] = useState<any>({
    inseedBatchRef: selectedStatus,
    assessmentDate: "",
    modulesCovered: [],
    assessmentType: "",
    remarks: "",
  });
  const [info, setInfo] = useState([]);
  const assessmentDetails = useSelector(
    (state: any) => state.employeeReducer.getAssessmentByIdDetails
  );
  useEffect(() => {
    if (assessmentDetails && assessmentDetails.data) {
      setInfo(assessmentDetails.data.dropDownData);
    }
  }, [assessmentDetails, info]);

  const options: any = info.map((ele: any) => ({
    value: ele._id,
    label: ele.moduleName,
  }));

  const handleOptionChange = (option: any, isChecked: any) => {
    if (isChecked) {
      setSelectedOptions([...selectedOptions, option]);
      setassessmentData({
        ...assessmentData,
        modulesCovered: [...selectedOptions, option].map((o) => o.value),
      });
    } else {
      setSelectedOptions(
        selectedOptions.filter((o: any) => o.value == option.value)
      );
      setassessmentData({
        ...assessmentData,
        modulesCovered: selectedOptions
          .filter((o: any) => o.value !== option.value)
          .map((o: any) => o.value),
      });
    }
  };

  const dispatch = useDispatch();
  const handleModuleSubmit = () => {
    apiCallCompleted(false);
    dispatch(
      actions.addInseedAssessmentAction(assessmentData, (res: any) => {
            if (res?.error?.error_description) {
              showErrorToastMessage(res.error.error_description);
              apiCallCompleted(true);
            } else if (res.code === 200) {
              showSuccessToastMessage(addSuccess);
              apiCallCompleted(true);
        }
      })
    );
    hideAddAssessment();
  };

  useEffect(() => {
    if (
      assessmentData.assessmentDate &&
      assessmentData.assessmentType &&
      selectedOptions?.length > 0
    ) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  });

  return (
    <>
      <div className="row g-3">
        <div className="col-6">
          <label htmlFor="inputName4" className="form-label">
            {assessmentDate}
          </label>
          <span className="required">{required}</span>
          <input
            type="date"
            className="form-control"
            value={assessmentData.assessmentDate}
            min={timeDateFormatYMD(inseedBatchStartDate)}
            onChange={(e) =>
              setassessmentData({
                ...assessmentData,
                assessmentDate: e.target.value,
              })
            }
          />
        </div>
        <div className="col-6">
          <label htmlFor="inputName4" className="form-label">
            {assessmentType}
          </label>
          <span className="required">{required}</span>
          <Dropdown
            onMouseLeave={() => setShowDropdown(false)}
            onMouseOver={() => setShowDropdown(true)}
            className="qualification-dropdown"
            onSelect={(eventKey: any) =>
              setassessmentData({
                ...assessmentData,
                assessmentType: eventKey,
              })
            }
          >
            <Dropdown.Toggle variant="light" className="form-control">
              {assessmentData.assessmentType}
            </Dropdown.Toggle>
            <Dropdown.Menu show={showDropdown}>
              {assessmentTypeDropdown.map((element: any) => (
                <Dropdown.Item eventKey={element}>{element}</Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="col-12">
          <label htmlFor="inputName4" className="form-label">
            {modulesCovered}
          </label>
          <span className="required">{required}</span>
          <Select
            options={options}
            isMulti
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            value={selectedOptions}
            onChange={(selected: any) => setSelectedOptions(selected)}
            closeMenuOnSelect={false}
            components={{
              Option: (props: any) => (
                <div className="custom-option buttonsize">
                  <div className="row">
                    <div className="col-6 mx-4 mt-2">{props.label}</div>
                    <div className="col-4 mt-2">
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          handleOptionChange(props, e.target.checked)
                        }
                        checked={selectedOptions.some(
                          (selectedOption: any) =>
                            selectedOption.value === props.data.value
                        )}
                      />
                    </div>
                  </div>
                </div>
              ),
            }}
          />
        </div>
        <div className="col-md-12">
          <label htmlFor="inputName4" className="form-label">
            {remarks}
          </label>
          <textarea
            rows={3}
            maxLength={100}
            className="form-control"
            value={assessmentData.remarks}
            onChange={(e) => {
              setassessmentData({
                ...assessmentData,
                remarks: e.target.value,
              });
            }}
          />
        </div>

        <div className="modal-btn-wrapper mt-5 mx-auto">
          <div>
            <button
              className="btn cancel-btn buttonsize"
              onClick={hideAddAssessment}
            >
              {cancel}
            </button>
          </div>
          <div>
            <button
              className={
                btnDisable
                  ? "btn add-submit-button buttonsize"
                  : "btn add-submit-button btn-disabled buttonsize"
              }
              onClick={handleModuleSubmit}
            >
              {submit}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddAssessment;
