import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../component/Toasts/Toasts";
import * as actions from "../../../Redux/Actions/index";
import { Strings } from "../../../constant/Strings";
import { isValidURL } from "../../../constant/Validations";

const UploadProgramPlan = (props: any) => {
  const { hideEditTrainingModule, trainingId, apiCallCompleted } = props;
  const [btnDisable, setBtnDisable] = useState(false);
  const [training, setTraining] = useState<any>({
    sharepointLink: "",
    trainingPlan: null,
  });
  const [docFile, setDocFile] = useState<any>({
    trainingPlan: null,
  });
  const {
    sharePoint_link,
    uploadFile,
    imageSize2mb,
    upload_program_plan,
    cancel,
    submit,
  } = Strings;

  useEffect(() => {
    if (training.trainingPlan || training.sharepointLink) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  }, [training]);

  const dispatch = useDispatch();
  const handleSubmitTrainingPlan = (e: any) => {
    const file = e.target.files[0];
    const fileSize = file.size;
    const fileType = file.type;
    if (fileSize > 2 * 1024 * 1024) {
      showErrorToastMessage("File size too large. Maximum size is 2 MB.");
      return;
    } else if (
      ![
        "image/jpeg",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/pdf",
      ].includes(fileType)
    ) {
      showErrorToastMessage(
        "File type not allowed. Allowed types are JPEG, DOC, and PDF."
      );
      return;
    } else {
      setDocFile({ ...docFile, trainingPlan: e.target.files[0] });
      dispatch(
        actions.trainingPlanUploadAction(
          { file: e.target.files[0] },
          (res: any) => {
            if (res.code == 200 && res.data) {
              setTraining((training: any) => ({
                ...training,
                trainingPlan: res.data,
              }));
            }
          }
        )
      );
    }
  };

  const hideEditModal = () => {
    hideEditTrainingModule();
  };
  const [errors, setErrors] = useState({
    sharepointLink: "",
  });
  let valid: boolean = true;
  const validation = () => {
    if (training.sharepointLink) {
      if (isValidURL(training.sharepointLink) == false) {
        valid = false;
        setErrors((errorObj) => ({
          ...errorObj,
          sharepointLink: "Enter a vaild URL",
        }));
        setBtnDisable(false);
      } else {
        setErrors((errorObj) => ({
          ...errorObj,
          sharepointLink: "",
        }));
      }
    }
  };
  const handleSubmit = (e: any) => {
    validation();
    e.preventDefault();
    if (valid) {
      apiCallCompleted(false);
      dispatch(
        actions.editInseedDataAction(trainingId, training, (res: any) => {
            if (res?.error?.error_description) {
                showErrorToastMessage(res.error.error_description);
                apiCallCompleted(true);
              } else if (res.code === 200) {
                showSuccessToastMessage("Uploaded successfully!");
                apiCallCompleted(true);
          }
        })
      );
      hideEditModal();
    }
  };

  return (
    <div>
      <form className="row g-3">
        {training.trainingPlan ? (
          <></>
        ) : (
          <div className="col-md-12">
            <label className="form-label">{sharePoint_link}</label>
            <input
              type="text"
              className="form-control"
              placeholder="Sharepoint URL"
              onChange={(e) => {
                setTraining((training: any) => ({
                  ...training,
                  sharepointLink: e.target.value,
                }));
              }}
            />
            <p className="error">{errors.sharepointLink}</p>
          </div>
        )}
        {training.sharepointLink ? (
          <></>
        ) : (
          <div className="col-md-12">
            <label className="form-label">{upload_program_plan}</label>
            <div>
              <input
                type="file"
                className="form-control"
                onChange={handleSubmitTrainingPlan}
                hidden
                id="actual-btn-10"
              />
              <label
                htmlFor="actual-btn-10"
                className="btn btn-outline-secondary buttonsize"
              >
                {uploadFile}
              </label>
            </div>
            <p className="image-size-condition">{imageSize2mb}</p>
            <a
              href={training.trainingPlan}
              target="_blank"
              className="buttonsize"
            >
              {training.trainingPlan ? "View File" : ""}
            </a>
            <p className="image-size-condition">{docFile.trainingPlan?.name}</p>
          </div>
        )}
        <div className="modal-btn-wrapper mt-5 mx-auto">
          <div>
            <button
              className="btn cancel-btn buttonsize"
              onClick={hideEditModal}
            >
              {cancel}
            </button>
          </div>
          <div>
            <button
              className={
                btnDisable
                  ? "btn add-submit-button buttonsize"
                  : "btn add-submit-button btn-disabled buttonsize"
              }
              onClick={handleSubmit}
            >
              {submit}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default UploadProgramPlan;
