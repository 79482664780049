import { useMemo, useState } from "react";
import SearchBar from "../../../component/SearchBar/SearchBar";
import {
  Strings,
  referredListFilters,
  statusFilters,
} from "../../../constant/Strings";
import FilterValues from "../../../component/FieldFilter/FilterValues";
import { useTable, useGlobalFilter, useSortBy } from "react-table";
import { TiArrowUnsorted } from "react-icons/ti";
import TablePagination from "../../../component/TablePagination/TablePagination";
import ShowEntries from "../../../component/ShowEntries/ShowEntries";
import "../SkillLinks.scss";
import { timeDateFormat } from "../../../component/FormatDate";

function SpecificEmployeeReferredListComponent(props: any) {
  const {
    info,
    searchValue,
    handleSerach,
    filterValue,
    filterChange,
    pageSize,
    setPageSize,
    totalRecords,
    totalEntries,
    totalPages,
    page,
    handlePageChange,
    setSearchValue,
    setFilterValue,
    setPage,
    onSearchClicked,
    setOnclickSearch,
  } = props;
  const { no_data_found, filters, employee_referral_details, status } = Strings;
  const Historycolumns: any = [
    {
      Header: "Candidate Name",
      accessor: "fullName",
    },
    {
      Header: "Candidate Email Id",
      accessor: "emailId",
    },
    {
      Header: "Candidate Contact No.",
      accessor: "contactNumber",
    },
    {
      Header: "Job Id",
      accessor: "jobId",
      Cell: (Action: any) => {
        const jobId = Action.row.original.PostJob.map((ele: any) => ele.jobId);
        return jobId;
      },
    },
    {
      Header: "Referred Date",
      accessor: "referredDate",
      Cell: (Action: any) => {
        const referredDate = Action.row.original.createdAt;
        return timeDateFormat(referredDate);
      },
    },
    {
      Header: "Referred Location",
      accessor: "location",
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: (Action: any) => {
        const status = Action.row.original.status;
        return (
          <>
            <div
              className={` ${
                status == 0
                  ? "projectRepo-new-btn"
                  : status == 1
                  ? "projectRepo-approved-btn"
                  : status == 2
                  ? "projectRepo-rejected-btn"
                  : status == 3
                  ? "projectRepo-updated-btn"
                  : ""
              }`}
            >
              {status == 0
                ? "New"
                : status == 1
                ? "Accepted"
                : status == 2
                ? "Rejected"
                : status == 3
                ? "KIV"
                : ""}
            </div>
          </>
        );
      },
    },
    {
      Header: "Remarks",
      accessor: "remarks",
      Cell: (Action: any) => {
        return <div className="remarks-cell">{Action.value}</div>;
      },
    },
  ];
  const [allRecordsValue, setAllRecordsValue] = useState("");
  const handleStatusChange = (value: any) => {
    setOnclickSearch(true);
    if (value == "All Records") {
      setFilterValue([]);
      setSearchValue("");
      setAllRecordsValue(value);
    } else {
      setFilterValue([{ title: "Status", value: "status" }]);
      setSearchValue(value);
      setAllRecordsValue("");
    }
    setPage(1);
  };
  const data = info;
  const columns = useMemo(() => Historycolumns, []);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useGlobalFilter,
      useSortBy
    );

  return (
    <>
      <div className="heading-button-wrapper mt-4">
        <div>
          <h2>{employee_referral_details}</h2>
        </div>
      </div>
      <div className="mt-2">
        <div className="mt-3 buttonsize filter-wrapper-bench-mgmt">
          <div className="searchbar-wrapper">
            <SearchBar
              filter={searchValue}
              setFilter={handleSerach}
              onSearchClicked={onSearchClicked}
            />
          </div>
          <div className="filter-container">
            <select
              id="select-filters"
              value={typeof filterValue === 'string' ? filterValue : ''}
              onChange={(event) => {
                const selectedValue = event.target.value;
                const selectedOption = referredListFilters.find(
                  (item: any) => item.value === selectedValue
                );
                if (selectedOption) {
                  filterChange(selectedOption);
                }
              }}
            >
              <option hidden>{filters}</option>
              {referredListFilters.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.title}
                </option>
              ))}
            </select>
          </div>
          <div className="filter-container">
            <select
              id="select-filters"
              value={allRecordsValue ? status : searchValue}
              onChange={(event) => {
                handleStatusChange(event.target.value);
              }}
            >
              <option hidden>{status}</option>
              {statusFilters.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
        </div>
        <FilterValues
          filterValueArray={filterValue}
          onFilterChange={filterChange}
        />
        {data.length > 0 ? (
          <>
            <div className="table-responsive">
              <div className="table">
                <table
                  className="table-main table table-sm mt-3"
                  {...getTableProps()}
                >
                  <thead className="table-head">
                    {headerGroups.map((headerGroup) => (
                      <tr
                        className="table-head-row"
                        {...headerGroup.getHeaderGroupProps()}
                      >
                        {headerGroup.headers.map((column: any) => (
                          <th
                            className="table-head-row"
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                            scope="col"
                          >
                            {column.render("Header")}

                            <span>
                              {column.id !== "action" &&
                              column.id !== "id" &&
                              column.id !== "remarks" ? (
                                column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <TiArrowUnsorted />
                                  ) : (
                                    <TiArrowUnsorted />
                                  )
                                ) : (
                                  <TiArrowUnsorted />
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody className="table-body" {...getTableBodyProps()}>
                    {rows.map((row) => {
                      prepareRow(row);
                      return (
                        <tr className="table-body-row" {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <td {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="show-entries-wrapper">
              <ShowEntries
                pageSize={pageSize}
                setPageSize={setPageSize}
                totalRecords={totalRecords}
                totalEntries={totalEntries}
              />
              <TablePagination
                totalPages={totalPages}
                currentPage={page}
                onClick={handlePageChange}
              />
            </div>
          </>
        ) : (
          <p className="no-data">{no_data_found}</p>
        )}
      </div>
    </>
  );
}

export default SpecificEmployeeReferredListComponent;
