import  { useEffect, useState } from "react";
import * as actions from "../../../../Redux/Actions/index";
import { useDispatch, useSelector } from "react-redux";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../../component/Toasts/Toasts";
import { Strings, workDetailsValidation } from "../../../../constant/Strings";
import {
  projectLocationValidation,
  projectProjectNameValidation,
  whiteSpaceValid,
} from "../../../../constant/Validations";
import Select from "react-select";

const EditWorkDetails = (props: any) => {
  const userLogin: any = localStorage.getItem("login");
  const loginId = JSON.parse(userLogin)?.id;
  const role = localStorage.getItem("role");
  const {
    clientName,
    workLocation,
    reportingManagerName,
    handleEditWorkDetails,
    eId,
    hrbpName,
    hrbpRef,
    status,
    apiCallCompleted,
    currentLocation,
  } = props;
  const {
    editSuccess,
    work_location,
    cliName,
    reporting_manager_name,
    hrbp_name,
    cancel,
    submit,
  } = Strings;
  const [btnDisable, setBtnDisable] = useState(false);
  const { clientNameValid, workLocationValid } = workDetailsValidation;
  const [employeeData, setEmployeeData] = useState({
    clientName: clientName,
    workLocation: workLocation,
    currentLocation: currentLocation,
    reportingManagerName: reportingManagerName,
    hrbpRef: hrbpRef,
    eId: eId,
    updatedBy: eId,
  });
  const [hrbpInfo, setHrbpInfo] = useState([]);
  const [errors, setErrors] = useState({
    clientName: "",
    workLocation: "",
  });

  const dispatch = useDispatch();
  let valid: boolean = true;
  const validate = () => {
    if (employeeData.clientName) {
      if (projectProjectNameValidation(employeeData.clientName) == false) {
        valid = false;
        setErrors((errorObj) => ({
          ...errorObj,
          clientName: clientNameValid,
        }));
      } else {
        setErrors((errorObj) => ({ ...errorObj, clientName: "" }));
      }
    }

    if (employeeData.workLocation) {
      if (
        projectLocationValidation(employeeData.workLocation) == false ||
        whiteSpaceValid(employeeData.workLocation) == false
      ) {
        valid = false;
        setErrors((errorObj) => ({
          ...errorObj,
          workLocation: workLocationValid,
        }));
      } else {
        setErrors((errorObj) => ({ ...errorObj, workLocation: "" }));
      }
    }
  };
  const handleEdit = () => {
    validate();
    if (valid) {
      handleDispatch();
    }
  };
  const handleDispatch = () => {
    apiCallCompleted(false);
    dispatch(
      actions.editEmployeeDataAction(employeeData, (res: any) => {
        if (res?.error?.error_description) {
              showErrorToastMessage(res.error.error_description);
              apiCallCompleted(true);
            }else if(res.code === 200) {
              showSuccessToastMessage(editSuccess);
              apiCallCompleted(true);
            }
      })
    );
    handleEditWorkDetails();
  };
  useEffect(() => {
    dispatch(actions.getEmployeeDbHrbpListAction());
  }, []);

  const employeeHrbpData = useSelector(
    (state: any) => state.employeeReducer.getEmployeeDbHrbpList
  );
  useEffect(() => {
    if (employeeHrbpData && employeeHrbpData.data) {
      setHrbpInfo(employeeHrbpData.data);
    }
  }, [employeeHrbpData, hrbpInfo]);

  useEffect(() => {
    if (employeeData.hrbpRef) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  }, [employeeData]);

  const hrbpOptions: any = hrbpInfo.map((ele: any) => ({
    value: ele._id,
    label: ele.middleName
      ? ele.candidateName +
        " " +
        ele.middleName +
        " " +
        ele.lastname +
        "-" +
        ele.employeeId
      : ele.candidateName + " " + ele.lastname + "-" + ele.employeeId,
  }));
  const handleHrbpManager = (selected: any) => {
    setEmployeeData({
      ...employeeData,
      hrbpRef: selected.value,
    });
  };
  const disableCondition: any =
    (!(role && role.includes("hr")) && status == "Active") ||
    status == "Inactive" ||
    (role && role.includes("hr") && status == "Active" && eId == loginId) || role == "undefined"

  return (
    <div>
      <div className="row g-4">
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {cliName}
          </label>
          <input
            type="text"
            className="form-control"
            value={employeeData.clientName}
            onChange={(e) => {
              setEmployeeData({
                ...employeeData,
                clientName: e.target.value,
              });
            }}
          />
          <p className="error">{errors.clientName}</p>
        </div>

        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {work_location}
          </label>
          <input
            type="text"
            className="form-control"
            value={employeeData.workLocation}
            onChange={(e) => {
              setEmployeeData({
                ...employeeData,
                workLocation: e.target.value,
              });
            }}
          />
          <p className="error">{errors.workLocation}</p>
        </div>

        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            Current Location
          </label>
          <input
            type="text"
            className="form-control"
            value={employeeData.currentLocation}
            onChange={(e) => {
              setEmployeeData({
                ...employeeData,
                currentLocation: e.target.value,
              });
            }}
          />
          <p className="error">{errors.workLocation}</p>
        </div>

        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {reporting_manager_name}
          </label>
          <input
            disabled
            type="text"
            className="form-control"
            value={employeeData.reportingManagerName}
            onChange={(e) => {
              setEmployeeData({
                ...employeeData,
                reportingManagerName: e.target.value,
              });
            }}
          />
        </div>
        <div className="col-md-4 mt-3">
          <label className="form-label">{hrbp_name}</label>
          <span className="required">*</span>
          <Select
            className="dropdown-select"
            placeholder={hrbpName}
            options={hrbpOptions}
            isSearchable={true}
            onChange={handleHrbpManager}
            isDisabled={disableCondition }
          />
        </div>
      </div>

      <div className="modal-btn-wrapper mt-4 mb-4">
        <div>
          <button
            className="btn cancel-btn buttonsize"
            onClick={handleEditWorkDetails}
          >
            {cancel}
          </button>
        </div>
        <div>
          <button
            className={
              btnDisable
                ? "btn add-submit-button buttonsize"
                : "btn add-submit-button btn-disabled buttonsize"
            }
            onClick={handleEdit}
          >
            {submit}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditWorkDetails;
