import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../component/Toasts/Toasts";
import * as actions from "../../../Redux/Actions/index";
import "../InseedProgram.scss";
import { Dropdown } from "react-bootstrap";
import Select from "react-select";
import {
  Strings,
  assessmentLabels,
  requiredFeild,
  assessmentTypeDropdown,
} from "../../../constant/Strings/index";
import ModalComponents from "../../../component/ModalComponent/ModalComponent";
import ModalWarning from "../../../component/MakerChecker/ModalWarning";
import ReasonForRejection from "../../../component/MakerChecker/ReasonForRejection";
import { timeDateFormatYMD } from "../../../component/FormatDate";

const EditAssessment = (props: any) => {
  const authRole = localStorage.getItem("role");
  const isInseedChecker = authRole?.includes("inseed-program-checker");
  const [showDropdown, setShowDropdown] = useState(false);
  const { hideEditAssessment, assessmentDetails,apiCallCompleted, inseedBatchStartDate } = props;
  const [btnDisable, setBtnDisable] = useState(false);
  const { editSuccess, cancel, submit, reject, approve } = Strings;
  const { assessmentDate, assessmentType, modulesCovered, remarks } =
    assessmentLabels;
  const { required } = requiredFeild;
  let emptyArray: string[] = [];
  assessmentDetails?.modulesCovered?.forEach((ele: any) => {
    let splitValue = ele.split("/")[1];
    emptyArray.push(splitValue);
  });
  const [assessmentData, setassessmentData] = useState<any>({
    assessmentDate: assessmentDetails.assessmentDate,
    modulesCovered: emptyArray,
    assessmentType: assessmentDetails.assessmentType,
    remarks: assessmentDetails.remarks,
    recordStatus: "Updated",
  });

  const [selectedOptions, setSelectedOptions] = useState<any>(
    assessmentDetails.modulesCovered.map((moduleName: string) => ({
      value: moduleName.split("/")[1],
      label: moduleName.split("/")[0],
    }))
  );
  const [modalWarning, setModalWarning] = useState("");
  const [showReason, setShowReason] = useState(false);
  const [showReasonForRejection, setShowReasonForRejection] = useState(false);
  const [info, setInfo] = useState([]);
  const assessmentInfo = useSelector(
    (state: any) => state.employeeReducer.getAssessmentByIdDetails
  );
  useEffect(() => {
    if (assessmentInfo && assessmentInfo.data) {
      setInfo(assessmentInfo.data.dropDownData);
    }
  }, [assessmentInfo, info]);

  const options: any = info
    .filter(
      (ele: any) => !selectedOptions.some((o: any) => o.value === ele._id)
    )
    .map((ele: any) => ({
      value: ele._id,
      label: ele.moduleName,
    }));

  const handleOptionChange = (option: any, isChecked: any) => {
    if (isChecked) {
      setSelectedOptions([...selectedOptions, option]);
      setassessmentData({
        ...assessmentData,
        modulesCovered: [...selectedOptions, option].map((o) => o.value),
      });
    } else {
      setSelectedOptions(
        selectedOptions.filter((o: any) => o.value == option.value)
      );
      setassessmentData({
        ...assessmentData,
        modulesCovered: selectedOptions
          .filter((o: any) => o.value !== option.value)
          .map((o: any) => o.value),
      });
    }
  };

  const dispatch = useDispatch();
  const handleModuleSubmit = () => {
    apiCallCompleted(false);
    dispatch(
      actions.editInseedAssessmentAction(
        assessmentDetails.id,
        assessmentData,
        (res: any) => {
              if (res?.error?.error_description) {
                showErrorToastMessage(res.error.error_description);
                apiCallCompleted(true);
              } else  if (res.code === 200) {
                showSuccessToastMessage(editSuccess);
                apiCallCompleted(true);
          }
        }
      )
    );
    hideEditAssessment();
  };

  useEffect(() => {
    if (
      assessmentData.assessmentDate &&
      assessmentData.assessmentType &&
      selectedOptions?.length > 0
    ) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  });

  const handleApproval = (text: any) => {
    setModalWarning(text);
    setShowReason(true);
  };

  const handleRejectModal = () => {
    setShowReasonForRejection(true);
  };

  const hideModal = () => {
    setShowReason(false);
  };

  const hideRejectModal = () => {
    setShowReasonForRejection(false);
  };

  return (
    <>
      <div className="row g-3">
        <div className="col-6">
          <label htmlFor="inputName4" className="form-label">
            {assessmentDate}
          </label>
          <span className="required">{required}</span>
          <input
            type="date"
            className="form-control"
            min={timeDateFormatYMD(inseedBatchStartDate)}
            value={timeDateFormatYMD(assessmentData.assessmentDate)}
            onChange={(e) =>
              setassessmentData({
                ...assessmentData,
                assessmentDate: e.target.value,
              })
            }
          />
        </div>
        <div className="col-6">
          <label htmlFor="inputName4" className="form-label">
            {assessmentType}
          </label>
          <span className="required">{required}</span>
          <Dropdown
            onMouseLeave={() => setShowDropdown(false)}
            onMouseOver={() => setShowDropdown(true)}
            className="qualification-dropdown"
            onSelect={(eventKey: any) =>
              setassessmentData({
                ...assessmentData,
                assessmentType: eventKey,
              })
            }
          >
            <Dropdown.Toggle variant="light" className="form-control">
              {assessmentData.assessmentType}
            </Dropdown.Toggle>
            <Dropdown.Menu show={showDropdown}>
              {assessmentTypeDropdown.map((element: any) => (
                <Dropdown.Item eventKey={element}>{element}</Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="col-12">
          <label htmlFor="inputName4" className="form-label">
            {modulesCovered}
          </label>
          <span className="required">{required}</span>
          <Select
            options={options}
            isMulti
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            value={selectedOptions}
            onChange={(selected: any) => {
              setSelectedOptions(selected);
              setassessmentData({
                ...assessmentData,
                modulesCovered: selected.map((o: any) => o.value),
              });
            }}
            closeMenuOnSelect={false}
            components={{
              Option: (props: any) => (
                <div className="custom-option buttonsize">
                  <div className="row">
                    <div className="col-8">{props.label}</div>
                    <div className="col-1 buttonsize">
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          handleOptionChange(props, e.target.checked)
                        }
                        checked={selectedOptions.some(
                          (selectedOption: any) =>
                            selectedOption.value === props.data.value
                        )}
                      />
                    </div>
                  </div>
                </div>
              ),
            }}
          />
        </div>
        <div className="col-md-12">
          <label htmlFor="inputName4" className="form-label">
            {remarks}
          </label>
          <textarea
            rows={3}
            maxLength={100}
            className="form-control"
            value={assessmentData.remarks}
            onChange={(e) =>
              setassessmentData({
                ...assessmentData,
                remarks: e.target.value,
              })
            }
          />
        </div>
        {isInseedChecker &&
          (assessmentDetails?.recordStatus == "Updated" ||
            assessmentDetails?.recordStatus == "New" ||
            assessmentDetails?.recordStatus == "Draft") && (
            <>
              <div className="modal-btn-wrapper mt-3 mx-auto">
                <div>
                  <button
                    type="button"
                    className="btn btn-success buttonsize"
                    onClick={() => {
                      handleApproval("Approve");
                    }}
                  >
                    {approve}
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    className="btn btn-danger buttonsize"
                    onClick={() => {
                      handleRejectModal();
                    }}
                  >
                    {reject}
                  </button>
                </div>
              </div>
            </>
          )}
        <div className="modal-btn-wrapper mt-5 mx-auto">
          <div>
            <button
              className="btn cancel-btn buttonsize"
              onClick={hideEditAssessment}
            >
              {cancel}
            </button>
          </div>
          <div>
            <button
              className={
                btnDisable
                  ? "btn add-submit-button buttonsize"
                  : "btn add-submit-button btn-disabled buttonsize"
              }
              onClick={handleModuleSubmit}
            >
              {submit}
            </button>
          </div>
        </div>
      </div>
      <ModalComponents
        show={showReason}
        onHide={hideModal}
        title=""
        modalBody={
          <ModalWarning
          apiCallCompleted = {apiCallCompleted}
            hideModal={hideModal}
            header={modalWarning}
            id={assessmentDetails.id}
            editApi={actions.editInseedAssessmentAction}
            data={{ recordStatus: modalWarning + "d" }}
            hideEditModal={hideEditAssessment}
            setShowUndoPopup={() => {}}
          />
        }
        className="modal-md"
      />
      <ModalComponents
        show={showReasonForRejection}
        onHide={hideRejectModal}
        title="Reason For Rejection"
        modalBody={
          <ReasonForRejection
          apiCallCompleted = {apiCallCompleted}
            hideModal={hideRejectModal}
            id={assessmentDetails.id}
            editApi={actions.editInseedAssessmentAction}
            statusKey="recordStatus"
            hideEditModal={hideEditAssessment}
            setShowUndoPopup={() => {}}
          />
        }
        className="modal-md"
      />
    </>
  );
};

export default EditAssessment;
