import { useMemo } from "react";
import {
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy,
} from "react-table";
import ShowEntries from "../../../../component/ShowEntries/ShowEntries";
import { TiArrowUnsorted } from "react-icons/ti";
import Pagination from "../../../../component/Pagination/Pagination";
import { timeDateFormat } from "../../../../component/FormatDate";

const PoHistory = (props: any) => {
  const { poHistoryData } = props;

  const poHistoryCloumn: any = [
    {
      Header: "PO No",
      accessor: "poNumber",
    },
    {
      Header: "PO Start Date",
      accessor: "poStartDate",
      Cell: (Action: any) => {
        const startDate = Action.row.original?.poStartDate;
        return startDate ? timeDateFormat(startDate) : "";
      },
    },
    {
      Header: "PO End Date",
      Cell: (Action: any) => {
        const endDate = Action.row.original?.poEndDate;
        return endDate ? timeDateFormat(endDate) : "";
      },
    },
    {
      Header: "Currency Type",
      accessor: "poValueIn",
    },
    {
      Header: "Billing Status",
      accessor: "billingRate",
    },
    {
      Header: "Billing Rate",
      accessor: "poAmount",
    },
    {
      Header: "Approved HC",
      accessor: "approvedHc",
    },
    {
      Header: "Actual HC",
      accessor: "actualHc",
    },
    {
      Header: "Technology Type",
      accessor: "techType",
    },
    {
      Header: "On-Boarding Date",
      accessor: "projectStartDate",
      Cell: (Action: any) => {
        const projectStartDate = Action.row.original?.projectStartDate;
        return projectStartDate ? timeDateFormat(projectStartDate) : "";
      },
    },
    {
      Header: "Off-Boarding Date",
      accessor: "projectEndDate",
      Cell: (Action: any) => {
        const projectEndDate = Action.row.original?.projectEndDate;
        return projectEndDate ? timeDateFormat(projectEndDate) : "";
      },
    },
    {
      Header: "PO Status",
      accessor: "status",
      Cell: (Action: any) => {
        const poSatus = Action.row.original?.status;
        return (
          <div
            className={
              poSatus === "Ongoing"
                ? "btn projectRepo-status-btn projectRepo-ongoing-btn"
                : poSatus === "New"
                ? "btn projectRepo-status-btn projectRepo-new-btn"
                : poSatus === "Delivered"
                ? "btn projectRepo-status-btn projectRepo-delivered-btn"
                : poSatus === "Closed" || poSatus === "Expired"
                ? "btn projectRepo-status-btn projectRepo-closed-btn"
                : poSatus === "On Hold"
                ? "btn projectRepo-status-btn projectRepo-onHold-btn"
                : poSatus === "Extended"
                ? "btn projectRepo-status-btn projectRepo-updated-btn"
                : poSatus === "No Extension"
                ? "btn projectRepo-status-btn projectRepo-rejected-btn"
                : poSatus === "Conversion"
                ? "btn projectRepo-status-btn projectRepo-approved-btn"
                : ""
            }
          >
            {poSatus ?? "-"}
          </div>
        );
      },
    },
    {
      Header: "PO Doc",
      accessor: "uploadPoNumber",
      Cell: (Action: any) => {
        const uploadPoNumber =
          Action.row.original?.uploadPoNumber?.document_path_url;
        return (
          <div
            className="text-primary veiw-cursor"
            onClick={() => {
              if (uploadPoNumber) {
                window.open(uploadPoNumber, "_blank");
              }
            }}
          >
            {uploadPoNumber ? "View" : "-"}
          </div>
        );
      },
    },
  ];

  const columns = useMemo(() => poHistoryCloumn, []);

  const data = useMemo(() => poHistoryData, []);

  const {
    getTableProps,
    getTableBodyProps,
    page,
    headerGroups,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: columns,
      data: data,
      initialState: { pageSize: 10, pageIndex: 0 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <div className="table table-responsive">
      <table className="table-main table table-sm mt-3" {...getTableProps()}>
        <thead className="table-head">
          {headerGroups.map((headerGroup) => (
            <tr
              className="table-head-row"
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column) => (
                <th
                  className="table-head-row"
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  scope="col"
                >
                  {column.render("Header")}
                  <span>
                    {column.isSorted ? (
                      <TiArrowUnsorted />
                    ) : (
                      <TiArrowUnsorted />
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {page.length > 0 ? (
          <tbody className="table-body" {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr className="table-body-row" {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        ) : (
          <p className="projectRepoData mt-4">no Data</p>
        )}
      </table>
      <div className="show-entries-wrapper">
        <ShowEntries
          pageSize={pageSize}
          setPageSize={setPageSize}
          totalRecords={0}
          totalEntries={page.length}
        />
        <Pagination
          nextPage={nextPage}
          previousPage={previousPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          gotoPage={gotoPage}
          pageOptions={pageOptions}
          pageCount={pageCount}
          pageIndex={pageIndex}
        />
      </div>
    </div>
  );
};

export default PoHistory;
