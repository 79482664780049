import  { useEffect, useState } from "react";
import MainCard from "../../../component/MainCard/MainCard";
import Button from "../../../component/Buttons/Button";
import { Strings } from "../../../constant/Strings";
import { icons } from "../../../assets/index";
import "./EnrollmentCourses.scss";
import { useDispatch } from "react-redux";
import * as actions from "../../../Redux/Actions/index";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../component/Toasts/Toasts";
import {
  isNumberValid,
  isValidURL,
  whiteSpaceValid,
} from "../../../constant/Validations";
import moment from "moment";
import useLoadingIndicator from "../../../component/Loader/Loader";

const EnrollmentCourses = () => {
  const [btnDisable, setBtnDisable] = useState(false);
  const [internalTraining, setInternalTraining] = useState(true);
  const [externalTraining, setExternalTraining] = useState(false);
  const [teamTraining, setTeamTraining] = useState(false);
  const [trainingMode, setTrainingMode] = useState("");
  const [currentDesignation, setCurrentDesignation] = useState("");
  const [courseName, setCourseName] = useState("");
  const [majorSkills, setMajorSkills] = useState("");
  const [courseLink, setCourseLink] = useState("");
  const [purposeDes, setPurposeDes] = useState("");
  const [instituteName, setInstituteName] = useState("");
  const [courseStartDate, setCourseStartDate] = useState("");
  const [courseEndDate, setCourseEndDate] = useState("");
  const [trainerName, setTrainerName] = useState("");
  const [courseFees, setCourseFees] = useState("");
  const [tenure, setTenure] = useState("");
  const [managerCheckerList, setManagerCheckerList] = useState(false);
  const [orangeTick, setOrangeTick] = useState(true);
  const [teamStrength, setTeamStrength] = useState("");
  const { loadingComponent, apiCallCompleted } = useLoadingIndicator();

  const {
    cancel,
    training_mode,
    current_designation,
    enter_course_name,
    institute_name,
    course_end_date,
    course_start_date,
    trainer_name,
    course_fees,
    major_skills,
    Purpose_description,
    internal_training,
    external_training,
    course_link,
    valid_institute,
    valid_designation,
    valid_trainer_Name,
    valid_courseLink,
    valid_courseFees,
    valid_courseName,
    valid_majorSkills,
    valid_description,
    hr_course_string,
    tenure_in_days,
    LearningDevelopment,
    TeamTraining,
    Select,
    Offline,
    Online,
    Request
  } = Strings;
  const { green_circle_icon, white_tick_icon } = icons;
  const dispatch = useDispatch();
  const userLogin: any = localStorage.getItem("login");
  const loginId = JSON.parse(userLogin)?.id;
  const role = localStorage.getItem("role");
  const checkManagerList = role?.includes("learninganddevelopment-manager");
  const handleInternal = () => {
    setInternalTraining(true);
    setExternalTraining(false);
    setTeamTraining(false);
    handleClearForm();
  };

  const handleExternal = () => {
    setInternalTraining(false);
    setExternalTraining(true);
    setTeamTraining(false);
    handleClearForm();
  };

  const handleTeam = () => {
    setInternalTraining(false);
    setExternalTraining(false);
    setTeamTraining(true);
    handleClearForm();
  };

  const [errors, setErrors] = useState({
    trainingMode_error: "",
    currentDesignation_error: "",
    courseName_error: "",
    majorSkills_error: "",
    courseLink_error: "",
    purposeDes_error: "",
    instituteName_error: "",
    courseStartDate_error: "",
    courseEndDate_error: "",
    trainerName_error: "",
    courseFees_error: "",
  });
  const {
    currentDesignation_error,
    courseName_error,
    majorSkills_error,
    courseLink_error,
    purposeDes_error,
    instituteName_error,
    courseStartDate_error,
    courseEndDate_error,
    trainerName_error,
    courseFees_error,
  } = errors;

  useEffect(() => {
    dispatch(
      actions.getemployeeDetailsAction(loginId, (res: any) => {
        if (res) {
          setCurrentDesignation(res?.data[0]?.designation);
        }
      })
    );
  }, []);

  let valid: boolean = true;
  const validate = () => {
    if (currentDesignation) {
      if (whiteSpaceValid(currentDesignation) == false) {
        valid = false;
        setErrors((errorObj) => ({
          ...errorObj,
          currentDesignation: valid_designation,
        }));
        setBtnDisable(false);
      } else {
        setErrors((errorObj) => ({
          ...errorObj,
          currentDesignation: "",
        }));
      }
    }
    if (courseName) {
      if (whiteSpaceValid(courseName) == false) {
        valid = false;
        setErrors((errorObj) => ({
          ...errorObj,
          courseName_error: valid_courseName,
        }));
        setBtnDisable(false);
      } else {
        setErrors((errorObj) => ({
          ...errorObj,
          courseName_error: "",
        }));
      }
    }
    if (majorSkills) {
      if (whiteSpaceValid(majorSkills) == false) {
        valid = false;
        setErrors((errorObj) => ({
          ...errorObj,
          majorSkills_error: valid_majorSkills,
        }));
        setBtnDisable(false);
      } else {
        setErrors((errorObj) => ({
          ...errorObj,
          majorSkills_error: "",
        }));
      }
    }
    if (courseLink) {
      if (
        whiteSpaceValid(courseLink) == false ||
        isValidURL(courseLink) == false
      ) {
        valid = false;
        setErrors((errorObj) => ({
          ...errorObj,
          courseLink_error: valid_courseLink,
        }));
        setBtnDisable(false);
      } else {
        setErrors((errorObj) => ({
          ...errorObj,
          courseLink_error: "",
        }));
      }
    }
    if (purposeDes) {
      if (whiteSpaceValid(purposeDes) == false) {
        valid = false;
        setErrors((errorObj) => ({
          ...errorObj,
          purposeDes_error: valid_description,
        }));
        setBtnDisable(false);
      } else {
        setErrors((errorObj) => ({
          ...errorObj,
          purposeDes_error: "",
        }));
      }
    }
    if (instituteName) {
      if (whiteSpaceValid(instituteName) == false) {
        valid = false;
        setErrors((errorObj) => ({
          ...errorObj,
          instituteName_error: valid_institute,
        }));
        setBtnDisable(false);
      } else {
        setErrors((errorObj) => ({
          ...errorObj,
          instituteName_error: "",
        }));
      }
    }
    if (courseFees) {
      if (
        whiteSpaceValid(courseFees) == false ||
        isNumberValid(courseFees) == false
      ) {
        valid = false;
        setErrors((errorObj) => ({
          ...errorObj,
          courseFees: valid_courseFees,
        }));
      } else {
        setErrors((errorObj) => ({
          ...errorObj,
          courseFees: "",
        }));
      }
    }
    if (trainerName) {
      if (whiteSpaceValid(trainerName) == false) {
        valid = false;
        setErrors((errorObj) => ({
          ...errorObj,
          trainerName_error: valid_trainer_Name,
        }));
      } else {
        setErrors((errorObj) => ({
          ...errorObj,
          trainerName_error: "",
        }));
      }
    }
  };
  const handleClearForm = () => {
    setTrainingMode("");
    setTrainerName("");
    setInstituteName("");
    setCourseName("");
    setCourseEndDate("");
    setCourseStartDate("");
    setPurposeDes("");
    setMajorSkills("");
    setCourseLink("");
    setCourseFees("");
    setTenure("");
    setTeamStrength("");
  };
  useEffect(() => {
    if (internalTraining) {
      if (currentDesignation && courseName) {
        setBtnDisable(false);
      } else {
        setBtnDisable(true);
      }
    } else if (externalTraining) {
      if (
        trainingMode &&
        currentDesignation &&
        courseName &&
        instituteName &&
        trainerName &&
        courseStartDate &&
        courseEndDate &&
        courseFees
      ) {
        setBtnDisable(false);
      } else {
        setBtnDisable(true);
      }
    } else if (teamTraining) {
      if (orangeTick) {
        if (courseName && teamStrength && trainingMode) {
          setBtnDisable(false);
        } else {
          setBtnDisable(true);
        }
      } else {
        if (
          courseName &&
          instituteName &&
          trainerName &&
          courseEndDate &&
          courseStartDate &&
          courseFees &&
          teamStrength &&
          trainingMode
        ) {
          setBtnDisable(false);
        } else {
          setBtnDisable(true);
        }
      }
    }
  }, [
    internalTraining,
    trainingMode,
    currentDesignation,
    courseName,
    courseStartDate,
    courseEndDate,
    courseFees,
    courseLink,
    trainerName,
    instituteName,
    btnDisable,
    orangeTick,
    teamStrength,
  ]);

  const internalData = {
    employeeRef: loginId,
    internalTraining: 1,
    trainingMode: "Online",
    currentDesignation: currentDesignation,
    courseName: courseName,
    majorSkills: majorSkills,
    purpose: purposeDes,
    courseLink: courseLink,
    tenure: tenure,
  };

  const externalData = {
    employeeRef: loginId,
    externalTraining: 1,
    trainingMode: trainingMode,
    currentDesignation: currentDesignation,
    courseName: courseName,
    instituteName: instituteName,
    courseStartDate: courseStartDate,
    courseEndDate: courseEndDate,
    trainerName: trainerName,
    courseFees: courseFees,
    majorSkills: majorSkills,
    purpose: purposeDes,
  };

  const teamDataAcceptance = {
    employeeRef: loginId,
    teamTraining: 1,
    courseName: courseName,
    trainingMode: trainingMode,
    teamStrength: teamStrength,
    majorSkills: majorSkills,
    purpose: purposeDes,
    teamTrainingAcceptance: 1,
  };

  const teamData = {
    employeeRef: loginId,
    teamTraining: 1,
    courseName: courseName,
    teamStrength: teamStrength,
    trainingMode: trainingMode,
    instituteName: instituteName,
    trainerName: trainerName,
    courseStartDate: courseStartDate,
    courseEndDate: courseEndDate,
    courseFees: courseFees,
    majorSkills: majorSkills,
    purpose: purposeDes,
  };

  const handleLearningSubmit = (e: any) => {
    e.preventDefault();
    validate();
    if (valid) {
      handleDispatch();
    }
  };
  const handleDispatch = () => {
    apiCallCompleted(false);
    dispatch(
      actions.addLearningDataAction(
        externalTraining
          ? externalData
          : internalTraining
          ? internalData
          : teamTraining
          ? orangeTick
            ? teamDataAcceptance
            : teamData
          : {},
        (res: any) => {
              if(res?.error?.error_description) {
                showErrorToastMessage(res.error.error_description);
                apiCallCompleted(true);
              } else if(res.code === 200) {
                handleClearForm();
                apiCallCompleted(true);
                showSuccessToastMessage("Added request successfully");
          }
        }
      )
    );
  };
  useEffect(() => {
    if (checkManagerList) {
      setManagerCheckerList(true);
    } else {
      setManagerCheckerList(false);
    }
  }, [checkManagerList]);

  return (
    <>
      {loadingComponent()}
      <MainCard>
        <div className="heading-button-wrapper">
          <div>
            <h2>{LearningDevelopment}</h2>
          </div>
          {internalTraining && (
            <div className="btn-wrapper">
              <a href="https://skillmine.udemy.com/" target="_blank">
                <Button title="Udemy Courses" className="add-employee-btn" />
              </a>
            </div>
          )}
        </div>

        <div className="enrollment-course-container">
          <div className="d-flex justify-content-center row">
            <div className="col-3 d-flex justify-content-center align-items-center">
              {internalTraining ? (
                <img
                  src={green_circle_icon}
                  height="20px"
                  width="20px"
                  alt="icon"
                />
              ) : (
                <div onClick={handleInternal} className="grey-circle"></div>
              )}
              <label className="mx-3 form-label mb-0">
                {internal_training}
              </label>
            </div>

            <div className="col-3 d-flex justify-content-center align-items-center">
              {externalTraining ? (
                <img
                  src={green_circle_icon}
                  height="20px"
                  width="20px"
                  alt="icon"
                />
              ) : (
                <div onClick={handleExternal} className="grey-circle"></div>
              )}
              <label className="mx-3 form-label mb-0">
                {external_training}
              </label>
            </div>

            {managerCheckerList && (
              <div className="col-3 d-flex justify-content-center align-items-center">
                {teamTraining ? (
                  <img
                    src={green_circle_icon}
                    height="20px"
                    width="20px"
                    alt="icon"
                  />
                ) : (
                  <>
                    <div onClick={handleTeam} className="grey-circle"></div>
                  </>
                )}
                <label className="mx-3 form-label mb-0">{TeamTraining}</label>
              </div>
            )}
          </div>

          <div>
            <div className="row mt-3">
              {(externalTraining || teamTraining) && (
                <div className="col-md-4">
                  <label className="form-label">{training_mode}</label>
                  <span className="required">*</span> <br />
                  <select
                    className="dropdown-select"
                    placeholder="Select Years of Experience"
                    value={trainingMode}
                    onChange={(e: any) => setTrainingMode(e.target.value)}
                  >
                    <option hidden>{Select}</option>
                    <option>{Offline}</option>
                    <option>{Online}</option>
                  </select>
                </div>
              )}

              {(internalTraining || externalTraining) && (
                <div className="col-md-4">
                  <label className="form-label">{current_designation}</label>
                  <span className="required">*</span>
                  <input
                    placeholder="Current Designation"
                    className="enrollment-input"
                    value={currentDesignation}
                    onChange={(e: any) => setCurrentDesignation(e.target.value)}
                    disabled
                  />
                  <p className="error">{currentDesignation_error}</p>
                </div>
              )}

              {internalTraining && (
                <div className="col-md-4">
                  <label className="form-label">{tenure_in_days}</label>
                  <input
                    placeholder="Enter Tenure"
                    className="enrollment-input"
                    value={tenure}
                    maxLength={3}
                    onChange={(e: any) =>
                      setTenure(e.target.value.replace(/\D/g, ""))
                    }
                  />
                </div>
              )}

              <div className="col-md-4">
                <label className="form-label">{enter_course_name}</label>
                <span className="required">*</span>
                <input
                  placeholder="Course Name"
                  className="enrollment-input"
                  value={courseName}
                  onChange={(e: any) => setCourseName(e.target.value)}
                />
                <p className="error">{courseName_error}</p>
              </div>

              {teamTraining && (
                <div
                  className="col-md-4 d-flex justify-content-center mt-3"
                  style={{ gap: "12px" }}
                >
                  {orangeTick ? (
                    <div
                      className="orange-checkbox-active w-10"
                      onClick={() => setOrangeTick(false)}
                    >
                      <img
                        src={white_tick_icon}
                        height="10px"
                        width="15px"
                        alt="icon"
                      />
                    </div>
                  ) : (
                    <div
                      className="orange-checkbox"
                      onClick={() => setOrangeTick(true)}
                    ></div>
                  )}

                  <label
                    className="form-label"
                    style={{ whiteSpace: "normal" }}
                  >
                    {hr_course_string}
                  </label>
                </div>
              )}
            </div>

            {teamTraining && (
              <>
                <div className="row mt-3">
                  {teamTraining && (
                    <div className="col-md-4">
                      <label className="form-label">{`Team Strength`}</label>
                      <span className="required">*</span>
                      <input
                        placeholder="Team Strength"
                        className="enrollment-input"
                        value={teamStrength}
                        onChange={(e: any) =>
                          setTeamStrength(e.target.value.replace(/\D/g, ""))
                        }
                      />
                    </div>
                  )}
                  <div className="col-md-4">
                    <label className="form-label">{institute_name}</label>
                    <span className="required">*</span> <br />
                    <input
                      placeholder="Institute Name"
                      className="enrollment-input"
                      value={instituteName}
                      onChange={(e: any) => setInstituteName(e.target.value)}
                      disabled={orangeTick}
                    />
                    <p className="error">{instituteName_error}</p>
                  </div>

                  <div className="col-md-4">
                    <label className="form-label">{course_start_date}</label>
                    <span className="required">*</span>
                    <input
                      type="date"
                      placeholder="Start Date"
                      className="enrollment-input"
                      value={courseStartDate}
                      onChange={(e: any) => setCourseStartDate(e.target.value)}
                      disabled={orangeTick}
                    />
                    <p className="error">{courseStartDate_error}</p>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-md-4">
                    <label className="form-label">{course_end_date}</label>
                    <span className="required">*</span>
                    <input
                      type="date"
                      placeholder="End Date"
                      min={moment(courseStartDate)
                        .add(1, "day")
                        .format("YYYY-MM-DD")}
                      className="enrollment-input"
                      value={courseEndDate}
                      onChange={(e: any) => setCourseEndDate(e.target.value)}
                      disabled={orangeTick}
                    />
                    <p className="error">{courseEndDate_error}</p>
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">{trainer_name}</label>
                    <span className="required">*</span> <br />
                    <input
                      className="enrollment-input"
                      placeholder={trainer_name}
                      value={trainerName}
                      onChange={(e: any) => setTrainerName(e.target.value)}
                      disabled={orangeTick}
                    />
                    <p className="error">{trainerName_error}</p>
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">{course_fees}</label>
                    <span className="required">*</span>
                    <input
                      type="text"
                      placeholder="Course Fees"
                      className="enrollment-input"
                      value={courseFees}
                      onChange={(e: any) =>
                        setCourseFees(e.target.value.replace(/\D/g, ""))
                      }
                      disabled={orangeTick}
                    />
                    <p className="error">{courseFees_error}</p>
                  </div>
                </div>
              </>
            )}

            {externalTraining && (
              <div className="row mt-3">
                <div className="col-md-4">
                  <label className="form-label">{institute_name}</label>
                  <span className="required">*</span> <br />
                  <input
                    placeholder="Institute Name"
                    className="enrollment-input"
                    value={instituteName}
                    onChange={(e: any) => setInstituteName(e.target.value)}
                  />
                  <p className="error">{instituteName_error}</p>
                </div>

                <div className="col-md-4">
                  <label className="form-label">{course_start_date}</label>
                  <span className="required">*</span>
                  <input
                    type="date"
                    placeholder="Start Date"
                    className="enrollment-input"
                    value={courseStartDate}
                    onChange={(e: any) => setCourseStartDate(e.target.value)}
                  />
                  <p className="error">{courseStartDate_error}</p>
                </div>

                <div className="col-md-4">
                  <label className="form-label">{course_end_date}</label>
                  <span className="required">*</span>
                  <input
                    type="date"
                    min={moment(courseStartDate)
                      .add(1, "day")
                      .format("YYYY-MM-DD")}
                    placeholder="End Date"
                    className="enrollment-input"
                    value={courseEndDate}
                    onChange={(e: any) => setCourseEndDate(e.target.value)}
                  />
                  <p className="error">{courseEndDate_error}</p>
                </div>
              </div>
            )}

            {externalTraining && (
              <div className="row mt-3">
                <div className="col-md-4">
                  <label className="form-label">{trainer_name}</label>
                  <span className="required">*</span> <br />
                  <input
                    className="enrollment-input"
                    placeholder={trainer_name}
                    value={trainerName}
                    onChange={(e: any) => setTrainerName(e.target.value)}
                  />
                  <p className="error">{trainerName_error}</p>
                </div>
                <div className="col-md-4">
                  <label className="form-label">{course_fees}</label>
                  <span className="required">*</span>
                  <input
                    type="text"
                    placeholder="Course Fees"
                    className="enrollment-input"
                    value={courseFees}
                    onChange={(e: any) =>
                      setCourseFees(e.target.value.replace(/\D/g, ""))
                    }
                  />
                  <p className="error">{courseFees_error}</p>
                </div>
                <div className="col-md-4">
                  <label className="form-label">{major_skills}</label>
                  <input
                    type="text"
                    placeholder="Max 5 Major Skills with space"
                    className="enrollment-input"
                    value={majorSkills}
                    onChange={(e: any) => setMajorSkills(e.target.value)}
                  />
                  <p className="error">{majorSkills_error}</p>
                </div>
              </div>
            )}

            {internalTraining && (
              <div className="row mt-3">
                <div className="col-md-4">
                  <label className="form-label">{major_skills}</label>
                  <input
                    placeholder="Max 5 Major Skills with space"
                    className="enrollment-input"
                    value={majorSkills}
                    onChange={(e: any) => setMajorSkills(e.target.value)}
                  />
                  <p className="error">{majorSkills_error}</p>
                </div>

                <div className="col-md-8">
                  <label className="form-label">{course_link}</label>
                  <input
                    placeholder="Enter Udemy Course Link Here"
                    className="enrollment-input"
                    value={courseLink}
                    onChange={(e: any) => setCourseLink(e.target.value)}
                  />
                  <p className="error">{courseLink_error}</p>
                </div>
              </div>
            )}
            <div className="row mt-3">
              {teamTraining && (
                <div className="col-md-4">
                  <label className="form-label">{major_skills}</label>
                  <input
                    type="text"
                    placeholder="Max 5 Major Skills with space"
                    className="enrollment-input"
                    value={majorSkills}
                    onChange={(e: any) => setMajorSkills(e.target.value)}
                  />
                  <p className="error">{majorSkills_error}</p>
                </div>
              )}
              <div className="col-md-8">
                <label className="form-label">{Purpose_description}</label>
                <textarea
                  placeholder="Enter the Purpose or Required Course Description."
                  onChange={(e: any) => setPurposeDes(e.target.value)}
                  value={purposeDes}
                />
                <p className="error">{purposeDes_error}</p>
              </div>
            </div>
          </div>
          <div className="modal-btn-wrapper mt-5 mx-auto">
            <div>
              <button className="btn cancel-btn buttonsize">{cancel}</button>
            </div>
            <div>
              <button
                className={
                  !btnDisable
                    ? "btn add-submit-button buttonsize"
                    : "btn add-submit-button btn-disabled buttonsize"
                }
                onClick={handleLearningSubmit}
              >
                {Request}
              </button>
            </div>
          </div>
        </div>
      </MainCard>
    </>
  );
};

export default EnrollmentCourses;
