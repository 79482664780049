import  { useEffect, useRef, useState } from "react";
import MainCard from "../../component/MainCard/MainCard";
import {
  Strings,
  ijpExportColumn,
  modeOfWorkDropdown,
  jobCategoryDropdown,
  locationDropdown,
  workLevelDropdown,
  SkillLinksValidationStrings,
  workLevelArray,
  workExperienceObj,
} from "../../constant/Strings";
import {
  experienceValidation,
  jobTitleValidation,
  locationValidation,
  workLevelValidation,
} from "../../constant/Validations/index";
import "../InternalJobPosting/InternalJob.scss";
import { icons } from "../../assets";
import { useDispatch } from "react-redux";
import * as actions from "../../Redux/Actions";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../component/Toasts/Toasts";
import * as XLSX from "xlsx";
import { Dropdown } from "react-bootstrap";
import useLoadingIndicator from "../../component/Loader/Loader";
import { useNavigate } from "react-router-dom";

const IjpPostaJob = () => {
  const { loadingComponent, hideLoadingIndicator, showLoadingIndicator } =
    useLoadingIndicator();
  const {
    post_a_job_to_ijp,
    template,
    bulk_upload,
    fault_records_ijp,
    job_title,
    work_mode,
    job_category,
    location_label,
    enter_location,
    work_level,
    experience,
    major_skills,
    description,
    upload_description,
    upload,
    imageSize2mb,
    cancel,
    submit,
    addSuccess,
    allowOnlyPdf,
    file_size_max_msg,
    sr_associate,
    lead,
    manager,
    sr_manager,
    avp,
    vp,
    sr_vp,
  } = Strings;
  const {
    exp_4_10_years,
    exp_7_11_years,
    exp_8_15_years,
    exp_10_20_years,
    exp_12_20_years,
    exp_15_20_years,
    exp_15_30_years,
  } = workExperienceObj;
  const { hamburger_icon, bulk_upload_icon, template_icon } = icons;
  const {
    jobTitle_error,
    jobCategory_error,
    location_error,
    modeOfWork_error,
    experience_error,
    workLevel_error,
  } = SkillLinksValidationStrings;
  const [showExportReport, setShowExportReport] = useState(false);
  const [jobs, setJobs] = useState<any>({
    jobTitle: "",
    jobCategory: "",
    modeOfWork: "",
    experience: "",
    location: "",
    workLevel: "",
    description: "",
    uploadJobDescription: "",
    majorSkills: [],
  });
  const [errors, setErrors] = useState({
    jobTitle: "",
    jobCategory: "",
    modeOfWork: "",
    experience: "",
    location: "",
    workLevel: "",
    description: "",
  });
  const [locationFeild, setLocationFeild] = useState("");

  const [docFile, setDocFile] = useState<any>({
    uploadJobDescription: null,
    bulkUpload: null,
  });
  const [btnDisable, setBtnDisable] = useState(false);
  const role = localStorage.getItem("role");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const unAuthorizeUser: any = role && !role.includes("internal-job-maker");
  useEffect(() => {
    if (unAuthorizeUser) {
      navigate("/");
    }
  }, []);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleBulkUpload = (e: any) => {
    if (e.target.files?.length > 0) {
      showLoadingIndicator();
      dispatch(
        actions.IJPBulkUploadAction({ file: e.target.files[0] }, (res: any) => {
              if (res?.error?.error_description) {
                showErrorToastMessage(res.error.error_description);
              } else if (res.code === 200) {
                hideLoadingIndicator();
                const uploadedCount = res.meta_data.uploadedRecordsCount;
                const faultRecordsCount = res.meta_data.faultRecordsCount;
                if (faultRecordsCount > 0) {
                  var BasicInfoData = [ijpExportColumn];
                  res.data?.forEach((ele: any) => {
                    let emptyArray: string[] = [];
                    emptyArray.push(
                      ele.jobTitle,
                      ele.jobCategory,
                      ele.modeOfWork,
                      ele.experience,
                      ele.location,
                      ele.workLevel,
                      ele.description
                    );
                    BasicInfoData.push(emptyArray);
                  });
                  var BasicInfo = XLSX.utils.aoa_to_sheet(BasicInfoData);
                  const wb = XLSX.utils.book_new();
                  XLSX.utils.book_append_sheet(
                    wb,
                    BasicInfo,
                    post_a_job_to_ijp
                  );

                  XLSX.writeFile(wb, fault_records_ijp);
                }
                if (fileInputRef.current) {
                  fileInputRef.current.value = "";
                }
                if (uploadedCount > 0 && faultRecordsCount === 0)
                  showSuccessToastMessage("Uploaded Successfully");
                if (faultRecordsCount > 0 && uploadedCount > 0)
                  showSuccessToastMessage(
                    `Uploaded ${uploadedCount} Records and Downloaded ${faultRecordsCount} Fault Records`
                  );
                if (uploadedCount === 0 && faultRecordsCount > 0)
                  showErrorToastMessage(
                    `Downloaded ${faultRecordsCount} Fault Records`
                  );
                if (uploadedCount === 0 && faultRecordsCount === 0)
                  showErrorToastMessage("Please Upload Valid Document");
              }
        })
      );
    }
  };

  useEffect(() => {
    if (
      jobs.jobTitle &&
      jobs.modeOfWork &&
      jobs.jobCategory &&
      jobs.experience &&
      jobs.location &&
      jobs.workLevel &&
      (jobs.description || jobs.uploadJobDescription) &&
      jobs.majorSkills?.length > 0
    ) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  }, [jobs]);

  const handleLocation = (eventKey: any) => {
    setLocationFeild(eventKey);
    setJobs({
      ...jobs,
      location: eventKey,
    });
  };

  const handleWorkLevelChange = (eventKey: any) => {
    workLevelArray.forEach((element: any) => {
      if (element == eventKey) {
        if (eventKey == sr_associate) {
          setJobs({
            ...jobs,
            experience: exp_4_10_years,
            workLevel: eventKey,
          });
        }
        if (eventKey == lead) {
          setJobs({
            ...jobs,
            experience: exp_7_11_years,
            workLevel: eventKey,
          });
        }
        if (eventKey == manager) {
          setJobs({
            ...jobs,
            experience: exp_8_15_years,
            workLevel: eventKey,
          });
        }
        if (eventKey == sr_manager) {
          setJobs({
            ...jobs,
            experience: exp_10_20_years,
            workLevel: eventKey,
          });
        }
        if (eventKey == avp) {
          setJobs({
            ...jobs,
            experience: exp_12_20_years,
            workLevel: eventKey,
          });
        }
        if (eventKey == vp) {
          setJobs({
            ...jobs,
            experience: exp_15_20_years,
            workLevel: eventKey,
          });
        }
        if (eventKey == sr_vp) {
          setJobs({
            ...jobs,
            experience: exp_15_30_years,
            workLevel: eventKey,
          });
        }
      }
    });
  };

  const handleJobDescriptionSubmit = (e: any) => {
    const file = e.target.files[0];
    const fileSize = file.size;
    const fileType = file.type;
    if (fileSize > 2 * 1024 * 1024) {
      showErrorToastMessage(file_size_max_msg);
      return;
    } else if (!["application/pdf"].includes(fileType)) {
      showErrorToastMessage(allowOnlyPdf);
      return;
    } else {
      setDocFile({ ...docFile, uploadJobDescription: e.target.files[0] });
      dispatch(
        actions.jobDescriptionDocumentUploadAction(
          { file: e.target.files[0] },
          (res: any) => {
            if (res.code == 200 && res.data) {
              setJobs({
                ...jobs,
                uploadJobDescription: res.data,
              });
            }
          }
        )
      );
    }
  };

  const handleJobCancel = (e: any) => {
    e.preventDefault();
    setJobs((obj: any) => ({
      ...obj,
      jobTitle: "",
      jobCategory: "",
      modeOfWork: "",
      experience: "",
      location: "",
      workLevel: "",
      description: "",
      uploadJobDescription: "",
      majorSkills: [],
    }));
    setDocFile((obj: any) => ({ ...obj, uploadJobDescription: null }));
    setLocationFeild("");
  };

  let valid: boolean = true;
  const validate = () => {
    if (jobTitleValidation(jobs.jobTitle) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        jobTitle: jobTitle_error,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        jobTitle: "",
      }));
    }
    if (jobTitleValidation(jobs.modeOfWork) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        modeOfWork: modeOfWork_error,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        modeOfWork: "",
      }));
    }
    if (jobTitleValidation(jobs.jobCategory) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        jobCategory: jobCategory_error,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        jobCategory: "",
      }));
    }
    if (experienceValidation(jobs.experience) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        experience: experience_error,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        experience: "",
      }));
    }
    if (locationValidation(jobs.location) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        location: location_error,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        location: "",
      }));
    }
    if (workLevelValidation(jobs.workLevel) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        workLevel: workLevel_error,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        workLevel: "",
      }));
    }
  };
  const handleJobsSubmit = (e: any) => {
    e.preventDefault();
    validate();

    if (valid) {
      handleDispatch();
    }
  };
  const handleDispatch = () => {
    showLoadingIndicator();
    dispatch(
      actions.addPostAJobIJPAction(jobs, (res: any) => {
         if (res?.error?.error_description) {
              showErrorToastMessage(res.error.error_description);
              hideLoadingIndicator();
            } else if(res.code === 200) {
              showSuccessToastMessage(addSuccess);
              hideLoadingIndicator();
        }
      })
    );
    handleClearForm();
  };

  const handleClearForm = () => {
    setJobs((obj: any) => ({
      ...obj,
      jobTitle: "",
      jobCategory: "",
      modeOfWork: "",
      experience: "",
      location: "",
      workLevel: "",
      description: "",
      uploadJobDescription: "",
      majorSkills: [],
    }));
    setDocFile((obj: any) => ({ ...obj, uploadJobDescription: null }));
    setLocationFeild("");
  };

  const handleViewJobDesc = (e: any) => {
    e.preventDefault();
    dispatch(
      actions.viewJobDescriptionDocAction(
        { filename: jobs.uploadJobDescription?.document_name },
        (res: any) => {
          if (res.code == 200 && res.data) {
            window.open(res.data, "_blank");
          }
        }
      )
    );
  };

  const exportReportRef = useRef<HTMLInputElement | null>(null);
  const imgIcon = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event:any) => {
      if (exportReportRef.current && !exportReportRef.current.contains(event.target as Node) ) {
        if(imgIcon.current && !imgIcon.current.contains(event.target as Node)){
        setShowExportReport(false);
        }
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showExportReport]);
  return (
    <>
      {loadingComponent()}
      <MainCard>
        <div className="heading-button-wrapper">
          <div>
            <h2>{post_a_job_to_ijp}</h2>
          </div>
          <div className="btn-wrapper">
            <div className="export-report-wrapper-ijp-post-a-job">
              <img
                src={hamburger_icon}
                onClick={() => setShowExportReport(!showExportReport)}
                alt="icon"
                height={30}
            width= {30}
            ref={imgIcon} 
              />
              {showExportReport && (
                <div className="report-btn-wrapper-ijp" ref={exportReportRef}>
                  <div className="export-btn-ijp">
                    <a
                      href="https://management-spotlight.sgp1.digitaloceanspaces.com/employee/Post-Job-Template-File/3cd376eb-9b49-44be-bbef-afddbfd34f9a.csv"
                      download={"tempfile.csv"}
                    >
                      <button>
                        <img src={template_icon} alt="icon" /> {template}
                      </button>
                    </a>
                  </div>
                  <div className="export-btn-ijp">
                    <button>
                      <img src={bulk_upload_icon} alt="icon" /> {bulk_upload}
                    </button>
                    <input
                      type="file"
                      accept={".csv"}
                      onChange={handleBulkUpload}
                      id="bulk_upload"
                      ref={fileInputRef}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="card mt-2">
          <form className="row g-3 m-2">
            <div className="col-md-4">
              <label className="form-label">{job_title}</label>
              <span className="required">*</span>
              <input
                type="text"
                className="form-control"
                placeholder="Job Title"
                value={jobs.jobTitle}
                onChange={(e) =>
                  setJobs({
                    ...jobs,
                    jobTitle: e.target.value,
                  })
                }
              />
              <p className="error">{errors.jobTitle}</p>
            </div>
            <div className="col-md-4">
              <label className="form-label">{work_mode}</label>
              <span className="required">*</span>
              <Dropdown
                onSelect={(eventKey: any) =>
                  setJobs({
                    ...jobs,
                    modeOfWork: eventKey,
                  })
                }
              >
                <Dropdown.Toggle variant="light" className="form-control">
                  {jobs.modeOfWork}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {modeOfWorkDropdown.map((value: any) => (
                    <Dropdown.Item key={value}>{value}</Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-md-4">
              <label className="form-label">{job_category}</label>
              <span className="required">*</span>
              <Dropdown
                onSelect={(eventKey: any) =>
                  setJobs({
                    ...jobs,
                    jobCategory: eventKey,
                  })
                }
              >
                <Dropdown.Toggle variant="light" className="form-control">
                  {jobs.jobCategory}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {jobCategoryDropdown.map((value: any) => (
                    <Dropdown.Item key={value}>{value}</Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-md-4">
              <label className="form-label">{location_label}</label>
              <span className="required">*</span>

              <Dropdown
                onSelect={(e) => {
                  handleLocation(e);
                }}
              >
                <Dropdown.Toggle variant="light" className="form-control">
                  {locationFeild}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {locationDropdown.map((value: any) => (
                    <Dropdown.Item key={value}>{value}</Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>

              <p className="error">{errors.location}</p>
            </div>
            {locationFeild == "Other" ? (
              <>
                <div className="col-md-4">
                  <label className="form-label">{enter_location}</label>
                  <span className="required">*</span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Location"
                    onChange={(e) =>
                      setJobs({
                        ...jobs,
                        location: e.target.value,
                      })
                    }
                  />
                </div>
              </>
            ) : (
              <></>
            )}
            <div className="col-md-4">
              <label className="form-label">{work_level}</label>
              <span className="required">*</span>
              <Dropdown
                onSelect={(eventKey: any) => {
                  handleWorkLevelChange(eventKey);
                }}
              >
                <Dropdown.Toggle variant="light" className="form-control">
                  {jobs.workLevel}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {workLevelDropdown.map((value: any) => (
                    <Dropdown.Item key={value}>{value}</Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              <p className="error">{errors.workLevel}</p>
            </div>
            <div className="col-md-4">
              <label className="form-label">{experience}</label>
              <span className="required">*</span>
              <input
                type="text"
                className="form-control"
                placeholder="5 to 10 years"
                value={jobs.experience}
                onChange={(e) =>
                  setJobs({
                    ...jobs,
                    experience: e.target.value,
                  })
                }
                disabled
              />
              <p className="error">{errors.experience}</p>
            </div>

            <div className="col-md-4">
              <label className="form-label">{major_skills}</label>
              <span className="required">*</span>
              <input
                type="text"
                value={jobs.majorSkills}
                className="form-control"
                placeholder="Max. 5 major skills seperated with comma"
                onChange={(e) => {
                  setJobs({
                    ...jobs,
                    majorSkills: e.target.value
                      .split(",")
                      .map((skill) => skill),
                  });
                }}
              />
              <p className="error">{errors.jobTitle}</p>
            </div>
            {!jobs.uploadJobDescription && (
              <div className="col-md-8">
                <label className="form-label">{description}</label>
                <span className="required">*</span>
                <textarea
                  className="form-control text-height"
                  placeholder="Please copy paste the entire JD including location experience, skillset required."
                  value={jobs.description}
                  onChange={(e) =>
                    setJobs({
                      ...jobs,
                      description: e.target.value,
                    })
                  }
                />
              </div>
            )}
            {!jobs.description && (
              <div className="col-md-4">
                <label className="form-label">{upload_description}</label>
                <span className="required">*</span>
                <div>
                  <input
                    type="file"
                    className="form-control"
                    onChange={handleJobDescriptionSubmit}
                    hidden
                    id="actual-btn-uploadJob"
                  />
                  <label
                    htmlFor="actual-btn-uploadJob"
                    className="upload-jd-button upload-width"
                  >
                    {upload}
                  </label>
                </div>
                <p className="image-size-condition buttonsize">
                  {imageSize2mb}
                  <span className="required">*</span>
                </p>
                <a
                  href=""
                  onClick={handleViewJobDesc}
                  target="_blank"
                  className="buttonsize"
                >
                  {jobs.uploadJobDescription?.document_name ? "View File" : ""}
                </a>
                <p className="image-size-condition">
                  {docFile.uploadJobDescription?.name}
                </p>
              </div>
            )}
            <div className="modal-btn-wrapper mt-5 mx-auto">
              <div>
                <button
                  className="btn cancel-btn buttonsize"
                  onClick={(e) => handleJobCancel(e)}
                >
                  {cancel}
                </button>
              </div>
              <div>
                <button
                  className={
                    btnDisable
                      ? "btn add-submit-button buttonsize"
                      : "btn add-submit-button btn-disabled buttonsize"
                  }
                  onClick={handleJobsSubmit}
                >
                  {submit}
                </button>
              </div>
            </div>
          </form>
        </div>
      </MainCard>
    </>
  );
};

export default IjpPostaJob;
