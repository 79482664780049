import React, { useEffect, useMemo, useState } from "react";
import SearchBar from "../../../component/SearchBar/SearchBar";
import { FaSort } from "react-icons/fa";
import { icons } from "../../../assets/index";
import { Table } from "react-bootstrap";
import * as actions from "../../../Redux/Actions/index";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import moment from "moment";
import { ToastContainer } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import MainCard from "../../../component/MainCard/MainCard";
import useLoadingIndicator from "../../../component/Loader/Loader";
import { showErrorToastMessage } from "../../../component/Toasts/Toasts";
import ShowEntries from "../../../component/ShowEntries/ShowEntries";
import {
  Strings,
  employeeCoachingProgressFilter,
} from "../../../constant/Strings";
import FilterValues from "../../../component/FieldFilter/FilterValues";
import "../CoachingProgress/Coaching.scss";
import TablePagination from "../../../component/TablePagination/TablePagination";
import hanldeUnauthorizedUser from "../../../component/AccessConditions/UnauthorizedUser";

const CandidateListTable = (props: any) => {
  const { unAuthorizeUser } = hanldeUnauthorizedUser();
  const { loadingComponent } = useLoadingIndicator();
  const [totalEntries, setTotalEntries] = useState<any>();
  const [searchValue, setSearchValue] = useState<any>("");
  const [filterValue, setFilterValue] = useState<any>([]);
  const [page, setPage] = useState<Number>(1);
  const [totalPages, setTotalPages] = useState(0);
  const userLogin: any = localStorage.getItem("login");
  const loginId = JSON.parse(userLogin).id;
  const { back, coachingProgress, noData, filters } = Strings;
  const dispatch = useDispatch();
  const [data, setdata] = useState<any>([]);
  const [order, setorder] = useState("ASC");
  const { backIcon } = icons;

  //  pagination
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const [isSorted, setisSorted] = useState(false);
  const [sortedData, setsortedData] = useState<any>([]);
  const sorting = (col: any) => {
    setisSorted(true);
    if (order === "ASC") {
      const sorted = [...data].sort((a: any, b: any) => {
        if (col == "candidateName") {
          if (a?.Employee[0]?.candidateName < b?.Employee[0]?.candidateName) {
            return 1;
          }
          if (a?.Employee[0]?.candidateName > b?.Employee[0]?.candidateName) {
            return -1;
          }
          return 0;
        } else if (col == "RequestedDate") {
          if (a?.createdAt < b?.createdAt) {
            return 1;
          }
          if (a?.createdAt > b?.createdAt) {
            return -1;
          }
          return 0;
        } else {
          if (a[col] < b[col]) {
            return 1;
          }
          if (a[col] > b[col]) {
            return -1;
          }
          return 0;
        }
      });
      setsortedData(sorted);
      setorder("DSC");
    } else {
      const sorted = [...data].sort((a: any, b: any) => {
        if (a[col] < b[col]) {
          return -1;
        }
        if (a[col] > b[col]) {
          return 1;
        }
        return 0;
      });
      setsortedData(sorted);
      setorder("ASC");
    }
  };
  const role = localStorage.getItem("role");
  const navigate = useNavigate();
  const [onclickSearch, setOnclickSearch] = useState(false);
  const onSearchClicked = (value: any) => {
    setOnclickSearch(value);
  };
  const condition: boolean =
    (filterValue.length == 0 && searchValue == "") ||
    (filterValue.length > 0 && onclickSearch);
  const filterKey = filterValue.map((ele: any) => ele.value);
  useEffect(() => {
    unAuthorizeUser();
    if (condition) {
      dispatch(
        actions.getAllAcceptedEmployeeListAction(
          page,
          pageSize,
          filterKey,
          searchValue,
          loginId,
          (res: any) => {
            if (res?.error?.error_description) {
              showErrorToastMessage(res?.error?.error_description);
            } else {
              if (res.code === 200) {
                setTotalPages(res.meta_data.totalPageCount);
                setTotalEntries(res.meta_data.totalEntries);
                setOnclickSearch(false);
              }
            }
          }
        )
      );
    }
  }, [page, pageSize, filterValue, searchValue, condition]);

  const getAllCoachList = useSelector(
    (state: any) => state.employeeReducer.getAllAcceptedEmployeeListDetails
  );

  useEffect(() => {
    if (getAllCoachList && getAllCoachList.data) {
      setdata(getAllCoachList.data);
      setTotalRecords(getAllCoachList.data.length);
    }
  }, [getAllCoachList, data]);

  const COLUMNS: any = [
    {
      Header: "Name of the Employee",
      accessor: "candidateName",
    },
    {
      Header: "Purpose for Coach",
      accessor: "Purpose",
    },
    {
      Header: "Requested Date",
      accessor: "RequestedDate",
    },
    {
      Header: "Status",
      accessor: "status",
    },
  ];

  const columns = useMemo(() => COLUMNS, []);
  const handleSearch = (value: any) => {
    setSearchValue(value);
    setPage(1);
  };
  const filterChange = (selectedOption: any) => {
    if (selectedOption) {
      if (filterValue.find((obj: any) => obj.value == selectedOption.value)) {
        setFilterValue((prevValue: any) =>
          prevValue.filter((obj: any) => obj.value != selectedOption.value)
        );
      } else {
        setFilterValue((prevValue: any) => [...prevValue, selectedOption]);
      }
    }
  };
  const handlePageChange = (newPage: any) => {
    setPage(newPage);
    setOnclickSearch(true);
  };
  return (
    <>
      {loadingComponent()}
      <MainCard>
        <div className="d-flex flex-column justify-content-center align-items-start progress-header">
          <div
            className="d-flex flex-row jutify-content-between request-back-button"
            onClick={() => navigate("/coaching-progress")}
          >
            <img src={backIcon} className="backIcon" alt="back button" />
            <p className="back-text">{back}</p>
          </div>
          <div>
            <h2>{coachingProgress}</h2>
          </div>
        </div>
        <div className="mt-2">
          <div
            className="mt-3 buttonsize filter-coaching-progress-wrapper"
          >
            <div className="coaching-progress-searchbar-wrapper">
              <SearchBar
                filter={searchValue}
                setFilter={handleSearch}
                onSearchClicked={onSearchClicked}
              />
            </div>
            <div className="coaching-progress-filter-container">
              <select
                id="select-filters"
                value={filterValue}
                onChange={(event) => {
                  const selectedValue = event.target.value;
                  const selectedOption = employeeCoachingProgressFilter.find(
                    (item: any) => item.value === selectedValue
                  );
                  if (selectedOption) {
                    filterChange(selectedOption);
                  }
                }}
              >
                <option hidden>{filters}</option>
                {employeeCoachingProgressFilter.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.title}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <FilterValues
            filterValueArray={filterValue}
            onFilterChange={filterChange}
          />
        </div>
        {data.length > 0 ? (
          <>
            <div className="table-responsive mt-2">
              <Table className="table-margin">
                <thead>
                  <tr className="custom-table-head-row">
                    {columns.map((ele: any) => (
                      <React.Fragment key={ele.Header}>
                        <td
                          onClick={() => sorting(ele.accessor)}
                          className="sorting-cursor"
                        >
                          {ele.Header}
                          {ele.Header !== "Action" &&
                          ele.Header !== "" &&
                          ele.Header != "Remark" ? (
                            <span> {<FaSort />} </span>
                          ) : (
                            " "
                          )}
                        </td>
                      </React.Fragment>
                    ))}
                  </tr>
                </thead>

                <tbody className="employeedata coaching-progress-table">
                  {(isSorted ? sortedData : data).map(
                    (ele: any, index: any) => (
                      <>
                        <tr key={ele?.loginId}>
                          <td>
                            {" "}
                            <Link
                              to={`/assign-session-card/${ele?.employeeRef}`}
                            >
                              {ele?.Employee[0]?.candidateName +
                                " " +
                                ele?.Employee[0]?.middleName +
                                " " +
                                ele?.Employee[0]?.lastname}
                            </Link>
                          </td>
                          <td>{ele?.purpose}</td>
                          <td>{moment(ele.createdAt).format("DD-MMM-YYYY")}</td>

                          <td>
                            <button
                              className={`btn ${
                                ele.status === 1
                                  ? "coaching-status-approved"
                                  : ele.status === 2
                                  ? "coaching-status-rejected"
                                  : ""
                              }`}
                            >
                              {ele?.status === 1
                                ? "Accepted"
                                : ele?.status === 2
                                ? "Rejected"
                                : ""}
                            </button>
                          </td>
                        </tr>
                      </>
                    )
                  )}
                </tbody>
              </Table>
              <div className="show-entries-wrapper">
                <ShowEntries
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  totalRecords={totalRecords}
                  totalEntries={totalEntries}
                />

                <TablePagination
                  totalPages={totalPages}
                  currentPage={page}
                  onClick={handlePageChange}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <p className="employeedata mt-2">{noData}</p>
          </>
        )}
        <ToastContainer />
      </MainCard>
    </>
  );
};

export default CandidateListTable;
