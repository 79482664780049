import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../component/Toasts/Toasts";
import * as actions from "../../../Redux/Actions/index";
import Select from "react-select";
import { Strings } from "../../../constant/Strings/index";
import { trainingCardValidation } from "../../../constant/Validations";

const EditTrainingModule = (props: any) => {
  const { hideEditTrainingModule, trainingId, trainingData, apiCallCompleted } =
    props;
  const {
    training_name,
    Start_Date,
    End_Date,
    modules_covered,
    cancel,
    submit,
    validName,
    editSuccess,
  } = Strings;
  const [btnDisable, setBtnDisable] = useState(false);
  const [training, setTraining] = useState<any>({
    inseedBatchRef: trainingData.inseedBatchRef,
    trainingName: trainingData.trainingName,
    trainingStartDate: trainingData.trainingStartDate,
    trainingEndDate: trainingData.trainingEndDate,
    selectedModules: trainingData.selectedModules.map(
      (moduleName: string) => moduleName.split("/")[1]
    ),
  });

  const [errors, setErrors] = useState({
    trainingName: "",
  });
  let valid: boolean = true;
  const validate = () => {
    if (training.trainingName) {
      if (trainingCardValidation(training.trainingName)) {
        setErrors((error) => ({
          ...error,
          trainingName: "",
        }));
      } else {
        valid = false;
        setErrors((error: any) => ({
          ...error,
          trainingName: validName,
        }));
      }
    }
  };

  const [selectedOptions, setSelectedOptions] = useState<any>(
    trainingData.selectedModules.map((moduleName: string) => ({
      value: moduleName.split("/")[1],
      label: moduleName.split("/")[0],
    }))
  );
  useEffect(() => {
    dispatch(
      actions.getInseedAllModulesAction("", "", "", "", (res: any) => {})
    );
  }, []);

  const [info, setInfo] = useState([]);
  const moduleData = useSelector(
    (state: any) => state.employeeReducer.getInseedAllModulesDetails
  );
  useEffect(() => {
    if (moduleData && moduleData.data) {
      setInfo(moduleData.data);
    }
  }, [moduleData, info]);

  const options: any = info
    .filter(
      (ele: any) => !selectedOptions.some((o: any) => o.value === ele._id)
    )
    .map((ele: any) => ({
      value: ele._id,
      label: ele.moduleName,
    }));

  const handleOptionChange = (option: any, isChecked: any) => {
    if (isChecked) {
      setSelectedOptions([...selectedOptions, option]);
      setTraining({
        ...training,
        selectedModules: [...selectedOptions, option].map((o) => o.value),
      });
    } else {
      setSelectedOptions(
        selectedOptions.filter((o: any) => o.value == option.value)
      );
      setTraining({
        ...training,
        selectedModules: selectedOptions
          .filter((o: any) => o.value !== option.value)
          .map((o: any) => o.value),
      });
    }
  };

  useEffect(() => {
    if (training.trainingName && training.selectedModules.length > 0) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  }, [training]);

  const dispatch = useDispatch();
  const handleDispatch = () => {
    apiCallCompleted(false);
    dispatch(
      actions.editTrainingPlanAction(trainingId, training, (res: any) => {
         if (res?.error?.error_description) {
              showErrorToastMessage(res.error.error_description);
              apiCallCompleted(true);
            } else if (res.code === 200) {
              showSuccessToastMessage(editSuccess);
              apiCallCompleted(true);
            }
      })
    );
    hideEditTrainingModule();
  };
  const hideEditModal = (e: any) => {
    e.preventDefault();
    hideEditTrainingModule();
  };
  const handleEdit = (e: any) => {
    e.preventDefault();
    validate();
    if (valid) {
      handleDispatch();
    }
  };
  return (
    <div>
      <form className="row g-3">
        <div className="col-md-4">
          <label className="form-label">{training_name}</label>
          <span className="required">*</span>
          <input
            type="text"
            className="form-control"
            placeholder="ABCDE"
            value={training.trainingName}
            onChange={(e) =>
              setTraining({
                ...training,
                trainingName: e.target.value,
              })
            }
          />
          <p className="error">{errors.trainingName}</p>
        </div>
        <div className="col-md-4">
          <label className="form-label">{Start_Date}</label>
          <input
            type="date"
            className="form-control"
            placeholder="ABCDE"
            value={training.trainingStartDate}
            onChange={(e) =>
              setTraining({
                ...training,
                trainingStartDate: e.target.value,
              })
            }
          />
        </div>
        <div className="col-md-4">
          <label className="form-label">{End_Date}</label>
          <input
            type="date"
            className="form-control"
            placeholder="ABCDE"
            value={training.trainingEndDate}
            onChange={(e) =>
              setTraining({
                ...training,
                trainingEndDate: e.target.value,
              })
            }
          />
        </div>
        <div className="col-12">
          <label htmlFor="inputName4" className="form-label">
            {modules_covered}
          </label>
          <span className="required">*</span>
          <Select
            options={options}
            isMulti
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            value={selectedOptions}
            onChange={(selected: any) => {
              setSelectedOptions(selected);
              setTraining({
                ...training,
                selectedModules: selected.map((o: any) => o.value),
              });
            }}
            closeMenuOnSelect={false}
            components={{
              Option: (props: any) => (
                <div className="custom-option buttonsize">
                  <div className="row">
                    <div className="col-8">{props.label}</div>
                    <div className="col-1 buttonsize">
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          handleOptionChange(props, e.target.checked)
                        }
                        checked={selectedOptions.some(
                          (selectedOption: any) =>
                            selectedOption.value === props.data.value
                        )}
                      />
                    </div>
                  </div>
                </div>
              ),
            }}
          />
        </div>
        <div className="modal-btn-wrapper mt-5 mx-auto">
          <div>
            <button
              className="btn cancel-btn buttonsize"
              onClick={hideEditModal}
            >
              {cancel}
            </button>
          </div>
          <div>
            <button
              className={
                btnDisable
                  ? "btn add-submit-button buttonsize"
                  : "btn add-submit-button btn-disabled buttonsize"
              }
              onClick={handleEdit}
            >
              {submit}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditTrainingModule;
