import { useDispatch } from "react-redux";
import { showErrorToastMessage } from "../../../../component/Toasts/Toasts";
import * as actions from "../../../../Redux/Actions/index";
import { Strings } from "../../../../constant/Strings";

const DeleteInvoice = (props: any) => {
  const { id, hideDeleteInvoice } = props;
  const { cancel, deleteText, confirmDelete } = Strings;
  const dispatch = useDispatch();
  const handleDelete = () => {
    dispatch(actions.deleteProjectRepositoryInvoiceAction(id));
    hideDeleteInvoice();
    showErrorToastMessage("Deleted Successfully!");
  };
  return (
    <div className="container">
      <div className="row mx-auto">{confirmDelete}</div>
      <div className="modal-btn-wrapper mt-5 mx-auto">
        <div>
          <button
            className="btn cancel-btn buttonsize"
            onClick={hideDeleteInvoice}
          >
            {cancel}
          </button>
        </div>
        <div>
          <button
            className="btn add-submit-button buttonsize"
            onClick={handleDelete}
          >
            {deleteText}
          </button>
        </div>
      </div>
    </div>
  );
};
export default DeleteInvoice;
