import  { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import ModalComponents from "../../../component/ModalComponent/ModalComponent";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../component/Toasts/Toasts";
import * as actions from "../../../Redux/Actions/index";
import ModalWarning from "../../../component/MakerChecker/ModalWarning";
import ReasonForRejection from "../../../component/MakerChecker/ReasonForRejection";
import {
  employeeEmailIdValidation,
  employeeMobileNumberValidtion,
  isCharactersValid,
} from "../../../constant/Validations";
import { timeDateFormatYMD } from "../../../component/FormatDate";

const EditInseedResource = (props: any) => {
  const authRole = localStorage.getItem("role");
  const isInseedChecker = authRole?.includes("inseed-program-checker");
  const {
    hideEditModal,
    resourceDetails,
    apiCallCompleted,
    inseedBatchStartDate,
  } = props;
  const [btnDisable, setBtnDisable] = useState(false);
  const id = resourceDetails._id;
  const [inseedResourceData, setinseedResourceData] = useState<any>({
    employeeRef: resourceDetails.employeeRef,
    inseedBatchRef: resourceDetails.inseedBatchRef,
    selectedStatus: resourceDetails.selectedStatus,
    resourceName: resourceDetails.resourceName,
    startDate: resourceDetails.startDate,
    endDate: resourceDetails.endDate,
    degreeCertificate: resourceDetails.degreeCertificate,
    bondLetter: resourceDetails.bondLetter,
    offerLetter: resourceDetails.offerLetter,
    status: resourceDetails.status,
    contactNumber: resourceDetails.contactNumber,
    personalEmail: resourceDetails.personalEmail,
    emergencyContact: resourceDetails.emergencyContact,
    dateOfOnBoarding: resourceDetails.dateOfOnBoarding,
    recordStatus: "Updated",
  });

  const [docFile, setDocFile] = useState<any>({
    degreeCertificate: null,
    bondLetter: null,
    offerLetter: null,
  });

  const [showReason, setShowReason] = useState(false);
  const [showReasonForRejection, setShowReasonForRejection] = useState(false);
  const handleRejectModal = () => {
    setShowReasonForRejection(true);
  };
  const hideRejectModal = () => {
    setShowReasonForRejection(false);
  };
  const [modalWarning, setModalWarning] = useState("");
  const handleApproval = (text: any) => {
    setModalWarning(text);
    setShowReason(true);
  };
  const hideModal = () => {
    setShowReason(false);
  };

  const [employeeInfo, setEmployeeInfo] = useState([]);

  const [errors, setErrors] = useState({
    employeeRef: "",
    inseedBatchRef: "",
    selectedStatus: "",
    resourceName: "",
    startDate: "",
    endDate: "",
    contactNumber: "",
    personalEmail: "",
    emergencyContact: "",
    dateOfOnBoarding: "",
  });
  useEffect(() => {
    dispatch(actions.getEmployeeDataAction((res: any) => {}));
  }, []);

  const {
    inseedBatchRef,
    selectedStatus,
    resourceName,
    startDate,
    endDate,
    degreeCertificate,
    bondLetter,
    offerLetter,
    status,
    contactNumber,
    personalEmail,
    emergencyContact,
    dateOfOnBoarding,
  } = inseedResourceData;

  const employeeData = useSelector(
    (state: any) => state.employeeReducer.getEmployeeData
  );

  useEffect(() => {
    if (employeeData && employeeData.data) {
      setEmployeeInfo(employeeData.data);
    }
  }, [employeeData, employeeInfo]);

  useEffect(() => {
    if (
      resourceName &&
      startDate &&
      endDate &&
      degreeCertificate &&
      bondLetter &&
      offerLetter &&
      personalEmail &&
      contactNumber
    ) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  });
  const dispatch = useDispatch();
  let valid: boolean = true;
  const validate = () => {
    if (contactNumber) {
      if (employeeMobileNumberValidtion(contactNumber) == false) {
        valid = false;
        setErrors((errorObj) => ({
          ...errorObj,
          contactNumber: "Enter a vaild Contact Number",
        }));
        setBtnDisable(false);
      } else {
        setErrors((errorObj) => ({
          ...errorObj,
          contactNumber: "",
        }));
      }
    }
    if (emergencyContact) {
      if (employeeMobileNumberValidtion(emergencyContact) == false) {
        valid = false;
        setErrors((errorObj) => ({
          ...errorObj,
          emergencyContact: "Enter a vaild Contact Number",
        }));
        setBtnDisable(false);
      } else {
        setErrors((errorObj) => ({
          ...errorObj,
          emergencyContact: "",
        }));
      }
    }
    if (inseedBatchRef.length == 0 && selectedStatus.length == 0) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        inseedBatchRef: "Batch Name is a required feild",
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({ ...errorObj, inseedBatchRef: "" }));
    }
    if (resourceName.length == 0) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        resourceName: "Trainee Name is a required feild",
      }));
      setBtnDisable(false);
    }
    if (isCharactersValid(resourceName) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        resourceName: "Enter a vaild Trainee Name",
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        resourceName: "",
      }));
    }
    if (startDate.length == 0) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        startDate: "This is a required feild",
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({ ...errorObj, startDate: "" }));
    }
    if (endDate.length == 0) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        endDate: "This is a required feild",
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({ ...errorObj, endDate: "" }));
    }

    if (personalEmail) {
      if (
        (employeeEmailIdValidation(personalEmail) == false &&
          personalEmail?.includes("gmail.com")) ||
        personalEmail?.includes("yahoo.com") ||
        personalEmail?.includes("hotmail.com") ||
        personalEmail?.includes("rediffmail.com") ||
        personalEmail?.includes("live.in") ||
        personalEmail?.includes("outlook.com")
      ) {
        valid = false;
        setErrors((errorObj) => ({
          ...errorObj,
          personalEmail: "Enter a vaild Email Address",
        }));
        setBtnDisable(false);
      } else {
        setErrors((errorObj) => ({
          ...errorObj,
          personalEmail: "",
        }));
      }
    }
  };
  const handleSubmit = () => {
    validate();
    if (valid) {
      handleDispatch();
      hideEditModal();
    }
  };
  const handleDispatch = () => {
    apiCallCompleted(false);
    dispatch(
      actions.editInseedResourceAction(id, inseedResourceData, (res: any) => {
          if (res?.error?.error_description) {
            showErrorToastMessage(res.error.error_description);
            apiCallCompleted(true); 
           }else if (res.code === 200) {
              showSuccessToastMessage("Edited successfully!");
              apiCallCompleted(true);
        }
      })
    );
  };

  const handleSubmitdegreeCertificate = (e: any) => {
    const file = e.target.files[0];
    const fileSize = file.size;
    const fileType = file.type;
    if (fileSize > 2 * 1024 * 1024) {
      showErrorToastMessage("File size too large. Maximum size is 2 MB.");
      return;
    } else if (
      !["image/jpeg", "image/png", "application/pdf"].includes(fileType)
    ) {
      showErrorToastMessage(
        "File type not allowed. Allowed types are JPEG, PNG, and PDF."
      );
      return;
    } else {
      setDocFile({ ...docFile, degreeCertificate: e.target.files[0] });
      dispatch(
        actions.inseedDocUploadAction(
          { file: e.target.files[0] },
          (res: any) => {
            if (res.code == 200 && res.data) {
              setinseedResourceData({
                ...inseedResourceData,
                degreeCertificate: res.data,
              });
            }
          }
        )
      );
    }
  };

  const handleSubmitbondLetter = (e: any) => {
    const file = e.target.files[0];
    const fileSize = file.size;
    const fileType = file.type;
    if (fileSize > 2 * 1024 * 1024) {
      showErrorToastMessage("File size too large. Maximum size is 2 MB.");
      return;
    } else if (
      !["image/jpeg", "image/png", "application/pdf"].includes(fileType)
    ) {
      showErrorToastMessage(
        "File type not allowed. Allowed types are JPEG, PNG, and PDF."
      );
      return;
    } else {
      setDocFile({ ...docFile, bondLetter: e.target.files[0] });
      dispatch(
        actions.inseedDocUploadAction(
          { file: e.target.files[0] },
          (res: any) => {
            if (res.code == 200 && res.data) {
              setinseedResourceData({
                ...inseedResourceData,
                bondLetter: res.data,
              });
            }
          }
        )
      );
    }
  };

  const handleSubmitofferLetter = (e: any) => {
    const file = e.target.files[0];
    const fileSize = file.size;
    const fileType = file.type;
    if (fileSize > 2 * 1024 * 1024) {
      showErrorToastMessage("File size too large. Maximum size is 2 MB.");
      return;
    } else if (
      !["image/jpeg", "image/png", "application/pdf"].includes(fileType)
    ) {
      showErrorToastMessage(
        "File type not allowed. Allowed types are JPEG, PNG, and PDF."
      );
      return;
    } else {
      setDocFile({ ...docFile, offerLetter: e.target.files[0] });

      dispatch(
        actions.inseedDocUploadAction(
          { file: e.target.files[0] },
          (res: any) => {
            if (res.code == 200 && res.data) {
              setinseedResourceData({
                ...inseedResourceData,
                offerLetter: res.data,
              });
            }
          }
        )
      );
    }
  };


  return (
    <>
      <div className="row g-3">
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            Trainee Name
          </label>
          <span className="required">*</span>
          <input
            type="text"
            className="form-control"
            value={resourceName}
            onChange={(e) =>
              setinseedResourceData({
                ...inseedResourceData,
                resourceName: e.target.value,
              })
            }
          />
          <p className="error">{errors.resourceName}</p>
        </div>

        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            DOJ-INSEED
          </label>
          <span className="required">*</span>
          <input
            min={timeDateFormatYMD(inseedBatchStartDate)}
            type="date"
            className="form-control"
            value={timeDateFormatYMD(startDate)}
            onChange={(e) =>
              setinseedResourceData({
                ...inseedResourceData,
                startDate: e.target.value,
              })
            }
          />
          <p className="error">{errors.startDate}</p>
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            LWD-INSEED
          </label>
          <span className="required">*</span>

          <input
            min={
              timeDateFormatYMD(startDate) ||
              timeDateFormatYMD(inseedBatchStartDate)
            }
            type="date"
            className="form-control"
            value={timeDateFormatYMD(endDate)}
            onChange={(e) =>
              setinseedResourceData({
                ...inseedResourceData,
                endDate: e.target.value,
              })
            }
          />
          <p className="error">{errors.endDate}</p>
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            Contact Number
          </label>
          <span className="required">*</span>
          <input
            type="text"
            className="form-control"
            maxLength={15}
            value={contactNumber}
            onChange={(e) =>
              setinseedResourceData({
                ...inseedResourceData,
                contactNumber: e.target.value,
              })
            }
          />
          <p className="error">{errors.contactNumber}</p>
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            Personal Email
          </label>
          <span className="required">*</span>
          <input
            type="email"
            className="form-control"
            value={personalEmail}
            onChange={(e) =>
              setinseedResourceData({
                ...inseedResourceData,
                personalEmail: e.target.value,
              })
            }
          />
          <p className="error">{errors.personalEmail}</p>
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            Emergency Contact Number
          </label>
          <input
            type="text"
            className="form-control"
            maxLength={10}
            value={emergencyContact}
            onChange={(e) =>
              setinseedResourceData({
                ...inseedResourceData,
                emergencyContact: e.target.value,
              })
            }
          />
          <p className="error">{errors.emergencyContact}</p>
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            On Boarding Date
          </label>

          <input
            type="date"
            className="form-control"
            min={new Date().toISOString().split("T")[0]}
            value={timeDateFormatYMD(dateOfOnBoarding)}
            onChange={(e) =>
              setinseedResourceData({
                ...inseedResourceData,
                dateOfOnBoarding: e.target.value,
              })
            }
          />
          <p className="error">{errors.dateOfOnBoarding}</p>
        </div>
        <div className="col-md-4">
          <label className="form-label">
            Record Status
            <span className="required">*</span>
          </label>
          <Dropdown
            onSelect={(eventKey: any) =>
              setinseedResourceData({
                ...inseedResourceData,
                status: eventKey,
              })
            }
          >
            <Dropdown.Toggle variant="light" className="form-control">
              {status}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item eventKey="In Training">In Training</Dropdown.Item>
              <Dropdown.Item eventKey="Mapped to Client Project">
                Mapped to Client Project
              </Dropdown.Item>
              <Dropdown.Item eventKey="Mapped to Internal Project">
                Mapped to Internal Project
              </Dropdown.Item>
              <Dropdown.Item eventKey="Mapped to RMG (Bench)">
                Mapped to RMG (Bench)
              </Dropdown.Item>
              <Dropdown.Item eventKey="Terminated">Terminated</Dropdown.Item>
              <Dropdown.Item eventKey="Released">Released</Dropdown.Item>
              <Dropdown.Item eventKey="Resigned">Resigned</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="row mt-3">
          <div className="col-md-4">
            <label className="form-label">Upload Certificate</label>
            <span className="required">*</span>
            <div>
              <input
                type="file"
                className="form-control"
                onChange={handleSubmitdegreeCertificate}
                hidden
                id="actual-btn-1"
              />
              <label
                htmlFor="actual-btn-1"
                className="btn btn-outline-secondary buttonsize"
              >
                Upload File
              </label>
            </div>
            <p className="image-size-condition">
              Image size (Max 2MB)<span className="required">*</span>
            </p>
            <a
              href={degreeCertificate?.document_path_url}
              target="_blank"
              className="buttonsize"
            >
              {degreeCertificate?.document_path_url ? "View File" : ""}
            </a>
            <p className="image-size-condition">
              {docFile.degreeCertificate?.name}
            </p>
          </div>

          <div className="col-md-4">
            <label className="form-label">Upload Agreement Letter</label>
            <span className="required">*</span>
            <div>
              <input
                type="file"
                className="form-control"
                onChange={handleSubmitbondLetter}
                hidden
                id="actual-btn-3"
              />
              <label
                htmlFor="actual-btn-3"
                className="btn btn-outline-secondary buttonsize"
              >
                Upload File
              </label>
            </div>
            <p className="image-size-condition">
              Image size (Max 2MB)<span className="required">*</span>
            </p>
            <a
              href={bondLetter?.document_path_url}
              target="_blank"
              className="buttonsize"
            >
              {bondLetter?.document_path_url ? "View File" : ""}
            </a>
            <p className="image-size-condition">{docFile.bondLetter?.name}</p>
          </div>
          <div className="col-md-4">
            <label className="form-label">Upload Offer Letter</label>
            <span className="required">*</span>
            <div>
              <input
                type="file"
                className="form-control"
                onChange={handleSubmitofferLetter}
                hidden
                id="actual-btn-4"
              />
              <label
                htmlFor="actual-btn-4"
                className="btn btn-outline-secondary buttonsize"
              >
                Upload File
              </label>
            </div>
            <p className="image-size-condition">
              Image size (Max 2MB)<span className="required">*</span>
            </p>
            <a
              href={offerLetter?.document_path_url}
              target="_blank"
              className="buttonsize"
            >
              {offerLetter?.document_path_url ? "View File" : ""}
            </a>
            <p className="image-size-condition">{docFile.offerLetter?.name}</p>
          </div>
        </div>
        <div className="modal-btn-wrapper mt-5 mx-auto">
          {isInseedChecker &&
          (resourceDetails.recordStatus == "Updated" ||
            resourceDetails.recordStatus == "Draft") ? (
            <>
              <button
                type="button"
                className="btn btn-success buttonsize"
                onClick={() => {
                  handleApproval("Approve");
                }}
              >
                Approve
              </button>
              <button
                type="button"
                className="btn btn-danger buttonsize"
                onClick={() => handleRejectModal()}
              >
                Reject
              </button>
            </>
          ) : (
            <>
              <div className="col-md-2"></div>
              <div className="col-md-6"></div>
            </>
          )}
        </div>

        <div className="modal-btn-wrapper mt-4">
          <button className="btn cancel-btn buttonsize" onClick={hideEditModal}>
            Cancel
          </button>

          <div>
            <button
              className={
                btnDisable
                  ? "btn add-submit-button buttonsize"
                  : "btn add-submit-button btn-disabled buttonsize"
              }
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      <ModalComponents
        show={showReason}
        onHide={hideModal}
        title=""
        modalBody={
          <ModalWarning
            apiCallCompleted={apiCallCompleted}
            hideModal={hideModal}
            header={modalWarning}
            id={id}
            editApi={actions.editInseedResourceAction}
            data={{ recordStatus: modalWarning + "d" }}
            hideEditModal={hideEditModal}
            setShowUndoPopup={() => {}}
          />
        }
        className="modal-md"
      />
      <ModalComponents
        show={showReasonForRejection}
        onHide={hideRejectModal}
        title="Reason For Rejection"
        modalBody={
          <ReasonForRejection
            apiCallCompleted={apiCallCompleted}
            hideModal={hideRejectModal}
            id={id}
            editApi={actions.editInseedResourceAction}
            statusKey="recordStatus"
            hideEditModal={hideEditModal}
            setShowUndoPopup={() => {}}
          />
        }
        className="modal-md"
      />
    </>
  );
};

export default EditInseedResource;
