import  { useState } from "react";
import * as actions from "../../../../Redux/Actions/index";
import { useDispatch } from "react-redux";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../../component/Toasts/Toasts";
import { Strings, pfDetailsValidations } from "../../../../constant/Strings";
import {
  validatePfNumber,
  whiteSpaceValid,
  validateUANNumber,
} from "../../../../constant/Validations";

const EditPFDetailsInfo = (props: any) => {
  const { pfNumber, uanNumber, handleEditPfDetails, eId, apiCallCompleted } = props;
  const { editSuccess, cancel, submit, uanNumberLabel, pfNumberLabel } =
    Strings;
  const [btnDisable, setBtnDisable] = useState(false);
  const [employeeData, setEmployeeData] = useState({
    pfNumber: pfNumber,
    uanNumber: uanNumber,
    eId: eId,
    updatedBy: eId,
  });
  const { pfNumberValid, uanNumberValid } = pfDetailsValidations;
  const [errors, setErrors] = useState({
    pfNumber: "",
    uanNumber: "",
  });

  const dispatch = useDispatch();
  let valid: boolean = true;
  const validate = () => {
    if (employeeData.pfNumber?.length > 0) {
      if (
        (validatePfNumber(employeeData.pfNumber) == false) ||
        whiteSpaceValid(employeeData.pfNumber) == false
      ) {
        valid = false;
        setErrors((errorObj) => ({
          ...errorObj,
          pfNumber: pfNumberValid,
        }));
      } else {
        setErrors((errorObj) => ({ ...errorObj, pfNumber: "" }));
      }
    }
    if (employeeData.uanNumber?.length > 0) {
      if (
        validateUANNumber(employeeData.uanNumber) == false ||
        whiteSpaceValid(employeeData.uanNumber) == false
      ) {
        valid = false;
        setErrors((errorObj) => ({
          ...errorObj,
          uanNumber: uanNumberValid,
        }));
      } else {
        setErrors((errorObj) => ({ ...errorObj, uanNumber: "" }));
      }
    }
  };
  const handleEdit = () => {
    validate();
    if (valid) {
      handleDispatch();
    }
  };

  const handleDispatch = () => {
    apiCallCompleted(false);
    dispatch(
      actions.editEmployeeDataAction(employeeData, (res: any) => {
         if (res?.error?.error_description) {
              showErrorToastMessage(res.error.error_description);
              apiCallCompleted(true);
            } else if(res.code === 200) {
              showSuccessToastMessage(editSuccess);
              apiCallCompleted(true);
            }
      })
    );
    handleEditPfDetails();
  };

  return (
    <div>
      <div className="row g-4">
        <div className="col-md-6">
          <label htmlFor="inputName4" className="form-label">
            {pfNumberLabel}
          </label>
          <input
            type="text"
            className="form-control"
            value={employeeData.pfNumber}
            onChange={(e) => {
              setBtnDisable(true);
              setEmployeeData({
                ...employeeData,
                pfNumber: e.target.value,
              });
            }}
          />
          <p className="error">{errors.pfNumber}</p>
        </div>

        <div className="col-md-6">
          <label htmlFor="inputName4" className="form-label">
            {uanNumberLabel}
          </label>
          <input
            type="text"
            className="form-control"
            value={employeeData.uanNumber}
            onChange={(e) => {
              setBtnDisable(true);
              setEmployeeData({
                ...employeeData,
                uanNumber: e.target.value,
              });
            }}
          />
          <p className="error">{errors.uanNumber}</p>
        </div>
      </div>

      <div className="modal-btn-wrapper mt-4 mb-4">
        <div>
          <button
            className="btn cancel-btn buttonsize"
            onClick={handleEditPfDetails}
          >
            {cancel}
          </button>
        </div>
        <div>
          <button
             className={btnDisable
              ? "btn add-submit-button buttonsize"
              : "btn add-submit-button btn-disabled buttonsize"}
            onClick={handleEdit}
          >
            {submit}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditPFDetailsInfo;
