import { useDispatch } from "react-redux";
import { showErrorToastMessage } from "../../component/Toasts/Toasts";
import * as actions from "../../Redux/Actions/index";
import { Strings } from "../../constant/Strings";
import { group_id } from "../../config/constants";

const DeleteAllRoles = (props: any) => {
  const { deleteData, hideDeleteProject } = props;
  const { deleteSuccess, confirmDeleteAllRoles, cancel, submit } = Strings;
  const dispatch = useDispatch();
  const handleDelete = () => {
    const payload = { sub: deleteData?.sub, group_id: group_id };
    dispatch(
      actions.deleteAllRolesAction(payload, (res: any) => {
         if (res?.error?.error_description) {
              showErrorToastMessage(res.error.error_description);
            } else if(res.code === 200) {
              showErrorToastMessage(deleteSuccess);
        }
      })
    );

    hideDeleteProject();
  };
  return (
    <>
      <div className="container">
        <div className="row mx-auto">{confirmDeleteAllRoles}</div>
        <div className="modal-btn-wrapper mt-5 mx-auto">
          <div>
            <button
              className="btn cancel-btn buttonsize"
              onClick={hideDeleteProject}
            >
              {cancel}
            </button>
          </div>
          <div>
            <button
              className="btn add-submit-button buttonsize"
              onClick={handleDelete}
            >
              {submit}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteAllRoles;
