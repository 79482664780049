import useLoadingIndicator from "../../component/Loader/Loader";
import MainCard from "../../component/MainCard/MainCard";
import "./RoleManagement.scss";
import { Strings } from "../../constant/Strings";
import SearchBar from "../../component/SearchBar/SearchBar";
import { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TiArrowUnsorted } from "react-icons/ti";
import ShowEntries from "../../component/ShowEntries/ShowEntries";
import TablePagination from "../../component/TablePagination/TablePagination";
import {
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy,
} from "react-table";
import { icons } from "../../assets";
import { showErrorToastMessage } from "../../component/Toasts/Toasts";
import * as actions from "../../Redux/Actions";
import ModalComponents from "../../component/ModalComponent/ModalComponent";
import AssignRoleManagement from "./AssignRoleManagement";
import DeleteAllRoles from "./DeleteAllRoles";
import { group_id } from "../../config/constants";

const RoleManagement = () => {
  const {
    loadingComponent,
    showLoadingIndicator,
    hideLoadingIndicator,
    apiCallCompleted,
  } = useLoadingIndicator();
  const { roleManagementLabel, searchNameEmail } = Strings;
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState<Number>(1);
  const [totalEntries, setTotalEntries] = useState<any>();
  const [pageSize, setPageSize] = useState<any>(10);
  const dispatch = useDispatch();
  const [showAssignRoles, setShowAssignRoles] = useState(false);
  const [employeeDetails, setEmployeeDetails] = useState({});
  const [allAuthRoles, setAllAuthRoles] = useState([]);
  const [roleKeys, setRoleKeys] = useState([]);
  const [showDeleteRoles, setShowDeleteRoles] = useState(false);
  const [deleteData, setDeleteData] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [onclickSearch, setOnclickSearch] = useState(false);
  const editEmployeeRoles = useSelector(
    (state: any) => state.employeeReducer.postUpdateRoles
  );

  const deleteEmployeeRoles = useSelector(
    (state: any) => state.employeeReducer.deleteAllroles
  );

  useEffect(() => {
    getAllRolesDataDispatch();
  }, []);

  useEffect(() => {
    GetApiDispatch();
  }, [
    editEmployeeRoles,
    deleteEmployeeRoles,
    totalEntries,
    pageSize,
    searchValue,
    onclickSearch,
    page,
  ]);

  const GetApiDispatch = () => {
    showLoadingIndicator();
    const payload = {
      skip: Number(Number(pageSize) * Number(page) - Number(pageSize)),
      take: pageSize,
      sort_by: "UPDATED_AT",
      query: {
        term: searchValue,
      },
    };
    dispatch(
      actions.getAllEmployeeRoleManagementAction(payload, (res: any) => {
            if (res?.error?.error_description) {
              showErrorToastMessage(res.error.error_description);
              hideLoadingIndicator();
            } else if (res.code === 200) {
              setData(res.data.users);
              setTotalEntries(res.data.count);
              const result = Number(res.data.count) / Number(pageSize);
              const pageNo = result % 1 === 0 ? result : Math.ceil(result);
              setTotalPages(pageNo);
              hideLoadingIndicator();
            }
      })
    );
  };

  const getAllRolesDataDispatch = () => {
    showLoadingIndicator();
    dispatch(
      actions.getAllRolesAction((res: any) => {
         if (res?.error?.error_description) {
              showErrorToastMessage(res.error.error_description);
              hideLoadingIndicator();
            } else if (res.code === 200) {
              const optionsObj: any = [];
              res.data.allowed_roles?.forEach((ele: any) => {
                optionsObj.push({
                  label: ele?.role_name,
                  value: ele?.role_key,
                });
              });
              setAllAuthRoles(optionsObj);
              hideLoadingIndicator();
            }
      })
    );
  };

  const handleEdit = (data: any) => {
    const filteredGroup = data?.groups?.filter((ele: any) => {
      return ele?.group_id == group_id;
    });
    const roleKey =
      filteredGroup?.length > 0 && filteredGroup[0]?.roles?.length > 0
        ? filteredGroup[0].roles
        : [];
    setRoleKeys(roleKey);
    setEmployeeDetails(data);
    setShowAssignRoles(true);
  };

  const columns: any = useMemo(
    () => [
      {
        Header: "Employee Name",
        accessor: "name",
        Cell: ({ value }: any) => <div>{value}</div>,
      },
      {
        Header: "Employee Email",
        accessor: "email",
        Cell: ({ value }: any) => <div>{value}</div>,
      },
      {
        Header: "Roles",
        accessor: "groups",
        Cell: (row: any) => {
          const groups = row.value;
          const rolesArr = groups.filter((ele: any) => {
            return ele?.group_id == group_id;
          });
          return (
            <>
              <div className="text-start role-margin">
                {rolesArr[0]?.roles?.length > 0
                  ? rolesArr[0]?.roles.map((ele: any, index: number) => (
                      <span key={index} className="roleName">
                        {ele}
                        {index < rolesArr[0].roles.length - 1 && " , "}
                      </span>
                    ))
                  : "No Roles Assigned"}
              </div>
            </>
          );
        },
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: (Action: any) => {
          return (
            <>
              <div className="d-flex flex-start justify-content-center align-items-start h-100 action-btn">
                <img
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Edit"
                  src={icons.edit_blue_icon}
                  alt="edit_icon"
                  className="edit-icon mx-2 my-2"
                  onClick={() => handleEdit(Action.row.original)}
                />
                <img
                  src={icons.delete_icon}
                  alt="delete-icon"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Delete"
                  onClick={() => handleDeleteRoles(Action.row.original)}
                  className="edit-icon my-2"
                />
              </div>
            </>
          );
        },
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        initialState: {
          pageSize: pageSize,
          pageIndex: 0,
        },
      },
      useGlobalFilter,
      useSortBy,
      usePagination
    );

  const handleSearch = (value: any) => {
    setSearchValue(value);
    setPage(1);
  };

  const handlePageChange = (newPage: any) => {
    setPage(newPage);
    setOnclickSearch(true);
  };

  const onSearchClicked = (value: any) => {
    setOnclickSearch(value);
  };

  const handleDeleteRoles = (data: any) => {
    setDeleteData(data);
    setShowDeleteRoles(true);
  };

  const hideAssignRoles = () => {
    setShowAssignRoles(false);
  };

  const hideDeleteProject = () => {
    setShowDeleteRoles(false);
  };
  return (
    <>
      {loadingComponent()}
      <MainCard>
        <div className="heading-button-wrapper">
          <div>
            <h2>{roleManagementLabel}</h2>
          </div>
        </div>
        <div className="mt-2">
          <div
            className="mt-3 buttonsize filter-wrapper"
          >
            <div className="emp-searchbar-wrapper width-search">
              <SearchBar
                filter={searchValue}
                setFilter={handleSearch}
                onSearchClicked={onSearchClicked}
                placeholderName={searchNameEmail}
              />
            </div>
          </div>
          {data?.length > 0 ? (
            <>
              <div className="table table-responsive">
                <table
                  className="table-main table table-sm mt-3"
                  {...getTableProps()}
                >
                  <thead className="table-head">
                    {headerGroups.map((headerGroup) => (
                      <tr
                        className="table-head-row"
                        {...headerGroup.getHeaderGroupProps()}
                      >
                        {headerGroup.headers.map((column: any) => (
                          <th
                            className="table-head-row"
                            {...column.getHeaderProps()}
                            scope="col"
                          >
                            {column.render("Header")}

                            <span>
                              {column.id !== "Action" && column.id !== "id" ? (
                                column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <TiArrowUnsorted />
                                  ) : (
                                    <TiArrowUnsorted />
                                  )
                                ) : (
                                  <TiArrowUnsorted />
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody className="table-body" {...getTableBodyProps()}>
                    {rows.map((row, index) => {
                      prepareRow(row);
                      return (
                        <tr className="table-body-row" {...row.getRowProps()}>
                          {row.cells.map((cell: any) => {
                            return (
                              <td
                                {...cell.getCellProps()}
                                style={
                                  cell?.column?.id === "groups"
                                    ? { width: "60%" }
                                    : {}
                                }
                              >
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              <div className="show-entries-wrapper">
                <ShowEntries
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  totalRecords={0}
                  totalEntries={totalEntries}
                />

                <TablePagination
                  totalPages={totalPages}
                  currentPage={page}
                  onClick={handlePageChange}
                />
              </div>
            </>
          ) : (
            <p className="employeedata">No Data Found</p>
          )}
        </div>
        <ModalComponents
          show={showAssignRoles}
          onHide={hideAssignRoles}
          title={"Assign Roles"}
          modalBody={
            <AssignRoleManagement
              employeeDetails={employeeDetails}
              hideAssignRoles={hideAssignRoles}
              apiCallCompleted={apiCallCompleted}
              options={allAuthRoles}
              roleKeys={roleKeys}
            />
          }
          className="modal-lg"
        />
        <ModalComponents
          show={showDeleteRoles}
          onHide={hideDeleteProject}
          title="Delete Assigned Roles"
          modalBody={
            <DeleteAllRoles
              deleteData={deleteData}
              hideDeleteProject={hideDeleteProject}
            />
          }
          className="modal-lg"
        />
      </MainCard>
    </>
  );
};

export default RoleManagement;
