import { useDispatch } from 'react-redux';
import { showErrorToastMessage, showSuccessToastMessage } from '../../../../component/Toasts/Toasts';
import * as actions from "../../../../Redux/Actions/index";
import { Strings } from '../../../../constant/Strings';


function DeleteResource(props: any) {
    const {id, hideDeleteResource } = props;
  const dispatch = useDispatch();
  const { deleteSuccess, confirmDelete, cancel, submit } = Strings;
  const handleDelete = () => {
    dispatch(actions.deleteResourceAllocationAction(id, (res: any)=>{
      if (res?.error?.error_description) {
            showErrorToastMessage(res.error.error_description)
          } else if(res.code === 200) {
            showSuccessToastMessage(deleteSuccess)
          }
    }));
    hideDeleteResource();
  };
  return (
    <div className="container">
    <div className="row mx-auto">{confirmDelete}</div>
    <div className="modal-btn-wrapper mt-5 mx-auto">
      <div>
        <button
          className="btn cancel-btn buttonsize"
          onClick={hideDeleteResource}
        >
          {cancel}
        </button>
      </div>
      <div>
        <button
          className="btn add-submit-button buttonsize"
          onClick={handleDelete}
        >
          {submit}
        </button>
      </div>
    </div>
  </div>
  )
}

export default DeleteResource