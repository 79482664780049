import  { useEffect, useState } from "react";
import Header from "../Header/Header";
import NewSideBar from "../NewSideBar/Sidebar";
import "./MainCard.scss";
import { useNavigate } from "react-router-dom";

const MainCard = (props: any) => {
  const navigate = useNavigate();
  const [hideSideBar, setHideSideBar] = useState(false);
  const handleSideBar = () => {
    setHideSideBar(!hideSideBar);
  };
  useEffect(() => {
    const userLogin: any = localStorage.getItem("login");
    const loginId = JSON.parse(userLogin)?.id;
    const pathname = window.location.pathname;

    const id = pathname.split("/").pop();

    if (pathname.includes("/employeeInfo/") && id?.includes(loginId)) {
      navigate(`/profile/${id}`);
    } else if (pathname.includes("/employeeInfo/")) {
      navigate(`/${id}`);
    }
  }, []);

  return (
    <>
      <div
        className={
          hideSideBar ? "main-container" : "main-container padding-left"
        }
      >
        <Header handleSideBar={handleSideBar} />
        <NewSideBar hideSideBar={hideSideBar} />
        <div className="header-container">{props.heading}</div>
        <div>{props.children}</div>
      </div>
    </>
  );
};

export default MainCard;
