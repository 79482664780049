import { useDispatch } from "react-redux";
import * as actions from "../../../Redux/Actions/index";
import {
  showErrorToastMessage, showSuccessToastMessage,
} from "../../../component/Toasts/Toasts";
import { Strings } from "../../../constant/Strings";

const DeleteTraining = (props: any) => {
  const { trainingId, hideDeleteModal, apiCallCompleted } = props;
  const {confirmDelete, cancel, deleteText}= Strings;
  const dispatch = useDispatch();
  const handleDelete = () => {
    apiCallCompleted(false);
    dispatch(actions.deleteTrainingModuleAction(trainingId, (res: any)=>{
       if (res?.error?.error_description) {
                showErrorToastMessage(res.error.error_description)
                apiCallCompleted(true);
            } else if (res.code === 200) {
              showSuccessToastMessage("Deleted Successfully!")
              apiCallCompleted(true);
    }
}));
    hideDeleteModal();
    
  };

  return (
    <div className="container">
      <div className="row mx-auto">{confirmDelete}</div>
       <div className="modal-btn-wrapper mt-5 mx-auto">
        <div>
          <button
            className="btn cancel-btn buttonsize"
            onClick={hideDeleteModal}
          >
            {cancel}
          </button>
        </div>
        <div>
          <button
            className="btn add-submit-button buttonsize"
            onClick={handleDelete}
          >
            {deleteText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteTraining;
