import React from "react";
import { icons } from "../../assets/index";
import { Strings } from "../../constant/Strings";
import { useNavigate } from "react-router-dom";
import "./Button.scss"
const BackButton = (props: any) => {
  const { navigateTo } = props;
  const { back } = Strings;
  const navigate = useNavigate();
  return (
    <>
    <div
      className="d-flex flex-row jutify-content-between back-button"
      onClick={() => navigate(navigateTo)}
    >
      <img src={icons.backIcon} className="backIcon" alt="back button" title="click here to move back"/>
      <p className="back-text">{back}</p>
    </div>
    </>
  );
};

export default BackButton;
