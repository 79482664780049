import { AnyAction } from "redux";
import * as actionTypes from "../ActionTypes";

interface typeStates {
  getEmployeeData: any;
  getEmployeePagData: any;
  addEmployeeData: any;
  editEmployeeData: any;
  editEmployeePicture: any;
  deleteEmployeeData: any;

  getEmployeeSkills: any;
  addEmployeeSkills: any;
  editEmployeeSkills: any;
  deleteEmployeeSkills: any;

  getExperienceData: any;
  addExperienceData: any;
  editExperienceData: any;
  deleteExperienceData: any;
  value: number;
  getEmployeeQualification: any;
  addEmployeeQualification: any;
  editEmployeeQualification: any;
  deleteEmployeeQualification: any;

  getEmployeeCertifications: any;
  addEmployeeCertifications: any;
  editEmployeeCertifications: any;
  deleteEmployeeCertifications: any;

  getEmployeePreference: any;
  addEmployeePreference: any;
  editEmployeePreference: any;
  deleteEmployeePreference: any;

  getEmployeeWorkAchievements: any;
  addEmployeeWorkAchievements: any;
  editEmployeeWorkAchievements: any;
  deleteEmployeeWorkAchievements: any;

  getEmployeeCareerGoals: any;
  addEmployeeCareerGoals: any;
  editEmployeeCareerGoals: any;
  deleteEmployeeCareerGoals: any;

  employeeLogin: any;
  logOut: any;
  employeeDetails: any;
  selectedId: any;

  qualificationsDocUpload: any;
  workAchievementsDocUpload: any;
  experienceDocUpload: any;
  employeeDetailsDocUpload: any;
  getStatesDetails: any;

  getProjectDetails: any;
  getProjectDetailsById: any;
  addProjectDetails: any;
  editProjectDetails: any;
  deleteProjectDetails: any;
  projectDetailsDocUpload: any;

  getProjectInvoiceDetails: any;
  addProjectInvoiceDetails: any;
  editProjectInvoiceDetails: any;
  projectInvoiceDetailsDocUpload: any;
  deleteProjectInvoiceDetails: any;

  getResourceAllocationDetails: any;
  getAllResourceAllocationDetails: any;
  addResourceAllocationDetails: any;
  editResourceAllocationDetails: any;
  deleteResourceAllocationDetails: any;
  getProjectDetailsByClient: any;
  getProjectDetailsByEmployeeId: any;

  getAllCompetencyDetails: any;
  getAllTechStackDetails: any;
  deleteCompetencyCoEDetails: any;
  addTechStackDetails: any;
  addBranchStackDetails: any;
  getTechStackByCompetencyDetails: any;
  deleteBranchStackDetails: null;
  addSubBranchDetails: null;
  getAllSubBranchDetails: any;
  addRequirementsDetails: any;
  editRequirementsDetails: any;
  deleteSubBranchStackDetails: any;
  addRateMyselfDetails: any;
  getAllCompetencyDevDetails: any;
  editCompetencyDevDetails: any;
  competencyDevPdpPlanUploadDetails: any;

  getAllCompetencyRequest: any;
  getAllsubDomain: any;
  updateStatusRequest: any;
  addEnrollmentData: any;
  getAllEnrollmentRequest: any;
  getStatusCount: any;
  getCountStatus: any;
  getAllProficiencyByTechIdDetails: any;
  getAllJobsDetails: any;
  addPostAJobDetails: any;
  jobDescriptionUploadDetails: any;
  BulkUploadDetails: any;
  resourceBulkUploadDetails: any;
  projectBulkUploadDetails: any;
  getJobByJobIdDetails: any;
  getAllInternalJobsDetails: any;
  addReferAResourceDetails: any;
  referResourceResumeUploadDetails: any;
  getAllReferralListDetails: any;
  editSkillLinksJobStatusDetails: any;
  getInternalJobByIdDetails: any;
  applyInternalJobsDetails: any;
  getAllInternallyAppliedJob: any;
  editApplicationStatusDetails: any;
  editSkillLinksReferResourceDetails: any;

  addInseedData: any;
  getInseedData: any;
  editInseedData: any;
  deleteInseedData: any;

  getInseedStatusCount: any;
  getAllInseedReport: any;

  addInseedResource: any;
  getInseedResource: any;
  editInseedResourceData: any;
  deleteInseedResource: any;
  inseedDocUpload: any;

  getJobCategoryDetails: any;
  addJobCategoryDetails: any;
  getAllCoachDetails: any;
  addBeACoachDetails: any;
  getAllCoachListDetails: any;
  addCoachRequestDetails: any;
  getAllCoachRequestDetails: any;
  getAllCoachReportCountDetails: any;
  getAllCoachReportDetails: any;
  updateStatusRemarksDetails: any;
  getAllSkillCoinsDetails: any;
  assignSkillCoinsDetails: any;
  assignmentLetterUploadDocDetails: any;
  deallocateSkillCoinsDetails: any;
  getFetchSkillCoinsDetails: any;
  editRedeemSkillCoinsDetails: any;
  getSkillCoinsReportDetails: any;
  getAllSessionByEmpIdDetails: any;
  coachEmployeeDocUploadDetails: any;
  getAllAcceptedCoachListDetails: any;
  getAllAcceptedEmployeeListDetails: any;
  addNewSessionDetails: any;
  ratingForSessionDetails: any;
  coachUpdateHwStatusDetails: any;
  uploadEmpHwDetails: any;
  getTrainingsByIdDetails: any;
  trainingPlanUploadDocDetails: any;
  createTrainingDetails: any;
  editTrainingPlanDetails: any;
  deleteTrainingModuleDetails: any;
  getEvaluationDetails: any;
  editEvaluationData: any;
  getDownloadPolicyData: any;
  fetchSearchData: any;
  getProficiencyByIdData: any;
  addClientNameDetails: any;
  getClientNameDetails: any;
  getCoachingReportPdfDetails: any;
  getAllNotification: any;
  editNotificationDetails: any;
  getInseedAllModulesDetails: any;
  getModulesByIdDetails: any;
  addInseedModuleDetails: any;
  editInseedModuleDetails: any;
  deleteInseedModuleDetails: any;
  addInseedAssessmentDetails: any;
  getAssessmentByIdDetails: any;
  editInseedAssessmentDetails: any;
  deleteInseedAssessmentDetails: any;
  getAllTrainingsDetails: any;
  getAllModulesByTrainingIdData: any;
  employeeBulkUploadDetails: any;
  postEmailNotificationDetails: any;
  getReportLessthan3DaysDetails: any;
  getReportGreaterthan3DaysDetails: any;
  EmployeeSkillsBulkUploadDetails: any;
  getSkillCoinValueDetails: any;
  updateSkillCoinValue: any;
  getSkillCoinsYearlyReportDetails: any;
  getTransactionHistory: any;
  getEmployeeMakerCheckerDetails: any;
  moduleBulkUploadDetails: any;
  uploadModuleSyllabusDetails: any;
  editBeACoachDetails: any;
  uploadProfileBeACoachDetails: any;
  getInvoicesByProjectIdDetails: any;
  getInvoicesByInvoiceIdDetails: any;
  getSkillCoinData: any;
  getBulkAssignSkillCoinData: any;
  getTechSpecReportDetails: any;
  getTechSpecDetails: any;
  getSkillcoinByEmpid: any;
  getSkillLinksEmployeeViewDetails: any;
  getApplicationDetailsViewDetails: any;
  resumeUploadDetails: any;
  viewUploadProfileBeACoachDetails: any;
  viewUploadResumeDetails: any;
  addPostAJobIJPDetails: any;
  getAllInternalJobsEmployeeViewDetails: any;
  editIJPJobStatusDetails: any;
  jobDescriptionDocumentUploadDetails: any;
  viewJobDescriptionDocDetails: any;
  IJPBulkUploadDetails: any;
  getApprovedProjectDetails: any;
  getApprovedInseedData: any;
  addInseedLocationData: any;
  getInseedLocationData: any;
  getCoachMasterListData: any;
  addLearningRequestData: any;
  checkMasterData: any;
  checkManagerData: any;
  managerRequestList: any;
  hrRequestList: any;
  learningDevDetails: any;
  financeRequestList: any;
  checkFinanceData: any;
  specificEmployeeReferredListDetails: any;
  allEmployeeReferralHistory: any;
  employeeDbCountryList: any;
  addEmployeeDbCountryList: any;
  checkCoachingRequestData: any;
  employeeDetailsImageUpload: any;
  getEligibleReferralPayouts: any;
  putEligibleReferralPayouts: any;
  getSkillCoinupload: any;
  addCompetencyDetails: any;
  getAllStreamsList: any;
  getAllSubStreamsList: any;
  getAllQuestionsList: any;
  addSelfAssessmentDetails: any;
  checkCompetencyDetails: any;
  getAssesssmentData: any;
  getAssesssmentlistById: any;
  getCerficateList: any;
  downloadCertificate: any;
  competancy_list: any;
  add_certificate: any;
  upload_certificate: any;
  get_stream_list: any;
  add_stream: any;
  update_stream: any;
  leader_list: any;
  add_bench: any;
  get_bench_table_api: any;
  get_project_history: any;
  complete_release: any;
  bulkApprove: any;
  getAllResourceExportApi: any;
  getGenerateResumeData: any;
  postProfileSummary: any;
  profileReminderApi: any;
  getBenchResourceApi: any;
  getAllEmployeeRoleManagementApi: any;
  getAllRolesApi: any;
  postUpdateRoles: any;
  deleteAllroles: any;
  getProjectHistoryByProjectId:any;
}

const initialState: typeStates = {
  getEmployeeData: null,
  getEmployeePagData: null,
  addEmployeeData: null,
  editEmployeeData: null,
  editEmployeePicture: null,
  deleteEmployeeData: null,

  getExperienceData: null,
  addExperienceData: null,
  editExperienceData: null,
  deleteExperienceData: null,
  value: 0,
  getEmployeeQualification: null,
  addEmployeeQualification: null,
  editEmployeeQualification: null,
  deleteEmployeeQualification: null,
  getEmployeeSkills: null,
  addEmployeeSkills: null,
  editEmployeeSkills: null,
  deleteEmployeeSkills: null,
  getEmployeeCertifications: null,
  addEmployeeCertifications: null,
  editEmployeeCertifications: null,
  deleteEmployeeCertifications: null,

  getEmployeeWorkAchievements: null,
  addEmployeeWorkAchievements: null,
  editEmployeeWorkAchievements: null,
  deleteEmployeeWorkAchievements: null,

  employeeLogin: null,
  logOut: null,
  employeeDetails: null,
  selectedId: null,

  getEmployeePreference: null,
  addEmployeePreference: null,
  editEmployeePreference: null,
  deleteEmployeePreference: null,

  getEmployeeCareerGoals: null,
  addEmployeeCareerGoals: null,
  editEmployeeCareerGoals: null,
  deleteEmployeeCareerGoals: null,

  qualificationsDocUpload: null,
  workAchievementsDocUpload: null,
  experienceDocUpload: null,
  employeeDetailsDocUpload: null,

  getStatesDetails: null,

  getProjectDetails: null,
  getProjectDetailsById: null,
  addProjectDetails: null,
  editProjectDetails: null,
  deleteProjectDetails: null,
  projectDetailsDocUpload: null,

  getProjectInvoiceDetails: null,
  addProjectInvoiceDetails: null,
  editProjectInvoiceDetails: null,
  projectInvoiceDetailsDocUpload: null,
  deleteProjectInvoiceDetails: null,

  getResourceAllocationDetails: null,
  getAllResourceAllocationDetails: null,
  addResourceAllocationDetails: null,
  editResourceAllocationDetails: null,
  deleteResourceAllocationDetails: null,
  getProjectDetailsByClient: null,
  getProjectDetailsByEmployeeId: null,

  getAllCompetencyDetails: null,
  getAllTechStackDetails: null,
  deleteCompetencyCoEDetails: null,

  addTechStackDetails: null,
  addBranchStackDetails: null,
  deleteBranchStackDetails: null,
  getTechStackByCompetencyDetails: null,
  addSubBranchDetails: null,
  getAllSubBranchDetails: null,
  addRequirementsDetails: null,
  editRequirementsDetails: null,
  deleteSubBranchStackDetails: null,
  addRateMyselfDetails: null,

  getAllCompetencyRequest: null,
  getAllsubDomain: null,
  addEnrollmentData: null,
  getAllEnrollmentRequest: null,
  updateStatusRequest: null,
  getStatusCount: null,

  getAllCompetencyDevDetails: null,
  editCompetencyDevDetails: null,
  competencyDevPdpPlanUploadDetails: null,
  getCountStatus: null,
  getAllProficiencyByTechIdDetails: null,
  getAllInternalJobsDetails: null,

  getAllJobsDetails: null,

  jobDescriptionUploadDetails: null,
  BulkUploadDetails: null,
  projectBulkUploadDetails: null,
  resourceBulkUploadDetails: null,
  getJobByJobIdDetails: null,
  addReferAResourceDetails: null,
  referResourceResumeUploadDetails: null,
  getAllReferralListDetails: null,
  editSkillLinksJobStatusDetails: null,
  getInternalJobByIdDetails: null,
  applyInternalJobsDetails: null,
  getAllInternallyAppliedJob: null,
  editApplicationStatusDetails: null,
  editSkillLinksReferResourceDetails: null,

  addInseedData: null,
  getInseedData: null,
  editInseedData: null,
  deleteInseedData: null,

  getInseedStatusCount: null,
  getAllInseedReport: null,

  addInseedResource: null,
  getInseedResource: null,
  editInseedResourceData: null,
  deleteInseedResource: null,
  inseedDocUpload: null,

  getJobCategoryDetails: null,
  addJobCategoryDetails: null,
  getAllCoachDetails: null,
  addBeACoachDetails: null,
  getAllCoachListDetails: null,
  addCoachRequestDetails: null,
  getAllCoachRequestDetails: null,
  getAllCoachReportCountDetails: null,
  getAllCoachReportDetails: null,
  updateStatusRemarksDetails: null,
  getAllSkillCoinsDetails: null,
  assignSkillCoinsDetails: null,
  assignmentLetterUploadDocDetails: null,
  deallocateSkillCoinsDetails: null,
  getFetchSkillCoinsDetails: null,
  editRedeemSkillCoinsDetails: null,
  getSkillCoinsReportDetails: null,
  getAllSessionByEmpIdDetails: null,
  coachEmployeeDocUploadDetails: null,
  getAllAcceptedCoachListDetails: null,
  getAllAcceptedEmployeeListDetails: null,
  addNewSessionDetails: null,
  ratingForSessionDetails: null,
  coachUpdateHwStatusDetails: null,
  uploadEmpHwDetails: null,
  getTrainingsByIdDetails: null,
  trainingPlanUploadDocDetails: null,
  createTrainingDetails: null,
  editTrainingPlanDetails: null,
  deleteTrainingModuleDetails: null,
  getEvaluationDetails: null,
  editEvaluationData: null,
  getDownloadPolicyData: null,
  fetchSearchData: null,
  getProficiencyByIdData: null,
  addClientNameDetails: null,
  getClientNameDetails: null,
  getCoachingReportPdfDetails: null,
  getAllNotification: null,
  editNotificationDetails: null,
  getInseedAllModulesDetails: null,
  getModulesByIdDetails: null,
  addInseedModuleDetails: null,
  editInseedModuleDetails: null,
  deleteInseedModuleDetails: null,
  addInseedAssessmentDetails: null,
  getAssessmentByIdDetails: null,
  editInseedAssessmentDetails: null,
  deleteInseedAssessmentDetails: null,
  getAllTrainingsDetails: null,
  getAllModulesByTrainingIdData: null,
  employeeBulkUploadDetails: null,
  postEmailNotificationDetails: null,
  getReportLessthan3DaysDetails: null,
  getReportGreaterthan3DaysDetails: null,
  EmployeeSkillsBulkUploadDetails: null,
  getSkillCoinValueDetails: null,
  updateSkillCoinValue: null,
  getSkillCoinsYearlyReportDetails: null,
  getTransactionHistory: null,
  getEmployeeMakerCheckerDetails: null,
  moduleBulkUploadDetails: null,
  uploadModuleSyllabusDetails: null,
  editBeACoachDetails: null,
  uploadProfileBeACoachDetails: null,
  getInvoicesByProjectIdDetails: null,
  getInvoicesByInvoiceIdDetails: null,
  getSkillCoinData: null,
  getBulkAssignSkillCoinData: null,
  getTechSpecReportDetails: null,
  getTechSpecDetails: null,
  getSkillcoinByEmpid: null,
  getSkillLinksEmployeeViewDetails: null,
  getApplicationDetailsViewDetails: null,
  resumeUploadDetails: null,
  addPostAJobDetails: null,
  viewUploadProfileBeACoachDetails: null,
  viewUploadResumeDetails: null,
  addPostAJobIJPDetails: null,
  getAllInternalJobsEmployeeViewDetails: null,
  editIJPJobStatusDetails: null,
  jobDescriptionDocumentUploadDetails: null,
  viewJobDescriptionDocDetails: null,
  IJPBulkUploadDetails: null,
  getApprovedProjectDetails: null,
  getApprovedInseedData: null,
  addInseedLocationData: null,
  getInseedLocationData: null,
  getCoachMasterListData: null,
  addLearningRequestData: null,
  checkManagerData: null,
  checkMasterData: null,
  managerRequestList: null,
  hrRequestList: null,
  learningDevDetails: null,
  financeRequestList: null,
  checkFinanceData: null,
  specificEmployeeReferredListDetails: null,
  allEmployeeReferralHistory: null,
  employeeDbCountryList: null,
  addEmployeeDbCountryList: null,
  checkCoachingRequestData: null,
  employeeDetailsImageUpload: null,
  getEligibleReferralPayouts: null,
  putEligibleReferralPayouts: null,
  getSkillCoinupload: null,
  addCompetencyDetails: null,
  getAllStreamsList: null,
  getAllSubStreamsList: null,
  getAllQuestionsList: null,
  addSelfAssessmentDetails: null,
  checkCompetencyDetails: null,
  getAssesssmentData: null,
  getAssesssmentlistById: null,
  getCerficateList: null,
  downloadCertificate: null,
  competancy_list: null,
  add_certificate: null,
  upload_certificate: null,
  get_stream_list: null,
  add_stream: null,
  update_stream: null,
  leader_list: null,
  add_bench: null,
  get_bench_table_api: null,
  get_project_history: null,
  complete_release: null,
  bulkApprove: null,
  getAllResourceExportApi: null,
  getGenerateResumeData: null,
  postProfileSummary: null,
  profileReminderApi: null,
  getBenchResourceApi: null,
  getAllEmployeeRoleManagementApi: null,
  getAllRolesApi: null,
  postUpdateRoles: null,
  deleteAllroles: null,
  getProjectHistoryByProjectId:null,
};

const Reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case actionTypes.GET_EMPLOYEE_DATA:
      return { ...state, getEmployeeData: action.payload };

    case actionTypes.GET_EMPLOYEE_DATA_PAGINATION:
      return { ...state, getEmployeePagData: action.payload };

    case actionTypes.ADD_EMPLOYEE_DATA:
      return { ...state, addEmployeeData: action.payload };

    case actionTypes.EDIT_EMPLOYEE_DATA:
      return { ...state, editEmployeeData: action.payload };

    case actionTypes.EDIT_EMPLOYEE_PICTURE:
      return { ...state, editEmployeePicture: action.payload };

    case actionTypes.DELETE_EMPLOYEE_DATA:
      return { ...state, deleteEmployeeData: action.payload };

    case actionTypes.GET_EMPLOYEE_QUALIFICATION:
      return { ...state, getEmployeeQualification: action.payload };

    case actionTypes.ADD_EMPLOYEE_QUALIFICATION:
      return { ...state, addEmployeeQualification: action.payload };

    case actionTypes.EDIT_EMPLOYEE_QUALIFICATION:
      return { ...state, editEmployeeQualification: action.payload };

    case actionTypes.DELETE_EMPLOYEE_QUALIFICATION:
      return { ...state, deleteEmployeeQualification: action.payload };

    case actionTypes.GET_EMPLOYEE_SKILLS:
      return { ...state, getEmployeeSkills: action.payload };

    case actionTypes.ADD_EMPLOYEE_SKILLS:
      return { ...state, addEmployeeSkills: action.payload };

    case actionTypes.EDIT_EMPLOYEE_SKILLS:
      return { ...state, editEmployeeSkills: action.payload };

    case actionTypes.DELETE_EMPLOYEE_SKILLS:
      return { ...state, deleteEmployeeSkills: action.payload };

    case actionTypes.GET_EMPLOYEE_CERTIFICATIONS:
      return { ...state, getEmployeeCertifications: action.payload };

    case actionTypes.ADD_EMPLOYEE_CERTIFICATIONS:
      return { ...state, addEmployeeCertifications: action.payload };

    case actionTypes.EDIT_EMPLOYEE_CERTIFICATIONS:
      return { ...state, editEmployeeCertifications: action.payload };

    case actionTypes.DELETE_EMPLOYEE_CERTIFICATIONS:
      return { ...state, deleteEmployeeCertifications: action.payload };

    case actionTypes.GET_EMPLOYEE_WORKACHIEVEMENTS:
      return { ...state, getEmployeeWorkAchievements: action.payload };

    case actionTypes.ADD_EMPLOYEE_WORKACHIEVEMENTS:
      return { ...state, addEmployeeWorkAchievements: action.payload };

    case actionTypes.EDIT_EMPLOYEE_WORKACHIEVEMENTS:
      return { ...state, editEmployeeWorkAchievements: action.payload };

    case actionTypes.DELETE_EMPLOYEE_WORKACHIEVEMENTS:
      return { ...state, deleteEmployeeWorkAchievements: action.payload };

    case actionTypes.GET_EXPERIENCE_DATA:
      return { ...state, getExperienceData: action.payload };

    case actionTypes.ADD_EXPERIENCE_DATA:
      return { ...state, addExperienceData: action.payload };

    case actionTypes.EDIT_EXPERIENCE_DATA:
      return { ...state, editExperienceData: action.payload };

    case actionTypes.DELETE_EXPERIENCE_DATA:
      return { ...state, deleteExperienceData: action.payload };

    case actionTypes.USER_LOGIN:
      return {
        ...state,
        employeeLogin: action.payload,
        selectedId: action.payload.data.id,
      };
    case actionTypes.LOG_OUT:
      return {
        ...state,
        logOut: action.payload,
      };
    case actionTypes.UPDATE_SELECTED_ID:
      return {
        ...state,
        selectedId: action.payload,
      };
    case actionTypes.EMPLOYEE_DETAILS:
      return { ...state, employeeDetails: action.payload };

    case actionTypes.GET_EMPLOYEE_PREFERENCE:
      return { ...state, getEmployeePreference: action.payload };

    case actionTypes.ADD_EMPLOYEE_PREFERENCE:
      return { ...state, addEmployeePreference: action.payload };

    case actionTypes.EDIT_EMPLOYEE_PREFERENCE:
      return { ...state, editEmployeePreference: action.payload };

    case actionTypes.DELETE_EMPLOYEE_PREFERENCE:
      return { ...state, deleteEmployeePreference: action.payload };

    case actionTypes.GET_EMPLOYEE_CAREERGOALS:
      return { ...state, getEmployeeCareerGoals: action.payload };

    case actionTypes.ADD_EMPLOYEE_CAREERGOALS:
      return { ...state, addEmployeeCareerGoals: action.payload };

    case actionTypes.EDIT_EMPLOYEE_CAREERGOALS:
      return { ...state, editEmployeeCareerGoals: action.payload };

    case actionTypes.DELETE_EMPLOYEE_CAREERGOALS:
      return { ...state, deleteEmployeeCareerGoals: action.payload };

    case actionTypes.QUALIFICATION_DOC_UPLOAD:
      return { ...state, qualificationsDocUpload: action.payload };

    case actionTypes.EXPERIENCE_DOC_UPLOAD:
      return { ...state, experienceDocUpload: action.payload };

    case actionTypes.WORKACHIEVEMENTS_DOC_UPLOAD:
      return { ...state, workAchievemntsDocUpload: action.payload };

    case actionTypes.EMPLOYEE_DETAILS_DOC_UPLOAD:
      return { ...state, employeeDetailsDocUpload: action.payload };

    case actionTypes.EMPLOYEE_DETAILS_IMAGE_UPLOAD:
      return { ...state, employeeDetailsImageUpload: action.payload };

    case actionTypes.GET_STATES_DETAILS:
      return { ...state, getStatesDetails: action.payload };

    case actionTypes.LOG_OUT:
      return { state: initialState };

    case actionTypes.GET_PROJECT_REPOSITORY:
      return { ...state, getProjectDetails: action.payload };
    case actionTypes.GET_PROJECT_REPOSITORY_BYID:
      return { ...state, getProjectDetailsById: action.payload };
    case actionTypes.ADD_PROJECT_REPOSITORY:
      return { ...state, addProjectDetails: action.payload };

    case actionTypes.EDIT_PROJECT_REPOSITORY:
      return { ...state, editProjectDetails: action.payload };

    case actionTypes.DELETE_PROJECT_REPOSITORY:
      return { ...state, deleteProjectDetails: action.payload };

    case actionTypes.PROJECT_DETAILS_DOC_UPLOAD:
      return { ...state, projectDetailsDocUpload: action.payload };

    case actionTypes.GET_PROJECT_REPOSITORY_INVOICE:
      return { ...state, getProjectInvoiceDetails: action.payload };

    case actionTypes.ADD_PROJECT_REPOSITORY_INVOICE:
      return { ...state, addProjectInvoiceDetails: action.payload };

    case actionTypes.EDIT_PROJECT_REPOSITORY_INVOICE:
      return { ...state, editProjectInvoiceDetails: action.payload };

    case actionTypes.PROJECT_INVOICE_DETAILS_DOC_UPLOAD:
      return { ...state, projectInvoiceDetailsDocUpload: action.payload };

    case actionTypes.DELETE_PROJECT_REPOSITORY_INVOICE:
      return { ...state, deleteProjectInvoiceDetails: action.payload };

    case actionTypes.GET_RESOURCE_ALLOCATION:
      return { ...state, getResourceAllocationDetails: action.payload };

    case actionTypes.GET_ALL_RESOURCE_ALLOCATION:
      return { ...state, getAllResourceAllocationDetails: action.payload };

    case actionTypes.ADD_RESOURCE_ALLOCATION:
      return { ...state, addResourceAllocationDetails: action.payload };

    case actionTypes.EDIT_RESOURCE_ALLOCATION:
      return { ...state, editResourceAllocationDetails: action.payload };

    case actionTypes.DELETE_RESOURCE_ALLOCATION:
      return { ...state, deleteResourceAllocationDetails: action.payload };

    case actionTypes.GET_PROJECT_BY_CLIENT:
      return { ...state, getProjectDetailsByClient: action.payload };

    case actionTypes.GET_PROJECT_DETAILS_BY_EMPLOYEEID:
      return { ...state, getProjectDetailsByEmployeeId: action.payload };

    case actionTypes.GET_ALL_COMPETENCY:
      return { ...state, getAllCompetencyDetails: action.payload };

    case actionTypes.GET_ALL_TECH_STACK:
      return { ...state, getAllTechStackDetails: action.payload };

    case actionTypes.DELETE_COMPETENCY_COE:
      return { ...state, deleteCompetencyCoEDetails: action.payload };

    case actionTypes.GET_TECH_STACK_BY_COMPETENCY:
      return { ...state, getTechStackByCompetencyDetails: action.payload };

    case actionTypes.ADD_TECH_STACK:
      return { ...state, addTechStackDetails: action.payload };

    case actionTypes.ADD_BRANCH_STACK:
      return { ...state, addBranchStackDetails: action.payload };

    case actionTypes.DELETE_BRANCH_STACK:
      return { ...state, deleteBranchStackDetails: action.payload };

    case actionTypes.ADD_SUB_BRANCH:
      return { ...state, addSubBranchDetails: action.payload };

    case actionTypes.GET_ALL_SUB_BRANCH:
      return { ...state, getAllSubBranchDetails: action.payload };

    case actionTypes.ADD_REQUIREMENTS:
      return { ...state, addRequirementsDetails: action.payload };

    case actionTypes.EDIT_REQUIREMENTS:
      return { ...state, editRequirementsDetails: action.payload };

    case actionTypes.DELETE_SUB_BRANCH_STACK:
      return { ...state, deleteSubBranchStackDetails: action.payload };

    case actionTypes.ADD_RATE_MYSELF:
      return { ...state, addRateMyselfDetails: action.payload };

    case actionTypes.GET_ALL_COMPETENCY_DEV:
      return { ...state, getAllCompetencyDevDetails: action.payload };

    case actionTypes.EDIT_COMPETENCY_DEV:
      return { ...state, editCompetencyDevDetails: action.payload };

    case actionTypes.COMPETENCY_DEV_PDP_PLAN_UPLOAD:
      return { ...state, competencyDevPdpPlanUploadDetails: action.payload };

    case actionTypes.GET_ALL_COMPETENCY_REQUESTS:
      return { ...state, getAllCompetencyRequest: action.payload };

    case actionTypes.GET_ALL_SUBDOMAIN:
      return { ...state, getAllsubDomain: action.payload };

    case actionTypes.ADD_ENROLLMENT_DATA:
      return { ...state, addEnrollmentData: action.payload };

    case actionTypes.GET_ALL_ENROLLMENT_REQUEST:
      return { ...state, getAllEnrollmentRequest: action.payload };

    case actionTypes.UPDATE_STATUS_REQUEST:
      return { ...state, updateStatusRequest: action.payload };

    case actionTypes.GET_ALL_JOBS:
      return { ...state, getAllJobsDetails: action.payload };

    case actionTypes.ADD_POST_A_JOB:
      return { ...state, addPostAJobDetails: action.payload };

    case actionTypes.JOB_DESCRIPTION_UPLOAD:
      return { ...state, jobDescriptionUploadDetails: action.payload };

    case actionTypes.BULK_UPLOAD:
      return { ...state, BulkUploadDetails: action.payload };

    case actionTypes.RESOURCE_BULK_UPLOAD:
      return { ...state, resourceBulkUploadDetails: action.payload };

    case actionTypes.PROJECT_BULK_UPLOAD:
      return { ...state, projectBulkUploadDetails: action.payload };

    case actionTypes.GET_JOB_BY_JOB_ID:
      return { ...state, getJobByJobIdDetails: action.payload };

    case actionTypes.GET_STATUS_COUNT:
      return { ...state, getStatusCount: action.payload };

    case actionTypes.GET_COUNT_STATUS:
      return { ...state, getCountStatus: action.payload };

    case actionTypes.GET_ALL_PROFICIENCY_BY_TECH_ID:
      return { ...state, getAllProficiencyByTechIdDetails: action.payload };

    case actionTypes.GET_ALL_INTERNAL_JOBS:
      return { ...state, getAllInternalJobsDetails: action.payload };

    case actionTypes.ADD_REFER_A_RESOURCE:
      return { ...state, addReferAResourceDetails: action.payload };

    case actionTypes.REFER_RESOURCE_RESUME_UPLOAD:
      return { ...state, referResourceResumeUploadDetails: action.payload };

    case actionTypes.GET_ALL_REFERRAL_LIST:
      return { ...state, getAllReferralListDetails: action.payload };

    case actionTypes.EDIT_SKILL_LINKS_JOB_STATUS:
      return { ...state, editSkillLinksJobStatusDetails: action.payload };

    case actionTypes.ADD_INSEED_DATA:
      return { ...state, addInseedData: action.payload };

    case actionTypes.GET_INSEED_DATA:
      return { ...state, getInseedData: action.payload };

    case actionTypes.EDIT_INSEED_DATA:
      return { ...state, editInseedData: action.payload };

    case actionTypes.DELETE_INSEED_DATA:
      return { ...state, deleteInseedData: action.payload };

    case actionTypes.GET_INSEED_STATUS_COUNT:
      return { ...state, getInseedStatusCount: action.payload };

    case actionTypes.GET_ALL_INSEED_REPORT:
      return { ...state, getAllInseedReport: action.payload };

    case actionTypes.ADD_INSEED_RESOURCE:
      return { ...state, addInseedResource: action.payload };

    case actionTypes.GET_INSEED_RESOURCE:
      return { ...state, getInseedResource: action.payload };

    case actionTypes.EDIT_INSEED_RESOURCE_DATA:
      return { ...state, editInseedResourceData: action.payload };

    case actionTypes.DELETE_INSEED_RESOURCE:
      return { ...state, deleteInseedResource: action.payload };

    case actionTypes.INSEED_DOC_UPLOAD:
      return { ...state, inseedDocUpload: action.payload };

    case actionTypes.GET_INTERNAL_JOB_BY_ID:
      return { ...state, getInternalJobByIdDetails: action.payload };

    case actionTypes.APPLY_INTERNAL_JOBS:
      return { ...state, applyInternalJobsDetails: action.payload };

    case actionTypes.GET_INTERNALLY_APPLIED_JOB:
      return { ...state, getAllInternallyAppliedJob: action.payload };

    case actionTypes.EDIT_APPLICATION_STATUS:
      return { ...state, editApplicationStatusDetails: action.payload };

    case actionTypes.EDIT_SKILL_LINKS_REFER_RESOURCE:
      return { ...state, editSkillLinksReferResourceDetails: action.payload };

    case actionTypes.GET_JOB_CATEGORY:
      return { ...state, getJobCategoryDetails: action.payload };

    case actionTypes.ADD_JOB_CATEGORY:
      return { ...state, addJobCategoryDetails: action.payload };

    case actionTypes.GET_ALL_COACH:
      return { ...state, getAllCoachDetails: action.payload };

    case actionTypes.ADD_BE_A_COACH:
      return { ...state, addBeACoachDetails: action.payload };

    case actionTypes.GET_ALL_SKILL_COINS:
      return { ...state, getAllSkillCoinsDetails: action.payload };

    case actionTypes.ASSIGN_SKILL_COINS:
      return { ...state, assignSkillCoinsDetails: action.payload };

    case actionTypes.ASSIGNMENT_LETTER_UPLOAD_DOC:
      return { ...state, assignmentLetterUploadDocDetails: action.payload };

    case actionTypes.DEALLOCATE_SKILL_COINS:
      return { ...state, deallocateSkillCoinsDetails: action.payload };

    case actionTypes.GET_FETCH_SKILL_COINS:
      return { ...state, getFetchSkillCoinsDetails: action.payload };

    case actionTypes.EDIT_REDEEM_SKILL_COINS:
      return { ...state, editRedeemSkillCoinsDetails: action.payload };

    case actionTypes.GET_SKILL_COINS_REPORT:
      return { ...state, getSkillCoinsReportDetails: action.payload };

    case actionTypes.GET_ALL_COACH_LIST:
      return { ...state, getAllCoachListDetails: action.payload };

    case actionTypes.ADD_COACH_REQUEST:
      return { ...state, addCoachRequestDetails: action.payload };

    case actionTypes.GET_ALL_COACH_REQUEST:
      return { ...state, getAllCoachRequestDetails: action.payload };

    case actionTypes.GET_ALL_COACH_REPORT_COUNT:
      return { ...state, getAllCoachReportCountDetails: action.payload };

    case actionTypes.GET_ALL_COACH_REPORT:
      return { ...state, getAllCoachReportDetails: action.payload };

    case actionTypes.UPDATE_STATUS_REMARKS:
      return { ...state, updateStatusRemarksDetails: action.payload };

    case actionTypes.GET_TRAININGS_BY_ID:
      return { ...state, getTrainingsByIdDetails: action.payload };

    case actionTypes.GET_ALL_ACCEPTED_COACH_LIST:
      return { ...state, getAllAcceptedCoachListDetails: action.payload };

    case actionTypes.GET_ALL_ACCEPTED_EMPLOYEE_LIST:
      return { ...state, getAllAcceptedEmployeeListDetails: action.payload };

    case actionTypes.GET_ALL_SESSION_BY_EMPID:
      return { ...state, getAllSessionByEmpIdDetails: action.payload };

    case actionTypes.COACH_EMPLOYEE_DOC_UPLOAD:
      return { ...state, coachEmployeeDocUploadDetails: action.payload };

    case actionTypes.ADD_NEW_SESSION:
      return { ...state, addNewSessionDetails: action.payload };

    case actionTypes.RATING_FOR_SESSION:
      return { ...state, ratingForSessionDetails: action.payload };

    case actionTypes.COACH_UPDATE_HW_STATUS:
      return { ...state, coachUpdateHwStatusDetails: action.payload };

    case actionTypes.UPLOAD_EMP_HW:
      return { ...state, uploadEmpHwDetails: action.payload };

    case actionTypes.GET_TRAININGS_BY_ID:
      return { ...state, getTrainingsByIdDetails: action.payload };

    case actionTypes.CREATE_TRAINING:
      return { ...state, createTrainingDetails: action.payload };

    case actionTypes.TRAINING_PLAN_UPLOAD_DOC:
      return { ...state, trainingPlanUploadDocDetails: action.payload };

    case actionTypes.EDIT_TRAINING_PLAN:
      return { ...state, editTrainingPlanDetails: action.payload };

    case actionTypes.DELETE_TRAINING_MODULE:
      return { ...state, deleteTrainingModuleDetails: action.payload };

    case actionTypes.GET_EVALUATION_DETAILS:
      return { ...state, getEvaluationDetails: action.payload };

    case actionTypes.EDIT_EVALUATION_DATA:
      return { ...state, editEvaluationData: action.payload };

    case actionTypes.GET_DOWNLOAD_POLICY:
      return { ...state, getDownloadPolicyData: action.payload };

    case actionTypes.FETCH_SEARCH_DATA:
      return { ...state, fetchSearchData: action.payload };

    case actionTypes.GET_PROFICIENCY_BY_ID:
      return { ...state, getProficiencyByIdData: action.payload };

    case actionTypes.ADD_CLIENT_NAME:
      return { ...state, addClientNameDetails: action.payload };

    case actionTypes.GET_CLIENT_NAME:
      return { ...state, getClientNameDetails: action.payload };

    case actionTypes.GET_COACHING_REPORT_PDF:
      return { ...state, getCoachingReportPdfDetails: action.payload };

    case actionTypes.GET_ALL_NOTIFICATION:
      return { ...state, getAllNotification: action.payload };

    case actionTypes.EDIT_NOTIFICATION_DETAILS:
      return { ...state, editNotificationDetails: action.payload };

    case actionTypes.GET_ALL_INSEED_MODULES:
      return { ...state, getInseedAllModulesDetails: action.payload };

    case actionTypes.ADD_INSEED_MODULE:
      return { ...state, addInseedModuleDetails: action.payload };

    case actionTypes.GET_INSEED_MODULES_BY_ID:
      return { ...state, getModulesByIdDetails: action.payload };

    case actionTypes.EDIT_INSEED_MODULE:
      return { ...state, editInseedModuleDetails: action.payload };

    case actionTypes.DELETE_INSEED_MODULE:
      return { ...state, deleteInseedModuleDetails: action.payload };

    case actionTypes.ADD_INSEED_ASSESSMENT:
      return { ...state, addInseedAssessmentDetails: action.payload };

    case actionTypes.GET_INSEED_ASSESSMENT_BY_ID:
      return { ...state, getAssessmentByIdDetails: action.payload };

    case actionTypes.EDIT_INSEED_ASSESSMENT:
      return { ...state, editInseedAssessmentDetails: action.payload };

    case actionTypes.DELETE_INSEED_ASSESSMENT:
      return { ...state, deleteInseedAssessmentDetails: action.payload };

    case actionTypes.GET_ALL_TRAININGS:
      return { ...state, getAllTrainingsDetails: action.payload };

    case actionTypes.GET_ALL_MODULES_BY_TRAINING_ID:
      return { ...state, getAllModulesByTrainingIdData: action.payload };

    case actionTypes.EMPLOYEE_BULK_UPLOAD:
      return { ...state, employeeBulkUploadDetails: action.payload };

    case actionTypes.POST_EMAIL_NOTIFICATION:
      return { ...state, postEmailNotificationDetails: action.payload };

    case actionTypes.GET_REPORT_LESSTHAN_3_DAYS:
      return { ...state, getReportLessthan3DaysDetails: action.payload };

    case actionTypes.GET_REPORT_GREATERTHAN_3_DAYS:
      return { ...state, getReportGreaterthan3DaysDetails: action.payload };

    case actionTypes.EMPLOYEE_SKILLS_BULK_UPLOAD:
      return { ...state, EmployeeSkillsBulkUploadDetails: action.payload };

    case actionTypes.GET_SKILL_COIN_VALUE:
      return { ...state, getSkillCoinValueDetails: action.payload };

    case actionTypes.UPDATE_SKILL_COIN_VALUE:
      return { ...state, updateSkillCoinValue: action.payload };

    case actionTypes.GET_SKILL_COINS_YEARLY_REPORT:
      return { ...state, getSkillCoinsYearlyReportDetails: action.payload };

    case actionTypes.GET_TRANSACTION_HISTORY:
      return { ...state, getTransactionHistory: action.payload };

    case actionTypes.GET_EMPLOYEE_MAKER_CHECKER_DETAILS:
      return { ...state, getEmployeeMakerCheckerDetails: action.payload };

    case actionTypes.MODULE_BULK_UPLOAD:
      return { ...state, moduleBulkUploadDetails: action.payload };

    case actionTypes.UPLOAD_MODULE_SYLLABUS_DETAILS:
      return { ...state, uploadModuleSyllabusDetails: action.payload };

    case actionTypes.EDIT_BE_A_COACH_DETAILS:
      return { ...state, editBeACoachDetails: action.payload };

    case actionTypes.UPLOAD_PROFILE_BE_A_COACH:
      return { ...state, uploadProfileBeACoachDetails: action.payload };

    case actionTypes.VIEW_UPLOAD_PROFILE_BE_A_COACH:
      return { ...state, viewUploadProfileBeACoachDetails: action.payload };

    case actionTypes.GET_INVOICES_BY_PROJECT_ID:
      return { ...state, getInvoicesByProjectIdDetails: action.payload };

    case actionTypes.GET_INVOICES_BY_INVOICE_ID:
      return { ...state, getInvoicesByInvoiceIdDetails: action.payload };

    case actionTypes.GET_SKILL_COINS_DATA:
      return { ...state, getSkillCoinData: action.payload };

    case actionTypes.GET_BULK_ASSIGN_SKILL_COINS_DATA:
      return { ...state, getBulkAssignSkillCoinData: action.payload };

    case actionTypes.GET_SkillCOIN_BY_EMPID:
      return { ...state, getSkillcoinByEmpid: action.payload };

    case actionTypes.GET_TECH_SPEC_REPORT_DETAILS:
      return { ...state, getTechSpecReportDetails: action.payload };

    case actionTypes.GET_TECH_SPEC_DETAILS:
      return { ...state, getTechSpecDetails: action.payload };

    case actionTypes.GET_SKILL_LINKS_EMPLOYEE_VIEW_DETAILS:
      return { ...state, getSkillLinksEmployeeViewDetails: action.payload };

    case actionTypes.GET_APPLICATION_DETAILS_DATA:
      return { ...state, getApplicationDetailsViewDetails: action.payload };

    case actionTypes.RESUME_UPLOAD:
      return { ...state, resumeUploadDetails: action.payload };

    case actionTypes.VIEW_UPLOAD_PROFILE_RESUME:
      return { ...state, viewUploadResumeDetails: action.payload };

    case actionTypes.ADD_POST_A_JOB_IJP:
      return { ...state, addPostAJobIJPDetails: action.payload };

    case actionTypes.GET_ALL_INTERNAL_JOBS_EMPLOYEE_VIEW:
      return {
        ...state,
        getAllInternalJobsEmployeeViewDetails: action.payload,
      };

    case actionTypes.EDIT_IJP_JOB_STATUS:
      return { ...state, editIJPJobStatusDetails: action.payload };

    case actionTypes.JOB_DESCRIPTION:
      return { ...state, jobDescriptionDocumentUploadDetails: action.payload };

    case actionTypes.VIEW_JOB_DESCRIPTION:
      return { ...state, viewJobDescriptionDocDetails: action.payload };

    case actionTypes.IJP_BULK_UPLOAD:
      return { ...state, IJPBulkUploadDetails: action.payload };

    case actionTypes.GET_RESOURCE_ALLOCATION_APPROVED:
      return { ...state, getApprovedProjectDetails: action.payload };

    case actionTypes.GET_APPROVED_INSEED_DATA:
      return { ...state, getApprovedInseedData: action.payload };

    case actionTypes.ADD_INSEED_LOCATION_DATA:
      return { ...state, addInseedLocationData: action.payload };

    case actionTypes.GET_INSEED_LOCATION_DATA:
      return { ...state, getInseedLocationData: action.payload };

    case actionTypes.GET_COACH_MASTER_LIST:
      return { ...state, getCoachMasterListData: action.payload };

    case actionTypes.ADD_LEARNING_DATA:
      return { ...state, addLearningRequestData: action.payload };

    case actionTypes.CHECK_LEARNING_MASTER:
      return { ...state, addLearningRequestData: action.payload };

    case actionTypes.CHECK_LEARNING_MANAGER:
      return { ...state, addLearningRequestData: action.payload };

    case actionTypes.MANAGER_REQUEST_LIST:
      return { ...state, managerRequestList: action.payload };

    case actionTypes.HR_REQUEST_LIST:
      return { ...state, hrRequestList: action.payload };

    case actionTypes.CHECK_FINANCE_MASTER:
      return { ...state, checkFinanceData: action.payload };

    case actionTypes.FINANCE_REQUEST_LIST:
      return { ...state, financeRequestList: action.payload };

    case actionTypes.LEARNING_DEV_DETAILS:
      return { ...state, learningDevDetails: action.payload };

    case actionTypes.SPECIFIC_EMPLOYEE_REFERRED_LIST:
      return { ...state, specificEmployeeReferredListDetails: action.payload };

    case actionTypes.ALL_EMPLOYEE_REFERRAL_HISTORY:
      return { ...state, allEmployeeReferralHistory: action.payload };

    case actionTypes.EMPLOYEE_COUNTRY_LIST:
      return { ...state, employeeDbCountryList: action.payload };

    case actionTypes.ADD_EMPLOYEE_COUNTRY_LIST:
      return { ...state, addEmployeeDbCountryList: action.payload };

    case actionTypes.GET_EMPLOYEE_HRBP_LIST:
      return { ...state, getEmployeeDbHrbpList: action.payload };

    case actionTypes.CHECK_COACHING_REQUEST_LIST:
      return { ...state, checkCoachingRequestData: action.payload };

    case actionTypes.GET_ELIGIBLE_REFERRAL_PAYOUTS:
      return { ...state, getEligibleReferralPayouts: action.payload };

    case actionTypes.PUT_ELIGIBLE_REFERRAL_PAYOUTS:
      return { ...state, putEligibleReferralPayouts: action.payload };

    case actionTypes.GET_SKILLCOIN_UPLOAD:
      return { ...state, getSkillCoinupload: action.payload };

    case actionTypes.ADD_COMPETENCY:
      return { ...state, addCompetencyDetails: action.payload };

    case actionTypes.ALL_STREAMS:
      return { ...state, getAllStreamsList: action.payload };

    case actionTypes.ALL_SUB_STREAMS:
      return { ...state, getAllSubStreamsList: action.payload };

    case actionTypes.ALL_QUESTIONS:
      return { ...state, getAllQuestionsList: action.payload };

    case actionTypes.ADD_SELF_ASSESSMENT:
      return { ...state, addSelfAssessmentDetails: action.payload };

    case actionTypes.CHECK_COMPETENCY:
      return { ...state, checkCompetencyDetails: action.payload };

    case actionTypes.GET_ASSESSMENT_DATA:
      return { ...state, getAssesssmentData: action.payload };

    case actionTypes.GET_ASSESSMENT_LIST_BYID:
      return { ...state, getAssesssmentlistById: action.payload };

    case actionTypes.GET_CERTIFICTIONS_LIST:
      return { ...state, getCerficateList: action.payload };

    case actionTypes.DOWNLOAD_CERTIFICATE:
      return { ...state, downloadCertificate: action.payload };

    case actionTypes.COMPETANCY_LIST:
      return { ...state, competancy_list: action.payload };

    case actionTypes.ADD_CERTIFICATE:
      return { ...state, add_certificate: action.payload };

    case actionTypes.UPLOAD_CERTIFICATE:
      return { ...state, upload_certificate: action.payload };

    case actionTypes.GET_STREAM_LIST:
      return { ...state, get_stream_list: action.payload };

    case actionTypes.ADD_STREAM:
      return { ...state, add_stream: action.payload };

    case actionTypes.UPDATE_STREAM:
      return { ...state, update_stream: action.payload };

    case actionTypes.LEADER_LIST:
      return { ...state, leader_list: action.payload };

    case actionTypes.ADD_BENCH:
      return { ...state, add_bench: action.payload };

    case actionTypes.GET_BECNH_TABLE_API:
      return { ...state, get_bench_table_api: action.payload };

    case actionTypes.GET_PROJECT_HISTORY_API:
      return { ...state, get_project_history: action.payload };

    case actionTypes.COMPLETE_REALESE:
      return { ...state, complete_release: action.payload };
    case actionTypes.BULK_APPROVE:
      return { ...state, bulkApprove: action.payload };

    case actionTypes.GET_ALLRESOURCE_EXPORT_API:
      return { ...state, getAllResourceExportApi: action.payload };

    case actionTypes.GET_GENERATE_RESUME_DATA:
      return { ...state, getGenerateResumeData: action.payload };

    case actionTypes.POST_PROFILE_SUMMARY_API:
      return { ...state, postProfileSummary: action.payload };

    case actionTypes.PROFILE_REMINDER_API:
      return { ...state, profileReminderApi: action.payload };

    case actionTypes.GET_BENCH_RESOURCE_API:
      return { ...state, getBenchResourceApi: action.payload };

    case actionTypes.GET_EMPLOYEE_ROLE_MANANGEMENT:
      return { ...state, getAllEmployeeRoleManagementApi: action.payload };

    case actionTypes.GET_ALL_ROLES:
      return { ...state, getAllRolesApi: action.payload };

    case actionTypes.UPDATE_ROLES:
      return { ...state, postUpdateRoles: action.payload };

    case actionTypes.DELETE_ALL_ROLES:
      return { ...state, deleteAllroles: action.payload };
    case actionTypes.GET_PROJECTHISTORY_BY_PROJECT_ID:
       return { ...state, getProjectHistoryByProjectId:action.payload}
    default:
      return state;
  }
};

export default Reducer;
