import React, { useEffect, useMemo, useState } from "react";
import SearchBar from "../../../component/SearchBar/SearchBar";
import { FaSort } from "react-icons/fa";
import { Table } from "react-bootstrap";
import * as actions from "../../../Redux/Actions/index";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import MainCard from "../../../component/MainCard/MainCard";
import { useNavigate } from "react-router-dom";
import ModalComponents from "../../../component/ModalComponent/ModalComponent";
import DeleteTraining from "./DeleteTrainingModule";
import EditTrainingModule from "./EditTrainingModule";
import { showErrorToastMessage } from "../../../component/Toasts/Toasts";
import useLoadingIndicator from "../../../component/Loader/Loader";
import ShowEntries from "../../../component/ShowEntries/ShowEntries";
import UploadProgramPlan from "./UploadProgramPlan";
import { icons } from "../../../assets/index";
import TablePagination from "../../../component/TablePagination/TablePagination";
import FilterValues from "../../../component/FieldFilter/FilterValues";
import {
  inseedTrainingSchedule,
  Strings,
  traineeMasterTable,
} from "../../../constant/Strings";
import EditButton from "../../../component/Buttons/EditButton/EditButton";
import "./TrainingSchedule.scss";
import hanldeUnauthorizedUser from "../../../component/AccessConditions/UnauthorizedUser";
import {
  timeDateFormat,
  timeDateFormatYMD,
} from "../../../component/FormatDate";
const TrainingMasterTable = (props: any) => {
  const {
    loadingComponent,
    showLoadingIndicator,
    hideLoadingIndicator,
    apiCallCompleted,
  } = useLoadingIndicator();
  const { unAuthorizeUser } = hanldeUnauthorizedUser();
  const [page, setPage] = useState<Number>(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchValue, setSearchValue] = useState<any>("");
  const [filterValue, setFilterValue] = useState<any>([]);
  const [totalEntries, setTotalEntries] = useState<any>();
  const [totalRecords, setTotalRecords] = useState<any>(0);
  const [pageSize, setPageSize] = useState(10);
  const [training, setTraining] = useState<any>({
    inseedBatchRef: "",
    trainingName: "",
    evaluationDate: "",
    trainingStartDate: "",
    trainingEndDate: "",
    selectedModules: "",
  });
  const dispatch = useDispatch();

  const {
    no_data_found,
    training_name,
    Start_Date,
    End_Date,
    modules,
    action,
    filters,
    create_training,
    training_Schedule,
  } = Strings;
  const {
    upgrade_icon,
    download_icon,
    edit_icon,
    delete_icon,
    arrow_drop_down,
  } = icons;
  const authRole = localStorage.getItem("role");
  const isInseedChecker = authRole?.includes("inseed-program-checker");
  const isInseedMaker = authRole?.includes("inseed-program-maker");
  const [expansion, setExpansion] = useState(false);
  const [rowid, setRowid] = useState("");
  const [data, setdata] = useState<any>([]);
  const [order, setorder] = useState("ASC");
  const [trainingId, setTrainingId] = useState("");
  const handleExpansion = (id: any) => {
    setRowid(id);
    setExpansion(!expansion);
  };
  const [isSorted, setisSorted] = useState(false);
  const [sortedData, setsortedData] = useState<any>([]);
  const sorting = (col: any) => {
    setisSorted(true);
    if (order === "ASC") {
      const sorted = [...data].sort((a: any, b: any) => {
        const isReversed = 1;
        return isReversed * a[col].localeCompare(b[col]);
      });
      setsortedData(sorted);
      setorder("DSC");
    } else {
      const sorted = [...data].sort((a: any, b: any) => {
        const isReversed = -1;
        return isReversed * a[col].localeCompare(b[col]);
      });
      setsortedData(sorted);
      setorder("ASC");
    }
  };
  const [showEditTraining, setShowEditTraining] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const handleEditTrainingModule = (
    id: any,
    inseedBatchRef: any,
    trainingName: any,
    evaluationDate: any,
    selectedModules: any,
    trainingStartDate: any,
    trainingEndDate: any
  ) => {
    setTraining({
      inseedBatchRef: inseedBatchRef,
      trainingName: trainingName,
      evaluationDate: evaluationDate,
      selectedModules: selectedModules,
      trainingStartDate: trainingStartDate,
      trainingEndDate: trainingEndDate,
    });
    setShowEditTraining(true);
    setTrainingId(id);
  };
  const hideEditTrainingModule = () => {
    setShowEditTraining(false);
  };
  const hideUploadModule = () => {
    setShowUpload(false);
  };

  const [deleteId, setDeleteId] = useState("");
  const [showDeleteTraining, setShowDeleteTraining] = useState(false);
  const handleDeleteTraining = (id: any) => {
    setDeleteId(id);
    setShowDeleteTraining(true);
  };

  const hideDeleteTraining = () => {
    setShowDeleteTraining(false);
  };

  const trainingsDetails = useSelector(
    (state: any) => state.employeeReducer.getApprovedInseedData
  );
  const editTrainingsDetails = useSelector(
    (state: any) => state.employeeReducer.editTrainingPlanDetails
  );
  const deleteTrainingsDetails = useSelector(
    (state: any) => state.employeeReducer.deleteTrainingModuleDetails
  );
  const editInseedData = useSelector(
    (state: any) => state.employeeReducer.editInseedData
  );
  const [onclickSearch, setOnclickSearch] = useState(false);
  const onSearchClicked = (value: any) => {
    setOnclickSearch(value);
  };
  const condition: boolean =
    (filterValue.length == 0 && searchValue == "") ||
    (filterValue.length > 0 && onclickSearch);
  const filterKey = filterValue.map((ele: any) => ele.value);
  const GetApiDispatch = () => {
    setExpansion(false);
    dispatch(
      actions.getApprovedInseedDataAction(
        page,
        pageSize,
        filterKey,
        searchValue,
        "",
        (res: any) => {
           if (res?.error?.error_description) {
                showErrorToastMessage(res.error.error_description);
              }else if (res.code === 200) {
                setTotalPages(res.meta_data.totalPageCount);
                setTotalEntries(res.meta_data.totalEntries);
                hideLoadingIndicator();
                setOnclickSearch(false);
          }
        }
      )
    );
  };
  useEffect(() => {
    unAuthorizeUser();
    if (condition) {
      showLoadingIndicator();
      GetApiDispatch();
    }
  }, [editTrainingsDetails, deleteTrainingsDetails]);

  useEffect(() => {
    if (condition) {
      GetApiDispatch();
    }
  }, [page, pageSize, filterValue, searchValue, condition, editInseedData]);

  useEffect(() => {
    if (trainingsDetails && trainingsDetails.data) {
      setdata(trainingsDetails.data);
      setTotalRecords(trainingsDetails.data.length);
    }
  }, [trainingsDetails, data]);

  const navigate = useNavigate();
  const handleCreateTraining = (
    batchName: any,
    batchRef: any,
    trainingPlan: any,
    trainingLength: any
  ) => {
    navigate(`/create-training/${batchRef}`);
    localStorage.setItem("InseedBatchName", batchName);
    localStorage.setItem("trainingPlan", trainingPlan);
    localStorage.setItem("trainingLength", trainingLength);
  };
  const handleViewTraining = (trainingId: any) => {
    navigate(`/training-modules/${trainingId}`);
  };
  const MakerCheckerColumns = [
    ...traineeMasterTable,
    {
      Header: "Action",
      accessor: "action",
    },
  ];
  const columns = useMemo(
    () =>
      isInseedChecker || isInseedMaker
        ? MakerCheckerColumns
        : traineeMasterTable,
    []
  );
  const handleUploadModal = (e: any, id: any) => {
    e.preventDefault();
    setTrainingId(id);
    setShowUpload(true);
  };
  const handlePageChange = (newPage: any) => {
    setPage(newPage);
    setOnclickSearch(true);
  };
  const handleSearch = (value: any) => {
    setSearchValue(value);
    setPage(1);
  };
  const filterChange = (selectedOption: any) => {
    if (selectedOption) {
      if (filterValue.find((obj: any) => obj.value == selectedOption.value)) {
        setFilterValue((prevValue: any) =>
          prevValue.filter((obj: any) => obj.value != selectedOption.value)
        );
      } else {
        setFilterValue((prevValue: any) => [...prevValue, selectedOption]);
      }
    }
  };
  return (
    <>
      {loadingComponent()}
      <MainCard>
        <div className="heading-button-wrapper">
          <div>
            <h2>{training_Schedule}</h2>
          </div>
        </div>
        <div className="mt-3 buttonsize filter-wrapper-training-schedule">
          <div className="searchbar-wrapper">
            <SearchBar
              filter={searchValue}
              setFilter={handleSearch}
              onSearchClicked={onSearchClicked}
            />
          </div>
          <div className="filter-container">
            <select
              id="select-filters"
              value={typeof filterValue === 'string' ? filterValue : ''}
              onChange={(event) => {
                const selectedValue = event.target.value;
                const selectedOption = inseedTrainingSchedule.find(
                  (item: any) => item.value === selectedValue
                );
                if (selectedOption) {
                  filterChange(selectedOption);
                }
              }}
            >
              <option hidden>{filters}</option>
              {inseedTrainingSchedule.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.title}
                </option>
              ))}
            </select>
          </div>
        </div>
        <FilterValues
          filterValueArray={filterValue}
          onFilterChange={filterChange}
        />
        {data.length > 0 ? (
          <>
            <Table className="table-training-schedule mt-3">
              <thead>
                <tr className="custom-table-head-row">
                  {columns.map((ele: any) => (
                    <React.Fragment key={ele.Header}>
                      <td
                        onClick={() => sorting(ele.accessor)}
                        className="sorting-cursor"
                      >
                        {ele.Header}
                        {ele.Header !== "Training Plan" &&
                        ele.Header !== "Action" &&
                        ele.Header !== "" &&
                        ele.Header != "Remark" ? (
                          <span>{<FaSort />}</span>
                        ) : (
                          " "
                        )}
                      </td>
                    </React.Fragment>
                  ))}
                </tr>
              </thead>
              <tbody className="employeedata training-master-table">
                {data?.length === 0 ? (
                  <tr>
                    <td colSpan={12}>{no_data_found}</td>
                  </tr>
                ) : (
                  (isSorted ? sortedData : data).map((ele: any, index: any) => (
                    <React.Fragment key={ele?._id}>
                      <tr>
                        <td
                          onClick={() => handleExpansion(index)}
                          className="text-start"
                        >
                          <img
                            src={arrow_drop_down}
                            className={
                              index === rowid
                                ? expansion
                                  ? "rotate-arrow-icon"
                                  : ""
                                : ""
                            }
                            alt="icon"
                          />
                          <span className="mx-3">{ele?.inseedBatch}</span>
                        </td>
                        <td>{timeDateFormat(ele?.startDate)}</td>
                        <td>{timeDateFormat(ele?.endDate)}</td>

                        <td>{ele?.client}</td>

                        <td>{ele?.technology}</td>
                        <td>
                          {ele?.trainingPlan?.document_path_url ||
                          ele?.sharepointLink ? (
                            <div className="action-btn">
                              <a
                                href={
                                  ele?.trainingPlan?.document_path_url
                                    ? ele?.trainingPlan?.document_path_url
                                    : ele?.sharepointLink
                                }
                                target="_blank"
                              >
                                <img src={download_icon} alt="icon" />
                              </a>
                            </div>
                          ) : (
                            (isInseedChecker || isInseedMaker) && (
                              <div>
                                <a
                                  href=""
                                  onClick={(e) =>
                                    handleUploadModal(e, ele?._id)
                                  }
                                >
                                  <img src={upgrade_icon} alt="icon" />
                                </a>
                              </div>
                            )
                          )}
                        </td>
                        {(isInseedChecker || isInseedMaker) && (
                          <td>
                            {ele.recordStatus == "Completed" ? (
                              <>-</>
                            ) : (
                              <>
                                <a
                                  className="assign-link"
                                  onClick={() =>
                                    handleCreateTraining(
                                      ele?.inseedBatch,
                                      ele._id,
                                      ele?.trainingPlan?.document_path_url
                                        ? ele?.trainingPlan?.document_path_url
                                        : "",
                                      ele.InseedTraining?.length + 1
                                    )
                                  }
                                >
                                  {create_training}
                                </a>
                              </>
                            )}
                          </td>
                        )}
                      </tr>
                      {index === rowid &&
                        expansion &&
                        (ele.TrainingData?.length === 0 ? (
                          <tr>
                            <td colSpan={12}> {no_data_found}</td>
                          </tr>
                        ) : (
                          ele.TrainingData.map((item: any) => (
                            <tr key={item._id}>
                              <td colSpan={12}>
                                <div className="d-flex justify-content-around">
                                  <div>
                                    <div className="title-label">
                                      {training_name}
                                    </div>
                                    <div>{item?.trainingName}</div>
                                  </div>
                                  <div>
                                    <div className="title-label">{modules}</div>
                                    <div>
                                      {item.ModuleData.map(
                                        (modules: any) => modules?.moduleName
                                      ).join(", ")}
                                    </div>
                                  </div>
                                  <div>
                                    <div className="title-label">
                                      {Start_Date}
                                    </div>
                                    <div>
                                      {item?.trainingStartDate
                                        ? timeDateFormat(
                                            item?.trainingStartDate
                                          )
                                        : "-"}
                                    </div>
                                  </div>
                                  <div>
                                    <div className="title-label">
                                      {End_Date}
                                    </div>
                                    <div>
                                      {item?.trainingEndDate
                                        ? timeDateFormat(item?.trainingEndDate)
                                        : "-"}
                                    </div>
                                  </div>
                                  {(isInseedChecker || isInseedMaker) && (
                                    <div>
                                      <div className="title-label">
                                        {action}
                                      </div>
                                      <div className="action-btn">
                                        <img
                                          data-toggle="tooltip"
                                          data-placement="bottom"
                                          title="Edit"
                                          src={edit_icon}
                                          alt="edit_icon"
                                          className="edit-icon"
                                          onClick={() =>
                                            handleEditTrainingModule(
                                              item?._id,
                                              ele?._id,
                                              item?.trainingName,
                                              timeDateFormat(
                                                item?.evaluationDate
                                              ),
                                              item.ModuleData.map(
                                                (modules: any) =>
                                                  modules?.moduleName +
                                                  "/" +
                                                  modules?._id
                                              ),
                                              timeDateFormatYMD(
                                                item?.trainingStartDate
                                              ),
                                              timeDateFormatYMD(
                                                item?.trainingEndDate
                                              )
                                            )
                                          }
                                        />
                                        <img
                                          data-toggle="tooltip"
                                          data-placement="bottom"
                                          title="Delete"
                                          src={delete_icon}
                                          alt="delete-icon"
                                          className="edit-icon"
                                          onClick={() =>
                                            handleDeleteTraining(item?._id)
                                          }
                                        />
                                      </div>
                                    </div>
                                  )}
                                  <div>
                                    <div>
                                      <EditButton
                                        title="View All Modules"
                                        onClick={() =>
                                          handleViewTraining(item?._id)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))
                        ))}
                    </React.Fragment>
                  ))
                )}
              </tbody>
            </Table>
            <div className="show-entries-wrapper">
              <ShowEntries
                pageSize={pageSize}
                setPageSize={setPageSize}
                totalRecords={totalRecords}
                totalEntries={totalEntries}
              />

              <TablePagination
                totalPages={totalPages}
                currentPage={page}
                onClick={handlePageChange}
              />
            </div>
          </>
        ) : (
          <p className="no-data">{no_data_found}</p>
        )}

        <ModalComponents
          show={showEditTraining}
          onHide={hideEditTrainingModule}
          title={"Edit Training Module"}
          modalBody={
            <EditTrainingModule
              hideEditTrainingModule={hideEditTrainingModule}
              trainingData={training}
              trainingId={trainingId}
              apiCallCompleted={apiCallCompleted}
            />
          }
          className="modal-md"
        />

        <ModalComponents
          show={showDeleteTraining}
          onHide={hideDeleteTraining}
          title="Delete Training Module "
          modalBody={
            <DeleteTraining
              trainingId={deleteId}
              hideDeleteModal={hideDeleteTraining}
              apiCallCompleted={apiCallCompleted}
            />
          }
          className="modal-md"
        />

        <ModalComponents
          show={showUpload}
          onHide={hideUploadModule}
          title={"Upload Program Plan"}
          modalBody={
            <UploadProgramPlan
              hideEditTrainingModule={hideUploadModule}
              trainingId={trainingId}
              apiCallCompleted={apiCallCompleted}
            />
          }
          className="modal-md"
        />
      </MainCard>
    </>
  );
};
export default TrainingMasterTable;
