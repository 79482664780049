import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../component/Buttons/Button";
import MainCard from "../../../component/MainCard/MainCard";
import CreateTraining from "./CreateTrainingCard";
import { Strings } from "../../../constant/Strings";
import hanldeUnauthorizedUser from "../../../component/AccessConditions/UnauthorizedUser";

const CreateTrainingData = () => {
  const { unAuthorizeUser } = hanldeUnauthorizedUser();

  const { create_training_title } = Strings;

  const navigate = useNavigate();
  useEffect(() => {
    unAuthorizeUser();
  }, []);

  let { id } = useParams();

  const handleExistingTraining = () => {
    navigate(`/all-trainings/${id}`);
  };

  return (
    <>
      <div>
        <MainCard>
          <div className="heading-button-wrapper">
            <div>
              <h2>{create_training_title}</h2>
            </div>
            <div className="btn-wrapper">
              <Button
                title="+ Add Existing Training"
                onClick={handleExistingTraining}
                className="add-employee-btn"
              />
            </div>
          </div>
          <CreateTraining />
        </MainCard>
      </div>
    </>
  );
};

export default CreateTrainingData;
