import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../component/Toasts/Toasts";
import * as actions from "../../../Redux/Actions";
import { Dropdown } from "react-bootstrap";
import {
  Strings,
  addEmployeeSkillCoinsErrorsValidations,
} from "../../../constant/Strings";
import { timeDateFormatYMD } from "../../../component/FormatDate";

const EditEmployeeStatus = (props: any) => {
  const [btnDisable, setBtnDisable] = useState(false);
  const { eId, hideEditStatusDetails, data, apiCallCompleted } = props;
  const resourceData: any = data?.resource[0];
  const userLogin: any = localStorage.getItem("login");
  const loginId = JSON.parse(userLogin).id;
  const [employeeStatus, setEmployeeStatus] = useState<any>({
    eId: eId,
    status: resourceData?.status,
    recordStatus: resourceData?.recordStatus,
    lastWorkingDate: resourceData?.lastWorkingDate,
    updatedBy: loginId,
  });
  const [errors, setErrors] = useState({
    status: "",
    recordStatus: "",
    lastWorkingDate: "",
  });
  const {
    absconded_label,
    resigned_label,
    cancel,
    submit,
    last_working_date,
    status,
    details_status,
    approved_label,
    active_label,
    inactive_label,
    editSuccess,
  } = Strings;
  const { skillcoinsVal } = addEmployeeSkillCoinsErrorsValidations;
  useEffect(() => {
    if (employeeStatus.status == active_label) {
      if (employeeStatus.status && employeeStatus.recordStatus) {
        setBtnDisable(true);
      } else {
        setBtnDisable(false);
      }
    }
    if (employeeStatus.status == inactive_label) {
      if (
        employeeStatus.status &&
        employeeStatus.recordStatus &&
        employeeStatus.lastWorkingDate
      ) {
        setBtnDisable(true);
      } else {
        setBtnDisable(false);
      }
    }
  });

  const dispatch = useDispatch();
  let valid: boolean = true;
  const validate = () => {
    if (employeeStatus.status.length == 0) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        status: skillcoinsVal,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({ ...errorObj, status: "" }));
    }
    if (employeeStatus.recordStatus.length == 0) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        recordStatus: skillcoinsVal,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({ ...errorObj, recordStatus: "" }));
    }
    if (employeeStatus.status == inactive_label) {
      if (employeeStatus.lastWorkingDate.length == 0) {
        valid = false;
        setErrors((errorObj) => ({
          ...errorObj,
          lastWorkingDate: skillcoinsVal,
        }));
        setBtnDisable(false);
      } else {
        setErrors((errorObj) => ({ ...errorObj, lastWorkingDate: "" }));
      }
    }
  };
  const handleEditEmployeeSubmit = () => {
    validate();
    if (valid) {
      handleDispatch();
      hideEditStatusDetails();
    }
  };
  const handleDispatch = () => {
    apiCallCompleted(false);
    dispatch(
      actions.editEmployeeDataAction(employeeStatus, (res: any) => {
         if (res?.error?.error_description) {
              showErrorToastMessage(res.error.error_description);
              apiCallCompleted(true);
            } else if(res.code === 200) {
              showSuccessToastMessage(editSuccess);
              apiCallCompleted(true);
            }
      })
    );
  };

  return (
    <>
      <div>
        <div className="row g-3">
          <div className="col-md-12">
            <label htmlFor="inputName4" className="form-label">
              {status}
            </label>
            <span className="required">*</span>
            <Dropdown
              onSelect={(eventKey: any) => {
                if (eventKey == "Active") {
                  setEmployeeStatus((employeeStatus: any) => ({
                    ...employeeStatus,
                    status: eventKey,
                    recordStatus: "Approved",
                  }));
                } else {
                  setEmployeeStatus((employeeStatus: any) => ({
                    ...employeeStatus,
                    status: eventKey,
                    recordStatus: "",
                  }));
                }
              }}
            >
              <Dropdown.Toggle variant="light" className="form-control">
                {employeeStatus.status}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item eventKey={active_label}>
                  {active_label}
                </Dropdown.Item>
                <Dropdown.Item eventKey={inactive_label}>
                  {inactive_label}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <p className="error">{errors.status}</p>
          </div>

          <div className="col-md-12">
            <label htmlFor="inputName4" className="form-label">
              {details_status}
            </label>
            <span className="required">*</span>
            {employeeStatus.status !== "Inactive" ? (
              <>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="light"
                    className="form-control"
                    disabled
                  >
                    {employeeStatus.recordStatus}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item eventKey={approved_label}>
                      {approved_label}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </>
            ) : (
              <>
                <Dropdown
                  onSelect={(eventKey: any) =>
                    setEmployeeStatus({
                      ...employeeStatus,
                      recordStatus: eventKey,
                    })
                  }
                >
                  <Dropdown.Toggle variant="light" className="form-control">
                    {employeeStatus.recordStatus}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item eventKey={absconded_label}>
                      {absconded_label}
                    </Dropdown.Item>
                    <Dropdown.Item eventKey={resigned_label}>
                      {resigned_label}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </>
            )}
            <p className="error">{errors.recordStatus}</p>
          </div>
          {employeeStatus.status == "Inactive" && (
            <>
              <div className="col-md-12">
                <label className="form-label">{last_working_date}</label>
                <span className="required">*</span>
                <input
                  type="date"
                  max={new Date().toISOString().split("T")[0]}
                  className="form-control"
                  value={timeDateFormatYMD(employeeStatus.lastWorkingDate)}
                  onChange={(e) =>
                    setEmployeeStatus({
                      ...employeeStatus,
                      lastWorkingDate: e.target.value,
                    })
                  }
                />
                <p className="error">{errors.lastWorkingDate}</p>
              </div>
            </>
          )}
        </div>

        <div className="modal-btn-wrapper mt-5 mx-auto">
          <div>
            <button
              className="btn cancel-btn buttonsize"
              onClick={hideEditStatusDetails}
            >
              {cancel}
            </button>
          </div>

          <div>
            <button
              className={
                btnDisable
                  ? "btn add-submit-button buttonsize"
                  : "btn add-submit-button btn-disabled buttonsize"
              }
              onClick={handleEditEmployeeSubmit}
            >
              {submit}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditEmployeeStatus;
