import { useEffect, useMemo, useState } from "react";
import { TiArrowUnsorted } from "react-icons/ti";
import ModalComponents from "../../../component/ModalComponent/ModalComponent";
import SearchBar from "../../../component/SearchBar/SearchBar";
import {
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy,
} from "react-table";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../Redux/Actions";
import ShowEntries from "../../../component/ShowEntries/ShowEntries";
import {
  CertificationFilter,
  Strings,
  myAssessmentMasterFilter,
  streamsFilter,
} from "../../../constant/Strings";
import FilterValues from "../../../component/FieldFilter/FilterValues";
import TablePagination from "../../../component/TablePagination/TablePagination";
import { showErrorToastMessage } from "../../../component/Toasts/Toasts";
import hanldeUnauthorizedUser from "../../../component/AccessConditions/UnauthorizedUser";
import MainCard from "../../../component/MainCard/MainCard";
import Button from "../../../component/Buttons/Button";
import { icons } from "../../../assets";
import AddorEditStreams from "./AddorEditStreams";
import { Link } from "react-router-dom";

const Streams = () => {
  const { unAuthorizeUser } = hanldeUnauthorizedUser();
  const { noData, filters, cetifications } = Strings;
  const [info, setInfo] = useState<any>([]);
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState<Number>(1);
  const [totalPages, setTotalPages] = useState(0);
  const [size, setPagePerSize] = useState<any>(10);
  const [filterValue, setFilterValue] = useState<any>([]);
  const [searchKey, setSearchKey] = useState("");
  const [totalEntries, setTotalEntries] = useState<any>();
  const [showAddOrEditStreams, setShowAddOrEditStreams] = useState(false);
  const [onclickSearch, setOnclickSearch] = useState(false);
  const [editData, setEditData] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const onSearchClicked = (value: any) => {
    setOnclickSearch(value);
  };

  const handleApiCall = () => {
    let obj = {
      size,
      page,
      searchKey: filterKey,
      searchValue,
      requestId: loginId,
    };
    dispatch(
      actions.getStreamListAction("stream", obj, (res: any) => {
        if (res?.error?.error_description) {
          showErrorToastMessage(res?.error?.error_description);
        } else {
          if (res.code === 200) {
            setTotalPages(res.meta_data.totalPageCount);
            setTotalEntries(res.meta_data.totalEntries);
            setOnclickSearch(false);
            setInfo(res.data);
          }
        }
      })
    );
  };
  const userLogin: any = localStorage.getItem("login");
  const loginId = JSON.parse(userLogin)?.id;
  const condition: boolean =
    (filterValue.length == 0 && searchValue == "") ||
    (filterValue.length > 0 && onclickSearch);
  const filterKey = filterValue.map((ele: any) => ele.value);
  useEffect(() => {
    unAuthorizeUser();
    if (condition) {
      handleApiCall();
    }
  }, [page, searchValue, searchKey, filterValue, size]);

  const assessmentData = useSelector(
    (state: any) => state.employeeReducer.get_stream_list
  );
  useEffect(() => {
    if (assessmentData) {
      setInfo(assessmentData.data);
      setTotalRecords(assessmentData.meta_data.totalPageCount);
    }
  }, [assessmentData, info]);

  const dispatch = useDispatch();
  const handleEditClick = (rowData: any) => {
    setEditData(rowData);
    setShowAddOrEditStreams(true);
  };
  const ResourceAddingMakerCheckerColumns: any = [
    {
      Header: "Stream Name",
      accessor: "streamName",
      Cell: (row: any) => (
        <div className="assessmet-veiw">
          <Link
            to={`/competency-substreams/${row.row.original.streamName}/${row.row.original._id}`}
          >
            {row.row.original.streamName}
          </Link>
        </div>
      ),
    },
    {
      Header: "Leader",
      accessor: "leadersData",
      Cell: (Action: any) => {
        const leadersData = Action.row.original?.leadersData?.length
          ? Action.row.original?.leadersData[0]
          : "";

        return leadersData
          ? `${leadersData.candidateName}  ${leadersData.middleName}  ${leadersData.lastname} `
          : "";
      },
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: (row: any) => (
        <div className="action-btn">
          <img
            data-toggle="tooltip"
            data-placement="bottom"
            title="Edit"
            src={icons.edit_icon}
            alt="edit_icon"
            className="edit-icon"
            onClick={() => handleEditClick(row.row.original)}
          />
        </div>
      ),
    },
  ];

  const data = info;
  const columns = useMemo(() => ResourceAddingMakerCheckerColumns, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setPageSize,
    state: { pageSize },
  } = useTable(
    { columns, data, initialState: { pageSize: 10, pageIndex: 0 } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const handlePageChange = (newPage: any) => {
    setPage(newPage);
    setOnclickSearch(true);
  };

  const handleSearch = (value: any) => {
    setSearchValue(value);
    setPage(1);
  };

  const filterChange = (selectedOption: any) => {
    if (selectedOption) {
      if (filterValue.find((obj: any) => obj.value == selectedOption.value)) {
        setFilterValue((prevValue: any) =>
          prevValue.filter((obj: any) => obj.value != selectedOption.value)
        );
      } else {
        setFilterValue((prevValue: any) => [...prevValue, selectedOption]);
      }
    }
  };

  return (
    <>
      <MainCard>
        <div className="assessment-heading-button-wrapper">
          <div>
            <h2>Streams</h2>
          </div>
          <div className="btn-wrapper">
            <Button
              title="+ Add Stream"
              onClick={() => setShowAddOrEditStreams(true)}
              className="add-employee-btn"
            />
          </div>
        </div>
        <div className="mt-2">
          <div
            className="mt-3 buttonsize filter-assessment-wrapper"
          >
            <div className="assessment-searchbar-wrapper mx-2">
              <SearchBar
                filter={searchValue}
                setFilter={handleSearch}
                onSearchClicked={onSearchClicked}
              />
            </div>
            <div className="assessment-filter-container mx-2">
              <select
                id="select-filters"
                value={filterValue}
                onChange={(e: any) => {
                  const selectedValue = e.target.value;
                  const selectedOption = streamsFilter.find(
                    (item: any) => item.value === selectedValue
                  );
                  if (selectedOption) {
                    filterChange(selectedOption);
                  }
                }}
              >
                <option hidden>{filters}</option>
                {streamsFilter?.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.title}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <FilterValues
            filterValueArray={filterValue}
            onFilterChange={filterChange}
          />
          {data?.length > 0 ? (
            <>
              <div className="table table-responsive">
                <table
                  className="table-main table table-sm mt-3"
                  {...getTableProps()}
                >
                  <thead className="table-head">
                    {headerGroups.map((headerGroup) => (
                      <tr
                        className="table-head-row"
                        {...headerGroup.getHeaderGroupProps()}
                      >
                        {headerGroup.headers.map((column: any) => (
                          <th
                            className="table-head-row"
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                            scope="col"
                          >
                            {column.render("Header")}

                            <span>
                              {column.id !== "Action" && column.id !== "id" ? (
                                column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <TiArrowUnsorted />
                                  ) : (
                                    <TiArrowUnsorted />
                                  )
                                ) : (
                                  <TiArrowUnsorted />
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>

                  <tbody className="table-body" {...getTableBodyProps()}>
                    {rows.map((row) => {
                      prepareRow(row);
                      return (
                        <tr className="table-body-row" {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <td {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="show-entries-wrapper">
                <ShowEntries
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  totalRecords={totalRecords}
                  totalEntries={totalEntries}
                />

                <TablePagination
                  totalPages={totalPages}
                  currentPage={page}
                  onClick={handlePageChange}
                />
              </div>
            </>
          ) : (
            <>
              <p className="employeedata mt-2">{noData}</p>
            </>
          )}
        </div>
        <ModalComponents
          show={showAddOrEditStreams}
          onHide={() => {
            setShowAddOrEditStreams(false);
            setEditData("");
          }}
          title={editData ? "Edit streams" : "Add Streams"}
          modalBody={
            <AddorEditStreams
              editData={editData}
              loginId={loginId}
              setEditData={setEditData}
              setShowAddOrEditStreams={setShowAddOrEditStreams}
              handleApiCall={handleApiCall}
            />
          }
          className="modal-md"
        />
      </MainCard>
    </>
  );
};
export default Streams;
