import  { useEffect } from "react";
import { useDispatch } from "react-redux";
import * as actions from "../../../Redux/Actions";
import { ToastContainer } from "react-toastify";
import BenchMgmtTable from "./BenchMgmtTable";

const BenchMgmt = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getClientNameAction());
  }, []);

  return (
    <>
      <BenchMgmtTable />
      <ToastContainer />
    </>
  );
};

export default BenchMgmt;
