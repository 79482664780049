import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../Redux/Actions";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../component/Toasts/Toasts";
import { ToastContainer } from "react-bootstrap";
import moment from "moment";
import {
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy,
} from "react-table";
import SearchBar from "../../../component/SearchBar/SearchBar";
import { TiArrowUnsorted } from "react-icons/ti";
import ShowEntries from "../../../component/ShowEntries/ShowEntries";
import TablePagination from "../../../component/TablePagination/TablePagination";
import { moduleMasterFilter } from "../../../constant/Strings";
import FilterValues from "../../../component/FieldFilter/FilterValues";
import HeaderDetailsCard from "../../../component/HeaderDetailsCard/HeaderDetailsCard";
import "./TrainingSchedule.scss";
import Button from "../../../component/Buttons/Button";
import { Strings } from "../../../constant/Strings";
import { trainingCardValidation } from "../../../constant/Validations";
import { icons } from "../../../assets";
import useLoadingIndicator from "../../../component/Loader/Loader";

const CreateTraining = () => {
  const { showLoadingIndicator, hideLoadingIndicator } = useLoadingIndicator();
  const { validName } = Strings;
  const [filterValue, setFilterValue] = useState<any>([]);
  const [searchValue, setSearchValue] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState<Number>(1);
  const [pageSize, setPageSize] = useState<any>(10);
  const [totalEntries, setTotalEntries] = useState<any>();
  let { id } = useParams();
  const [btnDisable, setBtnDisable] = useState(false);
  const [training, setTraining] = useState({
    inseedBatchRef: id,
    trainingName: "",
    evaluationDate: "",
    selectedModules: [],
    trainingStartDate: "",
    trainingEndDate: "",
  });
  const handleClearForm = () => {
    setTraining({
      inseedBatchRef: "",
      trainingName: "",
      evaluationDate: "",
      selectedModules: [],
      trainingStartDate: "",
      trainingEndDate: "",
    });
    setSelectedRows([]);
    setInputFieldData([]);
  };
  const {
    no_data_found,
    Start_Date,
    End_Date,
    training_name,
    modules,
    select,
    selected,
    filters,
  } = Strings;
  const { download_icon } = icons;
  const [onclickSearch, setOnclickSearch] = useState(false);
  const onSearchClicked = (value: any) => {
    setOnclickSearch(value);
  };
  const condition: boolean =
    (filterValue.length == 0 && searchValue == "") ||
    (filterValue.length > 0 && onclickSearch);
  const filterKey = filterValue.map((ele: any) => ele.value);
  useEffect(() => {
    if (condition) {
      dispatch(
        actions.getInseedAllModulesAction(
          pageSize,
          page,
          filterKey,
          searchValue,
          (res: any) => {
            if (res?.error?.error_description) {
              showErrorToastMessage(res?.error?.error_description);
            } else if (res.code === 200) {
                setTotalPages(res.meta_data.totalPageCount);
                setTotalEntries(res.meta_data.totalEntries);
                setOnclickSearch(false);
            }
          }
        )
      );
    }
  }, [page, pageSize, searchValue, filterValue, condition]);

  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [inputFieldData, setInputFieldData] = useState<any>([]);

  useEffect(() => {
    const selectedModuleNames = selectedRows.map((row: any) => row._id);
    setTraining((prevTraining: any) => ({
      ...prevTraining,
      selectedModules: selectedModuleNames,
    }));
  }, [selectedRows]);

  const handleRowClick = (rowData: any) => {
    if (selectedRows.find((row: any) => row._id === rowData._id)) {
      setSelectedRows(
        selectedRows.filter((row: any) => row._id !== rowData._id)
      );
    } else {
      setSelectedRows([...selectedRows, rowData]);
    }
  };

  const handleAddToInput = (rowData: any) => {
    if (inputFieldData.includes(rowData.moduleName)) {
      setInputFieldData(
        inputFieldData.filter((name: string) => name !== rowData.moduleName)
      );
    } else {
      setInputFieldData([...inputFieldData, rowData.moduleName]);
    }
  };

  const [totalRecords, setTotalRecords] = useState(0);
  const [info, setInfo] = useState([]);
  const moduleData = useSelector(
    (state: any) => state.employeeReducer.getInseedAllModulesDetails
  );
  useEffect(() => {
    if (moduleData && moduleData.data) {
      setInfo(moduleData.data);
      setTotalRecords(moduleData.data.length);
    }
  }, [moduleData, info]);

  const navigate = useNavigate();
  const handleProjectDetails = (moduleId: any) => {
    navigate(`/module/${moduleId}`);
    localStorage.setItem("routeTypeInseed", "TrainingSchedule");
    localStorage.setItem("createTrainingId", String(id));
  };

  const ResourceAddingColumns: any = [
    {
      Header: "Module Name",
      accessor: "moduleName",
      Cell: (Action: any) => {
        const moduleName = Action.row.original.moduleName;
        const moduleId = Action.row.original._id;
        return (
          <div
            className="hyperlink"
            onClick={() => handleProjectDetails(moduleId)}
          >
            {moduleName}
          </div>
        );
      },
    },
    {
      Header: "Content",
      accessor: "syllabus",
      Cell: (Action: any) => {
        const remarks = Action.row.original.content?.document_path_url;
        return (
          <div>
            {remarks ? (
              <div
                className="action-btn"
                data-toggle="tooltip"
                data-placement="top"
                title="Assignment Letter"
              >
                <a href={remarks} target="_blank">
                  <div className="center">
                    <img src={download_icon} alt="downloadIcon" />
                  </div>
                </a>
              </div>
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    {
      Header: "Duration",
      accessor: "duration",
    },
    {
      Header: "Trainer Name",
      accessor: "trainerName",
    },
    {
      Header: "Contact Number",
      accessor: "trainerContactNumber",
    },
    {
      Header: "Trainer Institute",
      accessor: "instituteName",
    },
    {
      Header: "Success Criteria",
      accessor: "successCriteria",
      Cell: (Action: any) => {
        const remarks = Action.row.original.successCriteria;
        return <div>{`${remarks ? remarks?.slice(0, 10) : ""}...`}</div>;
      },
    },
    {
      Header: "Technology Stream",
      accessor: "technologyStream",
      Cell: (Action: any) => {
        const remarks = Action.row.original.technologyStream;
        return <div>{`${remarks ? remarks?.slice(0, 10) : ""}...`}</div>;
      },
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: (Action: any) => {
        return (
          <div>
            <div
              className={
                inputFieldData.includes(Action.row.original.moduleName)
                  ? "btn btn-danger status-btn"
                  : "btn btn-success status-btn"
              }
              onClick={() => {
                handleRowClick(Action.row.original);
                handleAddToInput(Action.row.original);
              }}
            >
              {inputFieldData.includes(Action.row.original.moduleName)
                ? "- Remove"
                : "+ Add"}
            </div>
          </div>
        );
      },
    },
  ];

  const data = info;
  const columns = useMemo(() => ResourceAddingColumns, [inputFieldData]);
  const { getTableProps, getTableBodyProps, rows, headerGroups, prepareRow } =
    useTable(
      { columns, data, initialState: { pageSize: 10, pageIndex: 0 } },
      useGlobalFilter,
      useSortBy,
      usePagination
    );

  const [errors, setErrors] = useState({
    trainingName: "",
  });
  const handlePageChange = (newPage: any) => {
    setPage(newPage);
    setOnclickSearch(true);
  };

  const handleSearch = (value: any) => {
    setSearchValue(value);
    setPage(1);
  };

  const filterChange = (selectedOption: any) => {
    if (selectedOption) {
      if (filterValue.find((obj: any) => obj.value == selectedOption.value)) {
        setFilterValue((prevValue: any) =>
          prevValue.filter((obj: any) => obj.value != selectedOption.value)
        );
      } else {
        setFilterValue((prevValue: any) => [...prevValue, selectedOption]);
      }
    }
  };

  useEffect(() => {
    if (training.trainingName && training.selectedModules.length > 0) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  }, [training]);

  const batchName = localStorage.getItem("InseedBatchName");
  const dispatch = useDispatch();
  const handleTrainingSubmit = (e: any) => {
    e.preventDefault();
    validate();
    if (valid) {
      handleDispatch();
    }
  };
  let valid: boolean = true;
  const validate = () => {
    if (trainingCardValidation(training.trainingName)) {
      setErrors((error) => ({
        ...error,
        trainingName: "",
      }));
    } else {
      valid = false;
      setErrors((error: any) => ({
        ...error,
        trainingName: validName,
      }));
    }
  };
  const handleDispatch = () => {
    showLoadingIndicator();
    dispatch(
      actions.createTrainingAction(training, (res: any) => {
         if (res?.error?.error_description) {
              showErrorToastMessage(res.error.error_description);
              hideLoadingIndicator();
            } else if(res.code == 200) {
              showSuccessToastMessage("Added Successfully!");
              navigate(`/training-schedule`);
              handleClearForm();
              hideLoadingIndicator();
        }
      })
    );
  };
  return (
    <>
      <HeaderDetailsCard
        navigateTo={"/training-schedule"}
        tabHeading={"Program"}
        headingValue={batchName}
        firstColumnData={
          <>
            <div className="col-md-10 mt-4">
              <label className="form-label">{training_name}</label>
              <span className="required">*</span>
              <input
                type="text"
                className="form-control"
                placeholder="Training Name"
                value={training.trainingName}
                onChange={(e) =>
                  setTraining({
                    ...training,
                    trainingName: e.target.value,
                  })
                }
              />
              <p className="error">{errors.trainingName}</p>
            </div>
          </>
        }
        secondColumnData={
          <>
            <div className="col-md-6 mt-4">
              <label className="form-label">{Start_Date}</label>
              <input
                type="date"
                className="form-control"
                placeholder="ABCDE"
                value={training.trainingStartDate}
                onChange={(e) =>
                  setTraining({
                    ...training,
                    trainingStartDate: e.target.value,
                  })
                }
              />
            </div>
            <div className="col-md-6 mt-4">
              <label className="form-label">{End_Date}</label>
              <input
                min={moment(training.trainingStartDate)
                  .add(1, "days")
                  .format("YYYY-MM-DD")}
                type="date"
                className="form-control"
                placeholder="ABCDE"
                value={training.trainingEndDate}
                onChange={(e) =>
                  setTraining({
                    ...training,
                    trainingEndDate: e.target.value,
                  })
                }
              />
            </div>
          </>
        }
      />
      <div className="col-12">
        <label className="form-label mt-3">{select + " " + modules}</label>
        <span className="required">*</span>
        <>
          <div className="mt-2 buttonsize filter-module-wrapper">
            <div className="module-searchbar-wrapper">
              <SearchBar
                filter={searchValue}
                setFilter={handleSearch}
                onSearchClicked={onSearchClicked}
              />
            </div>
            <div className="module-filter-container">
              <select
                id="select-filters"
                value={filterValue}
                onChange={(event) => {
                  const selectedValue = event.target.value;
                  const selectedOption = moduleMasterFilter.find(
                    (item: any) => item.value === selectedValue
                  );
                  if (selectedOption) {
                    filterChange(selectedOption);
                  }
                }}
              >
                <option hidden>{filters}</option>
                {moduleMasterFilter.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.title}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <FilterValues
            filterValueArray={filterValue}
            onFilterChange={filterChange}
          />
          {data.length > 0 ? (
            <>
              <div className="table-responsive">
                <div className="table">
                  <table
                    className="table-main table table-sm mt-3"
                    {...getTableProps()}
                  >
                    <thead className="table-head">
                      {headerGroups.map((headerGroup) => (
                        <tr
                          className="table-head-row"
                          {...headerGroup.getHeaderGroupProps()}
                        >
                          {headerGroup.headers.map((column: any) => (
                            <th
                              className="table-head-row"
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                              scope="col"
                            >
                              {column.render("Header")}

                              <span>
                                {column.id !== "action" &&
                                column.id !== "id" &&
                                column.id !== "remarks" ? (
                                  column.isSorted ? (
                                    column.isSortedDesc ? (
                                      <TiArrowUnsorted />
                                    ) : (
                                      <TiArrowUnsorted />
                                    )
                                  ) : (
                                    <TiArrowUnsorted />
                                  )
                                ) : (
                                  ""
                                )}
                              </span>
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody className="table-body" {...getTableBodyProps()}>
                      {rows.map((row) => {
                        prepareRow(row);
                        return (
                          <tr className="table-body-row" {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                              return (
                                <td {...cell.getCellProps()}>
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="show-entries-wrapper">
                <ShowEntries
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  totalRecords={totalRecords}
                  totalEntries={totalEntries}
                />

                <TablePagination
                  totalPages={totalPages}
                  currentPage={page}
                  onClick={handlePageChange}
                />
              </div>
            </>
          ) : (
            <p className="no-data">{no_data_found}</p>
          )}
        </>
      </div>

      <div className="card mt-2 mb-4 p-4">
        <form>
          <div className="row align-items-center">
            <label className="form-label">{selected + " " + modules}</label>
            <textarea
              className="col selected-modules"
              placeholder="Modules Selected"
              value={inputFieldData}
            />

            <Button
              title="+ Add"
              onClick={handleTrainingSubmit}
              className={
                btnDisable ? "col-1 add-training-btn" : "col-1 add-disable-btn"
              }
            />
          </div>
        </form>
      </div>

      <ToastContainer />
    </>
  );
};

export default CreateTraining;
