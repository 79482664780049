import  { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import * as actions from "../../../Redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import { Strings } from "../../../constant/Strings";
import { showErrorToastMessage } from "../../../component/Toasts/Toasts";
import SpecificEmployeeReferredListComponent from "./SpecificEmployeeReferredListComponent";
import MainCard from "../../../component/MainCard/MainCard";

function MyReferredList() {
  const userLogin: any = localStorage.getItem("login");
  const loginId = JSON.parse(userLogin).id;
  const [page, setPage] = useState<Number>(1);
  const [totalPages, setTotalPages] = useState(0);
  const [filterValue, setFilterValue] = useState<any>([]);
  const [searchValue, setSearchValue] = useState<any>("");
  const [pageSize, setPageSize] = useState(10);
  const [totalEntries, setTotalEntries] = useState<any>();
  const [totalRecords, setTotalRecords] = useState(0);
  const [info, setInfo] = useState([]);
  const [referredCount, setReferredCount] = useState<any>([]);
  const [policy, setPolicy] = useState<any>([]);
  const { view_referral_policy } = Strings;
  const dispatch = useDispatch();
  const [onclickSearch, setOnclickSearch] = useState(false);
  const onSearchClicked = (value: any) => {
    setOnclickSearch(value);
  };
  const condition: boolean =
    (filterValue.length == 0 && searchValue == "") ||
    (filterValue.length > 0 && onclickSearch);
  const filterKey = filterValue.map((ele: any) => ele.value);
  useEffect(() => {
    if (condition) {
      dispatch(
        actions.specificEmployeeReferredListAction(
          loginId,
          page,
          pageSize,
          filterKey,
          searchValue,
          (res: any) => {
            if (res?.error?.error_description) {
              showErrorToastMessage(res.error.error_description);
            } else  if (res.code === 200) {
                setTotalPages(res.data.totalPageCount);
                setTotalEntries(res.data.totalCount);
                setInfo(res.data.result.referredResource);
                setReferredCount(res.data.result.referralCounts);
                setTotalRecords(res.data.result.referredResource?.length);
                setOnclickSearch(false);
            }
          }
        )
      );
    }
  }, [page, searchValue, filterValue, pageSize, condition]);

  useEffect(() => {
    dispatch(actions.getDownloadPolicyAction("ReferalPolicy"));
  }, []);
  const downloadPolicyDetails = useSelector(
    (state: any) => state.employeeReducer.getDownloadPolicyData
  );
  useEffect(() => {
    if (downloadPolicyDetails) {
      if (downloadPolicyDetails.data) {
        setPolicy(downloadPolicyDetails.data);
      }
    }
  }, [downloadPolicyDetails, policy]);
  const filterChange = (selectedOption: any) => {
    if (selectedOption) {
      if (filterValue.find((obj: any) => obj.value == selectedOption.value)) {
        setFilterValue((prevValue: any) =>
          prevValue.filter((obj: any) => obj.value != selectedOption.value)
        );
      } else {
        setFilterValue((prevValue: any) => [...prevValue, selectedOption]);
      }
    }
  };
  const handleSerach = (value: any) => {
    setPage(1);
    setSearchValue(value);
  };
  const handlePageChange = (newPage: any) => {
    setPage(newPage);
    setOnclickSearch(true);
  };

  const firstColumnHeadings: any[] = [
    "Total Referrals :",
    "Accepted Candidates :",
    "Rejected Candidates :",
    "KIV Candidates :",
  ];
  const firstColumnValues: any[] = [
    referredCount[0]?.TotalReferrals[0]?.TotalReferralsCount
      ? referredCount[0]?.TotalReferrals[0]?.TotalReferralsCount
      : "-",
    referredCount[0]?.AcceptedCandidates[0]?.TotalAcceptedCandidatesCount
      ? referredCount[0]?.AcceptedCandidates[0]?.TotalAcceptedCandidatesCount
      : "-",
    referredCount[0]?.RejectedCandidates[0]?.TotalRejectedCandidatesCount
      ? referredCount[0]?.RejectedCandidates[0]?.TotalRejectedCandidatesCount
      : "-",
    referredCount[0]?.KIVCandidates[0]?.TotalKIVCandidatesCount
      ? referredCount[0]?.KIVCandidates[0]?.TotalKIVCandidatesCount
      : "-",
  ];
  return (
    <>
      <MainCard>
        <Card className="invoice-card p-2 py-4">
          <div className="row">
            <div className="d-flex flex-row justify-content-between">
              <div className="col-md-10 mx-3">
                <div className="row">
                  <div className="col-md-4">
                    {firstColumnHeadings &&
                      firstColumnHeadings.map((element: any, index: any) => {
                        return (
                          <div key={index} className="invoice-details-label">
                            {element}
                          </div>
                        );
                      })}
                  </div>
                  <div className="col-md-8">
                    {firstColumnValues &&
                      firstColumnValues.map((element: any, index: any) => {
                        return (
                          <div key={index} className="invoice-details-values">
                            {element}
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div className="col-md-4 mx-3">
                <div className="row">
                  <div className="col-md-8">
                    <button
                      className="view-job-btn view-referral-list-btn"
                      onClick={() =>
                        window.open(
                          policy[0]?.uploadPolicy?.document_path_url,
                          "_blank"
                        )
                      }
                    >
                      {view_referral_policy}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>
        <div>
          <SpecificEmployeeReferredListComponent
            info={info}
            searchValue={searchValue}
            handleSerach={handleSerach}
            filterValue={filterValue}
            filterChange={filterChange}
            pageSize={pageSize}
            setPageSize={setPageSize}
            totalRecords={totalRecords}
            totalEntries={totalEntries}
            totalPages={totalPages}
            page={page}
            handlePageChange={handlePageChange}
            setPage={setPage}
            setFilterValue={setFilterValue}
            setSearchValue={setSearchValue}
            onSearchClicked={onSearchClicked}
            setOnclickSearch={setOnclickSearch}
          />
        </div>
      </MainCard>
    </>
  );
}

export default MyReferredList;
