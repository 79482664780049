import { useDispatch } from "react-redux";
import * as actions from "../../../Redux/Actions/index";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../component/Toasts/Toasts";
import { Strings } from "../../../constant/Strings";

const DeleteEmployee = (props: any) => {
  const {DeleteMsg,cancel,deleteButton} = Strings;
  const { id, hideDeleteModal } = props;
  const dispatch = useDispatch();
  const handleDelete = () => {
    dispatch(
      actions.deleteEmployeeDataAction(id, (res: any) => {
        if (res?.error?.error_description) {
          showErrorToastMessage(res?.error?.error_description);
        } else {
          if (res.code === 200) {
            showSuccessToastMessage("Deleted successfully");
          }
        }
      })
    );
    hideDeleteModal();
  };

  return (
    <div className="container">
      <div className="row mx-auto">{DeleteMsg}</div>
      <div className="modal-btn-wrapper mt-3 mx-auto">
        <div>
          <button
            className="btn btn-outline-danger buttonsize"
            onClick={hideDeleteModal}
          >
            {cancel}
          </button>
        </div>
        <div>
          <button className="btn btn-primary buttonsize" onClick={handleDelete}>
            {deleteButton}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteEmployee;
