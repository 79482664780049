import { useState, useEffect } from "react";
import MainCard from "../../../../component/MainCard/MainCard";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../Redux/Actions";
import EmployeeDbTable from "./EmployeeDbTable";
import { showErrorToastMessage } from "../../../../component/Toasts/Toasts";
import { useNavigate } from "react-router-dom";
import useLoadingIndicator from "../../../../component/Loader/Loader";

const EmployeeDbData = () => {
  const { loadingComponent, showLoadingIndicator, hideLoadingIndicator } =
    useLoadingIndicator();
  const [page, setPage] = useState<Number>(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchKey, setSearchKey] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [filterValue, setFilterValue] = useState<any>([]);
  const [pageSize, setPageSize] = useState<any>(10);
  const [totalEntries, setTotalEntries] = useState<any>();
  const [info, setInfo] = useState<any>([]);

  const addEmployee = useSelector(
    (state: any) => state.employeeReducer.addEmployeeData
  );
  const editEmployee = useSelector(
    (state: any) => state.employeeReducer.editEmployeeData
  );
  const editEmployeePicture = useSelector(
    (state: any) => state.employeeReducer.editEmployeePicture
  );
  const deleteEmployee = useSelector(
    (state: any) => state.employeeReducer.deleteEmployeeData
  );
  const employeeBulkUpload = useSelector(
    (state: any) => state.employeeReducer.employeeBulkUploadDetails
  );

  const postEmailNotificationDetails = useSelector(
    (state: any) => state.employeeReducer.postEmailNotificationDetails
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const role = localStorage.getItem("role");
  const [onclickSearch, setOnclickSearch] = useState(false);
  const onSearchClicked = (value: any) => {
    setOnclickSearch(value);
  };
  const condition: boolean =
    (filterValue.length == 0 && searchValue == "") ||
    (filterValue.length > 0 && onclickSearch);
  const filterKey = filterValue.map((ele: any) => ele.value);
  const unAuthourizeUser: any = () => {
    if (role && role === "undefined") {
      navigate("/profile");
    } else if (role && !role.includes("hr")) {
      navigate("/profile");
    }
  };
  const GetApiDispatch = () => {
    dispatch(
      actions.getEmployeeDataPaginationAction(
        pageSize,
        page,
        filterKey,
        searchValue,
        (res: any) => {
          if (res?.error?.error_description) {
                showErrorToastMessage(res.error.error_description);
            } else if (res.code === 200) {
                setTotalPages(res.meta_data.totalPageCount);
                setTotalEntries(res.meta_data.totalEntries);
                setInfo(res.data);
                hideLoadingIndicator();
                setOnclickSearch(false);
          }
        }
      )
    );
  };
  useEffect(() => {
    dispatch(actions.getEmployeeDataAction((res: any) => {}));
  }, []);
  useEffect(() => {
    unAuthourizeUser();
    if (condition) {
      showLoadingIndicator();
      GetApiDispatch();
    }
  }, [page, searchValue, searchKey, filterValue, pageSize, condition, role]);
  useEffect(() => {
    unAuthourizeUser();
    GetApiDispatch();
  }, [
    addEmployee,
    editEmployee,
    editEmployeePicture,
    postEmailNotificationDetails,
    deleteEmployee,
    employeeBulkUpload,
  ]);

  const handlePageChange = (newPage: any) => {
    setPage(newPage);
    setOnclickSearch(true);
  };

  const handleSearch = (value: any) => {
    setSearchValue(value);
    setPage(1);
  };

  const filterChange = (selectedOption: any) => {
    if (selectedOption) {
      if (filterValue.find((obj: any) => obj.value == selectedOption.value)) {
        setFilterValue((prevValue: any) =>
          prevValue.filter((obj: any) => obj.value != selectedOption.value)
        );
      } else {
        setFilterValue((prevValue: any) => [...prevValue, selectedOption]);
      }
    }
  };

  return (
    <>
      {loadingComponent()}
      <MainCard>
        <EmployeeDbTable
          totalPages={totalPages}
          onPage={page}
          handlePageChange={handlePageChange}
          searchKey={searchKey}
          searchValue={searchValue}
          handleSearch={handleSearch}
          setSearchKey={setSearchKey}
          filterValue={filterValue}
          filterChange={filterChange}
          pagePerSize={pageSize}
          setPagePerSize={setPageSize}
          totalEntries={totalEntries}
          info={info}
          onSearchClicked={onSearchClicked}
          filterKey={filterKey}
        />
      </MainCard>
    </>
  );
};

export default EmployeeDbData;
