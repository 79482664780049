import  { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../component/Toasts/Toasts";
import * as actions from "../../../Redux/Actions/index";
import Select from "react-select";
import "../InseedProgram.scss";
import {
  Strings,
  inseedMasterValidationStrings,
  requiredFeild,
  inseedMastertechnologyOptions,
} from "../../../constant/Strings";
import {
  inseedLocationValidation,
  isCharactersValid,
  whiteSpaceValid,
} from "../../../constant/Validations";
import { Dropdown, Form } from "react-bootstrap";
import { AiOutlineCheck } from "react-icons/ai";

function AddInseedProgram(props: any) {
  const { hideAddInseedProgram, apiCallCompleted } = props;
  const [techOtherField, setTechOtherField] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const [inseedData, setinseedData] = useState<any>({
    inseedBatch: "",
    client: "",
    technology: "",
    intakeHC: "",
    productionHC: "",
    stipend: "15000",
    ctc: "",
    trainingCost: "",
    location: "",
    startDate: "",
    endDate: "",
    remarks2: "",
    trainingLocation: [],
    dateOfOnBoarding: "",
    technologySubStream: "",
    inseedProgramOwner: "",
    spoc: "",
  });
  const [errors, setErrors] = useState({
    location: "",
    intakeHC: "",
    stipend: "",
    ctc: "",
    inseedProgramOwner: "",
    spoc: "",
  });
  const {
    client,
    training_location,
    technology_Stream,
    technology_sub_stream,
    work_location,
    batch_size,
    required_hc,
    stipend_per_month,
    proposed_ctc,
    training_cost,
    tco_sub,
    date_of_onBoarding,
    inseed_program_SPOC,
    inseed_program_owner,
    remarks,
    start_date,
    end_date,
    cancel,
    submit,
    addSuccess,
  } = Strings;
  const {
    inseedProgramOwner_error,
    spoc_error,
    intakeHC_error,
    ctc_error,
    stipend_error,
    location_error,
  } = inseedMasterValidationStrings;
  useEffect(() => {
    dispatch(actions.getClientNameAction());
  }, []);
  const [clientName, setClientName] = useState([]);
  const getClientName = useSelector(
    (state: any) => state.employeeReducer.getClientNameDetails
  );
  useEffect(() => {
    if (getClientName) {
      if (getClientName.data) {
        setClientName(getClientName.data);
      }
    }
  }, [getClientName, clientName]);
  const clientOptions: any = clientName.map((ele: any) => ({
    value: ele.clientName,
    label: ele.clientName,
  }));
  const handleClientName = (selected: any) => {
    setinseedData({
      ...inseedData,
      client: selected.value,
    });
  };

  const handleTechnologyChange = (selected: any) => {
    if (selected.value == "Other") {
      setTechOtherField(true);
    } else {
      setTechOtherField(false);
    }
    setinseedData({
      ...inseedData,
      technology: selected.value,
    });
  };
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      inseedData.client &&
      inseedData.technology &&
      inseedData.intakeHC &&
      inseedData.stipend &&
      inseedData.ctc &&
      inseedData.location &&
      inseedData.startDate &&
      inseedData.endDate &&
      inseedData.inseedProgramOwner &&
      inseedData.spoc
    ) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  });

  let valid: boolean = true;
  const validate = () => {
    if (inseedData?.location) {
      if (
        whiteSpaceValid(inseedData?.location) == false ||
        inseedLocationValidation(inseedData?.location) == false
      ) {
        valid = false;
        setErrors((error) => ({
          ...error,
          location: location_error,
        }));
        setBtnDisable(false);
      } else {
        setErrors((error) => ({
          ...error,
          location: "",
        }));
      }
    }
    if (inseedData.stipend) {
      if (
        inseedData.stipend.length < 4 ||
        inseedData.stipend.length > 5 ||
        parseInt(inseedData.stipend) < 9000 ||
        parseInt(inseedData.stipend) > 30000
      ) {
        valid = false;
        setErrors((error) => ({
          ...error,
          stipend: stipend_error,
        }));
        setBtnDisable(false);
      } else {
        setErrors((error) => ({
          ...error,
          stipend: "",
        }));
      }
    }
    if (inseedData.ctc) {
      if (
        parseInt(inseedData.ctc) < 250000 ||
        parseInt(inseedData.ctc) > 600000
      ) {
        valid = false;
        setErrors((error) => ({
          ...error,
          ctc: ctc_error,
        }));
        setBtnDisable(false);
      } else {
        setErrors((error) => ({
          ...error,
          ctc: "",
        }));
      }
    }
    if (inseedData.inseedProgramOwner) {
      if (
        whiteSpaceValid(inseedData.inseedProgramOwner) == false ||
        isCharactersValid(inseedData.inseedProgramOwner) == false
      ) {
        valid = false;
        setErrors((error) => ({
          ...error,
          inseedProgramOwner: inseedProgramOwner_error,
        }));
        setBtnDisable(false);
      } else {
        setErrors((error) => ({
          ...error,
          inseedProgramOwner: "",
        }));
      }
    }
    if (inseedData.intakeHC) {
      if (inseedData.intakeHC > 50) {
        valid = false;
        setErrors((error) => ({
          ...error,
          intakeHC: intakeHC_error,
        }));
        setBtnDisable(false);
      } else {
        setErrors((error) => ({
          ...error,
          intakeHC: "",
        }));
      }
    }
    if (inseedData.spoc) {
      if (
        whiteSpaceValid(inseedData.spoc) == false ||
        isCharactersValid(inseedData.spoc) == false
      ) {
        valid = false;
        setErrors((error) => ({
          ...error,
          spoc: spoc_error,
        }));
        setBtnDisable(false);
      } else {
        setErrors((error) => ({
          ...error,
          spoc: "",
        }));
      }
    }
  };
  const handleEnrollmentSubmit = () => {
    validate();
    if (valid) {
      handleDispatch();
      hideAddInseedProgram();
    }
  };

  const handleDispatch = () => {
    apiCallCompleted(false);
    dispatch(
      actions.addInseedDataAction(inseedData, (res: any) => {
            if (res?.error?.error_description) {
              showErrorToastMessage(res.error.error_description);
            } else if (res.code === 200) {
              showSuccessToastMessage(addSuccess);
              apiCallCompleted(true);
        }
      })
    );
  };

  const [showRequirements, setShowRequirements] = useState(false);
  const handleFocus = () => {
    setShowRequirements(true);
  };
  const handleBlur = () => {
    setShowRequirements(false);
  };

  const handledDateChange = (e: any) => {
    let newStartDate = e.target.value;
    let newEndDate = new Date(newStartDate);
    newEndDate.setDate(newEndDate.getDate() + 90);
    let formattedEndDate = newEndDate.toISOString().split("T")[0];
    setinseedData({
      ...inseedData,
      startDate: newStartDate,
      endDate: formattedEndDate,
    });
  };
  const [selectedOptions, setSelectedOptions] = useState<any>([]);
  const getLocationName = useSelector(
    (state: any) => state.employeeReducer.getInseedLocationData
  );
  const addLocationName = useSelector(
    (state: any) => state.employeeReducer.addInseedLocationData
  );
  useEffect(() => {
    dispatch(actions.getInseedLocationAction());
  }, [addLocationName]);

  const [location, setLocation] = useState({
    trainingLocationName: "",
  });
  const handleDropDownLocationSubmit = (e: any) => {
    e.preventDefault();
    dispatch(
      actions.addInseedLocationAction(location, (res: any) => {
         if (res?.error?.error_description) {
              showErrorToastMessage(res.error.error_description);
            } else if(res.code === 200) {
              showSuccessToastMessage(addSuccess);
              setLocation({ ...location, trainingLocationName: "" });
        }
      })
    );
    const inputField: any = document.getElementById("formAddOption");
    if (inputField) {
      inputField.value = "";
    }
  };

  const [locationName, setLocationName] = useState([]);
  useEffect(() => {
    if (getLocationName) {
      if (getLocationName.data) {
        setLocationName(getLocationName.data);
      }
    }
  }, [getLocationName, locationName]);

  const options: any = [
    { value: "Bangalore", label: "Bangalore" },
    { value: "Delhi", label: "Delhi" },
    { value: "Hyderabad", label: "Hyderabad" },
    { value: "Mumbai", label: "Mumbai" },
  ];
  const handleOptionChange = (option: any, isChecked: any) => {
    if (isChecked) {
      setSelectedOptions([...selectedOptions, option]);
      setinseedData({
        ...inseedData,
        trainingLocation: [...selectedOptions, option].map((o) => o.value),
      });
    } else {
      setSelectedOptions(
        selectedOptions.filter((o: any) => o.value == option.value)
      );
      setinseedData({
        ...inseedData,
        trainingLocation: selectedOptions
          .filter((o: any) => o.value !== option.value)
          .map((o: any) => o.value),
      });
    }
  };

  return (
    <>
      <div className="row g-3">
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {client}
          </label>
          <span className="required">*</span>
          <div>
            <Select
              className="dropdown-select"
              options={clientOptions}
              isSearchable={true}
              onChange={handleClientName}
            />
          </div>
        </div>

        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {training_location}
          </label>
          <span className="required">{requiredFeild.required}</span>
          <Dropdown
            onSelect={(eventKey: any) => {
              setinseedData({
                ...inseedData,
                location: eventKey,
              });
            }}
          >
            <Dropdown.Toggle
              variant="light"
              className="form-control training-location-dropdown"
            >
              {inseedData.location ? inseedData.location : "Select a location"}
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu">
              <Form>
                <Form.Group controlId="formAddOption">
                  <div className="input-group d-flex flex-row justify-content-between align-items-center">
                    <div className="otherInputform">
                      <Form.Control
                        type="text"
                        placeholder="Enter Other"
                        onChange={(e) =>
                          setLocation({
                            ...location,
                            trainingLocationName: e.target.value,
                          })
                        }
                        id="formAddOption"
                      />
                    </div>
                    <div className="input-group-append">
                      <button
                        type="button"
                        className={
                          location.trainingLocationName.trim() !== ""
                            ? "btn btn-primary"
                            : "btn btn-disabled"
                        }
                        onClick={handleDropDownLocationSubmit}
                      >
                        <AiOutlineCheck />
                      </button>
                    </div>
                    <p className="error">{errors.location}</p>
                  </div>
                </Form.Group>
              </Form>
              {locationName?.map((ele: any) => (
                <Dropdown.Item eventKey={ele.trainingLocationName}>
                  {ele.trainingLocationName}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {start_date}
          </label>
          <span className="required">*</span>
          <input
            type="date"
            className="form-control"
            onChange={(e) => {
              handledDateChange(e);
            }}
          />
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {end_date}
          </label>
          <span className="required">*</span>

          <input
            type="date"
            className="form-control"
            value={inseedData.endDate}
            onChange={(e) =>
              setinseedData({
                ...inseedData,
                endDate: e.target.value,
              })
            }
          />
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {technology_Stream}
          </label>
          <span className="required">*</span>

          <div style={{ height: "1rem" }}>
            <Select
              className="dropdown-select"
              options={inseedMastertechnologyOptions}
              isSearchable={true}
              onChange={handleTechnologyChange}
            />
          </div>
        </div>
        {techOtherField && (
          <>
            <div className="col-md-4">
              <label htmlFor="inputName4" className="form-label">
                Enter {technology_Stream}
              </label>
              <input
                type="text"
                className="form-control"
                onChange={(e) =>
                  setinseedData({
                    ...inseedData,
                    technology: e.target.value,
                  })
                }
              />
            </div>
          </>
        )}
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {technology_sub_stream}
          </label>
          <input
            type="text"
            className="form-control"
            onChange={(e) =>
              setinseedData({
                ...inseedData,
                technologySubStream: e.target.value,
              })
            }
          />
        </div>
        <div className="col-md-8">
          <label htmlFor="inputName4" className="form-label">
            {work_location}
          </label>
          <span className="required">*</span>

          <Select
            options={options}
            isMulti
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            value={selectedOptions}
            onChange={(selected: any) => {
              setSelectedOptions(selected);
            }}
            closeMenuOnSelect={false}
            placeholder="Select Status..."
            className="dropdown-select"
            components={{
              Option: (props: any) => (
                <div className="custom-option buttonsize">
                  <div className="row">
                    <div className="col-8 mx-4 mt-2">{props.label}</div>
                    <div className="col-2 mt-2">
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          handleOptionChange(props, e.target.checked)
                        }
                        checked={selectedOptions.some(
                          (selectedOption: any) =>
                            selectedOption.value === props.data.value
                        )}
                      />
                    </div>
                  </div>
                </div>
              ),
            }}
          />
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {batch_size}
          </label>
          <span className="required">*</span>
          <input
            maxLength={2}
            type="text"
            className="form-control"
            name="intakeHC"
            value={inseedData.intakeHC}
            onChange={(e) =>
              setinseedData({
                ...inseedData,
                intakeHC: e.target.value.replace(/\D/g, ""),
              })
            }
          />
          <p className="error">{errors.intakeHC}</p>
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {required_hc}
          </label>
          <input
            maxLength={2}
            type="text"
            className="form-control"
            value={inseedData.productionHC}
            onChange={(e) =>
              setinseedData((inseedData: any) => ({
                ...inseedData,
                productionHC: e.target.value.replace(/\D/g, ""),
              }))
            }
            name="productionHC"
          />
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {stipend_per_month}
          </label>
          <span className="required">*</span>
          <input
            minLength={4}
            maxLength={5}
            type="text"
            className="form-control"
            value={inseedData.stipend}
            onChange={(e) => {
              setinseedData({
                ...inseedData,
                stipend: e.target.value.replace(/\D/g, ""),
              });
            }}
          />
          <p className="error">{errors.stipend}</p>
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {proposed_ctc}
          </label>
          <span className="required">*</span>
          <input
            minLength={6}
            maxLength={6}
            type="text"
            className="form-control"
            value={inseedData.ctc}
            onChange={(e) =>
              setinseedData({
                ...inseedData,
                ctc: e.target.value.replace(/\D/g, ""),
              })
            }
          />
          <p className="error">{errors.ctc}</p>
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {training_cost}
          </label>

          <input
            onFocus={handleFocus}
            onBlur={handleBlur}
            maxLength={9}
            type="text"
            className="form-control"
            value={inseedData.trainingCost}
            onChange={(e) =>
              setinseedData({
                ...inseedData,
                trainingCost: e.target.value.replace(/\D/g, ""),
              })
            }
          />
          {showRequirements && (
            <div className="tco-popup tco-popup-edit">
              <div className="d-flex">
                <span className="required">*</span>
                <span className="buttonsize">{tco_sub}</span>
              </div>
            </div>
          )}
        </div>

        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {date_of_onBoarding}
          </label>
          <input
            min={new Date().toISOString().split("T")[0]}
            type="date"
            className="form-control"
            onChange={(e) =>
              setinseedData({
                ...inseedData,
                dateOfOnBoarding: e.target.value,
              })
            }
          />
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {inseed_program_owner}
          </label>
          <span className="required">*</span>
          <input
            type="text"
            className="form-control"
            onChange={(e) =>
              setinseedData({
                ...inseedData,
                inseedProgramOwner: e.target.value,
              })
            }
          />
          <p className="error">{errors.inseedProgramOwner}</p>
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {inseed_program_SPOC}
          </label>
          <span className="required">*</span>
          <input
            type="text"
            className="form-control"
            onChange={(e) =>
              setinseedData({
                ...inseedData,
                spoc: e.target.value,
              })
            }
          />
          <p className="error">{errors.spoc}</p>
        </div>

        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {remarks}
          </label>
          <input
            maxLength={150}
            type="text"
            className="form-control"
            onChange={(e) =>
              setinseedData({
                ...inseedData,
                remarks2: e.target.value,
              })
            }
          />
        </div>
        <div className="modal-btn-wrapper mt-5 mx-auto">
          <div>
            <button
              className="btn cancel-btn buttonsize"
              onClick={hideAddInseedProgram}
            >
              {cancel}
            </button>
          </div>
          <div>
            <button
              className={
                btnDisable
                  ? "btn add-submit-button buttonsize"
                  : "btn add-submit-button btn-disabled buttonsize"
              }
              onClick={handleEnrollmentSubmit}
            >
              {submit}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddInseedProgram;
