import moment from "moment";
import  { useEffect, useState } from "react";
import { Dropdown, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../../component/Toasts/Toasts";
import * as actions from "../../../../Redux/Actions/index";
import { AiOutlineCheck } from "react-icons/ai";
import Select from "react-select";
import {
  Strings,
  projectDetailsLabels,
  requiredFeild,
  addProjectErrorsValidations,
  geographyDropdown,
  projectTypeDropdown,
  techTypeDropdown,
  addProjectStatusDropdown,
  poValueInDropdown,
  billingRateDropdown,
} from "../../../../constant/Strings";
import {
  isEmailValid,
  isMobileValid,
  projectClientNameValidation,
  projectLocationValidation,
  projectPoNumberValidation,
  projectProjectCodeValidation,
  projectProjectNameValidation,
  projectSubProjectCodeValidation,
  employeeMobileNumberValidtion,
} from "../../../../constant/Validations";
import "../ProjectRepository.scss";

const AddProject = (props: any) => {
  const {
    hideAddProject,
    apiCallCompleted,
    setnextScreen,
    setAddDocModalData,
  } = props;
  const { cancel, submit, addSuccess } = Strings;
  const [btnDisable, setBtnDisable] = useState(false);

  const {
    poNumber,
    subProjectCode,
    projectCode,
    projectName,
    location,
    spocPersonName,
    spocNumber,
    spocEmail,
  } = addProjectErrorsValidations;

  const {
    geographyLabel,
    locationLabel,
    clientNameLabel,
    projectNameLabel,
    projectTypeLabel,
    projectCodeLabel,
    subProjectCodeLabel,
    serviceLineLabel,
    deliveryManagerLabel,
    hrBusinessPartnerLabel,
    projectStartDateLabel,
    projectEndDateLabel,
    projectStatusLabel,
    purchaseOrderNoLabel,
    poStartDateLabel,
    poEndDateLabel,
    approvedHcLabel,
    currencyTypeLabel,
    billingStatusLabel,
    billingRateLabel,
    spocPersonNameLabel,
    spocContactNumberLabel,
    spocEmailIDLabel,
    remarksLabel,
  } = projectDetailsLabels;
  const [projectData, setProjectData] = useState<any>({
    geography: "",
    location: "",
    client: "",
    projectName: "",
    projectCode: "",
    subProjectName: "",
    subProjectCode: "",
    projectStartDate: "",
    projectEndDate: "",
    tdm: "",
    hrbpRef: "",
    status: "",
    projectType: "",
    techType: "",
    poNumber: "",
    poDate: "",
    approvedHc: "",
    poValueIn: "",
    billingRate: "",
    poAmount: "",
    monthlyBilling: "",
    spocPersonName: "",
    spocNumber: "",
    spocEmail: "",
    poStartDate: "",
    poEndDate: "",
    projectDescription: "",
    actualHc: "",
    buHeadRef: "",
  });

  const [errors, setErrors] = useState({
    location: "",
    client: "",
    projectName: "",
    projectCode: "",
    subProjectName: "",
    subProjectCode: "",
    tdm: "",
    techType: "",
    poNumber: "",
    approvedHc: "",
    poAmount: "",
    spocPersonName: "",
    spocNumber: "",
    spocEmail: "",
    projectDescription: "",
    actualHc: "",
  });

  const dispatch = useDispatch();
  let valid: boolean = true;
  const validate = () => {
    if (projectLocationValidation(projectData.location) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        location: location,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        location: "",
      }));
    }
    
    if (projectProjectNameValidation(projectData.projectName) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        projectName: projectName,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        projectName: "",
      }));
    }
    if (projectProjectCodeValidation(projectData.projectCode) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        projectCode: projectCode,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        projectCode: "",
      }));
    }
    if (projectSubProjectCodeValidation(projectData.subProjectCode) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        subProjectCode: subProjectCode,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        subProjectCode: "",
      }));
    }

    if (projectPoNumberValidation(projectData.poNumber) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        poNumber: poNumber,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        poNumber: "",
      }));
    }
   
    if (projectData.spocPersonName) {
      if (projectClientNameValidation(projectData.spocPersonName) == false) {
        valid = false;
        setErrors((errorObj) => ({
          ...errorObj,
          spocPersonName: spocPersonName,
        }));
        setBtnDisable(false);
      } else {
        setErrors((errorObj) => ({
          ...errorObj,
          spocPersonName: "",
        }));
      }
    }
    if (projectData.spocNumber) {
      if (
        projectData.geography === "India" &&
        isMobileValid(projectData.spocNumber) == false
      ) {
        valid = false;
        setErrors((errorObj) => ({
          ...errorObj,
          spocNumber: spocNumber,
        }));
        setBtnDisable(false);
      } else if (
        projectData.geography !== "India" &&
        employeeMobileNumberValidtion(projectData.spocNumber) == false
      ) {
        valid = false;
        setErrors((errorObj) => ({
          ...errorObj,
          spocNumber: spocNumber,
        }));
        setBtnDisable(false);
      } else {
        setErrors((errorObj) => ({
          ...errorObj,
          spocNumber: "",
        }));
      }
    }
    if (projectData.spocEmail) {
      if (isEmailValid(projectData.spocEmail) == false) {
        valid = false;
        setErrors((errorObj) => ({
          ...errorObj,
          spocEmail: spocEmail,
        }));
        setBtnDisable(false);
      } else {
        setErrors((errorObj) => ({
          ...errorObj,
          spocEmail: "",
        }));
      }
    }
   
  };
  const handleInvoiceSubmit = () => {
    validate();
    if (valid) {
      handleDispatch();
      hideAddProject();
    }
  };
  const handleDispatch = () => {
    const userLogin: any = localStorage.getItem("login");
    const loginId = JSON.parse(userLogin)?.id;
    const payload = {
      ...projectData,
      PoAddedBy: loginId,
    };
    setBtnDisable(false);
    apiCallCompleted(false);
    dispatch(
      actions.addProjectRepositoryAction(payload, (res: any) => {
            if(res?.error?.error_description) {
              apiCallCompleted(true);
              showErrorToastMessage(res.error.error_description);
            }else if(res.code === 200) {
              apiCallCompleted(true);
              setAddDocModalData({ ...payload });
              showSuccessToastMessage("Project added successfully!");
              setnextScreen(true);
            }
      })
    );
  };

  const addClientName = useSelector(
    (state: any) => state.employeeReducer.addClientNameDetails
  );
  useEffect(() => {
    dispatch(actions.getClientNameAction());
  }, [addClientName]);

  const [client, setClient] = useState({
    clientName: "",
  });
  const handleDropDownClientSubmit = (e: any) => {
    e.preventDefault();
    dispatch(
      actions.addClientNameAction(client, (res: any) => {
       if (res?.error?.error_description) {
              showErrorToastMessage(res.error.error_description);
            } else  if (res.code === 200) {
              showSuccessToastMessage(addSuccess);
            }
      })
    );

    const inputField: any = document.getElementById("formAddOption");
    if (inputField) {
      inputField.value = "";
    }
  };

  const [clientName, setClientName] = useState([]);
  const [employeeInfo, setEmployeeInfo] = useState([]);
  const [hrbpInfo, setHrbpInfo] = useState([]);
  const getClientName = useSelector(
    (state: any) => state.employeeReducer.getClientNameDetails
  );
  const employeeManagerData = useSelector(
    (state: any) => state.employeeReducer.getEmployeeData
  );
  useEffect(() => {
    if (getClientName && getClientName.data) {
      setClientName(getClientName.data);
    }
    if (employeeManagerData && employeeManagerData.data) {
      setEmployeeInfo(employeeManagerData.data);
    }
  }, [getClientName, employeeManagerData]);
  useEffect(() => {
    dispatch(actions.getEmployeeDataAction());
  }, []);
  useEffect(() => {
    dispatch(actions.getEmployeeDbHrbpListAction());
  }, []);

  const employeeHrbpData = useSelector(
    (state: any) => state.employeeReducer.getEmployeeDbHrbpList
  );
  useEffect(() => {
    if (employeeHrbpData && employeeHrbpData.data) {
      setHrbpInfo(employeeHrbpData.data);
    }
  }, [employeeHrbpData, hrbpInfo]);
  useEffect(() => {
    if (
      projectData.geography &&
      projectData.location &&
      projectData.client &&
      projectData.projectName &&
      projectData.projectCode &&
      projectData.subProjectCode &&
      projectData.projectStartDate &&
      projectData.buHeadRef &&
      projectData.hrbpRef &&
      projectData.status &&
      projectData.projectType
    ) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  });

  const managerOptions: any = employeeInfo.map((ele: any) => ({
    value: ele._id,
    label: ele.middleName
      ? ele.candidateName +
        " " +
        ele.middleName +
        " " +
        ele.lastname +
        "-" +
        ele.employeeId
      : ele.candidateName + " " + ele.lastname + "-" + ele.employeeId,
    hrbp: ele?.hrbpData?.[0],
  }));

  const handleReportingManager = (selected: any) => {
    const hrbpName = `${selected?.hrbp?.candidateName ?? ""} ${
      selected?.hrbp?.middleName ?? ""
    } ${selected?.hrbp?.lastname ?? ""} - ${selected?.hrbp?.employeeId ?? ""}`;
    setProjectData({
      ...projectData,
      buHeadRef: selected.value,
      hrbp: hrbpName,
    });
  };

  const hrbpOptions: any = hrbpInfo.map((ele: any) => ({
    value: ele._id,
    label: ele.middleName
      ? ele.candidateName +
        " " +
        ele.middleName +
        " " +
        ele.lastname +
        "-" +
        ele.employeeId
      : ele.candidateName + " " + ele.lastname + "-" + ele.employeeId,
  }));

  const handleHrbpManager = (selected: any) => {
    setProjectData({
      ...projectData,
      hrbpRef: selected.value,
    });
  };
  return (
    <>
      <div className="row g-3">
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {geographyLabel}
          </label>
          <span className="required">{requiredFeild.required}</span>
          <Dropdown
            onSelect={(eventKey: any) =>
              setProjectData({
                ...projectData,
                geography: eventKey,
              })
            }
          >
            <Dropdown.Toggle variant="light" className="form-control">
              {projectData.geography}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {geographyDropdown.map((element: any) => (
                <Dropdown.Item eventKey={element}>{element}</Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {locationLabel}
          </label>
          <span className="required">{requiredFeild.required}</span>
          <input
            type="text"
            className="form-control"
            value={projectData.location}
            onChange={(e) =>
              setProjectData({
                ...projectData,
                location: e.target.value,
              })
            }
          />
          <p className="error">{errors.location}</p>
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {clientNameLabel}
          </label>
          <span className="required">{requiredFeild.required}</span>
          <Dropdown
            onSelect={(eventKey: any) =>
              setProjectData({
                ...projectData,
                client: eventKey.split("/")[0],
                clientRef: eventKey.split("/")[1],
              })
            }
          >
            <Dropdown.Toggle variant="light" className="form-control">
              {projectData.client
                ? `${projectData.client?.slice(0, 30)}...`
                : ""}
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu">
              <Form>
                <Form.Group controlId="formAddOption">
                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <div className="otherInputform">
                      <Form.Control
                        type="text"
                        placeholder="Enter Other"
                        onChange={(e) =>
                          setClient({
                            ...client,
                            clientName: e.target.value,
                          })
                        }
                        id="formAddOption"
                      />
                    </div>
                    <div className="input-group-append">
                      <button
                        type="button"
                        className={
                          client.clientName.trim() !== ""
                            ? "btn btn-primary"
                            : "btn btn-disabled"
                        }
                        onClick={handleDropDownClientSubmit}
                      >
                        <AiOutlineCheck />
                      </button>
                    </div>
                    <p className="error">{errors.client}</p>
                  </div>
                </Form.Group>
              </Form>
              {clientName?.map((ele: any) => (
                <Dropdown.Item eventKey={ele.clientName + "/" + ele._id}>
                  {ele.clientName}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {projectNameLabel}
          </label>
          <span className="required">{requiredFeild.required}</span>
          <input
            type="text"
            className="form-control"
            value={projectData.projectName}
            onChange={(e) =>
              setProjectData({
                ...projectData,
                projectName: e.target.value,
              })
            }
          />
          <p className="error">{errors.projectName}</p>
        </div>

        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            Project Description
          </label>
          <textarea
            maxLength={150}
            className="form-control"
            value={projectData.projectDescription}
            onChange={(e) =>
              setProjectData({
                ...projectData,
                projectDescription: e.target.value.slice(0, 150),
              })
            }
          />
          <p className="error">{errors.projectDescription}</p>
        </div>

        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {projectTypeLabel}
          </label>
          <span className="required">{requiredFeild.required}</span>
          <Dropdown
            onSelect={(eventKey: any) =>
              setProjectData({
                ...projectData,
                projectType: eventKey,
              })
            }
          >
            <Dropdown.Toggle variant="light" className="form-control">
              {projectData.projectType}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {projectTypeDropdown.map((element: any) => (
                <Dropdown.Item eventKey={element}>{element}</Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {projectCodeLabel}
          </label>
          <span className="required">{requiredFeild.required}</span>
          <input
            type="text"
            className="form-control"
            value={projectData.projectCode}
            onChange={(e) =>
              setProjectData({
                ...projectData,
                projectCode: e.target.value,
              })
            }
          />
          <p className="error">{errors.projectCode}</p>
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {subProjectCodeLabel}
          </label>
          <span className="required">{requiredFeild.required}</span>
          <input
            type="text"
            className="form-control"
            value={projectData.subProjectCode}
            onChange={(e) =>
              setProjectData({
                ...projectData,
                subProjectCode: e.target.value,
              })
            }
          />
          <p className="error">{errors.subProjectCode}</p>
        </div>

        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {serviceLineLabel}
          </label>
          <Dropdown
            onSelect={(eventKey: any) =>
              setProjectData({
                ...projectData,
                techType: eventKey,
              })
            }
          >
            <Dropdown.Toggle variant="light" className="form-control">
              {projectData.techType}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {techTypeDropdown.map((element: any) => (
                <Dropdown.Item eventKey={element}>{element}</Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {deliveryManagerLabel}
          </label>
          <span className="required">{requiredFeild.required}</span>
          
          <div style={{ height: "1rem" }}>
            <Select
              className="dropdown-select"
              options={managerOptions}
              isSearchable={true}
              onChange={handleReportingManager}
            />
          </div>
        </div>

        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {hrBusinessPartnerLabel}
          </label>
          <span className="required">{requiredFeild.required}</span>
          
          <Select
            className="dropdown-select"
            options={hrbpOptions}
            isSearchable={true}
            onChange={handleHrbpManager}
          />
        </div>

        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {projectStartDateLabel}
          </label>
          <span className="required">{requiredFeild.required}</span>
          <input
            type="date"
            max={moment(projectData.projectEndDate)
              .subtract(1, "day")
              .format("YYYY-MM-DD")}
            className="form-control"
            value={projectData.projectStartDate}
            onChange={(e) =>
              setProjectData({
                ...projectData,
                projectStartDate: e.target.value,
              })
            }
          />
        </div>

        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {projectEndDateLabel}
          </label>
          <input
            min={moment(projectData.projectStartDate)
              .add(1, "day")
              .format("YYYY-MM-DD")}
            type="date"
            className="form-control"
            value={projectData.projectEndDate}
            onChange={(e) =>
              setProjectData({
                ...projectData,
                projectEndDate: e.target.value,
              })
            }
          />
        </div>

        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {projectStatusLabel}
          </label>
          <span className="required">{requiredFeild.required}</span>
          <Dropdown
            onSelect={(eventKey: any) =>
              setProjectData({
                ...projectData,
                status: eventKey,
              })
            }
          >
            <Dropdown.Toggle variant="light" className="form-control">
              {projectData.status}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {addProjectStatusDropdown.map((element: any) => (
                <Dropdown.Item eventKey={element}>{element}</Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {purchaseOrderNoLabel}
          </label>
          <input
            type="text"
            className="form-control"
            value={projectData.poNumber}
            onChange={(e) =>
              setProjectData({
                ...projectData,
                poNumber: e.target.value,
              })
            }
          />
          <p className="error">{errors.poNumber}</p>
        </div>

        <div className="col-md-4 mb-3">
          <label htmlFor="inputName4" className="form-label">
            {poStartDateLabel}
          </label>
          <input
            type="date"
            max={moment(projectData.poEndDate)
              .subtract(1, "day")
              .format("YYYY-MM-DD")}
            className="form-control"
            value={projectData.poStartDate}
            onChange={(e) =>
              setProjectData({
                ...projectData,
                poStartDate: e.target.value,
              })
            }
          />
        </div>
        <div className="col-md-4 mb-3">
          <label htmlFor="inputName4" className="form-label">
            {poEndDateLabel}
          </label>
          <input
            min={moment(projectData.poStartDate)
              .add(1, "day")
              .format("YYYY-MM-DD")}
            type="date"
            className="form-control"
            value={projectData.poEndDate}
            onChange={(e) =>
              setProjectData({
                ...projectData,
                poEndDate: e.target.value,
              })
            }
          />
        </div>

        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            Actual HC
          </label>
          <input
            type="text"
            className="form-control"
            value={projectData.actualHc}
            disabled
            onChange={(e) =>
              setProjectData({
                ...projectData,
                actualHc: e.target.value.replace(/\D/g, ""),
              })
            }
          />
          <p className="error">{errors.actualHc}</p>
        </div>
        <div className="col-md-4 mb-3">
          <label htmlFor="inputName4" className="form-label">
            {approvedHcLabel}
          </label>
          <input
            type="text"
            className="form-control"
            value={projectData.approvedHc}
            onChange={(e) =>
              setProjectData({
                ...projectData,
                approvedHc: e.target.value.replace(/\D/g, ""),
              })
            }
          />
          <p className="error">{errors.approvedHc}</p>
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {currencyTypeLabel}
          </label>
          <Dropdown
            onSelect={(eventKey: any) =>
              setProjectData({
                ...projectData,
                poValueIn: eventKey,
              })
            }
          >
            <Dropdown.Toggle variant="light" className="form-control">
              {projectData.poValueIn}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {poValueInDropdown.map((element: any) => (
                <Dropdown.Item eventKey={element}>{element}</Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {billingStatusLabel}
          </label>
          <Dropdown
            onSelect={(eventKey: any) =>
              setProjectData({
                ...projectData,
                billingRate: eventKey,
              })
            }
          >
            <Dropdown.Toggle variant="light" className="form-control">
              {projectData.billingRate}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {billingRateDropdown.map((element: any) => (
                <Dropdown.Item eventKey={element}>{element}</Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {billingRateLabel}
          </label>
          <input
            type="text"
            className="form-control"
            value={projectData.poAmount}
            onChange={(e) =>
              setProjectData({
                ...projectData,
                poAmount: e.target.value,
              })
            }
          />
          <p className="error">{errors.poAmount}</p>
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {spocPersonNameLabel}
          </label>
          <input
            type="text"
            className="form-control"
            value={projectData.spocPersonName}
            onChange={(e) =>
              setProjectData({
                ...projectData,
                spocPersonName: e.target.value,
              })
            }
          />
          <p className="error">{errors.spocPersonName}</p>
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {spocContactNumberLabel}
          </label>
          <input
            type="text"
            maxLength={projectData.geography == "India" ? 10 : 15}
            className="form-control"
            value={projectData.spocNumber}
            onChange={(e) =>
              setProjectData({
                ...projectData,
                spocNumber: e.target.value,
              })
            }
          />
          <p className="error">{errors.spocNumber}</p>
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {spocEmailIDLabel}
          </label>
          <input
            type="email"
            className="form-control"
            value={projectData.spocEmail}
            onChange={(e) =>
              setProjectData({
                ...projectData,
                spocEmail: e.target.value,
              })
            }
          />
          <p className="error">{errors.spocEmail}</p>
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {remarksLabel}
          </label>
          <input
            type="text"
            value={projectData.remarks}
            className="form-control"
            onChange={(e) =>
              setProjectData({
                ...projectData,
                remarks: e.target.value,
              })
            }
          />
        </div>
        <div className="modal-btn-wrapper mt-5 mx-auto">
          <div>
            <button
              className="btn cancel-btn buttonsize"
              onClick={hideAddProject}
            >
              {cancel}
            </button>
          </div>
          <div>
            <button
              className={
                btnDisable
                  ? "btn add-submit-button buttonsize"
                  : "btn add-submit-button btn-disabled buttonsize"
              }
              onClick={handleInvoiceSubmit}
            >
              {submit}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddProject;
