import React, { useEffect, useRef, useState } from "react";
import { Dropdown, Table } from "react-bootstrap";
import * as actions from "../../../Redux/Actions/index";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import MainCard from "../../../component/MainCard/MainCard";
import "./Evaluation.scss";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../component/Toasts/Toasts";
import useLoadingIndicator from "../../../component/Loader/Loader";
import ShowEntries from "../../../component/ShowEntries/ShowEntries";
import { icons } from "../../../assets";
import { Strings } from "../../../constant/Strings";
import Button from "../../../component/Buttons/Button";
import TablePagination from "../../../component/TablePagination/TablePagination";
import hanldeUnauthorizedUser from "../../../component/AccessConditions/UnauthorizedUser";
import {
  timeDateFormat,
  timeDateFormatYMD,
} from "../../../component/FormatDate";
import * as XLSX from "xlsx";

const Evaluation = () => {
  const { unAuthorizeUser } = hanldeUnauthorizedUser();
  const { loadingComponent, showLoadingIndicator, hideLoadingIndicator } =
    useLoadingIndicator();
  const authRole = localStorage.getItem("role");
  const isInseedChecker = authRole?.includes("inseed-program-checker");
  const isInseedMaker = authRole?.includes("inseed-program-maker");
  const [selectedStatus, setSelectedStatus] = useState({ value: "", ref: "" });
  const [assessmentBatchIndex, setAssessmentBatchIndex] = useState(0);
  const [data, setdata] = useState<any>([]);
  const [employee, setemployee] = useState<any>([]);
  const [selectedBatchOptions, setSelectedBatchOptions] = useState<any>([]);
  const [showExportReport, setShowExportReport] = useState(false);
  const [inseedBatchId, setinseedBatchId] = useState<any>("");
  const [inseedBatches, setinseedBatches] = useState<any>([]);
  const [inseedBatchName, setInseedBatchName] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState<Number>(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalEntries, setTotalEntries] = useState<any>();
  const [btnDisable, setBtnDisable] = useState(false);
  const handlePageChange = (newPage: any) => {
    setPage(newPage);
  };
  const [totalEntriesLoaded, setTotalEntriesLoaded] = useState(false);
  const {
    trainee_name,
    Cgpa,
    final_status,
    client_feedback,
    onboarding_date,
    final_evaluation,
    previous_assessments,
    more_assessments,
    onHold,
    rejected,
    selected,
    no_data_found,
    exportReport,
    select_by_batch,
  } = Strings;
  const dispatch = useDispatch();
  const getInseedData = useSelector(
    (state: any) => state.employeeReducer.getInseedData
  );
  const [inseedBatch, setinseedBatch] = useState<any>([]);

  useEffect(() => {
    unAuthorizeUser();
    showLoadingIndicator();
    dispatch(
      actions.getApprovedInseedDataAction("", "", "", "", "", (res: any) => {
       if (res?.error?.error_description) {
              showErrorToastMessage(res.error.error_description);
            }else if (res.code === 200) {
              hideLoadingIndicator();
              setinseedBatches(res.data);
              setinseedBatchId(res.data[0]._id);
              setInseedBatchName(res.data[0].inseedBatch);
            }
      })
    );
  }, []);

  const editEvaluationData = useSelector(
    (state: any) => state.employeeReducer.editEvaluationData
  );
  const batchOptions: any = [];

  inseedBatch?.forEach((ele: any) => {
    let obj = {
      value: ele.inseedBatch + "&" + ele._id,
      label: ele.inseedBatch,
    };
    batchOptions.push(obj);
  });

  useEffect(() => {
    if (inseedBatchId) {
      dispatch(
        actions.getEvaluationDataAction(
          inseedBatchId,
          page,
          pageSize,
          (res: any) => {
            if (res?.error?.error_description) {
              showErrorToastMessage(res?.error?.error_description);
            } else if (res.code === 200) {
                const array: any = [];
                setdata(res.data);
                setTotalPages(res.meta_data.totalPageCount);
                setTotalEntries(res.meta_data.totalEntries);
                setTotalEntriesLoaded(true);
                setemployee(res.data[0]?.employees);
                setTotalRecords(res.data[0]?.employees?.length);
                res.data[0]?.employees[0]?.assessments.map(
                  (item: any, index: number) => {
                    if (index < 5) {
                      array.push(item);
                    }
                  }
                );
              }
          }
        )
      );
    }
  }, [
    getInseedData,
    selectedStatus,
    editEvaluationData,
    inseedBatchId,
    page,
    pageSize,
  ]);

  useEffect(() => {
    if (getInseedData && getInseedData.data) {
      setinseedBatch(getInseedData.data);
      setSelectedStatus({
        value: getInseedData.data[0]?.inseedBatch,
        ref: getInseedData.data[0]?._id,
      });
      setSelectedBatchOptions([
        ...selectedBatchOptions,
        {
          label: getInseedData.data[0]?.inseedBatch,
          value:
            getInseedData.data[0]?.inseedBatch +
            "&" +
            getInseedData.data[0]?._id,
        },
      ]);
    }
  }, [getInseedData]);

  const handleUpdate = () => {
    showLoadingIndicator();
    dispatch(
      actions.editEvaluationDataAction(
        data[0]?._id,
        { employees: employee },
        (res: any) => {
              if (res?.error?.error_description) {
                showErrorToastMessage(res.error.error_description);
                hideLoadingIndicator();
              } else if (res.code === 200) {
                showSuccessToastMessage("Updated successfully!");
                hideLoadingIndicator();
                setBtnDisable(false);
              }
        }
      )
    );
  };
  const [updateNextClicked, setUpdateNextClicked] = useState(false);
  const handleUpdateNext = () => {
    setUpdateNextClicked(true);
  };
  const handlePrev = () => {
    setUpdateNextClicked(false);
  };
  const [moduleScore, setModuleScore] = useState("");
  const [finalAssessment, setFinalAssessment] = useState("");
  const [remarks, setRemarks] = useState("");
  useEffect(() => {
    if (moduleScore !== "" || finalAssessment !== "" || remarks !== "") {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  }, [moduleScore, finalAssessment, remarks]);

  const handleChange = (employeeId: any, assessmentId: any) => (e: any) => {
    let newData: any = employee.map((ele: any) => {
      if (ele.employee_id == employeeId) {
        ele?.assessments.forEach((mod: any) => {
          if (mod.assessment_id == assessmentId) {
            let moduleObj = mod;
            if (e.target.value <= 100) {
              moduleObj.score = e.target.value.replace(/\D/g, "");
              setModuleScore(moduleObj.score);
            }
          }
        });
      }
      return ele;
    });
    const individualEmp = newData.filter(
      (emp: any) => emp.employee_id === employeeId
    );
    if (individualEmp.length) {
      let prevVal: number = 0;
      individualEmp[0]?.assessments.map((emp: any) => {
        if (emp.score !== "") {
          prevVal = parseInt(emp.score) + prevVal;
        }
      });
      const sumOfAssessmentTotalScore = employee[0]?.assessments?.length;
      const cgpa = prevVal / (sumOfAssessmentTotalScore * 9.5);
      newData.map((ele: any) => {
        if (ele.employee_id == employeeId) {
          ele.finalScore = cgpa > 0 ? cgpa?.toFixed(1) : "";
        }
      });
    }
    setemployee(newData);
  };
  const handleSelect = (employeeId: any) => (eventKey: any) => {
    const newData: any = employee.map((ele: any) => {
      if (ele.employee_id == employeeId) {
        let employeeObj = ele;
        employeeObj.finalAssessment = eventKey;
        setFinalAssessment(employeeObj.finalAssessment);
      }
      return ele;
    });
    setemployee(newData);
  };
  const handleRemarkChange = (employeeId: any) => (e: any) => {
    const newData: any = employee.map((ele: any) => {
      if (ele.employee_id == employeeId) {
        let employeeObj = ele;
        employeeObj.remarks = e.target.value;
        setRemarks(employeeObj.remarks);
      }
      return ele;
    });
    setemployee(newData);
  };

  const handlePlusClick = (e: any) => {
    e.preventDefault();
    const newIndex = assessmentBatchIndex + 1;
    setAssessmentBatchIndex(newIndex);
  };
  const handleMinusClick = (e: any) => {
    e.preventDefault();
    if (assessmentBatchIndex > 0) {
      const newIndex = assessmentBatchIndex - 1;
      setAssessmentBatchIndex(newIndex);
    }
  };

  const generateXLFile = () => {
    showLoadingIndicator();
    dispatch(
      actions.getEvaluationDataAction(
        inseedBatchId,
        1,
        totalEntries,
        (res: any) => {
          if (res.code === 200 && res?.data.length > 0) {
              var traineeMasterData = [
                [
                  "Trainee Name",
                  ...(res.data[0]?.employees[0]?.assessments || []).map(
                    (assessment: {
                      assessment_info: any;
                      assessmentNumber: string;
                    }) => assessment?.assessment_info?.assessmentNumber
                  ),
                  "CGPA",
                  "Final Status",
                  "Client Feedback",
                  "Onboarding Date",
                ],
              ];

              res?.data[0]?.employees?.forEach((item: any) => {
                const assessments = item?.assessments.reduce(
                  (
                    obj: any,
                    assessment: {
                      assessment_info: any;
                      assessmentNumber: string;
                      score: string;
                    }
                  ) => {
                    obj[
                      `module${assessment?.assessment_info?.assessmentNumber}`
                    ] = assessment.score;
                    return obj;
                  },
                  {}
                );

                let emptyArray: string[] = [
                  item?.employee_data?.resourceName,
                  ...(res.data[0]?.employees[0]?.assessments || []).map(
                    (assessment: { assessment_info: any }) =>
                      assessments[
                        `module${assessment?.assessment_info?.assessmentNumber}`
                      ]
                  ),
                  item?.finalScore,
                  item?.finalAssessment,
                  item?.remarks,
                  item?.employee_data?.dateOfOnBoarding
                    ? timeDateFormat(item?.employee_data?.dateOfOnBoarding)
                    : "",
                ];
                traineeMasterData.push(emptyArray);
              });

              var traineeMaster = XLSX.utils.aoa_to_sheet(traineeMasterData);
              const wb = XLSX.utils.book_new();
              XLSX.utils.book_append_sheet(
                wb,
                traineeMaster,
                "Final Evaluation"
              );
              hideLoadingIndicator();
              XLSX.writeFile(wb, `${inseedBatchName}_Final_Evaluation.csv`);
            }
        }
      )
    );
  };
  const exportReportRef = useRef<HTMLInputElement | null>(null);
  const imgIcon = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        exportReportRef.current &&
        !exportReportRef.current.contains(event.target as Node)
      ) {
        if (
          imgIcon.current &&
          !imgIcon.current.contains(event.target as Node)
        ) {
          setShowExportReport(false);
        }
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showExportReport]);
  return (
    <>
      {loadingComponent()}
      <MainCard>
        <div className="heading-button-wrapper">
          <div>
            <h2>{final_evaluation}</h2>
          </div>
          <div className="btn-wrapper">
            {updateNextClicked ? (
              <>
                <Button
                  title="Back to Previous"
                  onClick={handlePrev}
                  className="add-employee-btn"
                />
                {(isInseedChecker || isInseedMaker) && (
                  <Button
                    title="Update"
                    onClick={handleUpdate}
                    className={
                      btnDisable
                        ? "add-employee-btn"
                        : "btn add-employee-btn btn-disabled buttonsize"
                    }
                  />
                )}
              </>
            ) : (
              <>
                <Button
                  title="Update & Next"
                  onClick={handleUpdateNext}
                  className="add-employee-btn"
                />
              </>
            )}
          </div>
        </div>
        <div className="mt-3 buttonsize filter-wrapper-evaluation">
          <div className="buttonsize filter-container">
            <select
              value={inseedBatchId}
              onChange={(e: any) => {
                const selectedBatch = inseedBatches.find(
                  (obj: any) => obj._id == e.target.value
                );
                setinseedBatchId(selectedBatch._id);
                setInseedBatchName(selectedBatch.inseedBatch);
                setBtnDisable(false);
                setPage(1);
              }}
            >
              <option hidden>{select_by_batch}</option>
              {inseedBatches.map((item: any, index: any) => (
                <option key={index} value={item._id}>
                  {item.inseedBatch}
                </option>
              ))}
            </select>
          </div>
          <div className="export-report-wrapper">
            <img
              ref={imgIcon}
              src={icons.hamburger_icon}
              onClick={() => setShowExportReport(!showExportReport)}
              alt="icon"
              height={30}
              width={30}
            />
            {showExportReport && (
              <div className="report-btn-wrapper" ref={exportReportRef}>
                <>
                  <div className="export-btn">
                    <button onClick={generateXLFile}>
                      <img src={icons.export_report} alt="icon" />{" "}
                      {exportReport}
                    </button>
                  </div>
                </>
              </div>
            )}
          </div>
        </div>
        {employee?.length > 0 ? (
          <>
            <Table className="table-margin-evaluation mt-3">
              <thead>
                <tr className="custom-table-head-row">
                  <td>{trainee_name}</td>
                  {assessmentBatchIndex > 0 && !updateNextClicked && (
                    <td colSpan={1} className="more-previous-head"></td>
                  )}
                  {!updateNextClicked ? (
                    <>
                      {employee[0]?.assessments
                        ?.slice(
                          assessmentBatchIndex * 5,
                          assessmentBatchIndex * 5 + 5
                        )
                        ?.map((mod: any,index:any) => {
                          return (
                            <React.Fragment key={index}>
                              <td>{mod?.assessment_info?.assessmentNumber}</td>
                            </React.Fragment>
                          );
                        })}
                      {assessmentBatchIndex * 5 + 5 <
                        employee[0]?.assessments?.length && (
                        <td className="more-previous-head"></td>
                      )}
                    </>
                  ) : (
                    <>
                      <td>{Cgpa}</td>
                      <td>{final_status}</td>
                      <td>{client_feedback}</td>
                      <td>{onboarding_date}</td>
                    </>
                  )}
                </tr>
              </thead>
              <tbody className="buttonsize evaluation-master-table">
                {employee?.length === 0 ? (
                  <tr>
                    <td colSpan={12}>{no_data_found}</td>
                  </tr>
                ) : (
                  <>
                    {employee?.map((ele: any, index: any) => (
                      <React.Fragment key={index}>
                        <tr>
                          <td>{ele?.employee_data?.resourceName}</td>
                          {assessmentBatchIndex > 0 && !updateNextClicked && (
                            <td colSpan={1}>
                              {index == Math.floor(employee.length / 2) && (
                                <a
                                  className="more-previous-body"
                                  href=""
                                  onClick={handleMinusClick}
                                >
                                  - {previous_assessments}
                                </a>
                              )}
                            </td>
                          )}

                          {!updateNextClicked ? (
                            <>
                              {ele?.assessments
                                ?.slice(
                                  assessmentBatchIndex * 5,
                                  assessmentBatchIndex * 5 + 5
                                )
                                ?.map((mod: any,index:any) => {
                                  return (
                                      <td key={index}>
                                        <input
                                          type="number"
                                          className={`form-control text-center`}
                                          value={mod?.score}
                                          max={100}
                                          min={0}
                                          onKeyDown={(event) => {
                                            if (event.key === '.') {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={handleChange(
                                            ele?.employee_id,
                                            mod?.assessment_id
                                          )}
                                         
                                        />
                                      </td>
                                  );
                                })}
                              {assessmentBatchIndex * 5 + 5 <
                                employee[0]?.assessments?.length && (
                                <td>
                                  {index == Math.floor(employee.length / 2) && (
                                    <a
                                      href={""}
                                      onClick={handlePlusClick}
                                      className="more-previous-body"
                                    >
                                      + {more_assessments}
                                    </a>
                                  )}
                                </td>
                              )}
                            </>
                          ) : (
                            <>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={ele?.finalScore}
                                />
                              </td>
                              <td>
                                <Dropdown
                                  onSelect={handleSelect(ele?.employee_id)}
                                >
                                  <Dropdown.Toggle
                                    variant="light"
                                    className="form-control"
                                  >
                                    {ele?.finalAssessment}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item eventKey={onHold}>
                                      {onHold}
                                    </Dropdown.Item>
                                    <Dropdown.Item eventKey={rejected}>
                                      {rejected}
                                    </Dropdown.Item>
                                    <Dropdown.Item eventKey={selected}>
                                      {selected}
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={ele?.remarks}
                                  onChange={handleRemarkChange(
                                    ele?.employee_id
                                  )}
                                  maxLength={250}
                                />
                              </td>
                              <td>
                                <input
                                  type="date"
                                  className="form-control"
                                  value={
                                    ele?.employee_data?.dateOfOnBoarding
                                      ? timeDateFormatYMD(
                                          ele?.employee_data?.dateOfOnBoarding
                                        )
                                      : ""
                                  }
                                  disabled
                                />
                              </td>
                            </>
                          )}
                        </tr>
                      </React.Fragment>
                    ))}
                  </>
                )}
              </tbody>
            </Table>
            <div className="show-entries-wrapper">
              <ShowEntries
                pageSize={pageSize}
                setPageSize={setPageSize}
                totalRecords={totalRecords}
                totalEntries={totalEntries}
              />

              <TablePagination
                totalPages={totalPages}
                currentPage={page}
                onClick={handlePageChange}
              />
            </div>
          </>
        ) : (
          <p className="no-data">{no_data_found}</p>
        )}
      </MainCard>
    </>
  );
};

export default Evaluation;
