import React, { useEffect, useMemo, useRef, useState } from "react";
import SearchBar from "../../../component/SearchBar/SearchBar";
import Button from "../../../component/Buttons/Button";
import "../SkillCoins.scss";
import { BsDownload } from "react-icons/bs";
import ModalComponents from "../../../component/ModalComponent/ModalComponent";
import AllocateSkillCoins from "./AllocateSkillCoins";
import DeallocateSkillCoins from "./DeallocateSkillCoins";
import { Table } from "react-bootstrap";
import { FaSort } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../Redux/Actions/index";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../component/Toasts/Toasts";
import ShowEntries from "../../../component/ShowEntries/ShowEntries";
import AddSpecificEmployee from "./AddSpecialEmployee";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Header from "../../../assets/Header.jpg";
import Footer from "../../../assets/Footer.jpg";
import Signature from "../../../assets/Signature.jpg";
import TablePagination from "../../../component/TablePagination/TablePagination";
import FilterValues from "../../../component/FieldFilter/FilterValues";

import {
  Strings,
  postaJobExportCol,
  skillDbFilters,
} from "../../../constant/Strings";
import { useNavigate } from "react-router-dom";
import AssignBulkSkillCoins from "./AssignBulkSkillCoins";
import { icons } from "../../../assets/index";
import { timeDateFormat } from "../../../component/FormatDate";
import * as XLSX from "xlsx";
import YearAndMonthDropdown from "../../../component/YearAndMonthDropdown/YearAndMonthDropdown";

const SkillCoinsTable = (props: any) => {
  const { template_icon, bulk_upload_icon } = icons;
  const {
    noData,
    skill_heading,
    refNo,
    empId,
    desc,
    assignDate,
    noSkill,
    bookValue,
    dateOfVesting,
    aggreDesc,
    forwarDesc,
    bestWish,
    skillName,
    signature_name,
    role,
    template,
  } = Strings;
  const { edit_blue_icon, skill_add, skill_edit } = icons;
  const {
    totalPages,
    onPage,
    handlePageChange,
    searchKey,
    searchValue,
    handleSearch,
    filterValue,
    filterChange,
    pagePerSize,
    setPagePerSize,
    totalEntries,
    totalEntriesLoaded,
    info,
    getApi,
    onSearchClicked,
    showLoadingIndicator,
    hideLoadingIndicator,
    apiCallCompleted,
    loadingComponent,
    handleMonthChange,
    handleYearChange,
    setFilterYearValues,
    setFilterMonthValues,
    apiCallFunction,
    setClickedAdd,
    setClickedDeallocate,
    setClickedBulkUpload,
    filterYearValues,
    filterMonthValues,
    filterKey,
  } = props;

  const data = info;
  const navigate = useNavigate();
  const authRole = localStorage.getItem("role");
  const isCheckedData = authRole?.includes("skillcoin-checker");
  const isMakerData = authRole?.includes("skillcoin-maker");
  const [pData, setPData] = useState<any>({
    candidateName: "",
    middleName: "",
    lastname: "",
    referenceNo: "",
    employeeId: "",
    assigned_date: "",
    skillcoins: "",
    skValue: "",
    maturity_date: "",
  });
  const [datas, setdata] = useState<any>([]);
  const dispatch = useDispatch();
  const [skillCoinVal, setSkillCoinVal] = useState("");
  const [id, setId] = useState("");
  const [employeeName, setEmployeeName] = useState("");
  const [isSelected, setIsSelected] = useState(false);
  const [showAddEmployee, setShowAddEmployee] = useState(false);
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [showBulkAssignSkillCoins, setShowBulkAssignSkillCoins] =
    useState(false);
  const [showBulkDeallocation, setShowBulkDeallocation] = useState(false);
  const [showMonthAndYear, setShowMonthAndYear] = useState(false);
  const [assignBulkData, setAssignBulkData] = useState<any>([]);
  const [showAllocateSkillCoins, setShowAllocateSkillCoins] = useState(false);
  const [skillCoinData, setSkillCoinData] = useState({
    skillcoins: "",
    skillCoinId: "",
    assignedDate: "",
    maturityDate: "",
    employeeId: "",
    employeeName: "",
    recordStatus: "",
    status: "",
    assignment_letter: "",
    remarks: "",
  });
  const [displaySkillCoinValue, setDisplaySkillCoinValue] = useState({
    displayId: "",
    value: "",
  });
  const [showDeallocateSkillCoins, setShowDeallocateSkillCoins] =
    useState(false);
  const [disabled, setDisabled] = useState(false);
  const [expansion] = useState(false);
  const [rowid] = useState("");
  const [btnDisable, setBtnDisable] = useState(false);

  const { value } = displaySkillCoinValue;

  const fileInputRef = useRef<HTMLInputElement>(null);

  const SkillCoinData = useSelector(
    (state: any) => state.employeeReducer.getSkillCoinData
  );

  const updateSkillCoinValue = useSelector(
    (state: any) => state.employeeReducer.updateSkillCoinValue
  );

  useEffect(() => {
    if (totalEntriesLoaded) {
      const payload: any = {
        page: onPage,
        size: totalEntries,
        searchValue: searchValue,
      };
      if (filterYearValues) {
        payload.year = filterYearValues;
      }
      if (filterMonthValues) {
        payload.month = filterMonthValues;
      }
      if (!filterKey.includes("MonthAndYear")) {
        payload.searchKey = filterKey;
      } else {
        const filteredKeys = filterKey.filter(
          (key: any) => key !== "MonthAndYear"
        );
        if (filteredKeys.length > 0) {
          payload.searchKey = filteredKeys;
        }
      }
      dispatch(
        actions.getkillDataPaginationAction(
          payload,
          (res: any) => {
            if (res?.error?.error_description) {
              showErrorToastMessage(res.error.error_description);
            } else if (res?.code === 200) {
                setAssignBulkData(res.data);
              }
          }
        )
      );
    }
  }, [totalEntries]);

  useEffect(() => {
    if (isSelected) {
      const activeData =
        filteredData?.length > 0
          ? filteredData?.filter((ele: any) => ele.status !== "Inactive")
          : data?.filter((ele: any) => ele.status !== "Inactive");
      const allRowIds = activeData.map((ele: any) => ele._id);
      setSelectedRows(allRowIds);
    } else {
      setSelectedRows([]);
    }
  }, [isSelected]);

  useEffect(() => {
    if (SkillCoinData && SkillCoinData.data) {
      skillValueGetApi();
    }
  }, [
    SkillCoinData,
    info,
    onPage,
    searchValue,
    searchKey,
    filterValue,
    pagePerSize,
    data,
    updateSkillCoinValue,
  ]);

  useEffect(() => {
    const pdfContentDiv: any = document.getElementById("pdf-content");
    if (pdfContentDiv) {
      if (pdfContentDiv.style.display === "block") {
        const pdfDocument = new jsPDF();
        const table = pdfContentDiv;

        html2canvas(table).then((canvas) => {
          const imgData = canvas.toDataURL("image/png");
          const imgProps = pdfDocument.getImageProperties(imgData);
          const pdfWidth = pdfDocument.internal.pageSize.getWidth() - 20;
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

          pdfDocument.addImage(imgData, "PNG", 10, 10, pdfWidth, pdfHeight);
          pdfDocument.save("Assignment Letter.pdf");

          pdfContentDiv.style.display = "none";
        });
      }
    }
  }, [pData]);

  useEffect(() => {
    if (selectedRows.length == 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [disabled]);
  useEffect(() => {
    if (displaySkillCoinValue.value.length > 0) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  });

  const handleBulkAssignSkillCoins = () => {
    setShowBulkAssignSkillCoins(true);
  };
  const hideBulkAssignSkillCoins = () => {
    setShowBulkAssignSkillCoins(false);
  };

  const handleSelectAll = (event: any) => {
    if (event.target.checked) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }
  };
  const hideChecked = () => {
    setSelectedRows([]);
    if (isSelected) {
      setIsSelected(!isSelected);
    }
  };

  const handleRowCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    rowId: any
  ) => {
    const isChecked = event.target.checked;
    setSelectedRows((prevSelectedRows: any) =>
      isChecked
        ? [...prevSelectedRows, rowId]
        : prevSelectedRows.filter((id: any) => id !== rowId)
    );
    if (!isChecked && isSelected && selectedRows.length === 1) {
      setIsSelected(false);
    }
  };

  const handleDeallocateSkillCoins = (
    skillcoins: any,
    skillCoinId: any,
    assignedDate: any,
    maturityDate: any,
    employeeId: any,
    employeeName: any,
    recordStatus: any,
    status: any,
    assignment_letter: any,
    remarks: any
  ) => {
    setShowDeallocateSkillCoins(true);
    setSkillCoinData({
      skillcoins: skillcoins,
      skillCoinId: skillCoinId,
      assignedDate: assignedDate,
      maturityDate: maturityDate,
      employeeId: employeeId,
      employeeName: employeeName,
      recordStatus: recordStatus,
      status: status,
      assignment_letter: assignment_letter,
      remarks: remarks,
    });
  };

  const handleEmployeeDetails = (selectedId: any) => {
    navigate(`/employee-skillcoin/${selectedId}`);
  };

  const COLUMNS: any = [
    {
      Header: (
        <div>
          <input
            name="selectAll"
            className="form-check-input"
            type="checkbox"
            onClick={(e: any) => handleSelectAll(e)}
          />
        </div>
      ),
      accessor: "checkbox",
    },
    {
      Header: "Employee Name",
      accessor: "candidateName",
      Cell: (Action: any) => {
        const name = `${
          Action.row.original.middleName
            ? Action.row.original.candidateName +
              " " +
              Action.row.original.middleName +
              " " +
              Action.row.original.lastname
            : Action.row.original.candidateName +
              " " +
              Action.row.original.lastname
        } `;
        const selectedId = Action.row.original._id;
        return (
          <div
            className="hyperlink"
            onClick={() => handleEmployeeDetails(selectedId)}
          >
            {name}
          </div>
        );
      },
    },
    {
      Header: "E-Code",
    },
    {
      Header: "Date of Joining",
      accessor: "dateOfJoining",
      Cell: (Action: any) => {
        const joiningdate = Action.row.original.dateOfJoining;
        return timeDateFormat(joiningdate);
      },
    },

    {
      Header: "Total Skillcoins",
      accessor: "totalSkillCoins",
      Cell: (Action: any) => {
        const skillCoins = Action.row.original.totalSkillCoins;
        const formattedDates = skillCoins.map(
          (coin: any) => coin.totalSkillCoins
        );
        return (
          <div>
            {formattedDates.map((date: any, index: any) => (
              <p key={index}>{date}</p>
            ))}
          </div>
        );
      },
    },
  ];

  const makerColumns = [
    ...COLUMNS,
    {
      Header: "Action",
      disableSortBy: false,
      Cell: (Action: any) => {
        return (
          <>
            <div className="action-btn">
              <img
                data-toggle="tooltip"
                data-placement="bottom"
                title="Edit"
                src={edit_blue_icon}
                alt="edit_icon"
                className="edit-icon"
              />
            </div>
          </>
        );
      },
    },
  ];

  const columns = useMemo(
    () => (isMakerData ? makerColumns : COLUMNS),
    [pagePerSize, authRole]
  );

  const skillValueGetApi = () => {
    dispatch(
      actions.getSkillCoinValueAction((res: any) => {
            if (res?.error?.error_description) {
              showErrorToastMessage(res.error.error_description);
            }
         else  if (res?.code === 200) {
              setDisplaySkillCoinValue({
                displayId: res.data[0]._id,
                value: "",
              });
              setSkillCoinVal(res.data[0].skillCoinValue);
            }
      })
    );
  };

  const printPdf = (
    e: any,
    candidateName: any,
    middleName: any,
    lastname: any,
    employeeId: any,
    assigned_date: any,
    skillcoins: any,
    maturity_date: any
  ) => {
    e.preventDefault();
    let assignedDateFormat = timeDateFormat(assigned_date);
    let generateReference = "SMTC-SCA-" + employeeId + "-" + assignedDateFormat;
    setPData((obj: any) => ({
      ...obj,
      candidateName: candidateName,
      middleName: middleName,
      lastname: lastname,
      referenceNo: generateReference,
      employeeId: employeeId,
      assigned_date: assigned_date,
      skillcoins: skillcoins,
      skValue: skillCoinVal,
      maturity_date: maturity_date,
    }));
    const pdfContentDiv: any = document.getElementById("pdf-content");
    if (pdfContentDiv) {
      pdfContentDiv.style.display = "block";
    }
  };

  const handleAllocateSkillCoins = (id: any, employeeName: any) => {
    setShowAllocateSkillCoins(true);
    setId(id);
    setEmployeeName(employeeName);
  };

  const hideAllocateSkillCoins = () => {
    setShowAllocateSkillCoins(false);
  };

  const hideBulkDeallocateSkillCoins = () => {
    setShowBulkDeallocation(false);
  };

  const hideDeallocateSkillCoins = () => {
    setShowDeallocateSkillCoins(false);
  };

  const handleAddModalShow = () => {
    setShowAddEmployee(true);
  };
  const hideAddModal = () => {
    setShowAddEmployee(false);
  };

  const handleEditSubmit = () => {
    apiCallCompleted(false);
    dispatch(
      actions.updateSkillCoinValueAction(displaySkillCoinValue, (res: any) => {
        if (res) {
          if (res.error) {
            if (res.error.error_description) {
              showErrorToastMessage(res.error.error_description);
              apiCallCompleted(true);
            }
          } else {
            if (res.code === 200) {
              showSuccessToastMessage("Edited successfully");
              setDisplaySkillCoinValue({
                ...displaySkillCoinValue,
                value: "",
              });
              apiCallCompleted(true);
            }
          }
        }
      })
    );
  };

  const handleOnChange = (e: any) => {
    setDisplaySkillCoinValue({
      ...displaySkillCoinValue,
      value: e.target.value.replace(/\D/g, ""),
    });
  };

  const handleBulkUpload = (e: any) => {
    if (e.target.files?.length > 0) {
      setShowBulkDeallocation(false);
      showLoadingIndicator();
      if (e.target.files[0]?.type == "text/csv") {
        dispatch(
          actions.skillCoinUploadApiAction(
            { file: e.target.files[0] },
            (res: any) => {
                  if (res?.error?.error_description) {
                    hideLoadingIndicator();
                    showErrorToastMessage(res.error.error_description);
                  } else if(res.code === 200) {
                    hideLoadingIndicator();
                    const uploadedCount = res?.meta_data?.uploadedRecordsCount;
                    const faultRecordsCount = res?.meta_data?.faultRecordsCount;
                    setClickedDeallocate(true);
                    if (faultRecordsCount > 0) {
                      var BasicInfoData = [postaJobExportCol];
                      res.data?.forEach((ele: any) => {
                        let emptyArray: string[] = [];
                        emptyArray.push(
                          ele.jobTitle,
                          ele.jobCategory,
                          ele.modeOfWork,
                          ele.experience,
                          ele.location,
                          ele.workLevel,
                          ele.referralAmount,
                          ele.description
                        );
                        BasicInfoData.push(emptyArray);
                      });
                      var BasicInfo = XLSX.utils.aoa_to_sheet(BasicInfoData);
                      const wb = XLSX.utils.book_new();
                      XLSX.utils.book_append_sheet(wb, BasicInfo, "post a job");

                      XLSX.writeFile(wb, "Fault_Records_Post_Job.xlsx");
                    }
                    if (fileInputRef.current) {
                      fileInputRef.current.value = "";
                    }
                    if (uploadedCount > 0 && faultRecordsCount === 0)
                      showSuccessToastMessage("Uploaded Successfully");
                    if (faultRecordsCount > 0 && uploadedCount > 0)
                      showSuccessToastMessage(
                        `Uploaded ${uploadedCount} Records and Downloaded ${faultRecordsCount} Fault Records`
                      );
                    if (uploadedCount === 0 && faultRecordsCount > 0)
                      showErrorToastMessage(
                        `Downloaded ${faultRecordsCount} Fault Records`
                      );
                    if (uploadedCount === 0 && faultRecordsCount === 0)
                      showErrorToastMessage("Please Upload Valid Document");
                    apiCallFunction();
                  }
            }
          )
        );
      } else {
        hideLoadingIndicator();
        showErrorToastMessage("Please Upload .csv File");
      }
    }
  };
  const filteredData: any = assignBulkData?.filter((project: any) => {
    if (!project) {
      return false;
    }

    const { skillCoins } = project;
    const skillCoinsData = skillCoins && skillCoins[0];

    return (
      !searchValue ||
      project?.employeeId?.toLowerCase().includes(searchValue) ||
      project?.candidateName?.toLowerCase().includes(searchValue) ||
      project?.status?.toLowerCase() === searchValue?.toLowerCase() ||
      project?.middleName?.toLowerCase().includes(searchValue) ||
      project?.lastname?.toLowerCase().includes(searchValue) ||
      project?.dateOfJoining?.toLowerCase().includes(searchValue) ||
      (skillCoinsData &&
        (skillCoinsData.skillcoins == searchValue ||
          skillCoinsData.status?.toLowerCase().includes(searchValue) ||
          skillCoinsData.assigned_date?.toLowerCase().includes(searchValue) ||
          skillCoinsData.maturity_date?.toLowerCase().includes(searchValue) ||
          skillCoinsData.recordStatus?.toLowerCase().includes(searchValue)))
    );
  });

  return (
    <>
      {loadingComponent()}
      <div className="skill-heading-button-wrapper">
        <div>
          <h2>{skill_heading}</h2>
        </div>
        {isMakerData ? (
          <div className="btn-wrapper">
            <Button
              title="+ Add Employee"
              onClick={() => handleAddModalShow()}
              className="add-skillcoin-btn"
            />
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="skill-heading-section">
        <label className="heading-label">
          Skill Coin Value (1 Skill coin = {skillCoinVal}&#8377;)
        </label>
      </div>
      {!(isMakerData || isCheckedData) ? (
        ""
      ) : (
        <div className="update-skill-section-parent">
          <div className="update-left-section">
            <input
              type="text"
              className="form-control"
              onChange={(e) => handleOnChange(e)}
              value={value}
            />
            <div className="btn-wrapper">
              <Button
                title="Update Coin Value"
                onClick={handleEditSubmit}
                className={
                  btnDisable
                    ? "btn add-submit-button update-skillcoin-btn"
                    : "btn add-submit-button update-disable-skillcoin-btn"
                }
              />
            </div>
          </div>
          <div className="update-right-section">
            <div className="btn-wrapper">
              {isMakerData || isCheckedData ? (
                <>
                  <Button
                    title="+ Bulk Assign"
                    className={
                      selectedRows?.length == 0
                        ? "bulk-disable-btn"
                        : "update-skillcoin-btn"
                    }
                    onClick={() => handleBulkAssignSkillCoins()}
                    disabled={selectedRows?.length == 0}
                  />
                  <Button
                    title=" Bulk Deallocation"
                    className="update-skillcoin-btn"
                    onClick={() => setShowBulkDeallocation(true)}
                    disabled={selectedRows?.length == 0}
                  />
                </>
              ) : (
                ""
              )}
              <Button
                title="View Report"
                onClick={() => navigate("/skill-coins-report")}
                className="update-skillcoin-btn"
              />
            </div>
          </div>
        </div>
      )}

      <div className="mt-2">
        <div className="mt-3 buttonsize filter-wrapper margin-left">
          <div className="skill-searchbar-wrapper ">
            <SearchBar
              filter={searchValue}
              setFilter={handleSearch}
              onSearchClicked={onSearchClicked}
            />
          </div>

          <div className="skill-filter-container">
            <select
              id="select-filters"
              value={typeof filterValue === 'string' ? filterValue : ''}
              onChange={(event) => {
                const selectedValue = event.target.value;
                if (selectedValue === "MonthAndYear") {
                  setShowMonthAndYear(true);
                }
                const selectedOption = skillDbFilters.find(
                  (item: any) => item.value === selectedValue
                );
                if (selectedOption) {
                  filterChange(selectedOption);
                }
              }}
            >
              <option hidden>Filters</option>
              {skillDbFilters.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.title}
                </option>
              ))}
            </select>
          </div>
        </div>
        {showMonthAndYear && (
          <YearAndMonthDropdown
            onMonthChange={handleMonthChange}
            onYearChange={handleYearChange}
          />
        )}
        <FilterValues
          filterValueArray={filterValue}
          onFilterChange={filterChange}
          setShowMonthAndYear={setShowMonthAndYear}
          setFilterYearValues={setFilterYearValues}
          setFilterMonthValues={setFilterMonthValues}
        />

        {data.length > 0 ? (
          <>
            <div className="table table-responsive">
              <Table className="table-main table table-sm mt-3">
                <thead className="table-head">
                  <tr className="custom-table-head-row">
                    {columns.map((ele: any) => (
                      <React.Fragment key={ele.Header}>
                        <td className="table-head-row">
                          {ele.Header}
                          {ele.Header !== "Assignment Letter" &&
                          ele.Header !== "Action" &&
                          ele.Header !== "" &&
                          ele.Header !== "Record Status" &&
                          ele.Header != "Remark" ? (
                            <span>{<FaSort />}</span>
                          ) : (
                            " "
                          )}
                        </td>
                      </React.Fragment>
                    ))}
                  </tr>
                </thead>

                <tbody className="table-body">
                  {data?.length === 0 ? (
                    <tr className="table-body-row">
                      <td colSpan={12}> {noData}</td>
                    </tr>
                  ) : (
                    data.map((ele: any, index: any) => (
                      <React.Fragment key={index}>
                        <tr key={ele?._id} className="table-body-row">
                          <td>
                            {ele.status === "Inactive" ? (
                              <></>
                            ) : (
                              <input
                                name="remember"
                                className="form-check-input"
                                type="checkbox"
                                checked={
                                  ele?._id &&
                                  selectedRows?.includes(ele?._id as string)
                                }
                                onChange={(e: any) =>
                                  handleRowCheckboxChange(e, ele?._id)
                                }
                              />
                            )}
                          </td>

                          <td
                            className={
                              ele.status === "Inactive" ? "Inactive" : ""
                            }
                          >
                            <div
                              className="hyperlink"
                              onClick={() => handleEmployeeDetails(ele?._id)}
                            >
                              {ele?.candidateName +
                                " " +
                                ele?.middleName +
                                " " +
                                ele?.lastname}
                            </div>
                          </td>
                          <td>{ele?.employeeId}</td>
                          <td>
                            {`${
                              ele?.dateOfJoining
                                ? timeDateFormat(ele?.dateOfJoining)
                                : ""
                            }`}
                          </td>

                          <td> {ele?.totalSkillCoins}</td>
                          {isMakerData && (
                            <td className="d-flex flex-row justify-content-center gap align-items-center last-child">
                              <div
                                className={
                                  ele.status === "Inactive" || !isMakerData
                                    ? "disable-add-section"
                                    : "add-section"
                                }
                                onClick={() =>
                                  handleAllocateSkillCoins(
                                    ele._id,
                                    ele?.candidateName +
                                      " " +
                                      ele?.middleName +
                                      " " +
                                      ele?.lastname +
                                      "-" +
                                      ele?.employeeId
                                  )
                                }
                              >
                                <img
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title="Edit"
                                  src={skill_add}
                                  alt="edit_icon"
                                  className={
                                    ele.status === "Inactive" || !isMakerData
                                      ? "disable-icon"
                                      : "add-icon"
                                  }
                                />
                              </div>
                            </td>
                          )}
                        </tr>
                        {index === rowid &&
                          expansion &&
                          (ele.skillCoins?.length === 0 ? (
                            <tr className="table-body-row">
                              <td colSpan={11}> {noData}</td>
                            </tr>
                          ) : (
                            ele.skillCoins.slice(1).map((item: any) => (
                              <tr className="table-body-row">
                                <>
                                  <td>-</td>

                                  <td>-</td>
                                  <td>-</td>
                                  <td>
                                    <button
                                      type="button"
                                      className={
                                        item?.status === "allocated"
                                          ? "btn employeedb-status-btn employee-active-btn"
                                          : item?.status === "deallocated"
                                          ? "btn employeedb-status-btn employee-inactive-btn"
                                          : ""
                                      }
                                    >
                                      {item?.status === "allocated"
                                        ? "Allocated"
                                        : item?.status === "assigned"
                                        ? "Assigned"
                                        : item?.status === "deallocated"
                                        ? "Deallocated"
                                        : item?.status === "settled"
                                        ? "Settled"
                                        : ""}
                                    </button>
                                  </td>
                                  <td>
                                    <div>{item?.skillcoins}</div>
                                  </td>
                                  <td>
                                    <div>
                                      {timeDateFormat(item?.assigned_date)}
                                    </div>
                                  </td>
                                  <td>
                                    <div>
                                      {timeDateFormat(item?.maturity_date)}
                                    </div>
                                  </td>
                                  <td>
                                    {item.skillcoins ? (
                                      <a
                                        href=""
                                        className="center"
                                        onClick={(e) => {
                                          printPdf(
                                            e,
                                            ele?.candidateName,
                                            ele?.middleName,
                                            ele?.lastname,
                                            ele?.employeeId,
                                            item?.assigned_date,
                                            item?.skillcoins,
                                            item?.maturity_date
                                          );
                                        }}
                                      >
                                        <BsDownload />
                                      </a>
                                    ) : (
                                      <></>
                                    )}
                                  </td>
                                  <td>{item?.recordStatus}</td>
                                  <td>
                                    {item?.status != "Settled" ? (
                                      <>
                                        <div className="edit-section">
                                          <img
                                            data-toggle="tooltip"
                                            data-placement="bottom"
                                            title="Edit"
                                            src={skill_edit}
                                            alt="edit_icon"
                                            className={
                                              !item.skillcoins ||
                                              !isMakerData ||
                                              isCheckedData
                                                ? "bulk-disable-btn"
                                                : "edit-skil-icon"
                                            }
                                            onClick={() =>
                                              handleDeallocateSkillCoins(
                                                item.skillcoins,
                                                item._id,
                                                item?.assigned_date,
                                                item?.maturity_date,
                                                ele?.employeeId,
                                                ele?.candidateName +
                                                  " " +
                                                  ele?.middleName +
                                                  " " +
                                                  ele?.lastname,
                                                item?.recordStatus,
                                                item?.status,
                                                item?.assignement_letter,
                                                item?.remarks
                                              )
                                            }
                                          />
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div className="edit-section">
                                          <img
                                            data-toggle="tooltip"
                                            data-placement="bottom"
                                            title="Edit"
                                            src={skill_edit}
                                            alt="edit_icon"
                                            className="edit-skil-icon"
                                            onClick={() =>
                                              showErrorToastMessage(
                                                "Cannot Deallocate Skillcoins"
                                              )
                                            }
                                          />
                                        </div>
                                      </>
                                    )}
                                  </td>
                                </>
                              </tr>
                            ))
                          ))}
                      </React.Fragment>
                    ))
                  )}
                </tbody>
              </Table>
            </div>

            <div className="show-entries-wrapper">
              <ShowEntries
                pageSize={pagePerSize}
                setPageSize={setPagePerSize}
                totalRecords={0}
                totalEntries={totalEntries}
              />

              <TablePagination
                totalPages={totalPages}
                currentPage={onPage}
                onClick={handlePageChange}
              />
            </div>
          </>
        ) : (
          <p className="employeedata">{noData}</p>
        )}

        <ModalComponents
          show={showBulkDeallocation}
          onHide={hideBulkDeallocateSkillCoins}
          title="Bulk Deallocate"
          modalBody={
            <div>
              <div className="">
                <div className="col-md-8 d-flex align-items-center ">
                  <label className="form-label mb-0">Download Template</label>
                  <span className="required ml-1">*</span>

                  <div className="report-btn-wrapper">
                    <div className="export-btn">
                      <a
                        href="https://management-spotlight.sgp1.digitaloceanspaces.com/employee/Bulk-Deallocation-Template-File/48698cc6-97ef-498f-899c-534475b97cf6.csv"
                        download="tempfile.csv"
                      >
                        <button>
                          <img src={template_icon} alt="template_icon" />{" "}
                          {template}
                        </button>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-md-8 d-flex align-items-center">
                  <label className="form-label mb-0">Upload Document</label>
                  <span className="required ml-1">*</span>

                  <div className="report-btn-wrapper paddinf-left-upload">
                    <div className="export-btn">
                      <button>
                        <img src={bulk_upload_icon} alt="bulk_upload_icon" />{" "}
                        upload
                      </button>
                      <input
                        type="file"
                        accept={".csv"}
                        onChange={handleBulkUpload}
                        id="bulk_upload"
                        ref={fileInputRef}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
          className="modal-lg"
        />
        <ModalComponents
          show={showAllocateSkillCoins}
          onHide={hideAllocateSkillCoins}
          title="Assign Skill Coins"
          modalBody={
            <AllocateSkillCoins
              apiCallCompleted={apiCallCompleted}
              hideAllocateSkillCoins={hideAllocateSkillCoins}
              employeeId={id}
              employeeName={employeeName}
              apiCallFunction={apiCallFunction}
              setClickedAdd={setClickedAdd}
            />
          }
          className="modal-lg"
        />

        <ModalComponents
          show={showDeallocateSkillCoins}
          onHide={hideDeallocateSkillCoins}
          title="Edit Skill Coins Status"
          modalBody={
            <DeallocateSkillCoins
              apiCallCompleted={apiCallCompleted}
              skillCoinData={skillCoinData}
              hideDeallocateSkillCoins={hideDeallocateSkillCoins}
            />
          }
          className="modal-md"
        />

        <ModalComponents
          show={showAddEmployee}
          onHide={hideAddModal}
          title="Add Employee Details"
          modalBody={
            <AddSpecificEmployee
              hideAddModal={hideAddModal}
              apiCallCompleted={apiCallCompleted}
            />
          }
          className="modal-lg"
        />

        <ModalComponents
          show={showBulkAssignSkillCoins}
          onHide={hideBulkAssignSkillCoins}
          title="Assign Skill Coins"
          modalBody={
            <AssignBulkSkillCoins
              setData={setdata}
              hideChecked={hideChecked}
              hideAllocateSkillCoins={hideBulkAssignSkillCoins}
              employeeId={selectedRows}
              getApi={getApi}
              setShowBulkAssignSkillCoins={setShowBulkAssignSkillCoins}
              showLoadingIndicator={showLoadingIndicator}
              hideLoadingIndicator={hideLoadingIndicator}
              skillValueGetApi={skillValueGetApi}
              setClickedBulkUpload={setClickedBulkUpload}
              apiCallFunction={apiCallFunction}
            />
          }
          className="modal-md"
        />
        <div id="pdf-content" style={{ display: "none" }}>
          <div className="p-4">
            <img src={Header} alt="no_header_img" className="footer-img" />
          </div>
          <div className="p-4">
            <h5>
              <b>{refNo}</b>
              {pData.referenceNo}
            </h5>
            <p className="mt-3">To,</p>
            <p className="m-0">
              <b>
                {pData.candidateName +
                  " " +
                  pData.middleName +
                  " " +
                  pData.lastname}
              </b>
            </p>
            <h5 className="pt-3">
              {empId}
              {pData.employeeId}
            </h5>
            <p className="paragraph-section">{desc}</p>
          </div>
          <div className="table table-responsive">
            <table className="table-main table table-sm mt-3">
              <thead className="table-head">
                <tr>
                  <th className="table-head-row">{assignDate}</th>
                  <th className="table-head-row">{noSkill}</th>
                  <th className="table-head-row">{bookValue}</th>
                  <th className="table-head-row">{dateOfVesting}</th>
                </tr>
              </thead>
              <tbody className="table-body">
                <tr>
                  <td className="table-body-row">
                    {timeDateFormat(pData.assigned_date)}
                  </td>
                  <td className="table-body-row">{pData.skillcoins}</td>
                  <td className="table-body-row">{pData.skValue}</td>
                  <td className="table-body-row">
                    {timeDateFormat(pData.maturity_date)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="p-4">
            <p className="font-main-content">{aggreDesc}</p>
            <p className="font-main-content">{forwarDesc}</p>
            <p className="wishes-title">{bestWish}</p>
            <h4>{skillName}</h4>
            <img src={Signature} alt="no_img" />
            <h5>{signature_name}</h5>
            <h5>{role}</h5>
          </div>
          <div className="p-4">
            <img src={Footer} alt="no_footer_img" className="footer-img" />
          </div>
        </div>
      </div>
    </>
  );
};

export default SkillCoinsTable;
