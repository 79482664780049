import  { useState } from "react";
import MainCard from "../component/MainCard/MainCard";

const tableData: any = [
  { id: 1, name: "Alice", age: 28 },
  { id: 2, name: "Bob", age: 35 },
  { id: 3, name: "Charlie", age: 42 },
  { id: 4, name: "David", age: 19 },
  { id: 5, name: "Emily", age: 24 },
];

function Dashboard() {
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [inputFieldData, setInputFieldData] = useState("");

  const handleRowClick = (rowData: any) => {
    if (selectedRows.find((row: any) => row.id === rowData.id)) {
      setSelectedRows(selectedRows.filter((row: any) => row.id !== rowData.id));
    } else {
      setSelectedRows([...selectedRows, rowData]);
    }
  };

  const handleAddToInput = (rowData: any) => {
    if (inputFieldData.includes(rowData.name)) {
      setInputFieldData(inputFieldData.replace(rowData.name, ""));
    } else {
      setInputFieldData(inputFieldData + " " + rowData.name);
    }
  };

  return (
    <MainCard>
      <div>
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Age</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((rowData: any) => (
              <tr key={rowData.id}>
                <td>{rowData.id}</td>
                <td>{rowData.name}</td>
                <td>{rowData.age}</td>
                <td>
                  <button
                    onClick={() => {
                      handleRowClick(rowData);
                      handleAddToInput(rowData);
                    }}
                  >
                    {selectedRows.find((row: any) => row.id === rowData.id)
                      ? "Remove"
                      : "Add"}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <br />
        <label>Selected Rows: </label>
        {selectedRows.map((row: any) => (
          <span key={row.id}>{row.name}, </span>
        ))}
        <br />
        <label>Input Field Data: </label>
        <input
          type="text"
          value={inputFieldData}
          onChange={(event) => setInputFieldData(event.target.value)}
        />
      </div>
    </MainCard>
  );
}

export default Dashboard;
