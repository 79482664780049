import  { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import MainCard from "../../../component/MainCard/MainCard";
import "../EmployeeDb.scss";
import useLoadingIndicator from "../../../component/Loader/Loader";
import * as actions from "../../../Redux/Actions";
import { useDispatch } from "react-redux";
import { showErrorToastMessage } from "../../../component/Toasts/Toasts";
import { CSVLink } from "react-csv";
import Button from "../../../component/Buttons/Button";
import moment from "moment";
import { Strings } from "../../../constant/Strings";
import { icons } from "../../../assets";
import hanldeUnauthorizedUser from "../../../component/AccessConditions/UnauthorizedUser";

const EmployeeReports = () => {
  const { exportReport, employee_record_status_details ,Reports,Greater,Less} = Strings;
  const { upload_white_icon } = icons;
  const { unAuthorizeUser } = hanldeUnauthorizedUser();
  const { loadingComponent, showLoadingIndicator, hideLoadingIndicator } =
    useLoadingIndicator();
  const [reportLT3DData, setReportLT3DData] = useState<any>([]);
  const [reportGT3DData, setReportGT3DData] = useState<any>([]);
  const [infoLT3D, setInfoLT3D] = useState<any>([]);
  const [infoGT3D, setInfoGT3D] = useState<any>([]);
  const [selectOption, setSelectOption] = useState<any>("Less than 3 days");
  const dispatch = useDispatch();

  useEffect(() => {
    unAuthorizeUser();
    dispatch(
      actions.getReportLessthan3DaysAction((res: any) => {
        showLoadingIndicator();
            if (res?.error?.error_description) {
              showErrorToastMessage(res.error.error_description);
            } else if (res.code === 200) {
              setReportLT3DData(res.data);
              hideLoadingIndicator();
        }
      })
    );
  }, []);

 

  useEffect(() => {
    unAuthorizeUser();
    dispatch(
      actions.getReportGreaterthan3DaysAction((res: any) => {
         if (res?.error?.error_description) {
              showErrorToastMessage(res.error.error_description);
            }  else if (res.code === 200) {
              setReportGT3DData(res.data);
        }
      })
    );
  }, []);

  useEffect(() => {
    if (reportLT3DData) {
      setInfoLT3D(reportLT3DData?.statusLessThanThreeDays);
    }
  }, [reportLT3DData]);

  useEffect(() => {
    if (reportGT3DData) {
      setInfoGT3D(reportGT3DData?.statusMoreThanOrEqualToThreeDays);
    }
  }, [reportGT3DData]);

  const dataGT3D: any = {
    labels: reportGT3DData && [
      `New : ${reportGT3DData?.countOfStatus?.newCount[0]?.totalCount}`,
      `Draft : ${reportGT3DData?.countOfStatus?.draftCount[0]?.totalCount}`,
      `Published : ${reportGT3DData?.countOfStatus?.publishedCount[0]?.totalCount}`,
      `Re-Sent : ${reportGT3DData?.countOfStatus?.reSentCount[0]?.totalCount}`,
      `Re-Published : ${reportGT3DData?.countOfStatus?.rePublishedCount[0]?.totalCount}`,
      `Approved : ${reportGT3DData?.countOfStatus?.approvedCount[0]?.totalCount}`,
      `Absconded : ${reportGT3DData?.countOfStatus?.abscondedCount[0]?.totalCount}`,
      `Resigned : ${reportGT3DData?.countOfStatus?.resignedCount[0]?.totalCount}`,
    ],

    datasets: [
      {
        data: reportGT3DData && [
          `${reportGT3DData?.countOfStatus?.newCount[0]?.totalCount}`,
          `${reportGT3DData?.countOfStatus?.draftCount[0]?.totalCount}`,
          `${reportGT3DData?.countOfStatus?.publishedCount[0]?.totalCount}`,
          `${reportGT3DData?.countOfStatus?.reSentCount[0]?.totalCount}`,
          `${reportGT3DData?.countOfStatus?.rePublishedCount[0]?.totalCount}`,
          `${reportGT3DData?.countOfStatus?.approvedCount[0]?.totalCount}`,
          `${reportGT3DData?.countOfStatus?.abscondedCount[0]?.totalCount}`,
          `${reportGT3DData?.countOfStatus?.resignedCount[0]?.totalCount}`,
        ],
        backgroundColor: [
          "rgb(166, 238, 238)",
          "rgb(238, 214, 168)",
          "rgb(153, 153, 248)",
          "rgba(243, 142, 59, 0.918)",
          "rgb(81, 81, 253)",
          "#1ba08a",
          "rgb(245, 118, 118)",
          "rgb(255, 192, 203)",
        ],
        hoverBackgroundColor: [
          "rgb(166, 238, 238)",
          "rgb(238, 214, 168)",
          "rgb(153, 153, 248)",
          "rgba(243, 142, 59, 0.918)",
          "rgb(81, 81, 253)",
          "#1ba08a",
          "rgb(245, 118, 118)",
          "rgb(255, 192, 203)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const dataLT3D: any = {
    labels: reportLT3DData && [
      `New : ${reportLT3DData?.countOfStatus?.newCount[0]?.totalCount}`,
      `Draft : ${reportLT3DData?.countOfStatus?.draftCount[0]?.totalCount}`,
      `Published : ${reportLT3DData?.countOfStatus?.publishedCount[0]?.totalCount}`,
      `Re-Sent : ${reportLT3DData?.countOfStatus?.reSentCount[0]?.totalCount}`,
      `Re-Published : ${reportLT3DData?.countOfStatus?.rePublishedCount[0]?.totalCount}`,
      `Approved : ${reportLT3DData?.countOfStatus?.approvedCount[0]?.totalCount}`,
      `Absconded : ${reportLT3DData?.countOfStatus?.abscondedCount[0]?.totalCount}`,
      `Resigned : ${reportLT3DData?.countOfStatus?.resignedCount[0]?.totalCount}`,
    ],
    datasets: [
      {
        data: reportLT3DData && [
          `${reportLT3DData?.countOfStatus?.newCount[0]?.totalCount}`,
          `${reportLT3DData?.countOfStatus?.draftCount[0]?.totalCount}`,
          `${reportLT3DData?.countOfStatus?.publishedCount[0]?.totalCount}`,
          `${reportLT3DData?.countOfStatus?.reSentCount[0]?.totalCount}`,
          `${reportLT3DData?.countOfStatus?.rePublishedCount[0]?.totalCount}`,
          `${reportLT3DData?.countOfStatus?.approvedCount[0]?.totalCount}`,
          `${reportLT3DData?.countOfStatus?.abscondedCount[0]?.totalCount}`,
          `${reportLT3DData?.countOfStatus?.resignedCount[0]?.totalCount}`,
        ],
        backgroundColor: [
          "rgb(166, 238, 238)",
          "rgb(238, 214, 168)",
          "rgb(153, 153, 248)",
          "rgba(243, 142, 59, 0.918)",
          "rgb(81, 81, 253)",
          "#1ba08a",
          "rgb(245, 118, 118)",
          "rgb(255, 192, 203)",
        ],
        hoverBackgroundColor: [
          "rgb(166, 238, 238)",
          "rgb(238, 214, 168)",
          "rgb(153, 153, 248)",
          "rgba(243, 142, 59, 0.918)",
          "rgb(81, 81, 253)",
          "#1ba08a",
          "rgb(245, 118, 118)",
          "rgb(255, 192, 203)",
        ],
        borderWidth: 1,
      },
    ],
  };
  const options: any = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "right",
        labels: {
          usePointStyle: true,
        },
      },
    },
  };

  const headers = [
    { label: "Employee Id", key: "employeeId" },
    { label: "Employee Name", key: "candidateName" },
    { label: "Employee Email", key: "emailId" },
    { label: "Details Status", key: "status" },
    { label: "Status Change Date", key: "statusChangeDate" },
    { label: "Number of Days", key: "numberOfDays" },
  ];

  let reportdataLT3D: any = [];
  let reportdataGT3D: any = [];

  infoLT3D?.forEach(
    (item: {
      candidateName: any;
      middleName: any;
      lastname: any;
      employeeId: any;
      emailId: any;
      status: any;
      statusChangeDate: any;
      numberOfDays: any;
      Employee: any;
    }) => {
      reportdataLT3D.push({
        employeeId: item?.Employee[0]?.employeeId,
        candidateName: `${item?.Employee[0]?.candidateName} ${item?.Employee[0]?.middleName} ${item?.Employee[0]?.lastname}`,
        emailId: item?.Employee[0]?.emailId,
        status: item.status,
        statusChangeDate: moment(item?.statusChangeDate).format("DD-MMM-YYYY"),
        numberOfDays: item?.numberOfDays,
      });
    }
  );

  infoGT3D?.forEach(
    (item: {
      candidateName: any;
      middleName: any;
      lastname: any;
      employeeId: any;
      emailId: any;
      status: any;
      statusChangeDate: any;
      numberOfDays: any;
      Employee: any;
    }) => {
      reportdataGT3D.push({
        employeeId: item?.Employee[0]?.employeeId,
        candidateName: `${item?.Employee[0]?.candidateName} ${item?.Employee[0]?.middleName} ${item?.Employee[0]?.lastname}`,
        emailId: item?.Employee[0]?.emailId,
        status: item.status,
        statusChangeDate: moment(item?.statusChangeDate).format("DD-MMM-YYYY"),
        numberOfDays: item?.numberOfDays,
      });
    }
  );

  return (
    <>
      {loadingComponent()}
      <MainCard>
        <div className="heading-button-wrapper">
          <div>
            <h2>{Reports}</h2>
          </div>
          <div className="btn-wrapper">
            <CSVLink
              data={
                selectOption === "Less than 3 days"
                  ? reportdataLT3D
                  : reportdataGT3D
              }
              headers={headers}
              filename={
                selectOption === "Less than 3 days"
                  ? "Employees_report_LT3D.csv"
                  : "Employees_report_GT3D.csv"
              }
              style={{ textDecoration: "none", color: "#fff" }}
            >
              <Button
                icon={upload_white_icon}
                title={exportReport}
                className="add-employee-btn"
              />
            </CSVLink>
          </div>
        </div>
        <div className="report-chart-container">
          <div className="d-flex justify-content-between">
            <h5>{employee_record_status_details}</h5>
            <div>
              <select onChange={(e: any) => setSelectOption(e.target.value)}>
                <option>{Less}</option>
                <option>{Greater}</option>
              </select>
            </div>
          </div>
          <div className="d-flex">
            {selectOption === "Less than 3 days" && (
              <div className="chart-card p-3 m-2">
                <div className="card-body">
                  <div className="chart-container">
                    <Doughnut data={dataLT3D} options={options} />
                  </div>
                </div>
              </div>
            )}

            {selectOption === "Greater than 3 days" && (
              <div className="chart-card p-3 m-2">
                <div className="card-body">
                  <div className="chart-container">
                    <Doughnut data={dataGT3D} options={options} />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </MainCard>
    </>
  );
};

export default EmployeeReports;
