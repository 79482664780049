import  { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../Redux/Actions";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../component/Toasts/Toasts";
import Select from "react-select";
import "../../../App.scss";
import {
  poNumberValidation,
  roleDescriptionValidation,
  utilizationValidation,
} from "../../../constant/Validations";
import {
  BillingRateDropdown,
  Strings,
  currencyTypeDropdown,
  projectDetailsLabels,
} from "../../../constant/Strings";
import { timeDateFormatYMD } from "../../../component/FormatDate";

const AssignProject = (props: any) => {
  const {
    apiCallCompleted,
    employeeRef,
    resourceName,
    managerName,
    hideAddResource
  } = props;
  const [btnDisable, setBtnDisable] = useState(false);
  const [projectOptions, setProjectOptions] = useState([]);
  const [resourceData, setResourceData] = useState({
    employeeRef: employeeRef,
    clientRef: "",
    projectRef: "",
    managerRef: "",
    resourceName: resourceName,
    projectName: "",
    reportingManager: "",
    roleDescription: "",
    utilization: "",
    remarks: "",
    noOfDays: "",
    startDate: "",
    endDate: "",
    poStartDate: "",
    poEndDate: "",
    poNumber: "",
    currencyType: "",
    billingRate: "",
    poAmount: "",
  });
  const [employeeInfo, setEmployeeInfo] = useState([]);
  const dispatch = useDispatch();
  const [managerData, setManagerData] = useState("");
  let valid: boolean = true;
  const validate = () => {
    if (roleDescriptionValidation(resourceData.roleDescription) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        roleDescription: "Please enter valid role description",
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        roleDescription: "",
      }));
    }
    if (resourceData.utilization) {
      if (utilizationValidation(resourceData.utilization) == false) {
        valid = false;
        setErrors((errorObj) => ({
          ...errorObj,
          utilization: "Please enter valid utilization",
        }));
        setBtnDisable(false);
      } else {
        setErrors((errorObj) => ({
          ...errorObj,
          utilization: "",
        }));
      }
    }
   
    if (resourceData.poNumber) {
      if (poNumberValidation(resourceData.poNumber) == false) {
        valid = false;
        setErrors((errorObj) => ({
          ...errorObj,
          poNumber: "Please enter valid poNumber",
        }));
        setBtnDisable(false);
      } else {
        setErrors((errorObj) => ({
          ...errorObj,
          poNumber: "",
        }));
      }
    }
  };
  const handleResourceSubmit = () => {
    validate();
    if (valid) {
      handleDispatch();
    }
  };
  const handleDispatch = () => {
    apiCallCompleted(false);
    const userLogin: any = localStorage.getItem("login");
    const loginId = JSON.parse(userLogin)?.id;
    const payload ={
      ...resourceData,
      createdBy:loginId
    }
    dispatch(
      actions.addResourceAllocationAction(payload, (res: any) => {
        if (res?.error?.error_description) {
              showErrorToastMessage(res.error.error_description);
              apiCallCompleted(true);
            } else  if (res.code === 200) {
              showSuccessToastMessage("Assigned Successfully!");
              apiCallCompleted(true);
            }
      })
    );

    hideAddResource();
  };
  const {
    start_date,
    estimated_end_date,
    reporting_manager,
    role_desc,
    utilization,
    cancel,
    submit,
    select_new_client,
    select_new_project,
    employee_name,
  } = Strings;
  const {
    purchaseOrderNoLabel,
    poStartDateLabel,
    poEndDateLabel,
    currencyTypeLabel,
    billingRateLabel,
    billingStatusLabel,
    remarksLabel,
  } = projectDetailsLabels;

  const [dataOption, setDataOption] = useState<any>([]);
 
  useEffect(() => {
    dispatch(
      actions.clientNameListAction((res: any) => {
        if (res.code == 200 && res.data) {
          const options: any = res.data.map((ele: any) => ({
            value:  ele._id,
            label: ele.clientName,
          }));
          setDataOption(options);
        }
      })
    );
  
  }, []);

  const [projectDataByClient, setProjectDataByClient] = useState<any>([]);

  const employeeData = useSelector(
    (state: any) => state.employeeReducer.getEmployeeData
  );

  useEffect(() => {
    
    if (employeeData && employeeData.data) {
      setEmployeeInfo(employeeData.data);
    }
  }, [employeeData, employeeInfo]);

  const [errors, setErrors] = useState({
    roleDescription: "",
    utilization: "",
    poNumber: "",
    poAmount: "",
  });

  useEffect(() => {
    if (
      resourceData.projectName &&
      resourceData.roleDescription &&
      resourceData.startDate &&
      managerData || managerName
    ) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  });

  


  useEffect(() => {
    const array = [...projectDataByClient];
    const projectOptionsOld: any = array.map((ele: any) => ({
      value: ele.projectName + "/" + ele._id,
      label: ele.projectName + "-" + ele.subProjectCode,
    }));
    setProjectOptions(projectOptionsOld);
  }, [projectDataByClient]);

  const handleChange = (selected: any) => {
    const filteredEmployeeInfo: any = employeeInfo?.filter((ele: any) => {
      return ele?._id == selected.value.split("/")[1];
    });
    setManagerData(
      filteredEmployeeInfo?.[0]?.managerData?.length ?  filteredEmployeeInfo[0]?.managerData[0]?.candidateName +
        " " +
        filteredEmployeeInfo[0]?.managerData[0]?.middleName +
        " " +
        filteredEmployeeInfo[0]?.managerData[0]?.lastname :"-"
    );
    setResourceData({
      ...resourceData,
      clientRef: selected.value,
    });
    setProjectOptions([]);
    dispatch(
      actions.getProjectByClientAction(selected.value, (res: any) => {
        if (res.code == 200 && res.data) {
          setProjectDataByClient(res.data);
        }
      })
    );
  };

  const projectHandleChange = (selected: any) => {
    setResourceData({
      ...resourceData,
      projectRef: selected.value.split("/")[1],
      projectName: selected.value.split("/")[0],
    });
  };

 
  return (
    <div className="row g-3">
      <div className="col-md-4 mt-3">
        <label htmlFor="inputName4" className="form-label">
          {employee_name}
        </label>
        <input
          disabled
          type="text"
          className="form-control"
          value={resourceName}
        />
      </div>
    
      <div className="col-md-4">
        <label htmlFor="inputName4" className="form-label">
          {select_new_client}
        </label>
        <span className="required">*</span>
        <Select
          className="dropdown-select"
          options={dataOption}
          isSearchable={true}
          onChange={handleChange}
        />
      </div>
      <div className="col-md-4">
        <label htmlFor="inputName4" className="form-label">
          {select_new_project}
        </label>
        <span className="required">*</span>
        <div>
          <Select
            key={JSON.stringify(projectOptions)}
            className="dropdown-select"
            options={projectOptions}
            isSearchable={true}
            onChange={projectHandleChange}
          />
        </div>
      </div>
      <div className="col-md-4">
        <label htmlFor="inputName4" className="form-label">
          {start_date}
        </label>
        <span className="required">*</span>
        <input
          type="date"
          className="form-control"
          onChange={(e) =>
            setResourceData({
              ...resourceData,
              startDate: e.target.value,
            })
          }
        />
      </div>
      <div className="col-md-4">
        <label htmlFor="inputName4" className="form-label">
          {estimated_end_date}
        </label>
        <input
          min={timeDateFormatYMD(resourceData.startDate)}
          type="date"
          className="form-control"
          onChange={(e) =>
            setResourceData({
              ...resourceData,
              endDate: e.target.value,
            })
          }
        />
      </div>
      <div className="col-md-4 mt-3">
        <label htmlFor="inputName4" className="form-label">
          {reporting_manager}
        </label>
        <span className="required">*</span>
        <div className="height-one-rem">
          <input
            type="text"
            className="form-control"
            disabled={true}
            value={managerData && managerData != "-" ? managerData : managerName ? managerName : ""}
          />
        </div>
      </div>
      <div className="col-md-4 mt-3">
        <label htmlFor="inputName4" className="form-label">
          {role_desc}
        </label>
        <span className="required">*</span>
        <input
          type="text"
          className="form-control"
          onChange={(e) =>
            setResourceData({
              ...resourceData,
              roleDescription: e.target.value,
            })
          }
        />
        <p className="error">{errors.roleDescription}</p>
      </div>
      <div className="col-md-4 mt-3">
        <label htmlFor="inputName4" className="form-label">
          {utilization}
        </label>

        <input
          maxLength={4}
          type="text"
          className="form-control"
          value={resourceData.utilization}
          onChange={(e) =>
            setResourceData({
              ...resourceData,
              utilization: e.target.value.replace(/\D/g, ""),
            })
          }
        />
        <p className="error">{errors.utilization}</p>
      </div>
      <div className="col-md-4">
        <label htmlFor="inputName4" className="form-label">
          {purchaseOrderNoLabel}
        </label>

        <input
          type="text"
          className="form-control"
          onChange={(e) =>
            setResourceData({
              ...resourceData,
              poNumber: e.target.value,
            })
          }
        />
        <p className="error">{errors.poNumber}</p>
      </div>
      <div className="col-md-4">
        <label htmlFor="inputName4" className="form-label">
          {poStartDateLabel}
        </label>

        <input
          type="date"
          className="form-control"
          onChange={(e) =>
            setResourceData({
              ...resourceData,
              poStartDate: e.target.value,
            })
          }
        />
      </div>
      <div className="col-md-4">
        <label htmlFor="inputName4" className="form-label">
          {poEndDateLabel}
        </label>

        <input
          type="date"
          className="form-control"
          onChange={(e) =>
            setResourceData({
              ...resourceData,
              poEndDate: e.target.value,
            })
          }
        />
      </div>
      <div className="col-md-4">
        <label htmlFor="inputName4" className="form-label">
          {currencyTypeLabel}
        </label>
        <Dropdown
          onSelect={(eventKey: any) =>
            setResourceData({
              ...resourceData,
              currencyType: eventKey,
            })
          }
        >
          <Dropdown.Toggle variant="light" className="form-control">
            {resourceData.currencyType}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {currencyTypeDropdown.map((value: any) => (
              <Dropdown.Item key={value} eventKey={value}>
                {value}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>

      <div className="col-md-4">
        <label htmlFor="inputName4" className="form-label">
          {billingStatusLabel}
        </label>

        <Dropdown
          onSelect={(eventKey: any) =>
            setResourceData({
              ...resourceData,
              billingRate: eventKey,
            })
          }
        >
          <Dropdown.Toggle variant="light" className="form-control">
            {resourceData.billingRate}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {BillingRateDropdown.map((value: any) => (
              <Dropdown.Item key={value} eventKey={value}>
                {value}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="col-md-4">
        <label htmlFor="inputName4" className="form-label">
          {billingRateLabel}
        </label>
        <input
          type="text"
          className="form-control"
          value={resourceData.poAmount}
          onChange={(e) =>
            setResourceData({
              ...resourceData,
              poAmount: e.target.value,
            })
          }
        />
        <p className="error">
          {resourceData.poAmount.length !== 0 && errors.poAmount}
        </p>
      </div>
      <div className="col-md-4 mt-3">
        <label htmlFor="inputName4" className="form-label">
          {remarksLabel}
        </label>
        <input
          type="text"
          className="form-control"
          onChange={(e) =>
            setResourceData({
              ...resourceData,
              remarks: e.target.value,
            })
          }
        />
      </div>
      <div className="modal-btn-wrapper mt-5 mx-auto">
        <div>
          <button
            className="btn cancel-btn buttonsize"
            onClick={hideAddResource}
          >
            {cancel}
          </button>
        </div>
        <div>
          <button
            className={
              btnDisable
                ? "btn add-submit-button buttonsize"
                : "btn add-submit-button btn-disabled buttonsize"
            }
            onClick={handleResourceSubmit}
          >
            {submit}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AssignProject;
